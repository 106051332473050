import React, { Fragment } from "react";
import { format } from "d3-format";
import { Chip, Grid, Typography, Tooltip } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export function chipsList(chips, classes) {
  if (!!chips) {
    return chips.map(function(chip) {
      return <Chip key={chip} className={classes.chip} label={chip} />;
    });
  }
}

export function chipsListToolTip(chips, classes, names) {
  if (!!chips) {
    return chips.map(function(chip) {
      return (
        <Tooltip
          title={
            <React.Fragment>
              <b>Name : </b>
              {` ${
                names[chip]["name"] ? names[chip]["name"] : names[chip]
              }`}{" "}
              <br />
              <b>{names[chip]["cc_name"] && "CC name : "}</b>
              {names[chip]["cc_name"] && `${names[chip]["cc_name"]}`}
            </React.Fragment>
          }
        >
          <Chip key={chip} className={classes.chip} label={chip} />
        </Tooltip>
      );
    });
  }
}

export function getCompareContent(task, classes) {
  return (
    <Grid container>
      {["skip", "new", "update", "delete"].map(x => {
        return (
          <Fragment key={`compare_${x}`}>
            <Grid item xs={2}>
              <Typography variant="caption">{x} data:</Typography>
            </Grid>
            <Grid className={classes.countGrid} item xs={1}>
              <Typography variant="caption" align="right">
                {format("d")(task.summary[x + "_count"])}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="caption">in periods:</Typography>
            </Grid>
            <Grid className={classes.countGrid} item xs={7}>
              <Typography variant="caption" align="right">
                {chipsList(task.summary[x + "_periods"], classes)}
              </Typography>
            </Grid>
          </Fragment>
        );
      })}
      {getDeselect(task, classes)}
    </Grid>
  );
}

function getDeselect(task, classes) {
  if (!task.summary["deselected_count"]) return null;

  return (
    <Fragment key={`compare_deselected`}>
      <Grid item xs={2}>
        <Tooltip
          id="tooltip-fab"
          title={`Data related to deselected entities`}
          placement="bottom"
        >
          <Typography variant="caption">deselected data:</Typography>
        </Tooltip>
      </Grid>
      <Grid className={classes.countGrid} item xs={1}>
        <Typography variant="caption" align="right">
          {format("d")(task.summary["deselected_count"])}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption">in periods:</Typography>
      </Grid>
      <Grid className={classes.countGrid} item xs={7}>
        <Typography variant="caption" align="right">
          {chipsList(task.summary["deselected_periods"], classes)}
        </Typography>
      </Grid>
      {deselectedEntities(
        task,
        "deselected_des",
        "unmatched_des",
        "deselected des:",
        classes,
      )}
      {deselectedEntities(
        task,
        "deselected_ous",
        "unmatched_ous",
        "deselected ous:",
        classes,
      )}
      {deselectedEntities(
        task,
        "deselected_cocs",
        "unmatched_cocs",
        "deselected cocs:",
        classes,
      )}
    </Fragment>
  );
}

function deselectedEntities(task, key, unmatched_key, label, classes) {
  const hasKey = (task.summary[key] || []).length > 0;
  const hasUnmatchedKey = (task.summary[unmatched_key] || []).length > 0;

  if (!hasKey && !hasUnmatchedKey) {
    return null;
  }

  // TODO: This seems to be missing
  const tooltipText = {};
  return (
    <Fragment>
      <Grid item xs={2}>
        <Tooltip
          id="tooltip-fab"
          title={`Those ${tooltipText.entity} are in the ${tooltipText.group} source but not on the one in the target`}
          placement="bottom"
        >
          <Typography variant="caption">{label}</Typography>
        </Tooltip>
      </Grid>
      <Grid className={classes.countGrid} item xs={10}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {hasKey && (
              <TableRow>
                <TableCell align="left">
                  <b>Deselected : </b>
                </TableCell>
                <TableCell align="left">
                  {task.summary[key + "_name"]
                    ? chipsListToolTip(
                        task.summary[key],
                        classes,
                        task.summary[key + "_name"],
                      )
                    : chipsList(task.summary[key], classes)}
                </TableCell>
              </TableRow>
            )}
            {hasUnmatchedKey && (
              <TableRow>
                <TableCell align="left">
                  <b>Unmatched : </b>
                </TableCell>
                <TableCell align="left">
                  {task.summary[unmatched_key + "_name"]
                    ? chipsListToolTip(
                        task.summary[unmatched_key],
                        classes,
                        task.summary[unmatched_key + "_name"],
                      )
                    : chipsList(task.summary[unmatched_key], classes)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </Fragment>
  );
}

export function getPushContent(task, classes) {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography variant="caption">Pages:</Typography>
      </Grid>
      <Grid className={classes.countGrid} item xs={10}>
        <Typography variant="caption" align="right">
          {task.summary.push_pages_processed}/{task.summary.push_pages}
        </Typography>
      </Grid>
      {["created", "updated", "deleted", "errors"].map(x => (
        <Fragment key={`push_${x}`}>
          <Grid item xs={2}>
            <Typography variant="caption">{x} data:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={1}>
            <Typography variant="caption" align="right">
              {format("d")(task.summary["push_" + x + "_count"] || 0)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">in periods:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={7}>
            <Typography variant="caption">
              {chipsList(task.summary["push_" + x + "_periods"], classes)}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}

function getFetchContentSide(task, side, classes) {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="caption">{side} pages processed:</Typography>
      </Grid>
      <Grid className={classes.countGrid} item xs={8}>
        <Typography variant="caption" align="right">
          {format("d")(
            task.summary["fetch_" + side + "_pages_processed"] ||
              task["fetch_" + side + "_pages_processed"],
          )}
          /{format("d")((task.summary["fetch_pages_count"] || 0) / 2)}
          {!task.summary["fetch_" + side + "_pages_errors"]
            ? ""
            : ` pages in error: ${format("d")(
                task.summary["fetch_" + side + "_pages_errors"],
              )}`}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">{side} data fetched:</Typography>
      </Grid>
      <Grid className={classes.countGrid} item xs={8}>
        <Typography variant="caption" align="right">
          {task.summary["fetch_" + side + "_count"] &&
            format("d")(task.summary["fetch_" + side + "_count"])}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">{side} periods with data:</Typography>
      </Grid>
      <Grid className={classes.countGrid} item xs={8}>
        <Typography variant="caption" align="right">
          {chipsList(task.summary["fetch_" + side + "_periods"], classes)}
        </Typography>
      </Grid>
    </Grid>
  );
}

export function getFetchContent(task, sides, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      <Grid container>
        {sides.map(side => (
          <Grid item xs={12 / sides.length}>
            {getFetchContentSide(task, side, classes)}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

function s3FileChips(task, classes) {
  let chips = [];
  if (task.summary["file_details"]) {
    chips = task.summary["file_details"].map(({ name, link }) => {
      // Forcing the download here, because Chip link's don't seem to
      // work with external resources.
      return (
        <Chip
          key={name}
          icon={<CloudDownloadIcon />}
          className={classes.name}
          label={name}
          href={link}
          onClick={() => (window.location = link)}
          clickable
        />
      );
    });
  } else {
    chips = chipsList(task.summary["files"], classes);
  }
  return chips;
}

export function getDumpContent(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      <Grid container>
        <Grid item xs={2}>
          <Typography variant="caption">Bucket:</Typography>
        </Grid>
        <Grid className={classes.countGrid} item xs={4}>
          <Typography variant="caption" align="right">
            {task.summary["bucket"]}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption">Files written:</Typography>
        </Grid>
        <Grid className={classes.countGrid} item xs={4}>
          <Typography variant="caption" align="right">
            {s3FileChips(task, classes)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption">Unmatched COCs:</Typography>
        </Grid>
        <Grid className={classes.countGrid} item xs={4}>
          <Typography variant="caption" align="right">
            {chipsList(task.summary["missing_cocs"], classes)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption">Records written:</Typography>
        </Grid>
        <Grid className={classes.countGrid} item xs={4}>
          <Typography variant="caption" align="right">
            {format("d")(task.summary["records_count"])}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

function getFetchMetaContentSide(task, side, classes) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{side} entities fetched:</Typography>
      </Grid>
      {!!task.summary["fetch_" + side + "_dhis_entities"] && (
        <Fragment>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption" align="right">
              DHIS2:
            </Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption">
              {format("d")(task.summary["fetch_" + side + "_dhis_entities"])}
            </Typography>
          </Grid>
        </Fragment>
      )}
      {!!task.summary["fetch_" + side + "_d2d_entities"] && (
        <Fragment>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption" align="right">
              D2D:
            </Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption">
              {format("d")(task.summary["fetch_" + side + "_d2d_entities"])}
            </Typography>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
}

function getFetchMatchingMetaContentSide(task, side, classes) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {side} entities fetched from {task.params["project_name"]}:
        </Typography>
      </Grid>
      {!!task.summary["fetch_from_" + side + "_dhis_entities"] && (
        <Fragment>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption" align="right">
              DHIS2:
            </Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption">
              {format("d")(task.summary["fetch_" + side + "_dhis_entities"])}
            </Typography>
          </Grid>
        </Fragment>
      )}
      {!!task.summary["fetch_from_" + side + "_d2d_entities"] && (
        <Fragment>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption" align="right">
              D2D:
            </Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption">
              {format("d")(
                task.summary["fetch_from_" + side + "_d2d_entities"],
              )}
            </Typography>
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {side} entities fetched current project:
        </Typography>
      </Grid>
      {!!task.summary["fetch_" + side + "_dhis_entities"] && (
        <Fragment>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption" align="right">
              DHIS2:
            </Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption">
              {format("d")(task.summary["fetch_" + side + "_dhis_entities"])}
            </Typography>
          </Grid>
        </Fragment>
      )}
      {!!task.summary["fetch_" + side + "_d2d_entities"] && (
        <Fragment>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption" align="right">
              D2D:
            </Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={6}>
            <Typography variant="caption">
              {format("d")(task.summary["fetch_" + side + "_d2d_entities"])}
            </Typography>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
}

export function getFetchMetaContent(task, sides, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      <Grid container>
        {sides.map((side, index) => (
          <Grid key={index} item xs={12 / sides.length}>
            {getFetchMetaContentSide(task, side, classes)}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export function getFetchMatchingMetaContent(task, sides, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      <Grid container>
        {sides.map((side, index) => (
          <Grid key={index} item xs={12 / sides.length}>
            {getFetchMatchingMetaContentSide(task, side, classes)}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export function getCompareMetaContent(task, classes) {
  return (
    <Grid container>
      {[
        { label: "Source DHIS vs. Target DHIS", key: "left_dhis-right_dhis" },
        { label: "Source DHIS vs. Source D2D", key: "left_dhis-left_d2d" },
        { label: "Source D2D vs. Target D2D", key: "left_d2d-right_d2d" },
        { label: "Target D2D vs. Target DHIS", key: "right_d2d-right_dhis" },
      ].map(level => getCompareMeta(task, level))}
    </Grid>
  );
}

export function getCompareDsMetaContent(task, classes, meta) {
  return (
    <Grid container>
      {[
        { label: "Source DHIS vs. Target DHIS", key: "left_dhis-right_dhis" },
        { label: "Source DHIS vs. Source D2D", key: "left_dhis-left_d2d" },
        { label: "Source D2D vs. Target D2D", key: "left_d2d-right_d2d" },
        { label: "Target D2D vs. Target DHIS", key: "right_d2d-right_dhis" },
      ].map(level => getCompareDsMeta(task, level, meta))}
    </Grid>
  );
}

export function getCompareCcMetaContent(task, classes, meta) {
  return (
    <Grid container>
      {[
        { label: "Source DHIS vs. Target DHIS", key: "left_dhis-right_dhis" },
        { label: "Source DHIS vs. Source D2D", key: "left_dhis-left_d2d" },
        { label: "Source D2D vs. Target D2D", key: "left_d2d-right_d2d" },
        { label: "Target D2D vs. Target DHIS", key: "right_d2d-right_dhis" },
      ].map(level => getCompareCcMeta(task, level, meta))}
    </Grid>
  );
}

export function getApplyMetaContent(task, classes) {
  return <Grid container>{getApplyMeta(task)}</Grid>;
}

export function getApplyDsMetaContent(task, classes) {
  return <Grid container>{getApplyDsMeta(task)}</Grid>;
}

export function getApplyCcMetaContent(task, classes) {
  return <Grid container>{getApplyCcMeta(task)}</Grid>;
}

function getCompareMeta(task, level) {
  let key = level["key"];
  let label = level["label"];
  if (!task.summary[key]) return null;
  let counts = task.summary[key];
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Modified group attributes:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_attrs_count"]}
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption">New entites left:</Typography>
      </Grid>
      <Grid item xs={4}>
        {counts["news_left_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Not linked entites right:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["existing_new_right_count"]}
      </Grid>
      <Grid item xs={2}>
        <Typography variant="caption">New entities right:</Typography>
      </Grid>
      <Grid item xs={4}>
        {counts["news_right_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Modified entities:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_count"]}
      </Grid>
    </Fragment>
  );
}

function getCompareCcMeta(task, level, meta) {
  let key = level["key"];
  let label = level["label"];
  if (!task.summary[key]) return null;
  let counts = task.summary[key];
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Modified group attributes:</Typography>
      </Grid>
      <Grid item xs={8}>
        {counts["modified_attrs_count"]}
      </Grid>
      {Object.keys(meta).map(key => {
        return meta_cc_changes(
          `New entities ${meta[key]} left:`,
          counts[`new_${key}_left_count`],
        );
      })}
      {Object.keys(meta).map(key => {
        return meta_cc_changes(
          `New entities ${meta[key]} right:`,
          counts[`new_${key}_right_count`],
        );
      })}
      {Object.keys(meta).map(key => {
        return meta_cc_changes(
          `Modified entities ${meta[key]}:`,
          counts[`modified_${key}_count`],
        );
      })}
    </Fragment>
  );
}

function getCompareDsMeta(task, level, meta) {
  let key = level["key"];
  let label = level["label"];
  if (!task.summary[key]) return null;
  let counts = task.summary[key];
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="caption">Modified group attributes:</Typography>
      </Grid>
      <Grid item xs={9}>
        {counts["modified_attrs_count"]}
      </Grid>
      {Object.keys(meta).map(key => {
        return (
          key !== "first" &&
          key !== "second" &&
          meta_changes(
            `New entities ${meta[key]} left:`,
            counts[`new_${key}_left_count`],
          )
        );
      })}

      {Object.keys(meta).map(key => {
        return (
          (key === "first" || key === "second") &&
          meta_changes(
            `Linked entities ${meta[key]} on DS target:`,
            counts[`existing_new_${key}_right_count`],
          )
        );
      })}

      {Object.keys(meta).map(key => {
        return (
          key !== "first" &&
          key !== "second" &&
          meta_changes(
            `New entities ${meta[key]} right:`,
            counts[`new_${key}_right_count`],
          )
        );
      })}
      {Object.keys(meta).map(key => {
        return (
          key !== "first" &&
          key !== "second" &&
          meta_changes(
            `Modified entities ${meta[key]}:`,
            counts[`modified_${key}_count`],
          )
        );
      })}
    </Fragment>
  );
}

function meta_changes(label, value) {
  return (
    <Fragment>
      <Grid item xs={3}>
        <Typography variant="caption">{label}</Typography>
      </Grid>
      <Grid item xs={2}>
        {value}
      </Grid>
    </Fragment>
  );
}

function meta_cc_changes(label, value) {
  return (
    <Fragment>
      <Grid item xs={4}>
        <Typography variant="caption">{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        {value}
      </Grid>
    </Fragment>
  );
}

function getApplyMeta(task) {
  let key = "applied_changes";
  if (!task.summary[key]) return null;
  let counts = task.summary[key];
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Applied changes on DHIS2 Target
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Changed group attributes:</Typography>
      </Grid>
      <Grid item xs={8}>
        {counts["modified_group_attributes_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Added new entities:</Typography>
      </Grid>
      <Grid item xs={8}>
        {counts["new_added_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Removed entities:</Typography>
      </Grid>
      <Grid item xs={8}>
        {counts["removed_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Modified entities:</Typography>
      </Grid>
      <Grid item xs={8}>
        {counts["modified_entities_count"]}
      </Grid>
    </Fragment>
  );
}

function getApplyCcMeta(task) {
  let key = "applied_changes";
  if (!task.summary[key]) return null;
  let counts = task.summary[key];
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Applied changes on DHIS2 Target
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Changed group attributes:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_group_attributes_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Added new categories:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["new_cat_added_entities_count"]}
      </Grid>

      <Grid item xs={4}>
        <Typography variant="caption">Removed categories:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["removed_cat_entities_count"]}
      </Grid>

      <Grid item xs={4}>
        <Typography variant="caption">Modified categories:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_cat_entities_count"]}
      </Grid>

      <Grid item xs={4}>
        <Typography variant="caption">Skipped categories:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["skipped_cat_entities_count"]}
      </Grid>
    </Fragment>
  );
}

function getApplyDsMeta(task) {
  let key = "applied_changes";
  if (!task.summary[key]) return null;
  let counts = task.summary[key];
  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Applied changes on DHIS2 Target
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Changed group attributes:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_group_attributes_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Hierarchy ous:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["hierarchy_ou_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Added new des:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["new_de_added_entities_count"]}
      </Grid>

      <Grid item xs={4}>
        <Typography variant="caption">Added new ous:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["new_ou_added_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Removed des:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["removed_de_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Removed ous:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["removed_ou_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Modified des:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_de_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Modified ous:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_ou_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Skipped des:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["skipped_de_entities_count"]}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="caption">Skipped ous:</Typography>
      </Grid>
      <Grid item xs={2}>
        {counts["modified_ou_entities_count"]}
      </Grid>
    </Fragment>
  );
}

export function getImportMeta(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      {task.summary["imported_task"] && (
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="caption">New Group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["imported_task"]["imported_new_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">New Entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["imported_task"]["imported_new_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Modified group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["imported_task"]["imported_modified_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Modified entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {
                task.summary["imported_task"][
                  "imported_modified_entities_count"
                ]
              }
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Modified Group attrs:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {
                task.summary["imported_task"][
                  "imported_modified_attrs_group_count"
                ]
              }
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">deleted entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["imported_task"]["imported_deleted_entities_count"]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export function getCloneCcMeta(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      {task.summary["cloned_meta"] && (
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="caption">Cloned Group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["new_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Cloned categories:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["new_cat_added_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Skipped categories:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["skipped_cat_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Totals entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["total_entities"]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export function getCloneDsMeta(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      {task.summary["cloned_meta"] && (
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="caption">Cloned Group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["new_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Cloned Des:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["new_de_added_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Cloned Ous:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["new_ou_added_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Skipped Des:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["skipped_de_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Skipped Ous:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["skipped_ou_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Hierarchy entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["hierarchy_ou_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Totals entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["total_entities"]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export function getCloneMeta(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      {task.summary["cloned_meta"] && (
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="caption">Cloned Group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["new_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Cloned Entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["new_added_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Skipped entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["skipped_entities_count"]}
            </Typography>
          </Grid>
          {task.summary["cloned_meta"]["hierarchy_entities_count"] && (
            <Fragment>
              <Grid item xs={2}>
                <Typography variant="caption">Hierarchy entities:</Typography>
              </Grid>
              <Grid className={classes.countGrid} item xs={4}>
                <Typography variant="caption" align="right">
                  {task.summary["cloned_meta"]["hierarchy_entities_count"]}
                </Typography>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={2}>
            <Typography variant="caption">Totals entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["cloned_meta"]["total_entities"]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export function getMatchCloneMeta(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      {task.summary["match_meta"] && (
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="caption">Matched Group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["match_meta"]["matched_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Matched Entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["match_meta"]["matched_entities_count"]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export function getImportMatchingMeta(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      {task.summary["imported_task"] && (
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="caption">Imported Group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["imported_task"]["imported_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Imported Entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["imported_task"]["imported_entities_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Group Matching:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["imported_task"]["imported_group_matching_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Entities Matching:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {
                task.summary["imported_task"][
                  "imported_entities_matching_count"
                ]
              }
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export function getMatchMeta(task, classes) {
  return (
    <div style={{ paddingRight: 20 }}>
      {task.summary["match_task"] && (
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="caption">Matched Group:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["match_task"]["matched_group_count"]}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="caption">Skipped Group:</Typography>
          </Grid>

          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["match_task"]["skipped_group_count"]}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="caption">Matched Entities:</Typography>
          </Grid>
          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["match_task"]["matched_entities_count"]}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="caption">Left skipped entities:</Typography>
          </Grid>

          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["match_task"]["left_skipped_entities_count"]}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography variant="caption">Right skipped entities:</Typography>
          </Grid>

          <Grid className={classes.countGrid} item xs={4}>
            <Typography variant="caption" align="right">
              {task.summary["match_task"]["right_skipped_entities_count"]}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

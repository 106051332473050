import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { periodTypeAbbreviation } from "../Shared/PeriodTypesSelect";
import _castArray from "lodash/castArray";

export const disaplayPeriodTypes = periodTypes => {
  return periodTypes.map(periodTypeAbbreviation);
};

const useStyles = makeStyles({
  itemLabel: {
    fontWeight: 500,
  },
});

const renderItem = (item, key) => {
  const classes = useStyles();
  let blocks = [
    <Grid item xs={6} key={key}>
      <Typography
        component="h2"
        variant="caption"
        className={classes.itemLabel}
      >
        {item.label}
      </Typography>
    </Grid>,
  ];

  const values = _castArray(item.value).map((value, i) => {
    return (
      <Typography key={i} component="p" variant="caption">
        {value}
      </Typography>
    );
  });
  blocks = blocks.concat(
    <Grid item xs={6} align="right">
      {values}
    </Grid>,
  );
  return blocks;
};

function TaskHashCell(props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
    >
      {props.task.map((items, t) => {
        return items.map((item, i) => {
          return (
            <Grid
              container
              item
              key={i}
              xs={6}
              spacing={2}
              justifyContent="space-between"
              alignItems="stretch"
            >
              {renderItem(item, i)}
            </Grid>
          );
        });
      })}
    </Grid>
  );
}

export default TaskHashCell;

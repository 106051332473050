import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Grid,
  Chip,
} from "@material-ui/core";

import Edit from "@material-ui/icons/Edit";
import ArrowForward from "@material-ui/icons/ArrowForward";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import fadeIn from "react-animations/lib/fadeIn";
import PreviewSource from "../Shared/PreviewSource";
import SharedDeleter from "../Shared/SharedDeleter";
import { fetchDataElementGroupsMatchings } from "../../actions/dataElementGroupsMatchings";

const styles = theme => ({
  "@keyframes fadeIn": fadeIn,
  root: {
    animationName: "fadeIn",
    animationDuration: "0.5s",
  },
  arrowColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardActions: {
    borderTop: "1px solid #efefef",
  },
});

class DataElementGroupsMatchingPreview extends Component {
  render() {
    const {
      classes,
      projectId,
      currentProgram,
      dataElementGroupsMatching: {
        id,
        display_name,
        dependent_task_count,
        left,
        right,
      },
      match,
    } = this.props;
    return (
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <PreviewSource label={left.source.name} />
            </Grid>
            <Grid item xs={2} className={classes.arrowColumn} />
            <Grid item xs={5}>
              <PreviewSource label={right.source.name} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              {left.name}
              <br />
              <Typography variant="caption">{left.dhis_id}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.arrowColumn}>
              <ArrowForward />
            </Grid>
            <Grid item xs={5}>
              {right.name}
              <br />
              <Typography variant="caption">{right.dhis_id}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Chip label={`${left.elements_count} data elements`} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <Chip label={`${right.elements_count} data elements`} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <IconButton component={Link} to={`${match.url}/${id}/edit`}>
            <Edit />
          </IconButton>
          <SharedDeleter
            api="dataElementsGroupsMatchings"
            callback={() =>
              this.props.fetchDataElementGroupsMatchings(
                this.props.currentProgram.id,
                projectId,
                true,
              )
            }
            projectId={projectId}
            programId={currentProgram.id}
            id={id}
            message={
              dependent_task_count > 0
                ? `The "${display_name}" matching is used in ${dependent_task_count} tasks. Are you sure you want to delete it ?`
                : `Are you sure you want to delete the "${display_name}" matching ?`
            }
          />
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  currentProgram: state.programs.currentProgram,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchDataElementGroupsMatchings }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(DataElementGroupsMatchingPreview)),
);

import moment from "moment";
import PushDataFlowSummary from "./Summaries/PushDataFlowSummary";
import DumpDataFlowSummary from "./Summaries/DumpDataFlowSummary";
import MatchMetaFlowSummary from "./Summaries/MatchMetaFlowSummary";
import CloneOugMetaFlowSummary from "./Summaries/CloneOugMetaFlowSummary";
import CloneDegMetaFlowSummary from "./Summaries/CloneDegMetaFlowSummary";
import CloneCatMetaFlowSummary from "./Summaries/CloneCatMetaFlowSummary";
import CloneDsMetaFlowSummary from "./Summaries/CloneDsMetaFlowSummary";
import CloneCcMetaFlowSummary from "./Summaries/CloneCcMetaFlowSummary";
import CompareMetaFlowSummary from "./Summaries/CompareMetaFlowSummary";
import SynchronizeMetaFlowSummary from "./Summaries/SynchronizeMetaFlowSummary";
import ImportMetaFlowSummary from "./Summaries/ImportMetaFlowSummary";
import ImportMatchingFlowSummary from "./Summaries/ImportMatchingFlowSummary";

import PushDataFlowParams from "./Params/PushDataFlowParams";
import DumpDataFlowParams from "./Params/DumpDataFlowParams";
import PushAnalyticFlowParams from "./Params/PushAnalyticFlowParams";
import MatchMetaParams from "./Params/MatchMetaParams";
import CloneMetaFlowParams from "./Params/CloneMetaFlowParams";
import CompareMetaFlowParams from "./Params/CompareMetaFlowParams";
import SynchronizeMetaFlowParams from "./Params/SynchronizeMetaFlowParams";
import ImportMetaFlowParams from "./Params/ImportMetaFlowParams";
import ImportMatchingFlowParams from "./Params/ImportMatchingFlowParams";

import PushDataShow from "../ImmediateTasks/Details/PushDataShow";
import PushDataDetail from "../ImmediateTasks/Details/PushDataDetail";
import DumpDataShow from "../ImmediateTasks/Details/DumpDataShow";
import DumpDataDetail from "../ImmediateTasks/Details/DumpDataDetail";
import PushAnalyticsShow from "../ImmediateTasks/Details/PushAnalyticsShow";
import PushAnalyticsDetail from "../ImmediateTasks/Details/PushAnalyticsDetail";
import SynchronizeMetaShow from "../ImmediateTasks/Details/SynchronizeMetaShow";
import CompareMetaShow from "../ImmediateTasks/Details/CompareMetaShow";
import MatchMetaShow from "../ImmediateTasks/Details/MatchMetaShow";
import CompareMetaDetail from "../ImmediateTasks/Details/CompareMetaDetail";
import CompareDsMetaDetail from "../ImmediateTasks/Details/CompareDsMetaDetail";
import CompareCcMetaDetail from "../ImmediateTasks/Details/CompareCcMetaDetail";
import SynchronizeMetaDetail from "../ImmediateTasks/Details/SynchronizeMetaDetail";
import SynchronizeDsMetaDetail from "../ImmediateTasks/Details/SynchronizeDsMetaDetail";
import SynchronizeCcMetaDetail from "../ImmediateTasks/Details/SynchronizeCcMetaDetail";
import ImportMetaShow from "../ImmediateTasks/Details/ImportMetaShow";
import ImportMatchingShow from "../ImmediateTasks/Details/ImportMatchingShow";
import ImportMetaDetail from "../ImmediateTasks/Details/ImportMetaDetail";
import ImportMatchingDetail from "../ImmediateTasks/Details/ImportMatchingDetail";
import MatchMetaDetail from "../ImmediateTasks/Details/MatchMetaDetail";
import PushDataFlowFrom from "./Forms/PushDataFlowFrom";
import PushAnalyticFlow from "./Forms/PushAnalyticFlow";
import CloneMetaShow from "../ImmediateTasks/Details/CloneMetaShow";
import CloneMetaDetail from "../ImmediateTasks/Details/CloneMetaDetail";
import CloneDsMetaDetail from "../ImmediateTasks/Details/CloneDsMetaDetail";
import CloneCcMetaDetail from "../ImmediateTasks/Details/CloneCcMetaDetail";

import CompareMetaFormOug, {
  OUG_COMPARE_PARAMS,
  OUS_COMPARE_PARAMS,
} from "./Forms/CompareMetaFormOug";

import CompareMetaFormDeg, {
  DEG_COMPARE_PARAMS,
  DES_COMPARE_PARAMS,
} from "./Forms/CompareMetaFormDeg";

import CompareMetaFormDs, {
  DS_COMPARE_PARAMS,
  DSDES_COMPARE_PARAMS,
  DSOUS_COMPARE_PARAMS,
} from "./Forms/CompareMetaFormDs";

import CompareMetaFormCc, {
  CC_COMPARE_PARAMS,
  CAT_COMPARE_PARAMS,
} from "./Forms/CompareMetaFormCc";

import CompareMetaFormCat, {
  CATEGORY_COMPARE_PARAMS,
  CO_COMPARE_PARAMS,
} from "./Forms/CompareMetaFormCat";

import {
  AVAILABLE_PERIOD_TYPES,
  AVAILABLE_PERIOD_TYPE_UNITS,
} from "../Shared/PeriodTypesSelect";

export const PUSH_DATA_FLOW = "::Services::DataFlow::PushDataFlow";
export const DUMP_DATA_FLOW = "::Services::S3dumpFlow::S3dumpDataFlow";
export const PUSH_ANALYTIC_FLOW = "::Services::AnalyticFlow::PushAnalyticFlow";
export const MATCH_OUG_META_FLOW =
  "::Services::MetaMatchFlow::MatchOugMetaFlow";
export const MATCH_DEG_META_FLOW =
  "::Services::MetaMatchFlow::MatchDegMetaFlow";
export const MATCH_DS_META_FLOW = "::Services::MetaMatchFlow::MatchDsMetaFlow";
export const MATCH_CC_META_FLOW = "::Services::MetaMatchFlow::MatchCcMetaFlow";
export const MATCH_CAT_META_FLOW =
  "::Services::MetaMatchFlow::MatchCatMetaFlow";

export const CLONE_OUG_META_FLOW =
  "::Services::MetaCloneFlow::CloneOugMetaFlow";
export const CLONE_DEG_META_FLOW =
  "::Services::MetaCloneFlow::CloneDegMetaFlow";
export const CLONE_DS_META_FLOW = "::Services::MetaCloneFlow::CloneDsMetaFlow";
export const CLONE_CAT_META_FLOW =
  "::Services::MetaCloneFlow::CloneCatMetaFlow";
export const CLONE_CC_META_FLOW = "::Services::MetaCloneFlow::CloneCcMetaFlow";
export const COMPARE_OUG_DHIS_TO_DHIS_META_FLOW =
  "::Services::MetaCompareFlow::CompareOugDhisToDhisFlow";
export const COMPARE_OUG_DHIS_TO_D2D_META_FLOW =
  "::Services::MetaCompareFlow::CompareOugDhisToD2dFlow";
export const COMPARE_OUG_D2D_TO_D2D_META_FLOW =
  "::Services::MetaCompareFlow::CompareOugD2dToD2dFlow";
export const COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW =
  "::Services::MetaCompareFlow::CompareOugDhisThroughD2dFlow";
export const COMPARE_DS_DHIS_TO_DHIS_META_FLOW =
  "::Services::MetaCompareFlow::CompareOugDhisToDhisFlow";
export const COMPARE_CC_DHIS_TO_DHIS_META_FLOW =
  "::Services::MetaCompareFlow::CompareOugDhisToDhisFlow";
export const COMPARE_CAT_DHIS_TO_DHIS_META_FLOW =
  "::Services::MetaCompareFlow::CompareCatDhisToDhisFlow";
export const COMPARE_DEG_DHIS_TO_DHIS_META_FLOW =
  "::Services::MetaCompareFlow::CompareDegDhisToDhisFlow";
export const COMPARE_DEG_DHIS_TO_D2D_META_FLOW =
  "::Services::MetaCompareFlow::CompareDegDhisToD2dFlow";
export const COMPARE_DEG_D2D_TO_D2D_META_FLOW =
  "::Services::MetaCompareFlow::CompareDegD2dToD2dFlow";
export const COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW =
  "::Services::MetaCompareFlow::CompareDegDhisThroughD2dFlow";
export const COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW =
  "::Services::MetaCompareFlow::CompareDsDhisThroughD2dFlow";
export const COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW =
  "::Services::MetaCompareFlow::CompareCategoryComboFlow::CompareCcDhisThroughD2dFlow";
export const COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW =
  "::Services::MetaCompareFlow::CompareCategoryFlow::CompareCatDhisThroughD2dFlow";
export const SYNCHRONIZE_OUG_META_FLOW =
  "::Services::MetaSynchronizeFlow::SynchronizeOugFlow";
export const SYNCHRONIZE_DEG_META_FLOW =
  "::Services::MetaSynchronizeFlow::SynchronizeDegFlow";
export const SYNCHRONIZE_DS_META_FLOW =
  "::Services::MetaSynchronizeFlow::SynchronizeDsFlow";
export const SYNCHRONIZE_CAT_META_FLOW =
  "::Services::MetaSynchronizeFlow::SynchronizeCatFlow";
export const SYNCHRONIZE_CC_META_FLOW =
  "::Services::MetaSynchronizeFlow::SynchronizeCcFlow";
export const COMPARE_ANALYTIC_TASK =
  "::Services::AnalyticTask::CompareAnalyticTask";
export const COMPARE_DATA_TASK = "::Services::DataTask::CompareDataTask";

export const IMPORT_OUG_META_FLOW = "::Services::MetaImportFlow::ImportOugFlow";

export const IMPORT_DEG_META_FLOW = "::Services::MetaImportFlow::ImportDegFlow";

export const IMPORT_DS_META_FLOW = "::Services::MetaImportFlow::ImportDsFlow";

export const IMPORT_CC_META_FLOW = "::Services::MetaImportFlow::ImportCcFlow";

export const IMPORT_CAT_META_FLOW = "::Services::MetaImportFlow::ImportCatFlow";

export const IMPORT_OUG_MATCHING_FLOW =
  "::Services::MetaImportMatchingFlow::ImportMatchingOugMetaFlow";
export const IMPORT_DEG_MATCHING_FLOW =
  "::Services::MetaImportMatchingFlow::ImportMatchingDegMetaFlow";
export const IMPORT_DS_MATCHING_FLOW =
  "::Services::MetaImportMatchingFlow::ImportMatchingDsMetaFlow";
export const IMPORT_CAT_MATCHING_FLOW =
  "::Services::MetaImportMatchingFlow::ImportMatchingCatMetaFlow";
export const IMPORT_CC_MATCHING_FLOW =
  "::Services::MetaImportMatchingFlow::ImportMatchingCcMetaFlow";

export const TASK_LABELS = {
  [PUSH_DATA_FLOW]: "Transfer data flow",
  [DUMP_DATA_FLOW]: "S3 dump data flow",
  [PUSH_ANALYTIC_FLOW]: "Transfer analytic flow",
  [MATCH_OUG_META_FLOW]: "Match ougs",
  [MATCH_DEG_META_FLOW]: "Match des",
  [MATCH_DS_META_FLOW]: "Match Ds",
  [MATCH_CC_META_FLOW]: "Match Cc",
  [MATCH_CAT_META_FLOW]: "Match Cats",
  [CLONE_OUG_META_FLOW]: "Clone ougs",
  [CLONE_DEG_META_FLOW]: "Clone degs",
  [CLONE_CAT_META_FLOW]: "Clone categories",
  [CLONE_DS_META_FLOW]: "Clone data set",
  [CLONE_CC_META_FLOW]: "Clone category combo",
  [COMPARE_OUG_DHIS_TO_DHIS_META_FLOW]: "Compare OUGs DHIS2 to DHIS2",
  [COMPARE_OUG_DHIS_TO_D2D_META_FLOW]: "Compare OUGs DHIS2 to D2D",
  [COMPARE_OUG_D2D_TO_D2D_META_FLOW]: "Compare OUGs D2D to D2D",
  [COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW]: "Compare OUGs FULL DHIS2 AND D2D",
  [COMPARE_DEG_DHIS_TO_DHIS_META_FLOW]: "Compare DEGs DHIS2 to DHIS2",
  [COMPARE_DEG_DHIS_TO_D2D_META_FLOW]: "Compare DEGs DHIS2 to D2D",
  [COMPARE_DEG_D2D_TO_D2D_META_FLOW]: "Compare DEGs D2D to D2D",
  [COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW]: "Compare DEGs FULL DHIS2 AND D2D",
  [COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW]: "Compare DS FULL DHIS2 AND D2D",
  [COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW]: "Compare CC FULL DHIS2 AND D2D",
  [COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW]: "Compare CAT FULL DHIS2 AND D2D",
  [SYNCHRONIZE_OUG_META_FLOW]: "Synchronize oug",
  [SYNCHRONIZE_DEG_META_FLOW]: "Synchronize deg",
  [SYNCHRONIZE_DS_META_FLOW]: "Synchronize data set",
  [SYNCHRONIZE_CAT_META_FLOW]: "Synchronize categories",
  [SYNCHRONIZE_CC_META_FLOW]: "Synchronize category combo",
  [COMPARE_ANALYTIC_TASK]: "Compare analytic result",
  [COMPARE_DATA_TASK]: "Compare data result",
  [IMPORT_OUG_META_FLOW]: "Import OUGs",
  [IMPORT_DEG_META_FLOW]: "Import DEGs",
  [IMPORT_DS_META_FLOW]: "Import DS",
  [IMPORT_CC_META_FLOW]: "Import CC",
  [IMPORT_CAT_META_FLOW]: "Import Category",
  [IMPORT_OUG_MATCHING_FLOW]: "Import OUGs matchings",
  [IMPORT_DEG_MATCHING_FLOW]: "Import DEGs matchings",
  [IMPORT_DS_MATCHING_FLOW]: "Import Ds Matchings",
  [IMPORT_CAT_MATCHING_FLOW]: "Import Category Matching",
  [IMPORT_CC_MATCHING_FLOW]: "Import Cc Matchings",
};

export const TASK_PREVIEW = {
  [PUSH_ANALYTIC_FLOW]: COMPARE_ANALYTIC_TASK,
  [PUSH_DATA_FLOW]: COMPARE_DATA_TASK,
};

export const TASK_PARAMS_LAYOUTS = {
  [PUSH_DATA_FLOW]: PushDataFlowParams,
  [DUMP_DATA_FLOW]: DumpDataFlowParams,
  [PUSH_ANALYTIC_FLOW]: PushAnalyticFlowParams,
  [MATCH_OUG_META_FLOW]: MatchMetaParams,
  [MATCH_DEG_META_FLOW]: MatchMetaParams,
  [MATCH_DS_META_FLOW]: MatchMetaParams,
  [MATCH_CC_META_FLOW]: MatchMetaParams,
  [MATCH_CAT_META_FLOW]: MatchMetaParams,
  [CLONE_OUG_META_FLOW]: CloneMetaFlowParams,
  [CLONE_DEG_META_FLOW]: CloneMetaFlowParams,
  [CLONE_CAT_META_FLOW]: CloneMetaFlowParams,
  [CLONE_DS_META_FLOW]: CloneMetaFlowParams,
  [CLONE_CC_META_FLOW]: CloneMetaFlowParams,
  [COMPARE_OUG_DHIS_TO_DHIS_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_OUG_DHIS_TO_D2D_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_OUG_D2D_TO_D2D_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_DEG_DHIS_TO_DHIS_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_DEG_DHIS_TO_D2D_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_DEG_D2D_TO_D2D_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowParams,
  [COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowParams,
  [IMPORT_OUG_META_FLOW]: ImportMetaFlowParams,
  [IMPORT_DEG_META_FLOW]: ImportMetaFlowParams,
  [IMPORT_DS_META_FLOW]: ImportMetaFlowParams,
  [IMPORT_CC_META_FLOW]: ImportMetaFlowParams,
  [IMPORT_CAT_META_FLOW]: ImportMetaFlowParams,
  [SYNCHRONIZE_OUG_META_FLOW]: SynchronizeMetaFlowParams,
  [SYNCHRONIZE_DEG_META_FLOW]: SynchronizeMetaFlowParams,
  [SYNCHRONIZE_DS_META_FLOW]: SynchronizeMetaFlowParams,
  [SYNCHRONIZE_CAT_META_FLOW]: SynchronizeMetaFlowParams,
  [SYNCHRONIZE_CC_META_FLOW]: SynchronizeMetaFlowParams,
  [IMPORT_OUG_MATCHING_FLOW]: ImportMatchingFlowParams,
  [IMPORT_DEG_MATCHING_FLOW]: ImportMatchingFlowParams,
  [IMPORT_DS_MATCHING_FLOW]: ImportMatchingFlowParams,
  [IMPORT_CAT_MATCHING_FLOW]: ImportMatchingFlowParams,
  [IMPORT_CC_MATCHING_FLOW]: ImportMatchingFlowParams,
};

export const TASK_SUMMARY_LAYOUTS = {
  [PUSH_DATA_FLOW]: PushDataFlowSummary,
  [DUMP_DATA_FLOW]: DumpDataFlowSummary,
  [PUSH_ANALYTIC_FLOW]: PushDataFlowSummary,
  [MATCH_OUG_META_FLOW]: MatchMetaFlowSummary,
  [MATCH_DEG_META_FLOW]: MatchMetaFlowSummary,
  [MATCH_DS_META_FLOW]: MatchMetaFlowSummary,
  [MATCH_CC_META_FLOW]: MatchMetaFlowSummary,
  [MATCH_CAT_META_FLOW]: MatchMetaFlowSummary,
  [CLONE_OUG_META_FLOW]: CloneOugMetaFlowSummary,
  [CLONE_DEG_META_FLOW]: CloneDegMetaFlowSummary,
  [CLONE_CAT_META_FLOW]: CloneCatMetaFlowSummary,
  [CLONE_DS_META_FLOW]: CloneDsMetaFlowSummary,
  [CLONE_CC_META_FLOW]: CloneCcMetaFlowSummary,
  [COMPARE_OUG_DHIS_TO_DHIS_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_OUG_DHIS_TO_D2D_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_OUG_D2D_TO_D2D_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_DEG_DHIS_TO_DHIS_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_DEG_DHIS_TO_D2D_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_DEG_D2D_TO_D2D_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowSummary,
  [COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowSummary,
  [IMPORT_OUG_META_FLOW]: ImportMetaFlowSummary,
  [IMPORT_DEG_META_FLOW]: ImportMetaFlowSummary,
  [IMPORT_DS_META_FLOW]: ImportMetaFlowSummary,
  [IMPORT_CC_META_FLOW]: ImportMetaFlowSummary,
  [IMPORT_CAT_META_FLOW]: ImportMetaFlowSummary,
  [SYNCHRONIZE_OUG_META_FLOW]: SynchronizeMetaFlowSummary,
  [SYNCHRONIZE_DEG_META_FLOW]: SynchronizeMetaFlowSummary,
  [SYNCHRONIZE_DS_META_FLOW]: SynchronizeMetaFlowSummary,
  [SYNCHRONIZE_CAT_META_FLOW]: SynchronizeMetaFlowSummary,
  [SYNCHRONIZE_CC_META_FLOW]: SynchronizeMetaFlowSummary,
  [IMPORT_OUG_MATCHING_FLOW]: ImportMatchingFlowSummary,
  [IMPORT_DEG_MATCHING_FLOW]: ImportMatchingFlowSummary,
  [IMPORT_DS_MATCHING_FLOW]: ImportMatchingFlowSummary,
  [IMPORT_CAT_MATCHING_FLOW]: ImportMatchingFlowSummary,
  [IMPORT_CC_MATCHING_FLOW]: ImportMatchingFlowSummary,
};

export const COMPARE_TASKS_SCHEDULER_PANELS = {
  [COMPARE_OUG_DHIS_TO_DHIS_META_FLOW]: "panel1",
  [COMPARE_OUG_DHIS_TO_D2D_META_FLOW]: { source: "panel2", target: "panel3" },
  [COMPARE_OUG_D2D_TO_D2D_META_FLOW]: "panel4",
  [COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW]: "panel5",
  [COMPARE_DEG_DHIS_TO_DHIS_META_FLOW]: "panel1",
  [COMPARE_DEG_DHIS_TO_D2D_META_FLOW]: { source: "panel2", target: "panel3" },
  [COMPARE_DEG_D2D_TO_D2D_META_FLOW]: "panel4",
  [COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW]: "panel5",
  [COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW]: "panel5",
  [COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW]: "panel5",
  [COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW]: "panel5",
  [SYNCHRONIZE_OUG_META_FLOW]: "panel5",
  [SYNCHRONIZE_DEG_META_FLOW]: "panel5",
  [SYNCHRONIZE_DS_META_FLOW]: "panel5",
  [SYNCHRONIZE_CAT_META_FLOW]: "panel5",
  [SYNCHRONIZE_CC_META_FLOW]: "panel5",
};

export const TASK_SHOW_LAYOUTS = {
  [PUSH_DATA_FLOW]: PushDataShow,
  [DUMP_DATA_FLOW]: DumpDataShow,
  [PUSH_ANALYTIC_FLOW]: PushAnalyticsShow,
  [COMPARE_OUG_DHIS_TO_DHIS_META_FLOW]: CompareMetaShow,
  [MATCH_OUG_META_FLOW]: MatchMetaShow,
  [MATCH_DEG_META_FLOW]: MatchMetaShow,
  [MATCH_DS_META_FLOW]: MatchMetaShow,
  [MATCH_CC_META_FLOW]: MatchMetaShow,
  [MATCH_CAT_META_FLOW]: MatchMetaShow,
  [COMPARE_OUG_DHIS_TO_D2D_META_FLOW]: CompareMetaShow,
  [COMPARE_OUG_D2D_TO_D2D_META_FLOW]: CompareMetaShow,
  [COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaShow,
  [COMPARE_DEG_DHIS_TO_DHIS_META_FLOW]: CompareMetaShow,
  [COMPARE_DEG_DHIS_TO_D2D_META_FLOW]: CompareMetaShow,
  [COMPARE_DEG_D2D_TO_D2D_META_FLOW]: CompareMetaShow,
  [COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaShow,
  [COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaShow,
  [COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaShow,
  [COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaShow,
  [IMPORT_OUG_META_FLOW]: ImportMetaShow,
  [IMPORT_DEG_META_FLOW]: ImportMetaShow,
  [IMPORT_DS_META_FLOW]: ImportMetaShow,
  [IMPORT_CC_META_FLOW]: ImportMetaShow,
  [IMPORT_CAT_META_FLOW]: ImportMetaShow,
  [SYNCHRONIZE_OUG_META_FLOW]: SynchronizeMetaShow,
  [SYNCHRONIZE_DEG_META_FLOW]: SynchronizeMetaShow,
  [SYNCHRONIZE_DS_META_FLOW]: SynchronizeMetaShow,
  [SYNCHRONIZE_CAT_META_FLOW]: SynchronizeMetaShow,
  [SYNCHRONIZE_CC_META_FLOW]: SynchronizeMetaShow,
  [IMPORT_OUG_MATCHING_FLOW]: ImportMatchingShow,
  [IMPORT_DEG_MATCHING_FLOW]: ImportMatchingShow,
  [IMPORT_DS_MATCHING_FLOW]: ImportMatchingShow,
  [IMPORT_CAT_MATCHING_FLOW]: ImportMatchingShow,
  [IMPORT_CC_MATCHING_FLOW]: ImportMatchingShow,
  [CLONE_OUG_META_FLOW]: CloneMetaShow,
  [CLONE_DEG_META_FLOW]: CloneMetaShow,
  [CLONE_CAT_META_FLOW]: CloneMetaShow,
  [CLONE_DS_META_FLOW]: CloneMetaShow,
  [CLONE_CC_META_FLOW]: CloneMetaShow,
};

export const TASK_DETAIL_LAYOUTS = {
  [PUSH_DATA_FLOW]: PushDataDetail,
  [DUMP_DATA_FLOW]: DumpDataDetail,
  [PUSH_ANALYTIC_FLOW]: PushAnalyticsDetail,
  [COMPARE_OUG_DHIS_TO_DHIS_META_FLOW]: CompareMetaDetail,
  [COMPARE_OUG_DHIS_TO_D2D_META_FLOW]: CompareMetaDetail,
  [COMPARE_OUG_D2D_TO_D2D_META_FLOW]: CompareMetaDetail,
  [COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaDetail,
  [COMPARE_DEG_DHIS_TO_DHIS_META_FLOW]: CompareMetaDetail,
  [COMPARE_DEG_DHIS_TO_D2D_META_FLOW]: CompareMetaDetail,
  [COMPARE_DEG_D2D_TO_D2D_META_FLOW]: CompareMetaDetail,
  [COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaDetail,
  [COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW]: CompareDsMetaDetail,
  [COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW]: CompareCcMetaDetail,
  [COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaDetail,
  [IMPORT_OUG_META_FLOW]: ImportMetaDetail,
  [IMPORT_DEG_META_FLOW]: ImportMetaDetail,
  [IMPORT_DS_META_FLOW]: ImportMetaDetail,
  [IMPORT_CC_META_FLOW]: ImportMetaDetail,
  [IMPORT_CAT_META_FLOW]: ImportMetaDetail,
  [MATCH_OUG_META_FLOW]: MatchMetaDetail,
  [MATCH_DEG_META_FLOW]: MatchMetaDetail,
  [MATCH_DS_META_FLOW]: MatchMetaDetail,
  [MATCH_CC_META_FLOW]: MatchMetaDetail,
  [MATCH_CAT_META_FLOW]: MatchMetaDetail,
  [SYNCHRONIZE_OUG_META_FLOW]: SynchronizeMetaDetail,
  [SYNCHRONIZE_DEG_META_FLOW]: SynchronizeMetaDetail,
  [SYNCHRONIZE_DS_META_FLOW]: SynchronizeDsMetaDetail,
  [SYNCHRONIZE_CAT_META_FLOW]: SynchronizeMetaDetail,
  [SYNCHRONIZE_CC_META_FLOW]: SynchronizeCcMetaDetail,
  [IMPORT_OUG_MATCHING_FLOW]: ImportMatchingDetail,
  [IMPORT_DEG_MATCHING_FLOW]: ImportMatchingDetail,
  [IMPORT_DS_MATCHING_FLOW]: ImportMatchingDetail,
  [IMPORT_CAT_MATCHING_FLOW]: ImportMatchingDetail,
  [IMPORT_CC_MATCHING_FLOW]: ImportMatchingDetail,
  [CLONE_OUG_META_FLOW]: CloneMetaDetail,
  [CLONE_DEG_META_FLOW]: CloneMetaDetail,
  [CLONE_CAT_META_FLOW]: CloneMetaDetail,
  [CLONE_DS_META_FLOW]: CloneDsMetaDetail,
  [CLONE_CC_META_FLOW]: CloneCcMetaDetail,
};

export const DEBOUNCED_TIME = 500;

export const schedValid = (immediate, entity) =>
  immediate || !!entity.cron_expression;

export const periodsValid = (immediate, entity) => {
  if (immediate) {
    return (
      !!entity.params.start_date &&
      !!entity.params.end_date &&
      entity.params.start_date <= entity.params.end_date
    );
  } else {
    return (
      (!!entity.params.period_range && !!entity.params.period_range_unit) ||
      !!entity.params.start_date
    );
  }
};

export const filePatternsValid = (immediate, entity) => {
  if (entity.sequencer_class !== DUMP_DATA_FLOW) return true;
  //rely on project def
  if (!entity.params.s3bucket) return true;
  if (!entity.params.s3bucket.file_patterns) return true;
  try {
    JSON.parse(entity.params.s3bucket.file_patterns);
  } catch (e) {
    return false;
  }
  return true;
};

export const defaultPeriod = immediate => {
  if (!!immediate) {
    return {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment()
        .endOf("month")
        .format("YYYY-MM-DD"),
    };
  } else {
    return {
      period_range: 3,
      period_range_unit: AVAILABLE_PERIOD_TYPE_UNITS[0],
    };
  }
};

export const TASK_FORMS = {
  PushDataFlow: {
    label: "Push Data",
    sequencer_class: PUSH_DATA_FLOW,
    form: PushDataFlowFrom,
    resetParams: immediate => {
      return {
        ...defaultPeriod(immediate),
        period_types: [AVAILABLE_PERIOD_TYPES[2]],
        data_element_group_matching_id: "",
        data_set_matching_id: "",
        organisation_unit_group_matching_id: "",
      };
    },
    isValid: (immediate, entity) => {
      return (
        schedValid(immediate, entity) &&
        periodsValid(immediate, entity) &&
        filePatternsValid(immediate, entity) &&
        (!!entity.params.data_element_group_matching_id ||
          !!entity.params.data_set_matching_id) &&
        (!!entity.params.data_set_matching_id ||
          !!entity.params.organisation_unit_group_matching_id)
      );
    },
  },
  PushAnalyticFlow: {
    label: "Push from analytics",
    sequencer_class: PUSH_ANALYTIC_FLOW,
    form: PushAnalyticFlow,
    resetParams: immediate => {
      return {
        ...defaultPeriod(immediate),
        src_period_types: [AVAILABLE_PERIOD_TYPES[2]],
        dest_period_type: AVAILABLE_PERIOD_TYPES[2],
        data_element_group_matching_id: "",
        de_aggregation_type: "one2one",
        organisation_unit_group_matching_id: "",
        ou_aggregation_type: "one2one",
      };
    },
    isValid: (immediate, entity) =>
      schedValid(immediate, entity) &&
      periodsValid(immediate, entity) &&
      !!entity.params.data_element_group_matching_id &&
      (entity.params.de_aggregation_type === "one2one" ||
        (entity.params.de_aggregation_type === "n2uid" &&
          !!entity.params.de_target)) &&
      !!entity.params.organisation_unit_group_matching_id &&
      (entity.params.ou_aggregation_type === "one2one" ||
        (entity.params.ou_aggregation_type === "n2uid" &&
          !!entity.params.ou_target) ||
        (entity.params.ou_aggregation_type === "n2nplus" &&
          !!entity.params.ou_level_up) ||
        (entity.params.ou_aggregation_type === "n2nx" &&
          !!entity.params.ou_level_x)),
  },
  CompareOugFlow: {
    label: "Compare OUG",
    sequencer_class: COMPARE_OUG_DHIS_TO_DHIS_META_FLOW,
    form: CompareMetaFormOug,
    resetParams: immediate => {
      return {
        compare_group_params: [
          OUG_COMPARE_PARAMS[0].key,
          OUG_COMPARE_PARAMS[1].key,
          OUG_COMPARE_PARAMS[2].key,
          OUG_COMPARE_PARAMS[3].key,
        ],
        compare_entities_params: [OUS_COMPARE_PARAMS[0].key],
      };
    },
    isValid: (immediate, entity) =>
      schedValid(immediate, entity) &&
      !!entity.params.rightSourceGroup &&
      !!entity.params.leftSourceGroup,
  },
  CompareDegFlow: {
    label: "Compare DEG",
    sequencer_class: COMPARE_DEG_DHIS_TO_DHIS_META_FLOW,
    form: CompareMetaFormDeg,
    resetParams: immediate => {
      return {
        compare_group_params: [
          DEG_COMPARE_PARAMS[0].key,
          DEG_COMPARE_PARAMS[1].key,
          DEG_COMPARE_PARAMS[2].key,
          DEG_COMPARE_PARAMS[3].key,
        ],
        compare_entities_params: [DES_COMPARE_PARAMS[0].key],
      };
    },
    isValid: (immediate, entity) =>
      schedValid(immediate, entity) &&
      !!entity.params.rightSourceGroup &&
      !!entity.params.leftSourceGroup,
  },
  CompareDsFlow: {
    label: "Compare DS",
    sequencer_class: COMPARE_DS_DHIS_TO_DHIS_META_FLOW,
    form: CompareMetaFormDs,
    resetParams: immediate => {
      return {
        compare_group_params: [
          DS_COMPARE_PARAMS["data_element"][0].key,
          DS_COMPARE_PARAMS["data_element"][1].key,
          DS_COMPARE_PARAMS["data_element"][2].key,
          DS_COMPARE_PARAMS["data_element"][3].key,
          DS_COMPARE_PARAMS["organisation_unit"][0].key,
          DS_COMPARE_PARAMS["organisation_unit"][1].key,
          DS_COMPARE_PARAMS["organisation_unit"][2].key,
          DS_COMPARE_PARAMS["organisation_unit"][3].key,
        ],
        compare_entities_params: [DSDES_COMPARE_PARAMS[0].key],
        compare_entities_ous_params: [DSOUS_COMPARE_PARAMS[0].key],
      };
    },
    isValid: (immediate, entity) =>
      schedValid(immediate, entity) &&
      !!entity.params.rightSourceGroup &&
      !!entity.params.leftSourceGroup,
  },
  CompareCcFlow: {
    label: "Compare CC",
    sequencer_class: COMPARE_CC_DHIS_TO_DHIS_META_FLOW,
    form: CompareMetaFormCc,
    resetParams: immediate => {
      return {
        compare_group_params: CC_COMPARE_PARAMS.entities.map(
          params => params.key,
        ),
        compare_entities_params: [CAT_COMPARE_PARAMS[0].key],
      };
    },
    isValid: (immediate, entity) =>
      schedValid(immediate, entity) &&
      !!entity.params.rightSourceGroup &&
      !!entity.params.leftSourceGroup,
  },
  CompareCatFlow: {
    label: "Compare Cat",
    sequencer_class: COMPARE_CAT_DHIS_TO_DHIS_META_FLOW,
    form: CompareMetaFormCat,
    resetParams: immediate => {
      return {
        compare_group_params: [
          CATEGORY_COMPARE_PARAMS[0].key,
          CATEGORY_COMPARE_PARAMS[1].key,
          CATEGORY_COMPARE_PARAMS[2].key,
          CATEGORY_COMPARE_PARAMS[3].key,
        ],
        compare_entities_params: [CO_COMPARE_PARAMS[0].key],
      };
    },
    isValid: (immediate, entity) =>
      schedValid(immediate, entity) &&
      !!entity.params.rightSourceGroup &&
      !!entity.params.leftSourceGroup,
  },
};

export const taskToUpdate = [
  PUSH_DATA_FLOW,
  DUMP_DATA_FLOW,
  PUSH_ANALYTIC_FLOW,
  COMPARE_OUG_DHIS_TO_DHIS_META_FLOW,
  COMPARE_OUG_DHIS_TO_D2D_META_FLOW,
  COMPARE_OUG_D2D_TO_D2D_META_FLOW,
  COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW,
  COMPARE_DEG_DHIS_TO_DHIS_META_FLOW,
  COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
  COMPARE_DEG_D2D_TO_D2D_META_FLOW,
  COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
  COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
  COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
];

export const entityToForm = entity => {
  switch (!!entity && entity.sequencer_class) {
    case PUSH_DATA_FLOW:
      return "PushDataFlow";
    case DUMP_DATA_FLOW:
      return "PushDataFlow";
    case PUSH_ANALYTIC_FLOW:
      return "PushAnalyticFlow";
    case COMPARE_OUG_DHIS_TO_DHIS_META_FLOW:
      return "CompareOugFlow";
    case COMPARE_OUG_DHIS_TO_D2D_META_FLOW:
      return "CompareOugFlow";
    case COMPARE_OUG_D2D_TO_D2D_META_FLOW:
      return "CompareOugFlow";
    case COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW:
      return "CompareOugFlow";
    case SYNCHRONIZE_OUG_META_FLOW:
      return "CompareOugFlow";
    case SYNCHRONIZE_DEG_META_FLOW:
      return "CompareDegFlow";
    case SYNCHRONIZE_DS_META_FLOW:
      return "CompareDsFlow";
    case COMPARE_DEG_DHIS_TO_DHIS_META_FLOW:
      return "CompareDegFlow";
    case COMPARE_DEG_DHIS_TO_D2D_META_FLOW:
      return "CompareDegFlow";
    case COMPARE_DEG_D2D_TO_D2D_META_FLOW:
      return "CompareDegFlow";
    case COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW:
      return "CompareDegFlow";
    case COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW:
      return "CompareDsFlow";
    case COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW:
      return "CompareCcFlow";
    case COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW:
      return "CompareCatFlow";
    default:
      return "PushDataFlowDeg";
  }
};

import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";
import { fetchDataElements } from "./dataElements";
import * as types from "../actionTypes";
import { stringify } from "query-string";
import _omitBy from "lodash/omitBy";
import _isUndefined from "lodash/isUndefined";

export function fetchDataElementsMatchings(
  programId,
  projectId,
  matchingType,
  matchingId,
  page,
  force = false,
) {
  const meta = { meta: { force: force } };

  const queryParams = {
    page: page,
    per_page: 500,
  };
  queryParams[matchingType] = matchingId;

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));

  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/data_elements?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_DATA_ELEMENTS_MATCHINGS,
        { type: types.RECEIVE_DATA_ELEMENTS_MATCHINGS, ...meta },
        types.REQUEST_DATA_ELEMENTS_MATCHINGS_FAILED,
      ],
    },
  };
}

export function createNewDataElementsMatching(
  programId,
  projectId,
  payload,
  matchingType,
  matchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/data_elements`,
      method: "POST",
      body: JSON.stringify(payload),
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CREATE_DATA_ELEMENTS_MATCHING,
        {
          type: types.RECEIVE_CREATE_DATA_ELEMENTS_MATCHING,
          payload: (action, state, response) => {
            return response.json().then(responsePayload => {
              store.dispatch({
                type: types.DESTROY_LOCAL_DATA_ELEMENT,
                sourceId: payload.first_element.source_id,
                dataElementId: payload.first_element.id,
              });
              store.dispatch({
                type: types.DESTROY_LOCAL_DATA_ELEMENT,
                sourceId: payload.second_element.source_id,
                dataElementId: payload.second_element.id,
              });
              store.dispatch(
                fetchDataElementsMatchings(
                  programId,
                  projectId,
                  matchingType,
                  matchingId,
                  1,
                  true,
                ),
              );
              return responsePayload;
            });
          },
        },
        types.REQUEST_CREATE_DATA_ELEMENTS_MATCHING_FAILED,
      ],
    },
  };
}

export function destroyDataElementsMatching(
  programId,
  projectId,
  matchingType,
  matchingParam,
  matching,
  deMatchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/data_elements/${deMatchingId}`,
      method: "DELETE",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_DESTROY_DATA_ELEMENTS_MATCHING,
        {
          type: types.RECEIVE_DESTROY_DATA_ELEMENTS_MATCHING,
          payload: (action, state, response) => {
            store.dispatch(
              fetchDataElements(
                programId,
                projectId,
                matching.left.source.id,
                matchingType,
                matching.left.id,
                null,
                1,
                undefined,
              ),
            );
            store.dispatch(
              fetchDataElementsMatchings(
                programId,
                projectId,
                matchingParam,
                matching.id,
                1,
                true,
              ),
            );
          },
        },
        types.REQUEST_DESTROY_DATA_ELEMENTS_MATCHING_FAILED,
      ],
    },
  };
}

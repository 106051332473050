import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

class ContentDialogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  onSubmit(e) {
    e.preventDefault();
    this.props.handleClone({
      project: {
        ...this.state,
      },
    });
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.props.projectName} Clone
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Project Name"
            type="email"
            fullWidth
            onChange={this.handleChange("name")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>Cancel</Button>
          <Button onClick={this.onSubmit.bind(this)} color="primary">
            Clone
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ContentDialogForm;

import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class ImportMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;

    return (
      <TaskHashCell
        task={[
          [
            {
              label: "Imported Metas",
              value: task.summary["imported_metadatas"]
                ? task.summary["imported_metadatas"]
                : 0,
            },
          ],
        ]}
      />
    );
  }
}

export default ImportMetaFlowSummary;

import * as types from "../actionTypes";

function programs(
  state = {
    programs: [],
    isFetchingProgram: false,
    currentProgram: null,
    isFetchingProject: false,
    currentProject: null,
    countProjects: 0,
  },
  action,
) {
  switch (action.type) {
    case types.RECEIVE_LOGIN:
      var programs = action.payload.user.programs;
      var currentProgram = programs.length === 1 ? programs[0] : null;
      var currentProject =
        !!currentProgram && currentProgram.projects.length === 1
          ? currentProgram.projects[0]
          : null;
      return Object.assign({}, state, {
        programs,
        currentProgram,
        currentProject,
      });
    case types.REQUEST_LOGOUT:
      return Object.assign({}, state, {
        programs: [],
        currentProgram: null,
        currentProject: null,
      });
    case types.REQUEST_PROGRAM:
      return Object.assign({}, state, {
        isFetchingProgram: true,
        currentProgram: null,
      });
    case types.RECEIVE_PROGRAM:
      return Object.assign({}, state, {
        isFetchingProgram: false,
        currentProgram: action.currentProgram,
        currentProject:
          action.currentProgram.projects.length === 1
            ? action.currentProgram.projects[0]
            : null,
      });
    case types.ADD_PROGRAM_PROJECT:
      var currentPgme = { ...state.currentProgram };
      currentPgme.projects = action.projects;
      return Object.assign({}, state, {
        currentProgram: currentPgme,
        countProjects: state.countProjects + 1,
      });
    case types.DELETE_PROGRAM_PROJECT:
      var currentPgm = { ...state.currentProgram };
      currentPgm.projects = action.projects;
      return Object.assign({}, state, {
        currentProgram: currentPgm,
        countProjects: state.countProjects - 1,
      });
    case types.UPDATE_PROGRAM_PROJECT:
      var currentPro = { ...state.currentProgram };
      currentPro.projects = action.projects;
      return Object.assign({}, state, {
        currentProgram: currentPro,
        countProjects: state.countProjects,
      });
    case types.REQUEST_PROJECT:
      return Object.assign({}, state, {
        isFetchingProject: true,
        currentProject: null,
      });
    case types.REQUEST_PROJECT_FAILED:
      return Object.assign({}, state, {
        isFetchingProject: false,
        currentProject: null,
      });
    case types.RECEIVE_PROJECT:
      return Object.assign({}, state, {
        isFetchingProject: false,
        currentProject: action.payload,
      });
    case types.RECEIVE_PROJECT_CREATE:
      return Object.assign({}, state, {
        countProjects: state.countProjects + 1,
        currentProject: action.payload,
      });
    case types.REQUEST_PROJECT_CREATE_FAILED:
      return Object.assign({}, state, {
        countProjects: state.countProjects,
        error: true,
        error_message: action.payload.errors,
      });
    case types.REQUEST_PROJECT_UPDATE_S3BUCKET:
      let ste = { ...state };
      delete ste.s3updateError;
      return ste;
    case types.RECEIVE_PROJECT_UPDATE_S3BUCKET:
      var currentPject = state.currentProject;
      currentPject.s3bucket = action.meta;
      return Object.assign({}, state, {
        currentPject,
      });
    case types.REQUEST_PROJECT_UPDATE_S3BUCKET_FAILED:
      return Object.assign({}, state, {
        s3updateError: action.payload.response.errors,
      });
    default:
      return state;
  }
}

export default programs;

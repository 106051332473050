import React, { Component, Fragment } from "react";
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import ConfirmDialog from "./ConfirmDialog";
import { destroy } from "../../actions/api";
import withStyles from "@material-ui/core/styles/withStyles";
import { leftIcon as styles } from "../../helpers/commonStyles";

class SharedDeleter extends Component {
  state = {
    confirmBoxOpen: false,
    processing: false,
    error: null,
  };

  handleClickOpen = () => {
    this.setState({
      confirmBoxOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      confirmBoxOpen: false,
    });
  };

  isProcessing() {
    return this.state.processing;
  }

  startProcessing() {
    this.setState({
      error: null,
      processing: true,
    });
  }

  stopProcessing(error) {
    if (error) {
      this.setState({ error: error });
    }
    this.setState({ processing: false });
  }

  submitDestroyRequest = () => {
    const { api, projectId, id, programId } = this.props;
    this.startProcessing();
    this.handleClose();
    destroy(api, {
      programId: programId,
      projectId: projectId,
      id: id,
    })
      .then(() => this.props.callback())
      .catch(error => {
        this.stopProcessing(error);
      });
  };

  render() {
    let content;

    if (this.isProcessing()) {
      content = <CircularProgress size={24} />;
    } else {
      content = (
        <Fragment>
          <ConfirmDialog
            handleConfirm={this.submitDestroyRequest}
            handleClose={this.handleClose}
            content={this.props.message}
            open={this.state.confirmBoxOpen}
          />
          <IconButton onClick={e => this.handleClickOpen(e)}>
            <Delete />
          </IconButton>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {content}
        {this.state.error && (
          <Typography color="primary">{this.state.error.message}</Typography>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles)(SharedDeleter);

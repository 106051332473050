import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import MatchingList from "./MatchingList";

class MatchingItems extends Component {
  render() {
    const {
      matching: { right: groupRight },
      itemsRight,
      itemsRightRowCount,
      fetchItems,
      itemsRightFetching,
      selectedItemsIds,
      itemMatchingQuery,
      handleItemSelection,
      height = 300,
    } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MatchingList
            items={itemsRight}
            rowCount={itemsRightRowCount}
            fetchItems={fetchItems}
            sourceId={groupRight.source.id}
            matchingQuery={itemMatchingQuery}
            isFetching={itemsRightFetching}
            selectedItemIndex={selectedItemsIds.right.index}
            handleItemSelection={handleItemSelection}
            master={false}
            height={height}
            didUpdate={this.props.matchingMasterListRefreshed}
          />
        </Grid>
      </Grid>
    );
  }
}

export default MatchingItems;

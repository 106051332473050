import auth from "./auth";
import categories from "./categories";
import categoriesMatchings from "./categoriesMatchings";
import categoryOptions from "./categoryOptions";
import categoryOptionsMatchings from "./categoryOptionsMatchings";
import categoryCombos from "./categoryCombos";
import categoryCombosMatchings from "./categoryCombosMatchings";
import categoryComboCategoriesMatchings from "./categoryComboCategoriesMatchings";
import categoryOptionCombosMatchings from "./categoryOptionCombosMatchings";
import categoryOptionCombos from "./categoryOptionCombos";
import dataElementGroups from "./dataElementGroups";
import dataElementGroupsMatchings from "./dataElementGroupsMatchings";
import dataElements from "./dataElements";
import dataElementsMatchings from "./dataElementsMatchings";
import notifications from "./notifications";
import orgUnitGroupsMatchings from "./orgUnitGroupsMatchings";
import orgUnits from "./orgUnits";
import orgUnitsMatchings from "./orgUnitsMatchings";
import organisationUnits from "./organisationUnits";
import organisationUnitGroups from "./organisationUnitGroups";
import dataSets from "./dataSets";
import dataSetsMatchings from "./dataSetsMatchings";
import programs from "./programs";
import schedulers from "./schedulers";
import sources from "./sources";
import tasks from "./tasks";
import task from "./task";
import ui from "./ui";

const index = {
  auth,
  dataElementGroups,
  dataElementGroupsMatchings,
  dataElements,
  dataElementsMatchings,
  notifications,
  organisationUnits,
  organisationUnitGroups,
  orgUnitGroupsMatchings,
  categories,
  categoriesMatchings,
  categoryOptions,
  categoryOptionsMatchings,
  categoryCombos,
  categoryCombosMatchings,
  categoryComboCategoriesMatchings,
  categoryOptionCombosMatchings,
  categoryOptionCombos,
  orgUnits,
  orgUnitsMatchings,
  dataSets,
  dataSetsMatchings,
  tasks,
  task,
  schedulers,
  programs,
  sources,
  ui,
};

export default index;

import { RSAA } from "redux-api-middleware";
import _isUndefined from "lodash/isUndefined";
import _omitBy from "lodash/omitBy";
import store from "../store";
import { stringify } from "query-string";
import { baseApiUrl, authHeader } from "./api";
import * as types from "../actionTypes";

export function fetchCategoryComboCategoriesMatchings(
  programId,
  projectId,
  ccMatchingId,
  page,
  force = false,
) {
  const meta = { meta: { force: force } };

  const queryParams = {
    page: page,
    per_page: 50,
  };

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_combos/${ccMatchingId}/categories?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        { type: types.REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS, ...meta },
        { type: types.RECEIVE_CATEGORY_COMBO_CATEGORIES_MATCHINGS, ...meta },
        {
          type: types.REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS_FAILED,
          ...meta,
        },
      ],
    },
  };
}

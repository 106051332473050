import React, { Component } from "react";
import {
  withStyles,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import { loginUser } from "../../actions/auth";
import Notifications from "../Shared/Notifications";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { textField, formFooter } from "../../helpers/commonStyles";

const styles = theme => ({
  ...textField(theme),
  ...formFooter(theme),
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleClick(e) {
    e.preventDefault();
    this.props.loginUser({
      user: { email: this.state.email, password: this.state.password },
    });
  }

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  render() {
    const { classes } = this.props;

    return (
      <form onSubmit={this.handleClick.bind(this)}>
        <Notifications category="loginForm" />
        <Typography variant="h5" component="h1">
          Signin
        </Typography>
        <TextField
          id="email"
          className={classes.textField}
          label="Email"
          fullWidth
          margin="normal"
          onChange={this.handleChange("email")}
        />
        <FormControl className={classes.textField}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            type={this.state.showPassword ? "text" : "password"}
            value={this.state.password}
            onChange={this.handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={this.handleClickShowPasssword}
                  onMouseDown={this.handleMouseDownPassword}
                >
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <div className={classes.formFooter}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  errorMessage: state.auth.errorMessage,
});

export default connect(mapStateToProps, { loginUser })(
  withStyles(styles)(LoginForm),
);

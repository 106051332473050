import * as types from "../actionTypes";

function organisationUnitGroups(
  state = {
    suggestions: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_OUG_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: [],
        errors: null,
        isFetching: true,
      });
    case types.RECEIVE_OUG_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: action.payload,
        isFetching: false,
      });
    case types.REQUEST_OUG_SUGGESTIONS_FAILED:
      return Object.assign({}, state, {
        suggestions: [],
        errors: action.payload,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default organisationUnitGroups;

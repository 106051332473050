import {
  REQUEST_CATEGORIES_MATCHINGS,
  RECEIVE_CATEGORIES_MATCHINGS,
  REQUEST_CATEGORIES_MATCHINGS_FAILED,
} from "../actionTypes";

function categoriesMatchings(
  state = {
    entities: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CATEGORIES_MATCHINGS:
      return Object.assign({}, state, {
        entities: [],
        isFetching: true,
      });
    case RECEIVE_CATEGORIES_MATCHINGS:
      return Object.assign({}, state, {
        entities: action.payload.data,
        isFetching: false,
        meta: action.payload.meta,
      });
    case REQUEST_CATEGORIES_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        entities: [],
        errors: action.payload.errors,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default categoriesMatchings;

export const selectCategoriesMatching = (catMatchings, catMatchingId) => {
  return catMatchings.find(matching => matching.id === parseInt(catMatchingId, 10));
};

import { Grid, Tooltip, Typography, TextField } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import {
  createNewOrgUnitsMatching,
  destroyOrgUnitsMatching,
  fetchOrgUnitsMatchings,
} from "../../actions/orgUnitsMatchings";
import {
  orgUnitsMatchingsMasterListRefreshed,
  refreshOrgUnitsMatchingsMasterList,
} from "../../actions/ui";
import { get } from "../../actions/api";
import Alert from "../Shared/Alert";
import LayoutContainer from "../Shared/LayoutContainer";
import LinkIcon from "@material-ui/icons/Link";
import OrgUnitGroupPreview from "./OrgUnitGroupPreview";
import SectionHeader from "../Shared/SectionHeader";
import ConfirmDialog from "../Shared/ConfirmDialog";
import SearcheableMatchingTable from "../Shared/SearcheableMatchingTable";
import _values from "lodash/values";
import _isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import { fetchOrgUnits } from "../../actions/orgUnits";
import { formStyles, paper } from "../../helpers/commonStyles";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import UnmatchedNewSection from "../Shared/UnmatchedNewSection";
import Fab from "@material-ui/core/Fab";
import CreateMatchingDialog from "../Shared/CreateMatchingDialog";

const styles = theme => ({
  ...formStyles(theme),
  ...paper(theme),
  fabButton: {
    position: "absolute",
    right: -25,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
  },
  relative: {
    position: "relative",
  },
});

class OrgUnitGroupsMatchingEditForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmDestroyOpen: false,
      processingErrors: null,
      processingDialogOpen: false,
      timeoutId: null,
      searchedItems: [],
      searchedItemsCount: 0,
      searchParams: "",
      danglingMatchingRowCount: 0,
      existingMatchingRowCount: 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!prevProps.creatingMatching && !this.props.creatingMatching) {
      window.location.reload();
    } else if (
      prevProps.creatingMatching !== this.props.creatingMatching ||
      !_isEqual(prevProps.processingErrors, this.props.processingErrors)
    ) {
      this.setState((state, props) => ({
        processingDialogOpen:
          !!props.creatingMatching || !!props.processingErrors,
        processingErrors: props.processingErrors,
      }));
    }

    if (
      !_isEqual(
        prevProps.danglingMatchingRowCount,
        this.props.danglingMatchingRowCount,
      )
    ) {
      this.setState((state, props) => ({
        danglingMatchingRowCount: props.danglingMatchingRowCount,
      }));
    }

    if (
      !_isEqual(
        prevProps.existingMatchingRowCount,
        this.props.existingMatchingRowCount,
      )
    ) {
      this.setState((state, props) => ({
        existingMatchingRowCount: props.existingMatchingRowCount,
      }));
    }
  }

  handleFetchOrgUnits = params => {
    const { sourceId, query, page, master, forceReload } = params;
    const {
      projectId,
      programId,
      ougMatching: { left: orgUnitGroupLeft, right: orgUnitGroupRight },
    } = this.props;
    const orgUnitGroup = master ? orgUnitGroupLeft : orgUnitGroupRight;
    this.props.fetchOrgUnits(
      programId,
      projectId,
      sourceId,
      "organisation_unit_groups",
      orgUnitGroup.id,
      query,
      page,
      forceReload,
    );
  };

  closeConfirmDestroyDialog = () => {
    this.setState({
      confirmDestroyOpen: false,
    });
  };

  confirmDestroyContent = () => {
    return (
      <Fragment>
        <Typography>
          This org unit matching is shared by the following org unit groups
          matchings:
        </Typography>
        <Typography color="primary">
          {this.state.confirmDestroyWarnings &&
            this.state.confirmDestroyWarnings.join(", ")}
        </Typography>
        <Typography>
          Do you want to delete the org unit matching in all these org unit
          group matchings?
        </Typography>
      </Fragment>
    );
  };

  confirmDestroyDialog = () => (
    <ConfirmDialog
      handleConfirm={this.destroyOrgUnitsMatching}
      handleClose={this.closeConfirmDestroyDialog}
      open={this.state.confirmDestroyOpen}
    >
      {this.confirmDestroyContent()}
    </ConfirmDialog>
  );

  destroyOrgUnitsMatching = () => {
    this.props.destroyOrgUnitsMatching(
      this.props.programId,
      this.props.projectId,
      "organisation_unit_groups",
      "organisation_unit_group_matching",
      this.props.ougMatching,
      this.state.matchingIdToDestroy,
    );
    this.closeConfirmDestroyDialog();
  };

  closeProcessingDialog = () => {
    this.setState({
      processingDialogOpen: false,
    });
  };

  createMatchingDialog = () => {
    const { ougMatching } = this.props;
    return (
      <CreateMatchingDialog
        open={this.state.processingDialogOpen}
        closeProcessingDialog={this.closeProcessingDialog}
        srcLeft={ougMatching.left.source.name}
        matchTo={this.state.matchTo}
        srcRight={ougMatching.right.source.name}
        groupRight={ougMatching.right.name}
        toMap={this.state.toMap}
        processingErrors={this.props.createMatchingProcessingErrors}
      />
    );
  };

  _createNewOrgUnitsMatching = (
    programId,
    projectId,
    payload,
    matchingType,
    matching_id,
  ) => {
    this.setState(
      {
        toMap: payload.second_element.name || payload.second_element.dhis_id,
        matchTo: payload.first_element.name,
      },
      e =>
        this.props.createNewOrgUnitsMatching(
          programId,
          projectId,
          payload,
          matchingType,
          matching_id,
        ),
    );
  };

  handleOrgUnitMatchingCreate = matching => {
    const payload = {
      organisation_unit_group_matching: this.props.ougMatching.id,
      second_element: {
        source_id: this.props.ougMatching.right.source.id,
        id: matching.right.id,
        name: matching.right.name,
      },
    };
    this.setState(
      {
        toMap: payload.second_element.name || payload.second_element.dhis_id,
        matchTo: null,
      },
      e =>
        this.props.createNewOrgUnitsMatching(
          this.props.programId,
          this.props.projectId,
          payload,
          "organisation_unit_groups",
          this.props.ougMatching.id,
        ),
    );
  };

  handleOrgUnitMatchingDestroy = matchingIdToDestroy => {
    get("destroyOrgUnitsMatchingPrev", {
      programId: this.props.programId,
      projectId: this.props.projectId,
      id: matchingIdToDestroy,
    }).then(orgUnitGroups => {
      if (orgUnitGroups.length > 1) {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
          confirmDestroyWarnings: orgUnitGroups,
          confirmDestroyOpen: true,
        });
      } else {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
        });
        this.destroyOrgUnitsMatching();
      }
    });
  };

  orgUnitGroupsPreview() {
    const {
      ougMatching: { left: orgUnitGroupLeft, right: orgUnitGroupRight },
      classes,
      programId,
    } = this.props;

    return (
      <Grid container spacing={3}>
        {this.createMatchingDialog()}
        {[orgUnitGroupLeft, orgUnitGroupRight].map((oug, index) => (
          <Grid item xs={12} md={6} key={oug.id} className={classes.relative}>
            <OrgUnitGroupPreview
              projectId={this.props.projectId}
              programId={programId}
              group={oug}
              side={index === 0 ? "left" : "right"}
              target={index === 0 ? this.props.ougMatching.right.source : null}
            />
            {index === 0 && (
              <Tooltip
                id="tooltip-fab"
                title="These organisation unit groups should be equivalent."
                placement="bottom"
              >
                <Fab
                  color="primary"
                  aria-label="add"
                  className={classes.fabButton}
                >
                  <LinkIcon />
                </Fab>
              </Tooltip>
            )}
          </Grid>
        ))}
      </Grid>
    );
  }

  search_mappings = params => {
    const searched =
      params === ""
        ? []
        : this.props.orgUnitsLeft.filter(function(el) {
            return (
              el["name"].toLowerCase().includes(params.toLowerCase()) ||
              el["dhis_id"].toLowerCase().includes(params.toLowerCase())
            );
          });
    this.setState({
      searchedItems: searched,
      searchedItemsCount: searched.length,
      searchParams: params,
    });
  };

  searched_results = () => {
    return this.state.searchParams
      ? this.state.searchedItems
      : this.props.orgUnitsLeft;
  };

  handleCountChange = (dangling, count, searchParams) => {
    if (searchParams) {
      dangling
        ? this.setState({ danglingMatchingRowCount: count })
        : this.setState({ existingMatchingRowCount: count });
    } else {
      dangling
        ? this.setState((state, props) => ({
            danglingMatchingRowCount: props.danglingMatchingRowCount,
          }))
        : this.setState((state, props) => ({
            existingMatchingRowCount: props.existingMatchingRowCount,
          }));
    }
  };

  searched_results_count = () => {
    return this.state.searchParams
      ? this.state.searchedItemsCount
      : this.props.orgUnitsLeftRowCount;
  };

  render() {
    const {
      ougMatching: { left: orgUnitGroupLeft, right: orgUnitGroupRight },
      classes,
    } = this.props;

    const ougItemsCountDontMatch =
      orgUnitGroupLeft.elements_count !== orgUnitGroupRight.elements_count;

    return (
      <LayoutContainer>
        {this.confirmDestroyDialog()}
        <Grid item xs={12}>
          <p>
            Map all organistation units to the corresponding one in the
            destination DHIS2.
          </p>
          {ougItemsCountDontMatch && (
            <Alert type="danger">
              Organisations unit count in each group don’t match. Please verify
              that both group are identical in both Dhis2.
            </Alert>
          )}

          {this.orgUnitGroupsPreview()}

          <SectionHeader>
            Unmatched Organisation Units ({this.searched_results_count()})
          </SectionHeader>
          <UnmatchedNewSection
            search_mappings={this.search_mappings}
            projectId={this.props.projectId}
            programId={this.props.programId}
            itemTypeLabel="Organisation Unit"
            matching={this.props.ougMatching}
            itemsLeft={this.searched_results()}
            itemsLeftRowCount={this.searched_results_count()}
            itemsLeftFetching={this.props.orgUnitsLeftFetching}
            fetchItems={this.handleFetchOrgUnits}
            itemsRight={this.props.orgUnitsRight}
            knownMatch={true}
            itemsRightRowCount={this.props.orgUnitsRightRowCount}
            itemsRightFetching={this.props.orgUnitsRightFetching}
            matchingType="organisation_unit_groups"
            createItemsMatching={this._createNewOrgUnitsMatching}
            taskMatcher="task"
            itemClonesApi="orgUnitClones"
            sequencer_class="::Services::MetaMatchFlow::MatchOugMetaFlow"
            cloneOptions={[
              <TextField
                key="skipLevel"
                name="skip_level"
                label="Level to skip"
                fullWidth
                type="number"
                helperText="Level of the org unit hierarchy to skip (root = 1 and cannot be skipped)"
              />,
            ]}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.paper}>
              <SearcheableMatchingTable
                createMatching={this.handleOrgUnitMatchingCreate}
                projectId={this.props.projectId}
                matchingId={this.props.ougMatching.id}
                matchingType="organisation_unit_group_matching"
                dangling={true}
                fetchMetaMachings={(pageLoaded, force) =>
                  this.props.fetchOrgUnitsMatchings(
                    this.props.programId,
                    this.props.projectId,
                    "organisation_unit_group_matching",
                    this.props.ougMatching.id,
                    pageLoaded,
                    force,
                    true,
                  )
                }
                matchingsListRefreshed={this.props.matchingsListRefreshed}
                metaTypeMatching="orgUnitsMatchings"
                sectionHeaderLabel="Dangling Organisation Units Matchings"
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.paper}>
              <SearcheableMatchingTable
                destroyMatching={this.handleOrgUnitMatchingDestroy}
                projectId={this.props.projectId}
                matchingId={this.props.ougMatching.id}
                matchingType="organisation_unit_group_matching"
                dangling={false}
                fetchMetaMachings={(pageLoaded, force) =>
                  this.props.fetchOrgUnitsMatchings(
                    this.props.programId,
                    this.props.projectId,
                    "organisation_unit_group_matching",
                    this.props.ougMatching.id,
                    pageLoaded,
                    force,
                    false,
                  )
                }
                matchingsListRefreshed={this.props.matchingsListRefreshed}
                metaTypeMatching="orgUnitsMatchings"
                sectionHeaderLabel="Mapped Organisation Units"
              />
            </Grid>
          </Grid>
        </Grid>
      </LayoutContainer>
    );
  }
}

const orgUnitsForSource = (orgUnits, sourceId) => {
  return orgUnits[sourceId] && orgUnits[sourceId].entities
    ? _values(orgUnits[sourceId].entities)
    : [];
};

const orgUnitsMetaForSource = (orgUnits, sourceId) => {
  return orgUnits[sourceId] && orgUnits[sourceId].meta !== undefined
    ? orgUnits[sourceId].meta
    : { total_count: 0 };
};

const isFetching = (orgUnits, sourceId) => {
  return orgUnits[sourceId] && orgUnits[sourceId].isFetching;
};

const mapStateToProps = (state, props) => {
  const {
    ougMatching: { left: orgUnitGroupLeft, right: orgUnitGroupRight },
  } = props;

  const orgUnitsLeftMeta = orgUnitsMetaForSource(
    state.orgUnits,
    orgUnitGroupLeft.source.id,
  );
  const orgUnitsRightMeta = orgUnitsMetaForSource(
    state.orgUnits,
    orgUnitGroupRight.source.id,
  );

  const orgUnitsLeftRowCount = orgUnitsLeftMeta.total_count;
  const orgUnitsRightRowCount = orgUnitsRightMeta.total_count;

  const orgUnitsLeftFetching = isFetching(
    state.orgUnits,
    orgUnitGroupLeft.source.id,
  );
  const orgUnitsRightFetching = isFetching(
    state.orgUnits,
    orgUnitGroupRight.source.id,
  );

  const existingMatchingRowCount =
    state.orgUnitsMatchings.meta.total_count || 0;
  const danglingMatchingRowCount =
    state.orgUnitsMatchings.dangling.total_count || 0;

  return {
    programId: state.programs.currentProgram.id,
    orgUnitsLeft: orgUnitsForSource(state.orgUnits, orgUnitGroupLeft.source.id),
    orgUnitsRight: orgUnitsForSource(
      state.orgUnits,
      orgUnitGroupRight.source.id,
    ),
    orgUnitsLeftMeta,
    orgUnitsRightMeta,
    orgUnitsLeftRowCount,
    orgUnitsRightRowCount,
    orgUnitsLeftFetching,
    orgUnitsRightFetching,
    existingMatchingRowCount,
    danglingMatchingRowCount,
    creatingMatching: state.orgUnitsMatchings.creatingMatching,
    processingErrors: state.orgUnitsMatchings.processingErrors,
  };
};

export default connect(mapStateToProps, {
  fetchOrgUnits,
  createNewOrgUnitsMatching,
  destroyOrgUnitsMatching,
  refreshOrgUnitsMatchingsMasterList,
  orgUnitsMatchingsMasterListRefreshed,
  fetchOrgUnitsMatchings,
})(withRouter(withStyles(styles)(OrgUnitGroupsMatchingEditForm)));

import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
import React, { Component } from "react";

import MatchingListItem from "./MatchingListItem";
import SectionSpinner from "./SectionSpinner";

class MatchingList extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.initialState();
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refresh) {
      this.props.didUpdate();
      this.setState(this.initialState());
      this.fetchItems();
    }
    if (nextProps.matchingQuery !== this.props.matchingQuery) {
      this.props.fetchItems({
        sourceId: this.props.sourceId,
        query: nextProps.matchingQuery,
        page: this.state.pageLoaded,
        master: nextProps.master,
        forceReload: !nextProps.master,
      });
    }

    this.list && this.list.forceUpdateGrid();
  }

  fetchItems() {
    if (this.props.master) {
      this.props.fetchItems({
        sourceId: this.props.sourceId,
        query: this.props.matchingQuery,
        page: this.state.pageLoaded,
        master: this.props.master,
        forceReload: true,
      });
    }
  }

  initialState() {
    return {
      pageLoaded: 1,
    };
  }

  isRowLoaded = ({ index }) => {
    return !!this.props.items[index];
  };

  loadMoreRows = () => {
    const page = this.state.pageLoaded;
    this.setState(
      {
        pageLoaded: page + 1,
      },
      () =>
        this.props.fetchItems({
          sourceId: this.props.sourceId,
          query: this.props.matchingQuery,
          page: this.state.pageLoaded,
          master: this.props.master,
          forceReload: false,
        }),
    );
  };

  rowRenderer = ({ key, index, style }) => {
    return (
      <MatchingListItem
        key={key}
        index={index}
        style={style}
        item={this.props.items[index]}
        selected={this.props.selectedItemIndex === index}
        onSelect={this.selectItem}
      />
    );
  };

  selectItem = (index, item) => {
    if (index !== this.props.selectedItemIndex) {
      this.props.handleItemSelection(item, index, this.props.master);
    }
  };

  render() {
    const { isFetching, height = 300 } = this.props;

    if (!this.props.items.length && isFetching) {
      return <SectionSpinner />;
    }

    if (!this.props.items.length) {
      return null;
    }

    return (
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={this.props.rowCount}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                height={height}
                ref={ref => (this.list = ref)}
                onRowsRendered={onRowsRendered}
                rowCount={this.props.rowCount}
                rowHeight={80}
                rowRenderer={this.rowRenderer}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    );
  }
}

export default MatchingList;

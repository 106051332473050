import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class CompareMetaFlowParams extends Component {
  render() {
    const { task } = this.props;
    return (
      <TaskHashCell
        task={[
          !!task.params["side"]
            ? [{ label: "Side", value: task.params["side"] }]
            : [],
          [
            {
              label: "Meta left",
              value: `${
                task.params["leftSourceGroup"]
                  ? task.params["leftSourceGroup"]["name"]
                  : ""
              }`,
            },
          ],
          [
            {
              label: "Meta right",
              value: `${
                task.params["rightSourceGroup"]
                  ? task.params["rightSourceGroup"]["name"]
                  : ""
              }`,
            },
          ],
        ]}
      />
    );
  }
}
export default CompareMetaFlowParams;

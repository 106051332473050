import React, { Component, Fragment } from "react";
import { Chip, Divider, Grid, ListItem, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  OU_AGGREGATIONS,
  DE_AGGREGATIONS,
} from "../../Tasks/Forms/PushAnalyticFlow";
import ExecutionStatusShow from "./ExecutionStatusShow";

const styles = theme => ({
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
  },
});

class PushAnalyticsShow extends Component {
  render() {
    const { classes, task } = this.props;
    const periods_keys = task.summary["periods"]
      ? Object.keys(task.summary["periods"])
      : [];
    return (
      <Fragment>
        <ListItem>
          <Grid container spacing={3}>
            {!!task.summary["data_element_group_matching_name"] && (
              <Grid item xs={4}>
                <strong>Data Element Group matching:</strong>
              </Grid>
            )}
            {!!task.summary["data_element_group_matching_name"] && (
              <Grid item xs={8}>
                <Typography>
                  {task.summary["data_element_group_matching_name"]}
                </Typography>
              </Grid>
            )}
            {!!task.summary["data_set_matching_name"] && (
              <Grid item xs={4}>
                <strong>Data Set matching:</strong>
              </Grid>
            )}
            {!!task.summary["data_set_matching_name"] && (
              <Grid item xs={8}>
                <Typography>
                  {task.summary["data_set_matching_name"]}
                </Typography>
              </Grid>
            )}
            <Grid item xs={4}>
              <strong>Data Elements:</strong>
            </Grid>
            <Grid item xs={2}>
              <Typography>{task.summary["des_matching_count"]}</Typography>
            </Grid>
            <Grid item xs={2}>
              <strong>aggregation:</strong>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {DE_AGGREGATIONS[task.params["de_aggregation_type"]](
                  task.params,
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <strong>Organisation Units Group matching:</strong>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {task.summary["organisation_unit_group_matching_name"]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <strong>Organisation Units:</strong>
            </Grid>
            <Grid item xs={2}>
              <Typography>{task.summary["ous_matching_count"]}</Typography>
            </Grid>
            <Grid item xs={2}>
              <strong>aggregation:</strong>
            </Grid>
            <Grid item xs={4}>
              <Typography>
                {OU_AGGREGATIONS[task.params["ou_aggregation_type"]](
                  task.params,
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <strong>Periods(src => [target]):</strong>
            </Grid>
            <Grid item xs={8}>
              <div className={classes.root}>
                {task.summary["periods"] &&
                  periods_keys.map(function(period) {
                    return (
                      <Fragment>
                        <strong>[</strong>
                        {task.summary["periods"][period].map(function(
                          source_period,
                        ) {
                          return (
                            <Chip
                              key={source_period}
                              className={classes.chip}
                              label={source_period}
                            />
                          );
                        })}
                        <strong>]=></strong>
                        <Chip
                          key={period}
                          className={classes.chip}
                          label={period}
                        />
                        {periods_keys.length > 1 ? <Divider light /> : ""}
                      </Fragment>
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
        <ExecutionStatusShow task={task} />
      </Fragment>
    );
  }
}

export default withStyles(styles)(PushAnalyticsShow);

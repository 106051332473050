import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Lock } from "@material-ui/icons";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusPurging extends React.Component {
  render() {
    const { classes, withLabel } = this.props;
    return (
      <TaskStatus
        help="purging loaded dhis2 data from d2d database"
        lbl={withLabel ? "Purging data" : null}
        icon={<Lock className={classes.iconLeft} />}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusPurging);

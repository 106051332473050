import { RSAA } from "redux-api-middleware";
import store from "../store";

import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FAILED,
  RECEIVE_LOGIN,
  REQUEST_LOGOUT,
} from "../actionTypes";
import { addErrorNotifications, removeNotifications } from "./notifications";
import { toggleDrawer } from "./ui";
import { baseApiUrl } from "./api";

// Action creators

function receiveLogout() {
  return {
    type: REQUEST_LOGOUT,
  };
}

export function logoutUser() {
  return dispatch => {
    dispatch(receiveLogout());
    dispatch(toggleDrawer());
  };
}

// Api calls

export function loginUser(userParams) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/sessions`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userParams),
      types: [
        {
          type: REQUEST_LOGIN,
          payload: (action, state, response) => {
            store.dispatch(removeNotifications("loginForm"));
            return response;
          },
        },
        RECEIVE_LOGIN,
        {
          type: REQUEST_LOGIN_FAILED,
          payload: (action, state, response) => {
            response
              .json()
              .then(json =>
                store.dispatch(addErrorNotifications("loginForm", json.errors)),
              );
            return response;
          },
        },
      ],
    },
  };
}

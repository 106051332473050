import * as types from "../actionTypes";

import { authHeader, baseApiUrl } from "./api";

import { RSAA } from "redux-api-middleware";
import store from "../store";

const typeTypes = {
  data_elements: [
    types.REQUEST_DE_SUGGESTIONS,
    types.RECEIVE_DE_SUGGESTIONS,
    types.REQUEST_DE_SUGGESTIONS_FAILED,
  ],
  data_element_groups: [
    types.REQUEST_DEG_SUGGESTIONS,
    types.RECEIVE_DEG_SUGGESTIONS,
    types.REQUEST_DEG_SUGGESTIONS_FAILED,
  ],
  organisation_units: [
    types.REQUEST_OU_SUGGESTIONS,
    types.RECEIVE_OU_SUGGESTIONS,
    types.REQUEST_OU_SUGGESTIONS_FAILED,
  ],
  organisation_unit_groups: [
    types.REQUEST_OUG_SUGGESTIONS,
    types.RECEIVE_OUG_SUGGESTIONS,
    types.REQUEST_OUG_SUGGESTIONS_FAILED,
  ],
  categories: [
    types.REQUEST_CATEGORY_SUGGESTIONS,
    types.RECEIVE_CATEGORY_SUGGESTIONS,
    types.REQUEST_CATEGORY_SUGGESTIONS_FAILED,
  ],
  category_combos: [
    types.REQUEST_CATEGORY_COMBO_SUGGESTIONS,
    types.RECEIVE_CATEGORY_COMBO_SUGGESTIONS,
    types.REQUEST_CATEGORY_COMBO_SUGGESTIONS_FAILED,
  ],
  data_sets: [
    types.REQUEST_DATA_SET_SUGGESTIONS,
    types.RECEIVE_DATA_SET_SUGGESTIONS,
    types.REQUEST_DATA_SET_SUGGESTIONS_FAILED,
  ],
};

export function fetchSuggestions(params) {
  const { projectId, sourceId, type, query, instance, programId } = params;
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/sources/${sourceId}/${type}?q=${query}${
        instance !== undefined ? "&instance=" + instance : ""
      }`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: typeTypes[type],
    },
  };
}

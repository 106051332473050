import React, { Component, Fragment } from "react";
import { Divider, Grid, ListItem, Typography } from "@material-ui/core";
import ExecutionStatusShow from "./ExecutionStatusShow";

class CompareMetaShow extends Component {
  sided = (task, side) => (
    <Fragment>
      <Grid item xs={4}>
        <strong class="capitalize">{side} group:</strong>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          {`${task.params[`${side}SourceGroup`]["name"]} in ${
            !!task.params[`${side}SourceGroup`]["local"] ? "D2D" : "DHIS"
          } `}
          <font class="capitalize">
            {task.params["side"] || side === "left" ? "Source" : "Target"}
          </font>
        </Typography>
      </Grid>
    </Fragment>
  );

  render() {
    const { task } = this.props;

    return (
      <Fragment>
        <ListItem>
          <Grid container spacing={3}>
            {["left", "right"].map(side => this.sided(task, side))}
          </Grid>
        </ListItem>
        <Divider light />
        <ExecutionStatusShow task={task} />
      </Fragment>
    );
  }
}

export default CompareMetaShow;

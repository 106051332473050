import React, { Component } from "react";
import moment from "moment";

import TaskHashCell, { disaplayPeriodTypes } from "../TaskHashCell";

class DumpDataFlowParams extends Component {
  render() {
    const { task } = this.props;
    return (
      <TaskHashCell
        task={[
          [
            {
              label: "Ougs matching",
              value: task.summary["organisation_unit_group_matching_name"],
            },
            {
              label: "Degs matching",
              value: task.summary["data_element_group_matching_name"],
            },
            {
              label: "Ds matching",
              value: task.summary["data_set_matching_name"],
            },
          ],
          task.params["start_date"] && task.params["end_date"]
            ? [
                {
                  label: "Start date",
                  value: moment
                    .utc(task.params["start_date"])
                    .format("MM/YYYY"),
                },
                {
                  label: "End date",
                  value: moment.utc(task.params["end_date"]).format("MM/YYYY"),
                },
                {
                  label: "Period types",
                  value: disaplayPeriodTypes(task.params["period_types"] || []),
                },
              ]
            : [],
        ]}
      />
    );
  }
}
export default DumpDataFlowParams;

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Home from "@material-ui/icons/Home";

const styles = theme => ({
  homeIcon: {
    color: theme.palette.primary[500],
    position: "relative",
    top: 5,
    marginRight: 5,
    marginLeft: 5,
  },
});

function PreviewSource(props) {
  return (
    <div>
      <Home className={props.classes.homeIcon} />
      {props.label}
    </div>
  );
}

export default withStyles(styles)(PreviewSource);

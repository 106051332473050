import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Grid,
  FormControl,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withStyles from "@material-ui/core/styles/withStyles";
import S3BucketConfig from "../../General/S3BucketConfig";
import DataElementGroupsMatchingsSelect from "../../DataElementGroupsMatchings/DataElementGroupsMatchingsSelect";
import OrgUnitGroupsMatchingsSelect from "../../OrgUnitGroupsMatchings/OrgUnitGroupsMatchingsSelect";
import PeriodIntervalForm from "../PeriodIntervalForm";
import PeriodRangeForm from "../PeriodRangeForm";
import PeriodTypesSelect from "../../Shared/PeriodTypesSelect";
import CheckBoxComponent from "../Shared/CheckBoxComponent";
import MenuItem from "@material-ui/core/MenuItem";
import {
  formStyles,
  expansionPanelStyles,
} from "../../../helpers/commonStyles";
import DataSetsMatchingsSelect from "../../DataSetsMatchings/DataSetsMatchingsSelect";
import { PUSH_DATA_FLOW, DUMP_DATA_FLOW } from "../constants";
import { KeyboardDatePicker } from "@material-ui/pickers";
import HelpIcon from "@material-ui/icons/Help";

const styles = theme => ({
  ...formStyles(theme),
  ...expansionPanelStyles(theme),
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: "5px",
  },
});

class PushDataFlowFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.expandDsOrDeg(props.entity.params),
      preview: props.immediate ? "Preview" : "",
      expandedLastUpdated: this.expendedType(props.entity.params),
      lastUpdated: this.getSelectDate(props.entity.params),
      timeUnit: this.getSelectedDuration(props.entity.params, "timetype"),
      duration: this.getSelectedDuration(props.entity.params, "duration"),
      type: this.checkedType(props.entity.params),
    };
  }

  expandDsOrDeg(params) {
    if (params.data_set_matching_id !== "") {
      return "panel-ds";
    } else if (params.data_element_group_matching_id !== "") {
      return "panel-deg";
    } else {
      return "panel-deg";
    }
  }

  componentDidMount() {
    this.props.handleChange(`send_mail`, 0);
    if (this.props.immediate) {
      this.props.handleParamChange("preview", true);
    }
  }

  checkedType(params) {
    if ("last_updated" in params) {
      return "last_updated";
    } else if ("last_updated_duration" in params) {
      return "last_updated_duration";
    } else {
      return null;
    }
  }

  expendedType(params) {
    if ("last_updated" in params) {
      return "panel_expanded_1";
    } else if ("last_updated_duration" in params) {
      return "panel_expanded_2";
    } else {
      return "";
    }
  }

  getSelectDate(params) {
    if ("last_updated" in params) {
      return params["last_updated"];
    } else {
      new Date();
    }
  }

  getSelectedDuration(params, type) {
    if ("last_updated_duration" in params) {
      return type === "duration"
        ? params["last_updated_duration"].substring(
            0,
            params["last_updated_duration"].length - 1,
          )
        : params["last_updated_duration"][
            params["last_updated_duration"].length - 1
          ];
    } else {
      return type === "duration" ? 0 : "d";
    }
  }

  setDefaultLastUpdatedPeriod(type) {
    let params = this.props.entity.params;
    switch (type) {
      case "last_updated":
        const currentDate = new Date();
        if (!("last_updated" in params)) {
          this.props.handleParamChange("last_updated", currentDate);
          this.removeParam("last_updated_duration");
        }
        break;
      case "last_updated_duration":
        if (!("last_updated_duration" in params)) {
          this.props.handleParamChange("last_updated_duration", "0d");
          this.removeParam("last_updated");
        }
        break;
      default:
    }
  }

  handleChange = panel => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  handleDateChange = date => {
    this.setState({ lastUpdated: date });
    this.props.handleParamChange("last_updated", date.format("YYYY-MM-DD"));
    this.removeParam("last_updated_duration");
    this.setState({ type: "last_updated" });
  };

  handleExpandeLastUpdated = (panel, type) => (event, isExpanded) => {
    this.setState({
      expandedLastUpdated: isExpanded ? panel : false,
      type: type,
    });
    if (
      this.state.expandedLastUpdated === "" ||
      !this.state.expandedLastUpdated
    ) {
      this.setDefaultLastUpdatedPeriod(type);
    }
  };

  handleTypeChange = (type, panel) => event => {
    if (panel === this.state.expandedLastUpdated) {
      this.setState({ type: null });
      this.removeParam("last_updated");
      this.removeParam("last_updated_duration");
    } else {
      this.setDefaultLastUpdatedPeriod(type);
      this.setState({ type: type, expandedLastUpdated: panel });
    }
  };

  onChangeS3bucket = s3bucket => {
    this.props.handleParamChange("s3bucket", s3bucket);
  };

  handlePeriodTypeChange = period => event => {
    let sched = this.props.entity;
    let periods = sched.params.period_types;
    if (event.target.checked) {
      periods.push(period);
    } else {
      periods = periods.filter(p => p !== period);
    }
    this.props.handleParamChange("period_types", periods);
  };

  handlePreviewChange = event => {
    if (this.state.preview === "") {
      this.setState({ preview: "Preview" });
      this.props.handleParamChange("preview", true);
    } else {
      this.setState({ preview: "" });
      this.props.handleParamChange("preview", false);
    }
  };

  selectDegLabel = degs => {
    this.getDegLabel(degs);
  };

  getDegLabel = degs => {
    const degId = this.props.entity.params.data_element_group_matching_id;
    const deg = degs.find(deg => deg.value === degId);
    if (deg !== undefined) {
      this.props.handleParamChange("deg_label", deg.label);
    }
  };

  selectOugLabel = ougs => {
    this.getOugLabel(ougs);
  };

  getOugLabel = ougs => {
    const ougId = this.props.entity.params.organisation_unit_group_matching_id;
    const oug = ougs.find(oug => oug.value === ougId);
    if (oug !== undefined) {
      this.props.handleParamChange("oug_label", oug.label);
    }
  };

  selectDsLabel = dataSets => {
    this.getDsLabel(dataSets);
  };

  getDsLabel = dataSets => {
    const dsId = this.props.entity.params.data_set_matching_id;
    const ds = dataSets.find(ds => ds.value === dsId);
    this.props.handleParamChange("ds_label", ds.label);
  };

  handleTimeUnitChange = (time, type) => event => {
    switch (time) {
      case "duration":
        this.setState({ duration: event.target.value });
        this.props.handleParamChange(
          "last_updated_duration",
          this.parseDuration(event.target.value, this.state.timeUnit),
        );

        break;
      case "timeUnit":
        this.setState({ timeUnit: event.target.value });
        this.props.handleParamChange(
          "last_updated_duration",
          this.parseDuration(this.state.duration, event.target.value),
        );
        break;
      default:
    }
    this.removeParam("last_updated");
    this.setState({ type: "last_updated_duration" });
  };

  parseDuration(duration, timeUnit) {
    switch (timeUnit) {
      case "h":
        return duration + timeUnit;
      case "d":
        return duration + timeUnit;
      case "w":
        return duration * 7 + "d";
      case "m":
        return duration * 30 + "d";
      case "y":
        return duration * 365 + "d";
      default:
    }
  }

  removeParam(key) {
    if (this.props.entity.params[key] !== undefined) {
      this.props.handleRemoveParam(key);
    }
  }

  render() {
    const { lastUpdated } = this.state;
    const timeUnits = [
      {
        value: "y",
        label: " Year(s)",
      },
      {
        value: "m",
        label: " Month(s)",
      },
      {
        value: "w",
        label: " Week(s)",
      },
      {
        value: "d",
        label: " Day(s)",
      },
      {
        value: "h",
        label: " Hour(s)",
      },
    ];
    const {
      classes,
      project,
      projectId,
      entity,
      immediate,
      handleParamChange,
      handleRemoveParam,
    } = this.props;
    const previewInfo =
      "The preview data is not selected. If it isn’t selected you will not be able to check the data before pushing it on the target. We strongly recommend to select the preview option!";
    return (
      <Fragment>
        <Grid item xs={12}>
          <ExpansionPanel
            className={classes.expansionPanel}
            square
            expanded={this.state.expanded === "panel-deg"}
            onChange={this.handleChange("panel-deg")}
          >
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>FROM DEG</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <DataElementGroupsMatchingsSelect
                projectId={projectId}
                name="data_element_group_matching_id"
                onChange={e =>
                  handleParamChange(
                    "data_element_group_matching_id",
                    e.target.value,
                  )
                }
                onChangeLabel={this.selectDegLabel}
                value={entity.params.data_element_group_matching_id}
              />
              <OrgUnitGroupsMatchingsSelect
                projectId={projectId}
                name="organisation_unit_group_matching_id"
                onChange={e =>
                  handleParamChange(
                    "organisation_unit_group_matching_id",
                    e.target.value,
                  )
                }
                onChangeLabel={this.selectOugLabel}
                value={entity.params.organisation_unit_group_matching_id}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.expansionPanel}
            square
            expanded={this.state.expanded === "panel-ds"}
            onChange={this.handleChange("panel-ds")}
          >
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography>FROM DS</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <DataSetsMatchingsSelect
                projectId={projectId}
                name="data_set_matching_id"
                onChange={e =>
                  handleParamChange("data_set_matching_id", e.target.value)
                }
                onChangeLabel={this.selectDsLabel}
                value={entity.params.data_set_matching_id}
              />
              <OrgUnitGroupsMatchingsSelect
                projectId={projectId}
                label="OUG matching (optional: replace DS OU members)"
                name="organisation_unit_group_matching_id"
                onChange={e =>
                  handleParamChange(
                    "organisation_unit_group_matching_id",
                    e.target.value,
                  )
                }
                onChangeLabel={this.selectOugLabel}
                value={entity.params.organisation_unit_group_matching_id}
                withNull={true}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        {immediate && (
          <Grid item xs={12}>
            <FormLabel className={classes.sectionTitle} component="legend">
              Period interval(From which date to which date)
            </FormLabel>
            <PeriodIntervalForm
              entity={entity}
              handleParamChange={handleParamChange}
            />
          </Grid>
        )}
        {!immediate && (
          <Grid item xs={12}>
            <PeriodRangeForm
              entity={entity}
              handleParamChange={handleParamChange}
              handleRemoveParam={handleRemoveParam}
            />
          </Grid>
        )}
        {this.state.expanded !== "panel-ds" && (
          <Grid item xs={12}>
            <FormControl className={classes.textField}>
              <PeriodTypesSelect
                selectedPeriodTypes={entity.params.period_types}
                handlePeriodTypeChange={this.handlePeriodTypeChange}
                componentStyle={classes.sectionTitle}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormLabel className={classes.sectionTitle} component="legend">
            Last updated data on source
          </FormLabel>
          <ExpansionPanel
            className={classes.expansionPanel}
            square
            expanded={this.state.expandedLastUpdated === "panel_expanded_1"}
            onChange={this.handleExpandeLastUpdated(
              "panel_expanded_1",
              "last_updated",
            )}
          >
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="paneld-content"
              id="paneld-header"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.type === "last_updated"}
                    color="primary"
                    onChange={this.handleTypeChange(
                      "last_updated",
                      "panel_expanded_1",
                    )}
                  />
                }
                label="Last updated date"
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <FormControl className={classes.textField}>
                    <KeyboardDatePicker
                      label="Date picker"
                      value={lastUpdated}
                      format="DD/MM/YYYY"
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.expansionPanel}
            square
            expanded={this.state.expandedLastUpdated === "panel_expanded_2"}
            onChange={this.handleExpandeLastUpdated(
              "panel_expanded_2",
              "last_updated_duration",
            )}
          >
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="paneld-content"
              id="paneld-header"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.type === "last_updated_duration"}
                    color="primary"
                    onChange={this.handleTypeChange(
                      "last_updated_duration",
                      "panel_expanded_2",
                    )}
                  />
                }
                label="Last updated duration"
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <FormControl className={classes.textField}>
                    <TextField
                      id="standard-number"
                      label="Number"
                      value={this.state.duration}
                      onChange={this.handleTimeUnitChange("duration")}
                      type="number"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl className={classes.textField}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select"
                      className={classes.textField}
                      value={this.state.timeUnit}
                      onChange={this.handleTimeUnitChange("timeUnit")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                    >
                      {timeUnits.map(time => (
                        <MenuItem key={time.value} value={time.value}>
                          {time.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <FormLabel className={classes.sectionTitle} component="legend">
            Target
          </FormLabel>
          <FormGroup>
            <ExpansionPanel
              className={classes.expansionPanel}
              square
              expanded={false}
            >
              <ExpansionPanelSummary className={classes.expansionPanelSummary}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={entity.sequencer_class === PUSH_DATA_FLOW}
                      color="primary"
                      onChange={e =>
                        this.props.handleChange(
                          "sequencer_class",
                          PUSH_DATA_FLOW,
                        )
                      }
                    />
                  }
                  label={`DHIS2: ${project.right_source.name} - ${project.right_source.dhis_url}`}
                />
              </ExpansionPanelSummary>
            </ExpansionPanel>
            <ExpansionPanel
              className={classes.expansionPanel}
              square
              expanded={entity.sequencer_class === DUMP_DATA_FLOW}
            >
              <ExpansionPanelSummary
                className={classes.expansionPanelSummary}
                expandIcon={<ExpandMoreIcon />}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={entity.sequencer_class === DUMP_DATA_FLOW}
                      color="primary"
                      onChange={e =>
                        this.props.handleChange(
                          "sequencer_class",
                          DUMP_DATA_FLOW,
                        )
                      }
                    />
                  }
                  label="S3 bucket"
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                <S3BucketConfig
                  s3bucket={{ ...project.s3bucket, ...entity.params.s3bucket }}
                  currentProgram={this.props.currentProgram}
                  onChange={this.onChangeS3bucket}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </FormGroup>
        </Grid>
        {immediate && !this.state.preview && (
          <Typography color="primary">
            <HelpIcon />
            <b>{previewInfo}</b>
          </Typography>
        )}
        {immediate && entity.sequencer_class !== DUMP_DATA_FLOW && (
          <Grid item xs={12}>
            <CheckBoxComponent
              preview={this.state.preview}
              checkboxLabel="Preview data to push :"
              label="Preview"
              handlePreviewChange={() => this.handlePreviewChange}
              componentStyle={classes.sectionTitle}
            />
          </Grid>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps)(withStyles(styles)(PushDataFlowFrom));

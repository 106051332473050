import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";
import { get, post } from "../../actions/api";
import UnmatchedLeft from "./UnmatchedLeft";
import ConfirmDialog from "./ConfirmDialog";
import ProcessingDialog from "./ProcessingDialog";
import CloneForm from "./CloneForm";
import MatchingRight from "./MatchingRight";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { general } from "../../helpers/commonStyles";
import { createImmediateTask } from "../../actions/tasks";

const styles = theme => ({
  ...general(theme),
});

const selectedItemsEmptyState = {
  selectedItemsIds: {
    left: { id: null, index: null },
    right: { id: null, index: null },
  },
};

class UnmatchedNewSection extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...selectedItemsEmptyState,
      itemMatchingQuery: undefined,
      matchingAction: "match",
      confirmItemCloneOpen: false,
      confirmBulkCloneOpen: false,
      processingDialogOpen: false,
      processorId: null,
      timeoutId: null,
    };
    this.contextualMenuItemSelected = this.contextualMenuItemSelected.bind(
      this,
    );
    this.confirmBulkCloneDialog = this.confirmBulkCloneDialog.bind(this);
    this.confirmItemCloneDialog = this.confirmItemCloneDialog.bind(this);
    this.launchBulkAutoMap = this.launchBulkAutoMap.bind(this);
    this.handleBulkClone = this.handleBulkClone.bind(this);
    this.launchBulkClone = this.launchBulkClone.bind(this);
    this.handleItemClone = this.handleItemClone.bind(this);
    this.launchItemClone = this.launchItemClone.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handleMatchingActionChange = (e, value) => {
    this.setState({ matchingAction: value });
  };

  handleItemSelection = (item, index, master) => {
    const side = master ? "left" : "right";

    if (side === "left") {
      this.setState({
        selectedItemsIds: {
          left: {
            id: item.id,
            index: index,
            name: item.name,
          },
          right: { id: null, index: null },
        },
        itemMatchingQuery: item.name,
      });
    } else {
      this.createItemsMatching(item);
    }
  };

  closeConfirmBulkCloneDialog = () => {
    this.setState({ confirmBulkCloneOpen: false });
  };

  closeConfirmItemCloneDialog = () => {
    this.setState({ confirmItemCloneOpen: false });
  };

  closeProcessingDialog = () => {
    clearTimeout(this.state.timeoutId);
    this.setState({ processingDialogOpen: false });
  };

  contextualMenuItemSelected(index) {
    switch (index) {
      case 0:
        this.launchBulkAutoMap();
        break;
      case 1:
        this.handleBulkClone();
        break;
      default:
        break;
    }
  }

  createItemsMatching(item) {
    if (this.state.selectedItemsIds.left.id) {
      const payload = {
        [`${this.props.matchingType}_matching`]: this.props.matching.id,
        first_element: {
          source_id: this.props.matching.left.source.id,
          id: this.state.selectedItemsIds.left.id,
          name: this.state.selectedItemsIds.left.name,
        },
        second_element: {
          source_id: this.props.matching.right.source.id,
          id: item.id,
          name: item.name,
          dhis_id: item.dhis_id,
        },
      };
      this.setState(
        {
          ...selectedItemsEmptyState,
        },
        e =>
          this.props.createItemsMatching(
            this.props.programId,
            this.props.projectId,
            payload,
            this.props.matchingType,
            this.props.matching.id,
          ),
      );
    }
  }

  launchBulkAutoMap() {
    this.setState({
      processingErrors: null,
      matchingAction: "match",
    });
    this.props
      .createImmediateTask(
        this.props.programId,
        this.props.projectId,
        {
          task: {
            params: {
              left_source: this.props.matching.left.source.id,
              right_source: this.props.matching.right.source.id,
              matchingId: this.props.matching.id,
              left_dhis_id: this.props.matching.left.dhis_id,
              right_dhis_id: this.props.matching.right.dhis_id,
              left_name: this.props.matching.left.name,
              right_name: this.props.matching.right.name,
              entities_type: this.props.entitiesType
                ? this.props.entitiesType
                : false,
            },
            sequencer_class: this.props.sequencer_class,
          },
        },
        false,
      )
      .then(autoMatcher => {
        this.setState(
          { processorId: autoMatcher.payload.id, processingDialogOpen: true },
          this.delayedCheckProcessing,
        );
      });
  }

  handleBulkClone = () => {
    this.setState({ confirmBulkCloneOpen: true });
  };

  launchClone(payload) {
    this.setState({
      processingErrors: null,
      matchingAction: "clone",
    });
    post(
      this.props.itemClonesApi,
      {
        programId: this.props.programId,
        projectId: this.props.projectId,
        sourceId: this.props.matching.left.source.id,
      },
      payload,
    ).then(cloner => {
      this.setState(
        { processorId: cloner.id, processingDialogOpen: true },
        this.delayedCheckProcessing,
      );
    });
  }

  launchBulkClone() {
    this.closeConfirmBulkCloneDialog();
    let content = {
      group_matching_type: this.props.matchingType,
      group_matching_id: this.props.matching.id,
      prefix: this.state.prefix,
      postfix: this.state.postfix,
    };
    if (this.props.cloneOptions) {
      this.props.cloneOptions.forEach(option => {
        content[option.props.name] = this.state[option.props.name];
      });
    }
    this.launchClone(content);
  }

  handleItemClone = () => {
    this.setState({ confirmItemCloneOpen: true });
  };

  launchItemClone() {
    this.closeConfirmItemCloneDialog();
    let content = {
      group_matching_type: this.props.matchingType,
      group_matching_id: this.props.matching.id,
      item_id: this.state.selectedItemsIds.left.id,
      prefix: this.state.prefix,
      postfix: this.state.postfix,
    };
    if (this.props.cloneOptions) {
      this.props.cloneOptions.forEach(option => {
        content[option.props.name] = this.state[option.props.name];
      });
    }
    this.launchClone(content);
  }

  delayedCheckProcessing() {
    this.setState({
      timeoutId: setInterval(this.checkProcessing, 2000),
    });
  }

  checkProcessing = () => {
    this.state.matchingAction === "match"
      ? this.checkAutoMapProcessing()
      : this.checkCloneProcessing();
  };

  checkAutoMapProcessing = () => {
    const { projectId, programId } = this.props;
    get(this.props.taskMatcher, {
      projectId: projectId,
      programId: programId,
      id: this.state.processorId,
    }).then(autoMatcher => {
      if (autoMatcher.has_completed) {
        window.location.reload();
      } else if (autoMatcher.has_failed) {
        this.setState({ processingErrors: autoMatcher.message });
      }
    });
  };

  checkCloneProcessing = () => {
    const {
      projectId,
      programId,
      matching: { left: groupLeft },
    } = this.props;
    get(this.props.itemClonesApi, {
      programId: programId,
      projectId: projectId,
      sourceId: groupLeft.source.id,
      id: this.state.processorId,
    }).then(cloner => {
      if (cloner.is_processed) {
        window.location.reload();
      } else if (cloner.has_errored) {
        this.setState({ processingErrors: cloner.latest_errors });
      }
    });
  };

  confirmBulkCloneDialog = () => {
    const {
      matching,
      itemTypeLabel,
      classes: { primaryText },
    } = this.props;
    return (
      <ConfirmDialog
        handleConfirm={this.launchBulkClone}
        handleClose={this.closeConfirmBulkCloneDialog}
        open={this.state.confirmBulkCloneOpen}
        title="Please confirm"
      >
        <Typography>
          Are you sure you want to <b>COPY</b>{" "}
          <strong className={primaryText}>ALL</strong> unmatched {itemTypeLabel}
          s from
          <strong className={primaryText}> {matching.left.source.name} </strong>
          to
          <strong className={primaryText}> {matching.right.source.name}</strong>
          ?
        </Typography>
        <CloneForm
          handleChange={this.handleChange}
          cloneOptions={this.props.cloneOptions}
        />
      </ConfirmDialog>
    );
  };

  confirmItemCloneDialog = () => {
    const {
      matching,
      itemTypeLabel,
      classes: { primaryText },
    } = this.props;
    return (
      <ConfirmDialog
        handleConfirm={this.launchItemClone}
        handleClose={this.closeConfirmItemCloneDialog}
        open={this.state.confirmItemCloneOpen}
        title="Please confirm"
      >
        <Typography>
          Are you sure you want to <b>COPY</b> the
          <strong className={primaryText}>
            {" "}
            {this.state.selectedItemsIds.left.name}{" "}
          </strong>
          {itemTypeLabel} from
          <strong className={primaryText}> {matching.left.source.name} </strong>
          to
          <strong className={primaryText}> {matching.right.source.name}</strong>
          ?
        </Typography>
      </ConfirmDialog>
    );
  };

  processingDialog = () => {
    const {
      matching,
      classes: { primaryText, secondaryText },
    } = this.props;
    return (
      <ProcessingDialog
        open={this.state.processingDialogOpen}
        handleClose={this.closeProcessingDialog}
        errors={this.state.processingErrors}
      >
        <Typography className={secondaryText}>
          {this.state.matchingAction === "match" ? "Matching " : "Copying "}
          from
          <strong className={primaryText}> {matching.left.source.name} </strong>
          to
          <strong className={primaryText}> {matching.right.source.name}</strong>
          ?
        </Typography>
      </ProcessingDialog>
    );
  };

  render() {
    const { classes, ...others } = this.props;
    return (
      <Grid container spacing={3}>
        {this.confirmBulkCloneDialog()}
        {this.confirmItemCloneDialog()}
        {this.processingDialog()}
        <Grid item xs={12} md={6}>
          <UnmatchedLeft
            {...others}
            contextualMenuItemSelected={this.contextualMenuItemSelected}
            handleItemSelection={this.handleItemSelection}
            selectedItemIndex={this.state.selectedItemsIds.left.index}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MatchingRight
            {...others}
            itemMatchingQuery={this.state.itemMatchingQuery}
            itemsRightFetching={this.props.itemsRightFetching}
            handleItemSelection={this.handleItemSelection}
            handleCloneFormChange={this.handleChange}
            handleItemClone={this.handleItemClone}
            selectedItemsIds={this.state.selectedItemsIds}
          />
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createImmediateTask })(
  withRouter(withStyles(styles)(UnmatchedNewSection)),
);

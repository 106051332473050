import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import TestConnectionButton from "./TestConnectionButton";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  testSourceConnection,
  invalidateSourceConnection,
} from "../../actions/sources";
import { textField } from "../../helpers/commonStyles";
import _isEqual from "lodash/isEqual";

const styles = theme => ({
  ...textField(theme),
  formControl: {
    width: "100%",
  },
  formFooter: {
    flex: "0 0 auto",
    margin: `${theme.spacing()} 0`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const DHIS_VERSIONS = ["2.25", "2.26", "2.27", "2.28"];

const _newSource = {
  name: "",
  dhis_user: "",
  dhis_url: "",
  dhis_password: "",
  dhis_version: "",
};

class DhisConnectorForm extends Component {
  constructor(props) {
    super(props);
    this.state = { source: { ..._newSource, ...props.source } };
    this.handleChange = this.handleChange.bind(this);
    this.propagateChange = () => {
      this.props.handleSourceChange(this.state.source);
    };
  }

  componentDidMount() {
    this.setState(
      { source: { ...this.state.source, ...this.props.source } },
      () => {
        if (this.state.dhis_url) {
          this.handleTestConnection();
        }
      },
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_isEqual(prevProps.source, this.props.source)) {
      this.setState({ source: { ...this.state.source, ...this.props.source } });
    }

    if (
      this.props.sourceConnection.version &&
      !_isEqual(
        prevProps.sourceConnection.version,
        this.props.sourceConnection.version,
      )
    ) {
      this.handleVersionChange();
    }
  }

  handleChange = (prop, value) => {
    this.setState({ source: { ...this.state.source, [prop]: value } }, () => {
      this.propagateChange();
    });
  };

  handleTestConnection = () => {
    const formValues = {
      name: this._name.value,
      dhis_url: this._dhis_url.value,
      dhis_user: this._dhis_user.value,
      dhis_password: this._dhis_password.value,
    };
    this.props.testSourceConnection(this.props.sourceType, formValues);
  };

  handleVersionChange = () => {
    let { source } = this.state;
    const source_dhis_version = source.dhis_version;
    let version = this.props.sourceConnection.version;
    if (
      source_dhis_version === "" ||
      (source_dhis_version && version !== source_dhis_version)
    ) {
      source.dhis_version = version;
      this.setState({
        ...source,
      });
      this.setState(
        {
          source: {
            ...this.state.source,
            dhis_version: this.dhis_version(version),
            dhis_version_display: version,
          },
        },
        () => {
          this.propagateChange();
        },
      );
    } else {
    }
  };

  dhis_version = version => {
    if (DHIS_VERSIONS.includes(version)) {
      return version;
    } else {
      return "2.28";
    }
  };

  display_dhis_version = (connection, db_dhis_version, local_dhis_version) => {
    if (!connection && db_dhis_version) {
      return db_dhis_version;
    } else if (connection && local_dhis_version) {
      return local_dhis_version;
    }
  };

  render() {
    const { classes } = this.props;
    const { source } = this.state;
    const local_dhis_version = this.props.sourceConnection.version;
    const connectionOk = this.props.sourceConnection.connectionOk;
    return (
      <div>
        <TextField
          className={classes.textField}
          inputRef={input => (this._name = input)}
          label="Dhis name"
          fullWidth
          margin="normal"
          value={source.name}
          onChange={e => this.handleChange("name", e.target.value)}
        />
        <TextField
          className={classes.textField}
          inputRef={input => (this._dhis_url = input)}
          label="Url"
          fullWidth
          margin="normal"
          value={source.dhis_url}
          onChange={e => this.handleChange("dhis_url", e.target.value)}
        />
        <TextField
          className={classes.textField}
          inputRef={input => (this._dhis_user = input)}
          label="User"
          fullWidth
          margin="normal"
          value={source.dhis_user}
          onChange={e => this.handleChange("dhis_user", e.target.value)}
        />
        <TextField
          className={classes.textField}
          inputRef={input => (this._dhis_password = input)}
          label="Password"
          fullWidth
          margin="normal"
          type="password"
          value={source.dhis_password}
          onChange={e => this.handleChange("dhis_password", e.target.value)}
        />
        {source.dhis_version_display && (
          <TextField
            className={classes.textField}
            label="Dhis Version"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            margin="normal"
            variant="filled"
            value={this.display_dhis_version(
              connectionOk,
              source.dhis_version_display,
              local_dhis_version,
            )}
          />
        )}

        <div className={classes.formFooter}>
          <TestConnectionButton
            source={this.props.sourceConnection}
            handleTestConnection={this.handleTestConnection}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  sourceConnection: state.sources[props.sourceType],
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { testSourceConnection, invalidateSourceConnection },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(DhisConnectorForm));

import * as types from "../actionTypes";

function tasks(
  state = {
    entities: [],
    isFetching: false,
    meta: {
      total_count: 0,
      per_page: 100,
      page: 1,
    },
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_PUSH_TASKS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case types.RECEIVE_PUSH_TASKS:
      return Object.assign({}, state, {
        entities: action.payload.data,
        isFetching: false,
        meta: action.payload.meta,
      });
    case types.REQUEST_PUSH_TASKS_FAILED:
      return Object.assign({}, state, {
        errors: action.payload.errors,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default tasks;

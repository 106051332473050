import React, { Component, Fragment } from "react";
import GeneralForm from "./GeneralForm";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchProject, updateProject } from "../../actions/projects";
import { leftIcon, formStyles } from "../../helpers/commonStyles";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  ...formStyles(theme),
  ...leftIcon(theme),
});

class GeneralEdit extends Component {
  render() {
    const { classes, currentProgram } = this.props;
    return (
      <Fragment>
        <AppBarDefault>
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${
              !!currentProgram ? currentProgram.id : ""
            }/projects`}
          >
            <ArrowBack className={classes.leftIcon} />
            Cancel
          </Button>
        </AppBarDefault>
        <GeneralForm
          project={this.props.project}
          onSubmit={this.props.updateProject}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchProject, updateProject }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(GeneralEdit));

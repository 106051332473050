import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import LayoutContainer from "../Shared/LayoutContainer";
import Alert from "../Shared/Alert";
import SectionHeader from "../Shared/SectionHeader";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { fetchCategoryComboCategoriesMatchings } from "../../actions/categoryComboCategoriesMatchings";
import SearcheableMatchingTable from "../Shared/SearcheableMatchingTable";
import { paper as styles } from "../../helpers/commonStyles";
import CategoryCombosPreviews from "./CategoryCombosPreviews";
import UnmatchedNewSection from "../Shared/UnmatchedNewSection";
import _values from "lodash/values";
import { bindActionCreators } from "redux";
import {
  createNewCategoryOptionCombosMatching,
  destroyCategoryOptionCombosMatching,
  fetchCategoryOptionCombosMatchings,
} from "../../actions/categoryOptionCombosMatchings";
import {
  refreshCategoryOptionCombosMatchingMasterList,
  categoryOptionCombosMatchingMasterListRefreshed,
  catsMatchingsListRefreshed,
  cocsMatchingsListRefreshed,
} from "../../actions/ui";
import { fetchCategoryOptionCombos } from "../../actions/categoryOptionCombos";
import { fetchCategories } from "../../actions/categories";

const selectedCategoriesEmptyState = {
  selectedCategoriesIds: {
    left: { id: null, index: null },
    right: { id: null, index: null },
  },
};

const selectedCategoryOptionCombosEmptyState = {
  selectedCategoryOptionCombosIds: {
    left: { id: null, index: null },
    right: { id: null, index: null },
  },
};

class CategoryCombosMatchingEditForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...selectedCategoriesEmptyState,
      ...selectedCategoryOptionCombosEmptyState,
    };
  }

  createCategoryOptionComboMatching = categoryOptionCombo => {
    if (this.state.selectedCategoryOptionCombosIds.left.id) {
      const payload = {
        first_element: {
          source_id: this.props.ccMatching.left.source.id,
          id: this.state.selectedCategoryOptionCombosIds.left.id,
        },
        second_element: {
          source_id: this.props.ccMatching.right.source.id,
          id: categoryOptionCombo.id,
        },
      };
      this.props.createNewCategoryOptionCombosMatching(
        this.props.programId,
        this.props.projectId,
        payload,
        "categoryCombos",
        this.props.ccMatching.id,
      );
      this.setState({
        ...selectedCategoryOptionCombosEmptyState,
        categoryOptionComboMatchingQuery: undefined,
      });
    }
  };

  handleCategoryOptionComboSelection = (categoryOptionCombo, index, master) => {
    const side = master ? "left" : "right";

    if (side === "left") {
      this.setState({
        selectedCategoryOptionCombosIds: {
          left: {
            id: categoryOptionCombo.id,
            index: index,
          },
          right: { id: null, index: null },
        },
        categoryOptionComboMatchingQuery: categoryOptionCombo.name,
      });
    } else {
      this.createCategoryOptionComboMatching(categoryOptionCombo);
    }
  };

  handleFetchCategoryOptionCombos = params => {
    const { sourceId, query, page, master, forceReload } = params;
    const {
      projectId,
      programId,
      ccMatching: { left: categoryComboLeft, right: categoryComboRight },
    } = this.props;
    const categoryCombo = master ? categoryComboLeft : categoryComboRight;
    this.props.fetchCategoryOptionCombos(
      programId,
      projectId,
      sourceId,
      categoryCombo.id,
      query,
      page,
      forceReload,
    );
  };

  handleFetchCategories = params => {
    const { sourceId, query, page, master, forceReload } = params;
    const {
      projectId,
      programId,
      ccMatching: { left: categoryComboLeft, right: categoryComboRight },
    } = this.props;
    const categoryCombo = master ? categoryComboLeft : categoryComboRight;
    this.props.fetchCategories(
      programId,
      projectId,
      sourceId,
      categoryCombo.id,
      query,
      page,
      forceReload,
    );
  };

  handleCategoryOptionComboMatchingDestroy = matchingIdToDestroy => {
    const { projectId, ccMatching, programId } = this.props;
    this.props.destroyCategoryOptionCombosMatching(
      programId,
      projectId,
      "categoryCombos",
      ccMatching,
      matchingIdToDestroy,
    );
  };

  search_category_mappings = params => {
    const searched =
      params === ""
        ? []
        : this.props.categoriesLeft.filter(function(el) {
            return (
              el["name"].toLowerCase().includes(params.toLowerCase()) ||
              el["dhis_id"].toLowerCase().includes(params.toLowerCase())
            );
          });
    this.setState({
      searchedItems: searched,
      searchedItemsCount: searched.length,
      searchParams: params,
    });
  };

  search_mappings = params => {
    const searched =
      params === ""
        ? []
        : this.props.categoryOptionCombosLeft.filter(function(el) {
            return (
              el["name"].toLowerCase().includes(params.toLowerCase()) ||
              el["dhis_id"].toLowerCase().includes(params.toLowerCase())
            );
          });
    this.setState({
      searchedItems: searched,
      searchedItemsCount: searched.length,
      searchParams: params,
    });
  };

  searched_results = () => {
    return this.state.searchParams
      ? this.state.searchedItems
      : this.props.categoryOptionCombosLeft;
  };

  searched_results_cats = () => {
    return this.state.searchParams
      ? this.state.searchedItems
      : this.props.categoriesLeft;
  };

  searched_results_count = () => {
    return this.state.searchParams
      ? this.state.searchedItemsCount
      : this.props.categoryOptionCombosLeftRowCount;
  };

  searched_results_cats_count = () => {
    return this.state.searchParams
      ? this.state.searchedItemsCount
      : this.props.categoriesLeftRowCount;
  };

  render() {
    const {
      ccMatching: {
        id: matchingId,
        left: categoryComboLeft,
        right: categoryComboRight,
      },
      projectId,
      programId,
    } = this.props;
    const itemsCountDontMatch =
      categoryComboLeft.categories_count !==
        categoryComboRight.categories_count ||
      categoryComboLeft.category_option_combos_count !==
        categoryComboRight.category_option_combos_count;
    return (
      <LayoutContainer>
        <Grid item xs={12}>
          <p>
            The category combos matching defines the category option combos
            matchings used during push tasks.
          </p>
          {itemsCountDontMatch && (
            <Alert type="danger">
              Category Combos don’t match.
              <br />
              Please verify that both category combos are identical in both
              Dhis2.
            </Alert>
          )}
          <CategoryCombosPreviews
            programId={programId}
            projectId={projectId}
            matchingId={matchingId}
            categoryCombos={[categoryComboLeft, categoryComboRight]}
            tooltipText={`The values will be pushed from ${categoryComboLeft.source.name} to ${categoryComboRight.source.name}`}
            target={categoryComboRight.source}
          />

          <SectionHeader>
            Unmatched Categories ({this.searched_results_cats_count()})
          </SectionHeader>
          <UnmatchedNewSection
            search_mappings={this.search_category_mappings}
            projectId={this.props.projectId}
            programId={this.props.programId}
            itemTypeLabel="Categories"
            matching={this.props.ccMatching}
            itemsLeft={this.searched_results_cats()}
            itemsLeftRowCount={this.searched_results_cats_count()}
            itemsLeftFetching={this.props.categoriesLeftFetching}
            fetchItems={this.handleFetchCategories}
            itemsRight={this.props.categoriesRight}
            itemsRightRowCount={this.props.categoriesRightRowCount}
            itemsRightFetching={this.props.categoriesRightFetching}
            matchingType="category_combos"
            entitiesType="categories"
            taskMatcher="task"
            createItemsMatching={
              this.props.createNewCategoryOptionCombosMatching
            }
            autoMatchersApi="categoriesAutoMappers"
            itemClonesApi="categoryClones"
            sequencer_class="::Services::MetaMatchFlow::MatchCcMetaFlow"
          />

          <SearcheableMatchingTable
            projectId={projectId}
            catMatchingId={matchingId}
            fetchMetaMachings={(payload, force) =>
              this.props.fetchCategoryComboCategoriesMatchings(
                this.props.programId,
                projectId,
                matchingId,
                payload,
                force,
              )
            }
            matchingsListRefreshed={this.props.catsMatchingsListRefreshed}
            metaTypeMatching="categoryComboCategoriesMatchings"
            sectionHeaderLabel="Matched Categories"
          />

          <SectionHeader>
            Unmatched Category Option Combos ({this.searched_results_count()})
          </SectionHeader>
          <UnmatchedNewSection
            search_mappings={this.search_mappings}
            projectId={this.props.projectId}
            programId={this.props.programId}
            itemTypeLabel="Category Option Combo"
            matching={this.props.ccMatching}
            itemsLeft={this.searched_results()}
            itemsLeftRowCount={this.searched_results_count()}
            itemsLeftFetching={this.props.categoryOptionCombosLeftFetching}
            fetchItems={this.handleFetchCategoryOptionCombos}
            itemsRight={this.props.categoryOptionCombosRight}
            itemsRightRowCount={this.props.categoryOptionCombosRightRowCount}
            itemsRightFetching={this.props.categoryOptionCombosRightFetching}
            matchingType="category_combos"
            entitiesType="category_option_combos"
            taskMatcher="task"
            createItemsMatching={
              this.props.createNewCategoryOptionCombosMatching
            }
            autoMatchersApi="categoryOptionCombosAutoMappers"
            itemClonesApi="categoryOptionComboClones"
            sequencer_class="::Services::MetaMatchFlow::MatchCcMetaFlow"
          />

          <SearcheableMatchingTable
            destroyMatching={this.handleCategoryOptionComboMatchingDestroy}
            projectId={projectId}
            catMatchingId={matchingId}
            fetchMetaMachings={(payload, force) =>
              this.props.fetchCategoryOptionCombosMatchings(
                this.props.programId,
                projectId,
                matchingId,
                payload,
                force,
              )
            }
            matchingsListRefreshed={this.props.cocsMatchingsListRefreshed}
            metaTypeMatching="categoryOptionCombosMatchings"
            sectionHeaderLabel="Matched Category Option Combos"
          />
        </Grid>
      </LayoutContainer>
    );
  }
}

const categoryOptionCombosForSource = (categoryOptionCombos, sourceId) => {
  return categoryOptionCombos[sourceId] &&
    categoryOptionCombos[sourceId].entities
    ? _values(categoryOptionCombos[sourceId].entities)
    : [];
};

const categoriesForSource = (categories, sourceId) => {
  return categories[sourceId] && categories[sourceId].entities
    ? _values(categories[sourceId].entities)
    : [];
};

const categoryOptionCombosMetaForSource = (categoryOptionCombos, sourceId) => {
  return categoryOptionCombos[sourceId] &&
    categoryOptionCombos[sourceId].meta !== undefined
    ? categoryOptionCombos[sourceId].meta
    : { total_count: 0 };
};

const categoriesMetaForSource = (categories, sourceId) => {
  return categories[sourceId] && categories[sourceId].meta !== undefined
    ? categories[sourceId].meta
    : { total_count: 0 };
};

const isFetching = (categoryOptionCombos, sourceId) => {
  return (
    categoryOptionCombos[sourceId] && categoryOptionCombos[sourceId].isFetching
  );
};

const mapStateToProps = (state, props) => {
  const {
    ccMatching: { left: categoryComboLeft, right: categoryComboRight },
  } = props;

  const categoryOptionCombosLeftMeta = categoryOptionCombosMetaForSource(
    state.categoryOptionCombos,
    categoryComboLeft.source.id,
  );
  const categoryOptionCombosRightMeta = categoryOptionCombosMetaForSource(
    state.categoryOptionCombos,
    categoryComboRight.source.id,
  );

  const categoriesLeftMeta = categoriesMetaForSource(
    state.categories,
    categoryComboLeft.source.id,
  );
  const categoriesRightMeta = categoriesMetaForSource(
    state.categories,
    categoryComboRight.source.id,
  );

  const categoryOptionCombosLeftRowCount =
    categoryOptionCombosLeftMeta.total_count;
  const categoryOptionCombosRightRowCount =
    categoryOptionCombosRightMeta.total_count;
  const categoriesLeftRowCount = categoriesLeftMeta.total_count;
  const categoriesRightRowCount = categoriesRightMeta.total_count;

  const categoryOptionCombosLeftFetching = isFetching(
    state.categoryOptionCombos,
    categoryComboLeft.source.id,
  );

  const categoriesLeftFetching = isFetching(
    state.categories,
    categoryComboLeft.source.id,
  );
  const categoryOptionCombosRightFetching = isFetching(
    state.categoryOptionCombos,
    categoryComboRight.source.id,
  );

  const categoriesRightFetching = isFetching(
    state.categories,
    categoryComboRight.source.id,
  );

  return {
    programId: state.programs.currentProgram.id,
    projectId: state.programs.currentProject.id,
    categoryOptionCombosLeft: categoryOptionCombosForSource(
      state.categoryOptionCombos,
      categoryComboLeft.source.id,
    ),
    categoryOptionCombosRight: categoryOptionCombosForSource(
      state.categoryOptionCombos,
      categoryComboRight.source.id,
    ),
    categoriesLeft: categoriesForSource(
      state.categories,
      categoryComboLeft.source.id,
    ),
    categoriesRight: categoriesForSource(
      state.categories,
      categoryComboRight.source.id,
    ),
    categoryOptionCombosLeftMeta,
    categoryOptionCombosRightMeta,
    categoryOptionCombosLeftRowCount,
    categoryOptionCombosRightRowCount,
    categoryOptionCombosLeftFetching,
    categoryOptionCombosRightFetching,
    categoriesRightFetching,
    categoriesLeftFetching,
    categoriesLeftRowCount,
    categoriesRightRowCount,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCategoryOptionCombos,
      fetchCategories,
      createNewCategoryOptionCombosMatching,
      destroyCategoryOptionCombosMatching,
      refreshCategoryOptionCombosMatchingMasterList,
      categoryOptionCombosMatchingMasterListRefreshed,
      fetchCategoryComboCategoriesMatchings,
      catsMatchingsListRefreshed,
      fetchCategoryOptionCombosMatchings,
      cocsMatchingsListRefreshed,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(CategoryCombosMatchingEditForm)));

import React, { Component, Fragment } from "react";

import {
  COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
  SYNCHRONIZE_DEG_META_FLOW,
  COMPARE_TASKS_SCHEDULER_PANELS,
} from "../constants";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, FormControl } from "@material-ui/core";
import AutoComplete from "../../Shared/AutoComplete";
import CompareDsParams from "../../Shared/CompareDsParams";
import CompareParams from "../../Shared/CompareParams";
import CheckBoxComponent from "../Shared/CheckBoxComponent";
import {
  formStyles,
  expansionPanelStyles,
} from "../../../helpers/commonStyles";

const styles = theme => ({
  ...formStyles(theme),
  ...expansionPanelStyles(theme),
  sectionTitle: {
    fontWeight: "bold",
  },
});

export const DS_COMPARE_PARAMS = {
  group: [
    { key: "name", label: "Name", tooltip: "The DS's name" },
    { key: "short_name", label: "Short Name", tooltip: "The DS's short name" },
  ],
  organisation_unit: [
    {
      key: "new_ous_entities_left",
      tooltip:
        "Uncheck this if you don't want to create new OU on your target DHIS",
      label: "Create new OUs on target",
    },
    {
      key: "existing_ous_entities_right",
      tooltip:
        "Uncheck this if you don't want to create new OU on your target DHIS",
      label: "link existing OUs on Target",
    },
    {
      key: "new_ous_entities_right",
      tooltip:
        "Uncheck that if you don't want to remove any OU from your target Data Set",
      label: "Remove OUs from DS target",
    },
    {
      key: "modified_ous_entities",
      tooltip:
        "Uncheck that if you don't want to update any OU on your target DHIS",
      label: "Update OUs on Target",
    },
  ],
  data_element: [
    {
      key: "new_des_entities_left",
      tooltip: "Uncheck this if you don't want to create new DE on your target",
      label: "Create new DEs on target",
    },
    {
      key: "existing_des_entities_right",
      tooltip:
        "Uncheck this if you don't want to create new DE on your target DHIS",
      label: "link existing DEs on Target",
    },
    {
      key: "new_des_entities_right",
      tooltip:
        "Uncheck that if you don't want to remove any DE from your target Data Set",
      label: "Remove DEs from DS target",
    },
    {
      key: "modified_des_entities",
      tooltip:
        "Uncheck that if you don't want to update any DE on your target DHIS",
      label: "Update DEs",
    },
  ],
};

export const DSDES_COMPARE_PARAMS = [
  { key: "name", label: "Name", tooltip: "The DE's name" },
  { key: "short_name", label: "Short Name", tooltip: "The DE's short name" },
  {
    key: "category_combo",
    label: "Category Combo",
    tooltip: "The DE's category combo",
  },
];

export const DSOUS_COMPARE_PARAMS = [
  { key: "name", label: "Name", tooltip: "The OU's name" },
  { key: "short_name", label: "Short Name", tooltip: "The OU's short name" },
  { key: "parent", label: "Parent", tooltip: "The OU's parent" },
  { key: "path", label: "Path", tooltip: "The OU's path" },
  {
    key: "feature_type",
    label: "Feature Type",
    tooltip: "The OU's feature type",
  },
  { key: "coordinates", label: "Coordinates", tooltip: "The OU's coordinates" },
  {
    key: "closed_date",
    label: "Closed date",
    tooltip: "Is the OU closed?",
  },
  {
    key: "opening_date",
    label: "Opening date",
    tooltip: "The OU's opening date",
  },
];

class CompareMetaFormDs extends Component {
  constructor(props) {
    super();
    this.state = {
      expanded:
        props.entity.id !== undefined
          ? props.entity.params.side !== undefined
            ? COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class][
                props.entity.params.side
              ]
            : COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class]
          : "panel5",
      preview: props.entity.params.preview ? "Preview" : "",
      preview_checkbox: true,
      sequencer_class: COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
    };
  }

  componentDidMount() {
    this.props.handleChange(`send_mail`, 3);
  }

  handleCompareDegParamsChange = (param, des_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_group_params;
    if (event.target.checked) {
      this.check_des_param(param);
      this.check_ou_param(param);
      params.push(param);
    } else {
      this.uncheck_des_param(param);
      this.uncheck_ou_param(param);
      params = params.filter(p => p !== param);
    }
    this.props.handleParamChange("compare_group_params", params);
  };

  uncheck_des_param = param => {
    if (param === DS_COMPARE_PARAMS.data_element[3].key) {
      this.props.handleParamChange("compare_entities_params", []);
    }
  };

  check_des_param = param => {
    if (param === DS_COMPARE_PARAMS.data_element[3].key) {
      this.props.handleParamChange("compare_entities_params", [
        DSDES_COMPARE_PARAMS[0].key,
      ]);
    }
  };

  uncheck_ou_param = param => {
    if (param === DS_COMPARE_PARAMS.organisation_unit[3].key) {
      this.props.handleParamChange("compare_entities_ous_params", []);
    }
  };

  check_ou_param = param => {
    if (param === DS_COMPARE_PARAMS.organisation_unit[3].key) {
      this.props.handleParamChange("compare_entities_ous_params", [
        DSOUS_COMPARE_PARAMS[0].key,
      ]);
    }
  };

  uncheck_ds_de_param = (params, ds_params) => {
    if (
      params.length === 0 &&
      ds_params.includes(DS_COMPARE_PARAMS.data_element[3].key)
    ) {
      ds_params = ds_params.filter(
        p => p !== DS_COMPARE_PARAMS.data_element[3].key,
      );
      this.props.handleParamChange("compare_group_params", ds_params);
    }
  };

  check_ds_de_param = ds_params => {
    if (!ds_params.includes(DS_COMPARE_PARAMS.data_element[3].key)) {
      ds_params = ds_params.push(DS_COMPARE_PARAMS.data_element[3].key);
    }
  };

  uncheck_ds_ou_param = (params, ds_params) => {
    if (
      params.length === 0 &&
      ds_params.includes(DS_COMPARE_PARAMS.organisation_unit[3].key)
    ) {
      ds_params = ds_params.filter(
        p => p !== DS_COMPARE_PARAMS.organisation_unit[3].key,
      );
      this.props.handleParamChange("compare_group_params", ds_params);
    }
  };

  check_ds_ou_param = ds_params => {
    if (!ds_params.includes(DS_COMPARE_PARAMS.organisation_unit[3].key)) {
      ds_params = ds_params.push(DS_COMPARE_PARAMS.organisation_unit[3].key);
    }
  };

  handleCompareDesParamsChange = (param, des_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_entities_params;
    if (event.target.checked) {
      this.check_ds_de_param(des_params);
      params.push(param);
    } else {
      params = params.filter(p => p !== param);
      this.uncheck_ds_de_param(params, des_params);
    }
    this.props.handleParamChange("compare_entities_params", params);
  };

  handleCompareOusParamsChange = (param, ous_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_entities_ous_params;
    if (event.target.checked) {
      this.check_ds_ou_param(ous_params);
      params.push(param);
    } else {
      params = params.filter(p => p !== param);
      this.uncheck_ds_ou_param(params, ous_params);
    }
    this.props.handleParamChange("compare_entities_ous_params", params);
  };

  handleChange = (panel, sequencer_class) => (event, isExpanded) => {
    if (sequencer_class !== COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW) {
      this.props.handleRemoveParam("preview");
      this.setState({
        preview: "",
      });
    }
    this.setState({
      preview_checkbox:
        sequencer_class === COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW ? true : false,
      expanded: isExpanded ? panel : false,
      sequencer_class: sequencer_class,
    });
  };

  placeholderText = source => {
    return `Search for Data Set from ${source}`;
  };

  getValueEdit = (entity, value, sequencer_class, side) => {
    if (side !== undefined) {
      if (side === entity["params"]["side"]) {
        return value;
      } else {
        return undefined;
      }
    } else if (sequencer_class === entity["sequencer_class"]) {
      return value;
    } else {
      return undefined;
    }
  };

  handlePreviewChange = event => {
    if (this.state.preview === "") {
      this.setState({ preview: "Preview" });
      this.props.handleParamChange("preview", true);
      this.props.handleChangeSequencerClassProp([
        {
          prop: "sequencer_class",
          value: SYNCHRONIZE_DEG_META_FLOW,
        },
      ]);
    } else {
      this.setState({ preview: "" });
      this.props.handleParamChange("preview", false);
      this.props.handleChangeSequencerClassProp([
        {
          prop: "sequencer_class",
          value: COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
        },
      ]);
    }
  };

  render() {
    const { project, entity, handleChanges, classes } = this.props;
    const compare_scenarios = [
      {
        label: "DHIS2 AND D2D FULL COMPARISON",
        left_instance: "dhis2",
        right_instance: "dhis2",
        left_source: this.getValueEdit(
          entity,
          entity.params.leftSourceGroup,
          entity.params.preview
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
          undefined,
        ),
        right_source: this.getValueEdit(
          entity,
          entity.params.rightSourceGroup,
          entity.params.preview
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
          undefined,
        ),
        left_source_id: this.props.project.left_source.id,
        right_source_id: this.props.project.right_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class:
          this.state.sequencer_class === COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW &&
          this.state.preview !== ""
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
        left_source_name: `DHIS2 ${this.props.project.left_source.name}`,
        right_source_name: `DHIS2 ${this.props.project.right_source.name}`,
      },
    ];

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div>
              {compare_scenarios.map((item, index) => {
                return (
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <AutoComplete
                        projectId={project.id}
                        sourceId={item["left_source_id"]}
                        onChange={e =>
                          handleChanges(
                            [
                              {
                                prop: "sequencer_class",
                                value: item["sequencer_class"],
                              },
                            ],
                            [
                              {
                                prop: item["left_identity"],
                                value: e,
                              },
                              item["side"] !== undefined
                                ? { prop: "side", value: item["side"] }
                                : {},
                            ],
                          )
                        }
                        type="data_sets"
                        instance={item["left_instance"]}
                        value={item["left_source"]}
                        placeholder={this.placeholderText(
                          item["left_source_name"],
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AutoComplete
                        projectId={project.id}
                        sourceId={item["right_source_id"]}
                        onChange={e =>
                          handleChanges(
                            [
                              {
                                prop: "sequencer_class",
                                value: item["sequencer_class"],
                              },
                            ],
                            [
                              {
                                prop: item["right_identity"],
                                value: e,
                              },
                              item["side"] !== undefined
                                ? { prop: "side", value: item["side"] }
                                : {},
                            ],
                          )
                        }
                        type="data_sets"
                        instance={item["right_instance"]}
                        value={item["right_source"]}
                        placeholder={this.placeholderText(
                          item["right_source_name"],
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.textField}>
              <CompareDsParams
                label="Data Sets Params"
                group_params={DS_COMPARE_PARAMS}
                params={entity.params.compare_group_params}
                entities_params={entity.params.compare_entities_params}
                handleCompareParamsChange={this.handleCompareDegParamsChange}
              />
            </FormControl>
            <FormControl className={classes.textField}>
              <CompareParams
                label="Data Elements Params"
                group_params={DSDES_COMPARE_PARAMS}
                params={entity.params.compare_entities_params}
                entities_params={entity.params.compare_group_params}
                handleCompareParamsChange={this.handleCompareDesParamsChange}
              />
            </FormControl>
            <FormControl className={classes.textField}>
              <CompareParams
                label="Organisation units Params"
                group_params={DSOUS_COMPARE_PARAMS}
                params={entity.params.compare_entities_ous_params}
                entities_params={entity.params.compare_group_params}
                handleCompareParamsChange={this.handleCompareOusParamsChange}
              />
            </FormControl>
          </Grid>
          {this.state.preview_checkbox && (
            <Grid item xs={12}>
              <CheckBoxComponent
                componentStyle={classes.sectionTitle}
                preview={this.state.preview}
                checkboxLabel="Apply changes without confirmation :"
                label="Apply without confirmation"
                handlePreviewChange={() => this.handlePreviewChange}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
});
export default connect(mapStateToProps)(withStyles(styles)(CompareMetaFormDs));

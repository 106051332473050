import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class CloneCcMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;
    const clone_deg_summary = (action_key, clone_key) => {
      if (task.summary[action_key]) {
        return task.summary[action_key][clone_key]
          ? task.summary[action_key][clone_key]
          : 0;
      } else {
        return "Not counted";
      }
    };

    const summaries = [
      [
        {
          label: "Cloned cc",
          value: clone_deg_summary("cloned_meta", "new_group_count"),
        },
        {
          label: "Total entities",
          value: clone_deg_summary("cloned_meta", "total_entities"),
        },
        {
          label: "Cloned categories",
          value: clone_deg_summary(
            "cloned_meta",
            "new_cat_added_entities_count",
          ),
        },
      ],
      [
        {
          label: "Skipped categories",
          value: clone_deg_summary("cloned_meta", "skipped_cat_entities_count"),
        },
        {
          label: "Matched cc",
          value: clone_deg_summary("match_meta", "matched_group_count"),
        },
      ],
      [
        {
          label: "Matched entities",
          value: clone_deg_summary("match_meta", "matched_entities_count"),
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default CloneCcMetaFlowSummary;

import React from "react";
import { Badge, Tooltip } from "@material-ui/core";

export const withBadge = (p, e, onClick) => {
  if (!p.active_flags.length && !p.resolved_flags.length) {
    return e;
  }
  var badges = p.resolved_flags;
  if (!!p.active_flags.length) {
    badges = p.active_flags;
  }
  return (
    <Tooltip
      id="tooltip-badge"
      placement="top-start"
      title={
        <div>
          {badges
            .map(f => f.message)
            .slice(0, 3)
            .join("; ")}
        </div>
      }
    >
      <Badge
        badgeContent={badges.length}
        style={{ width: "100%" }}
        max={999}
        color={!!p.active_flags.length ? "primary" : "secondary"}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClick={onClick}
      >
        {e}
      </Badge>
    </Tooltip>
  );
};

import {
  PUSH_DATA_FLOW,
  PUSH_ANALYTIC_FLOW,
  COMPARE_OUG_DHIS_TO_DHIS_META_FLOW,
  COMPARE_OUG_DHIS_TO_D2D_META_FLOW,
  COMPARE_OUG_D2D_TO_D2D_META_FLOW,
  COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW,
  SYNCHRONIZE_OUG_META_FLOW,
} from "../Tasks/constants";
import PushDataFlowPreview from "./Previews/PushDataFlowPreview";
import PushAnalyticFlowPreview from "./Previews/PushAnalyticFlowPreview";
import CompareMetaFlowPreview from "./Previews/CompareMetaFlowPreview";

export const META_TASKS_SCHEDULER_PREVIEW = {
  [PUSH_DATA_FLOW]: PushDataFlowPreview,
  [PUSH_ANALYTIC_FLOW]: PushAnalyticFlowPreview,
  [COMPARE_OUG_DHIS_TO_DHIS_META_FLOW]: CompareMetaFlowPreview,
  [COMPARE_OUG_DHIS_TO_D2D_META_FLOW]: CompareMetaFlowPreview,
  [COMPARE_OUG_D2D_TO_D2D_META_FLOW]: CompareMetaFlowPreview,
  [COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW]: CompareMetaFlowPreview,
  [SYNCHRONIZE_OUG_META_FLOW]: CompareMetaFlowPreview,
};

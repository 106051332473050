import React, { Component } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import Done from "@material-ui/icons/Done";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import withStyles from "@material-ui/core/styles/withStyles";
import green from "@material-ui/core/colors/green";
import { withRouter } from "react-router";
import { leftIcon } from "../../helpers/commonStyles";
import { createImmediateTask } from "../../actions/tasks";
import { connect } from "react-redux";
import { get } from "../../actions/api";

const styles = theme => ({
  buttonSuccess: {
    color: green[500],
    "&:hover": {
      color: green[700],
      background: green[100],
    },
  },
  ...leftIcon(theme),
});

class ImportDataSetButton extends Component {
  constructor(props) {
    super(props);
    this.state = this.defaultState();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.propsChanged(nextProps) ||
      nextProps.sourceId !== this.props.sourceId
    ) {
      this.setState(this.defaultState());
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
  }

  checkImportStatus = () => {
    get(this.taskImportApi(), {
      programId: this.programId(),
      projectId: this.projectId(),
      sourceId: this.props.sourceId,
      id: this.state.taskImport.payload.id,
    })
      .then(taskImport => {
        if (taskImport.has_completed) {
          this.stopProcessing(taskImport);
          this.informParent(taskImport);
          return;
        }
        if (taskImport.has_failed) {
          this.stopProcessing(taskImport, taskImport.message);
          return;
        }
        this.delayedCheck();
      })
      .catch(error => {
        this.stopProcessing(null, error);
      });
  };

  taskImportApi() {
    return "task";
  }

  defaultState() {
    return {
      taskImport: null,
      timeoutId: null,
      error: null,
    };
  }

  delayedCheck() {
    this.setState({
      timeoutId: setTimeout(this.checkImportStatus, 2000),
    });
  }

  projectId() {
    return this.props.projectId;
  }

  informParent(degImport) {
    if (this.props.onChange) {
      this.props.onChange({
        ...this.props.dataSet,
        id: degImport.params.dhis_id,
        is_processed: degImport.is_processed,
      });
    }
  }

  programId() {
    return this.props.programId;
  }

  stopProcessing(taskImport, error) {
    this.setState({ taskImport: taskImport, error: error });
  }

  startProcessing() {
    this.setState({ taskImport: { is_processed: false } });
  }

  triggerImport = () => {
    this.startProcessing();
    this.props
      .createImmediateTask(
        this.programId(),
        this.projectId(),
        {
          task: {
            params: {
              source: this.props.sourceId,
              side: this.props.side,
              dhis_id: this.props.dataSet.dhis_id,
              name: this.props.dataSet.name,
            },
            sequencer_class: "::Services::MetaImportFlow::ImportDsFlow",
          },
        },
        false,
      )
      .then(taskImport => {
        this.setState({ taskImport: taskImport });
        this.checkImportStatus();
      });
  };

  propsChanged(nextProps) {
    return nextProps.dataSet.dhis_id !== this.props.dataSet.dhis_id;
  }

  doImport() {}

  render() {
    const { classes } = this.props;
    const { taskImport, error } = this.state;
    if (taskImport && taskImport.has_failed) {
      return (
        <div>
          This import failed:
          {error}
        </div>
      );
    }

    if (taskImport && !taskImport.has_completed) {
      return (
        <Button disabled>
          <CircularProgress size={24} className={classes.leftIcon} />
          importing
        </Button>
      );
    }

    if (taskImport && taskImport.has_completed) {
      return (
        <Button
          onClick={this.triggerImport}
          color="primary"
          className={this.props.classes.buttonSuccess}
        >
          <Done className={classes.leftIcon} />
          Import Successful
        </Button>
      );
    }

    return (
      <Button onClick={this.triggerImport} color="primary">
        <ArrowDownward className={classes.leftIcon} />
        {this.props.btnText}
      </Button>
    );
  }
}

const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createImmediateTask })(
  withRouter(withStyles(styles)(ImportDataSetButton)),
);

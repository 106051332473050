export const general = theme => ({
  primaryText: {
    color: theme.palette.primary.main,
  },
  secondaryText: {
    color: theme.palette.secondary.main,
  },
});
export const helpSection = theme => ({
  helpSection: {
    textAlign: "center",
    margin: `${theme.spacing(8)}px auto`,
  },
});

export const leftIcon = theme => ({
  leftIcon: {
    marginRight: theme.spacing(),
  },
});

export const paper = theme => ({
  paper: {
    padding: "16px 32px",
    margin: `${theme.spacing(4)}px 0`,
    position: "relative",
  },
});

export const card = theme => ({
  paperHeader: {
    height: "60px",
    borderBottom: "1px solid #ddd",
  },
  paperTitle: {
    paddingTop: "18px",
    paddingBottom: "18px",
    marginTop: -theme.spacing(),
    textAlign: "center",
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    borderBottom: "1px solid #ddd",
    fontWeight: 400,
    fontSize: "80%",
    whiteSpace: "normal",
    textTransform: "uppercase",
  },
  paperMenu: {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  paperProcessing: {
    position: "absolute",
    right: "20px",
    top: "20px",
  },
  cardTab: {
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    borderBottom: "1px solid #ddd",
  },
});

export const tableStyles = theme => ({
  cell: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    alignContent: "center",
    boxSizing: "border-box",
  },
  actions: {
    textAlign: "right",
  },
  listItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
});

export const expansionPanel = theme => ({
  expansionPanel: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    margin: "0px !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:last-child)": {
      marginBottom: theme.spacing(2),
    },
    "&:before": {
      display: "none",
    },
  },
});

export const expansionPanelSummary = theme => ({
  expansionPanelSummary: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
  },
});

export const expansionPanelDetails = theme => ({
  expansionPanelDetails: {
    padding: theme.spacing(2),
  },
});

export const expansionPanelStyles = theme => ({
  ...expansionPanel(theme),
  ...expansionPanelSummary(theme),
  ...expansionPanelDetails(theme),
});

export const formFooter = theme => ({
  formFooter: {
    flex: "0 0 auto",
    margin: `${theme.spacing()} 0`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

export const textField = theme => ({
  textField: {
    width: "100%",
    marginBottom: theme.spacing(4),
  },
});

export const dateField = theme => ({
  dateField: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
});

export const inlineLabel = theme => ({
  inlineLabel: {
    width: "100%",
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(4),
  },
});

export const fabButton = theme => ({
  fabButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
});

export const formStyles = theme => ({
  ...general(theme),
  ...paper(theme),
  ...inlineLabel(theme),
  ...textField(theme),
  ...dateField(theme),
  ...formFooter(theme),
  ...card(theme),
});

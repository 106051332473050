import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { Error, Done } from "@material-ui/icons";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import { leftIcon } from "../../helpers/commonStyles";

const styles = theme => ({
  buttonSuccess: {
    color: green[500],
    "&:hover": {
      color: green[700],
      background: green[100],
    },
  },
  ...leftIcon(theme),
});

function TestConnectionButton(props) {
  const { errors, connectionOk, isFetching } = props.source;
  const { classes } = props;

  if (isFetching) {
    return (
      <Button>
        <CircularProgress size={24} />
      </Button>
    );
  }

  if (errors) {
    return (
      <Button onClick={props.handleTestConnection.bind(this)}>
        <Error className={classes.leftIcon} />
        Connection failed
      </Button>
    );
  } else if (connectionOk && !errors) {
    return (
      <Button
        color="primary"
        className={props.classes.buttonSuccess}
        onClick={props.handleTestConnection.bind(this)}
      >
        <Done className={classes.leftIcon} />
        Connection verified
      </Button>
    );
  } else {
    return (
      <Button color="primary" onClick={props.handleTestConnection.bind(this)}>
        Test connection
      </Button>
    );
  }
}

export default withStyles(styles)(TestConnectionButton);

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CancelScheduleSendOutlinedIcon from "@material-ui/icons/CancelScheduleSendOutlined";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusStoppedAndPurged extends React.Component {
  render() {
    const { classes, withLabel, errors } = this.props;
    return (
      <TaskStatus
        help={errors}
        lbl={withLabel ? "Stopped - purged" : null}
        icon={<CancelScheduleSendOutlinedIcon className={classes.iconLeft} />}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusStoppedAndPurged);

import {
  RECEIVE_PROJECT,
  REQUEST_SOURCE_CONNECTION,
  REQUEST_SOURCE_CONNECTION_FAILED,
  RECEIVE_SOURCE_CONNECTION,
  INVALIDATE_SOURCE_CONNECTION,
} from "../actionTypes";

function ui(
  state = {
    leftSource: {
      isFetching: false,
      connectionOk: false,
      errors: null,
    },
    rightSource: {
      isFetching: false,
      connectionOk: false,
      errors: null,
    },
  },
  action,
) {
  switch (action.type) {
    case RECEIVE_PROJECT:
      return Object.assign({}, state, {
        leftSource: {
          isFetching: false,
          connectionOk: false,
          errors: null,
        },
        rightSource: {
          isFetching: false,
          connectionOk: false,
          errors: null,
        },
      });
    case REQUEST_SOURCE_CONNECTION:
      return Object.assign({}, state, {
        [action.meta.sourceType]: {
          isFetching: true,
          connectionOk: false,
          errors: null,
        },
      });
    case RECEIVE_SOURCE_CONNECTION:
      return Object.assign({}, state, {
        [action.meta.sourceType]: {
          version: action.payload.dhis_version,
          isFetching: false,
          connectionOk: true,
          errors: null,
        },
      });
    case REQUEST_SOURCE_CONNECTION_FAILED:
      return Object.assign({}, state, {
        [action.meta.sourceType]: {
          isFetching: false,
          connectionOk: false,
          errors: action.error,
        },
      });
    case INVALIDATE_SOURCE_CONNECTION:
      return Object.assign({}, state, {
        [action.sourceType]: {
          isFetching: false,
          connectionOk: false,
          errors: null,
        },
      });
    default:
      return state;
  }
}

export default ui;

import React from "react";
import { withStyles, Paper } from "@material-ui/core";
import logo from "../images/logo.svg";
import LoginForm from "./Home/LoginForm";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.primary.main,
    minHeight: "100vh",
    boxShadow: "inset 0px 0px 250px #D24D00",
  },
  logo: {
    margin: "0 auto 80px",
    width: 250,
  },
  formBox: {
    background: "white",
    width: 500,
    padding: 20,
  },
});

function Home(props) {
  const {
    classes,
    location,
    isAuthenticated,
    currentProgram,
    currentProject,
    backUser,
    userId,
  } = props;
  if (!isAuthenticated) {
    return (
      <div className={classes.root}>
        <div>
          <div className={classes.logo}>
            <img src={logo} alt="Dhis2Dhis" />
          </div>
          <Paper elevation={8} className={classes.formBox}>
            <LoginForm />
          </Paper>
        </div>
      </div>
    );
  }
  if (!!currentProject) {
    return (
      <Redirect
        to={
          location.state &&
          location.state.backUrl !== undefined &&
          backUser === userId
            ? location.state.backUrl
            : `/programs/${currentProgram.id}/projects/${currentProject.id}/tasks`
        }
      />
    );
  } else if (!!currentProgram) {
    return (
      <Redirect
        to={
          location.state &&
          location.state.backUrl !== undefined &&
          backUser === userId
            ? location.state.backUrl
            : `/programs/${currentProgram.id}/projects`
        }
      />
    );
  } else {
    return (
      <Redirect
        to={
          location.state &&
          location.state.backUrl !== undefined &&
          backUser === userId
            ? location.state.backUrl
            : `/programs`
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  currentProgram: state.programs.currentProgram,
  currentProject: state.programs.currentProject,
  isAuthenticated: state.auth.isAuthenticated,
  backUser: state.auth.backUser && state.auth.backUser,
  userId: state.auth.user && state.auth.user.id,
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Home)));

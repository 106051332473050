import { Card, CardActions, CardContent } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import _remove from "lodash/remove";
import AppBarDefault from "../../Shared/AppBarDefault";
import Button from "@material-ui/core/Button/Button";
import ConfirmDialog from "../../Shared/ConfirmDialog";
import ContentDialogForm from "../../Shared/ContentDialogForm";
import ProcessingDialog from "../../Shared/ProcessingDialog";
import Grid from "@material-ui/core/Grid";
import LayoutContainer from "../../Shared/LayoutContainer";
import { Link } from "react-router-dom";
import NoDataInfoPage from "../../Shared/NoDataInfoPage";
import SectionSpinner from "../../Shared/SectionSpinner";
import Typography from "@material-ui/core/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import history from "../../../history";
import { get } from "../../../actions/api";
import { connect } from "react-redux";
import { destroyProject } from "../../../actions/projects";
import { cloneProject } from "../../../actions/projects";
import { leftIcon, formStyles } from "../../../helpers/commonStyles";
import FlagsDialog from "../../FlagsDialog";
import { withBadge } from "../../../helpers/projectHelpers";
import { fetchProgramProjects } from "../../../actions/projects";
import store from "../../../store";
import * as types from "../../../actionTypes";
const styles = theme => ({
  ...formStyles(theme),
  ...leftIcon(theme),
  projectAlert: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
});
class ProgramProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmBoxOpen: false,
      dialogFormOpen: false,
      flagsDialogOpen: false,
      flagsDialogProject: null,
      projectIdToDestroy: null,
      projectNameToClone: null,
      projectIdToClone: null,
      processingDialogOpen: false,
      timeoutId: null,
      flowId: null,
      projectName: null,
    };
  }

  componentDidMount() {
    this.getCurrentProgram();
  }

  getCurrentProgram = () => {
    const program = this.getProgram(this.props.programs);
    store.dispatch({
      type: types.RECEIVE_PROGRAM,
      currentProgram: program,
    });
    store.dispatch({
      type: types.RECEIVE_PROGRAM_PROJECTS,
      projects: program.projects,
    });
  };
  getProgram = programs => {
    return programs.find(p => p.id.toString() === this.props.programId);
  };

  closeProcessingDialog = () => {
    clearTimeout(this.state.timeoutId);
    this.setState({
      processingDialogOpen: false,
    });
    history.push(this.props.doneUrl);
  };

  checkProcessing = () => {
    get("projectImporters", {
      programId: this.props.programId,
      projectId: this.state.flowId,
    }).then(flow => {
      if (flow.is_processed) {
        this.closeProcessingDialog();
      }
      if (flow.has_errored) {
        this.setState({ processingErrors: flow.latest_errors });
      }
    });
  };

  processingDialog = () => {
    const {
      classes: { primaryText, secondaryText },
    } = this.props;
    return (
      <ProcessingDialog
        open={this.state.processingDialogOpen}
        handleClose={this.closeProcessingDialog}
        errors={this.state.processingErrors}
      >
        <Typography className={secondaryText}>
          Cloning{" "}
          <strong className={primaryText}> {this.state.projectName} </strong>{" "}
          project
        </Typography>
      </ProcessingDialog>
    );
  };

  delayedCheckProcessing() {
    this.setState({
      timeoutId: setInterval(this.checkProcessing, 2000),
    });
  }

  handleClickDialogFormOpen = (id, projectName) => {
    this.setState({
      dialogFormOpen: true,
      projectIdToClone: id,
      projectNameToClone: projectName,
    });
  };

  handleCloseDialogForm = () => {
    this.setState({
      dialogFormOpen: false,
      projectIdToClone: null,
      projectNameToClone: null,
    });
  };

  handleClickOpen = id => {
    this.setState({
      confirmBoxOpen: true,
      projectIdToDestroy: id,
    });
  };

  handleClose = () => {
    this.setState({
      confirmBoxOpen: false,
      projectIdToDestroy: null,
    });
  };

  submitDestroyRequest = () => {
    this.handleClose();
    this.props
      .destroyProject(
        this.props.currentProgram.id,
        this.state.projectIdToDestroy,
      )
      .then(payload => {
        var projects_array = this.props.currentProgram.projects;
        _remove(projects_array, function(project) {
          return project.id === payload.payload.id;
        });
        var remainProjects = projects_array.filter(
          project => project.id !== payload.payload.id,
        );

        store.dispatch({
          type: types.DELETE_PROGRAM_PROJECT,
          projects: remainProjects,
        });
      });
  };

  submitCloneRequest = p => {
    this.handleCloseDialogForm();
    p.project["id"] = this.state.projectIdToClone;
    p.project["program_id"] = this.props.currentProgram.id;
    this.props.cloneProject(p).then(payload => {
      this.setState(
        {
          processingErrors: null,
          processingDialogOpen: true,
          flowId: payload.payload.project_id,
          projectName: p.project.name,
          projectNameToClone: p.project.name,
          projectIdToClone: payload.payload.project_id,
        },
        this.delayedCheckProcessing,
      );

      var projects_array = this.props.currentProgram.projects;
      projects_array.push(payload.payload.project);
      store.dispatch({
        type: types.ADD_PROGRAM_PROJECT,
        projects: projects_array,
      });
    });
  };

  title = p => <Typography variant="h5">{p.name}</Typography>;

  render() {
    const { classes, currentProgram } = this.props;
    if (!currentProgram) {
      return <SectionSpinner />;
    }
    return (
      <Fragment>
        <AppBarDefault></AppBarDefault>
        <FlagsDialog
          open={this.state.flagsDialogOpen}
          project={this.state.flagsDialogProject}
          programId={this.props.programId}
          onClose={e => this.setState({ flagsDialogOpen: false })}
        />
        <ConfirmDialog
          handleConfirm={this.submitDestroyRequest}
          handleClose={this.handleClose}
          content="Are you sure you want to delete this project?"
          open={this.state.confirmBoxOpen}
        />

        <ContentDialogForm
          handleClone={this.submitCloneRequest}
          handleClose={this.handleCloseDialogForm}
          projectName={this.state.projectNameToClone}
          open={this.state.dialogFormOpen}
        />

        <LayoutContainer position="static">
          {this.processingDialog()}
          {!currentProgram.projects ||
            (!currentProgram.projects.length && (
              <NoDataInfoPage
                type="projects"
                helpText={
                  "A project is where you define the connection from one dhis to another one, the organisation units matchings, the data element matchings and the category option combos to use."
                }
              />
            ))}
          {!!currentProgram.projects &&
            currentProgram.projects.map(project => (
              <Grid item xs={12} md={6} key={project.id}>
                <Card
                  className={
                    !!project.active_flags.length ? classes.projectAlert : null
                  }
                >
                  <CardContent>
                    {withBadge(project, this.title(project), e =>
                      this.setState({
                        flagsDialogOpen: true,
                        flagsDialogProject: project,
                      }),
                    )}
                  </CardContent>
                  <CardActions style={{ display: "flex" }}>
                    <Button
                      to={`/programs/${currentProgram.id}/projects/${project.id}/tasks`}
                      component={Link}
                      color="primary"
                    >
                      Open
                    </Button>
                    <Button onClick={() => this.handleClickOpen(project.id)}>
                      Delete
                    </Button>

                    <Button
                      onClick={() =>
                        this.handleClickDialogFormOpen(project.id, project.name)
                      }
                      style={{ marginLeft: "auto" }}
                    >
                      Copy
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </LayoutContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  programId:
    !!props.match && !!props.match.params
      ? props.match.params.program_id
      : null,
  programs: state.auth.user.programs,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, {
  destroyProject,
  cloneProject,
  fetchProgramProjects,
})(withStyles(styles)(ProgramProjects));

import { ADD_ERROR_NOTIFICATIONS, REMOVE_NOTIFICATIONS } from "../actionTypes";

function buildErrorNotification(message) {
  return { message: message, category: "alert" };
}

function notifications(state = {}, action) {
  switch (action.type) {
    case ADD_ERROR_NOTIFICATIONS:
      return {
        ...state,
        [action.category]: action.messages.map(message =>
          buildErrorNotification(message),
        ),
      };
    case REMOVE_NOTIFICATIONS:
      return { ...state, [action.category]: [] };
    default:
      return state;
  }
}

export default notifications;

import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/auth";
import { Redirect, withRouter } from "react-router";

const SESSION_TIMEOUT = 5 * 60 * 1000;

class PrivateRoutes extends Component {
  componentDidMount() {
    this.ensureValidSession();
  }

  ensureValidSession() {
    if (this.isValidSession()) {
      setTimeout(this.ensureValidSession.bind(this), SESSION_TIMEOUT);
    } else {
      this.props.logoutUser();
    }
  }

  isValidSession() {
    return (
      this.props.isAuthenticated && new Date(this.props.expiresAt) > new Date()
    );
  }

  render() {
    const { children, location } = this.props;

    if (this.isValidSession()) {
      return children;
    } else {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { backUrl: location.pathname },
          }}
        />
      );
    }
  }
}

const mapStateToProps = (state, props) => ({
  expiresAt: state.auth.expiresAt,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logoutUser })(
  withRouter(PrivateRoutes),
);

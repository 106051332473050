import React from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
// import NewMatchingForm from "../Shared/NewMatchingForm";
import NewMatchingFormMeta from "../Shared/NewMatchingFormMeta";
import { connect } from "react-redux";
import { createNewCategoryCombosMatching } from "../../actions/categoryCombosMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

function CategoryCombosMatchingNew(props) {
  const { classes, project, currentProgram } = props;

  return (
    <div>
      <AppBarDefault>
        <Button
          color="secondary"
          aria-label="ArrowBack"
          component={NavLink}
          to={`/programs/${currentProgram.id}/projects/${project.id}/category_combos_matchings`}
        >
          <ArrowBack className={classes.leftIcon} />
          Category Combos matchings
        </Button>
      </AppBarDefault>
      <NewMatchingFormMeta
        currentProgram={currentProgram}
        project={project}
        onSubmit={props.createNewCategoryCombosMatching}
        type="category_combos"
        groupName="Category Combo"
        childrenName="Category Option Combos"
        flowApi="categoryComboFlows"
        doneUrl={`/programs/${
          !!currentProgram ? currentProgram.id : ""
        }/projects/${project.id}/category_combos_matchings`}
        comment="This matching can be used in both directions when pushing data.
        If you need to copy category option combos from one side to the other, set
        the source on the left."
      />
    </div>
  );
}

const mapStateToProps = state => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createNewCategoryCombosMatching })(
  withStyles(styles)(CategoryCombosMatchingNew),
);

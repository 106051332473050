import {
  REQUEST_DATA_SETS_MATCHINGS,
  RECEIVE_DATA_SETS_MATCHINGS,
  REQUEST_DATA_SETS_MATCHINGS_FAILED,
} from "../actionTypes";

function dataSetsMatchings(
  state = {
    entities: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_DATA_SETS_MATCHINGS:
      return Object.assign({}, state, {
        entities: [],
        isFetching: true,
      });
    case RECEIVE_DATA_SETS_MATCHINGS:
      return Object.assign({}, state, {
        entities: action.payload.data,
        isFetching: false,
        meta: action.payload.meta,
      });
    case REQUEST_DATA_SETS_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        entities: [],
        errors: action.payload.errors,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default dataSetsMatchings;

export const selectDataSetsMatching = (dsMatchings, dsMatchingId) => {
  return dsMatchings.find(matching => matching.id === parseInt(dsMatchingId, 10));
};

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { FeedbackOutlined } from "@material-ui/icons";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusErroredAndPurged extends React.Component {
  render() {
    const { classes, withLabel, errors } = this.props;
    return (
      <TaskStatus
        help={errors}
        lbl={withLabel ? "Done - purged, with errors" : null}
        icon={<FeedbackOutlined className={classes.iconLeft} />}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusErroredAndPurged);

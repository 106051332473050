import React, { Component } from "react";
import { connect } from "react-redux";
import _unionBy from "lodash/unionBy";
import store from "../store";
import * as types from "../actionTypes";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { resolveFlags } from "../actions/projects";

class FlagsDialog extends Component {
  state = {};

  render() {
    const { project, open, onClose } = this.props;

    const editProjectFlags = payload => {
      var projects_array = this.props.currentProgram.projects;
      var flaged_project = projects_array.filter(
        proj => proj.id === payload.payload.id,
      )[0];
      flaged_project.active_flags = payload.payload.active_flags;
      flaged_project.resolved_flags = payload.payload.resolved_flags;
      var new_projects_array = _unionBy([flaged_project], projects_array, "id");
      store.dispatch({
        type: types.UPDATE_PROGRAM_PROJECT,
        projects: new_projects_array,
      });
    };

    if (!project) return null;
    return (
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        aria-labelledby="info-dialog-title"
      >
        <DialogTitle id="info-dialog-title">
          <Typography variant="h5">{project.name}</Typography>
        </DialogTitle>
        <DialogContent>
          {!!project.active_flags.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <Typography variant="h6">Active flags</Typography>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>Solution</TableCell>
                  <TableCell>
                    <Button
                      onClick={e => {
                        this.props
                          .resolveFlags(
                            this.props.currentProgram.id,
                            this.props.project.id,
                            project.active_flags.map(f => f.id),
                            "Bulk resolve",
                          )
                          .then(payload => {
                            editProjectFlags(payload);
                            window.location.reload(false);
                          });
                        this.props.onClose();
                      }}
                    >
                      RESOLVE ALL
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {project.active_flags.map((f, i) => (
                  <TableRow key={`active-${i}`}>
                    <TableCell>
                      {moment.utc(f.created_at).calendar()} - {f.message}
                    </TableCell>
                    <TableCell>
                      <TextField
                        onChange={e => this.setState({ [i]: e.target.value })}
                      ></TextField>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={e => {
                          this.props
                            .resolveFlags(
                              this.props.currentProgram.id,
                              this.props.project.id,
                              [f.id],
                              this.state[i],
                            )
                            .then(payload => {
                              editProjectFlags(payload);
                              window.location.reload(false);
                            });
                          this.props.onClose();
                        }}
                      >
                        RESOLVE
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {!!project.resolved_flags.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <Typography variant="h6">Resolved flags</Typography>
                </TableRow>
              </TableHead>
              <TableHead>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>Resolved</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {project.resolved_flags.map((f, i) => (
                  <TableRow key={`resolved-${i}`}>
                    <TableCell>
                      {moment.utc(f.created_at).calendar()} - {f.message}
                    </TableCell>
                    <TableCell>
                      {moment.utc(f.resolved_at).calendar()} -{" "}
                      {f.resolved_comment}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { resolveFlags })(FlagsDialog);

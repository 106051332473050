import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Chip } from "@material-ui/core";

import {
  OU_AGGREGATIONS,
  DE_AGGREGATIONS,
} from "../../Tasks/Forms/PushAnalyticFlow";

const styles = theme => ({
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
    marginBottom: "5px",
  },
});

class PushAnalyticFlowPreview extends Component {
  render() {
    const { classes, scheduler } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>DEG matching:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {scheduler.resolved_params.data_element_group_matching_name}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>DE Aggreg.:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {DE_AGGREGATIONS[scheduler.params.de_aggregation_type](
                scheduler.params,
              )}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>OUG matching:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {scheduler.resolved_params.organisation_unit_group_matching_name}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>OU Aggreg.:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {OU_AGGREGATIONS[scheduler.params.ou_aggregation_type](
                scheduler.params,
              )}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Src period type(s):</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {!!scheduler.params.src_period_types &&
                scheduler.params.src_period_types.join(", ")}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Dest period type:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>{scheduler.params.dest_period_type}</strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Period range:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {!!scheduler.params.period_range &&
                !!scheduler.params.period_range_unit &&
                `${scheduler.params.period_range} ${scheduler.params.period_range_unit}`}
            </strong>
          </Typography>
        </Grid>
        {!!scheduler.resolved_params.periods && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>Next periods:</Typography>
            </Grid>
            <Grid item xs={8}>
              {scheduler.resolved_params.periods.map((p, i) => (
                <Chip className={classes.chip} key={`p-${i}`} label={p} />
              ))}
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PushAnalyticFlowPreview);

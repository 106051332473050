import React from "react";
import TaskStatusPurging from "./TaskStatus/TaskStatusPurging";
import TaskStatusFailed from "./TaskStatus/TaskStatusFailed";
import TaskStatusFailedAndPurged from "./TaskStatus/TaskStatusFailedAndPurged";
import TaskStatusPaused from "./TaskStatus/TaskStatusPaused";
import TaskStatusErrored from "./TaskStatus/TaskStatusErrored";
import TaskStatusErroredAndPurged from "./TaskStatus/TaskStatusErroredAndPurged";
import TaskStatusDone from "./TaskStatus/TaskStatusDone";
import TaskStatusDoneAndPurged from "./TaskStatus/TaskStatusDoneAndPurged";
import TaskStatusRunning from "./TaskStatus/TaskStatusRunning";
import TaskStatusRunningWithErrors from "./TaskStatus/TaskStatusRunningWithErrors";
import TaskStatusWaiting from "./TaskStatus/TaskStatusWaiting";
import TaskStatusStopped from "./TaskStatus/TaskStatusStopped";
import TaskStatusStoppedAndPurged from "./TaskStatus/TaskStatusStoppedAndPurged";

function TaskStatusFactory(props) {
  let hasErrors =
    props.task.summary["latest_errors"] &&
    !!props.task.summary["latest_errors"].length;
  let bagde_message = props.badge_message ? props.badge_message : "";
  if (props.task.is_purging && !props.task.is_stopped) {
    return (
      <TaskStatusPurging withLabel={props.withLabel} tooltip={`Purging`} />
    );
  } else if (props.task.failed_at && !props.task.purged_at) {
    return (
      <TaskStatusFailed
        withLabel={props.withLabel}
        error={props.task.message}
      />
    );
  } else if (props.task.failed_at) {
    return (
      <TaskStatusFailedAndPurged
        withLabel={props.withLabel}
        error={props.task.message}
        tooltip={`Failed and purged`}
      />
    );
  } else if (props.task.is_resumable) {
    return (
      <TaskStatusPaused
        withLabel={props.withLabel}
        pausedAt={props.task.paused_at}
        tooltip={`It has been paused`}
      />
    );
  } else if (props.task.completed_at && hasErrors && !props.task.purged_at) {
    return (
      <TaskStatusErrored
        withLabel={props.withLabel}
        errors={props.task.summary["latest_errors"]}
        tooltip={`Errored and failed`}
      />
    );
  } else if (props.task.completed_at && hasErrors) {
    return (
      <TaskStatusErroredAndPurged
        withLabel={props.withLabel}
        errors={props.task.summary["latest_errors"]}
        tooltip={`Errored and purged`}
      />
    );
  } else if (
    props.task.completed_at &&
    !props.task.purged_at &&
    !props.task.is_stopped
  ) {
    return (
      <TaskStatusDone
        withLabel={props.withLabel}
        tooltip={`Successfully done ${bagde_message}`}
      />
    );
  } else if (props.task.completed_at && !props.task.is_stopped) {
    return (
      <TaskStatusDoneAndPurged
        withLabel={props.withLabel}
        tooltip={`Done ${bagde_message} and after purged`}
      />
    );
  } else if (hasErrors) {
    return (
      <TaskStatusRunningWithErrors
        withLabel={props.withLabel}
        errors={props.task.summary["latest_errors"]}
        tooltip={`Running with an error`}
      />
    );
  } else if (props.task.is_waiting) {
    return (
      <TaskStatusWaiting
        withLabel={props.withLabel}
        pausedAt={props.task.paused_at}
        tooltip={`Paused ${bagde_message}`}
      />
    );
  } else if (!!props.task.is_stopped && !props.task.purged_at) {
    return (
      <TaskStatusStopped withLabel={props.withLabel} tooltip={`Stopped`} />
    );
  } else if (!!props.task.is_stopped && !!props.task.purged_at) {
    return (
      <TaskStatusStoppedAndPurged
        withLabel={props.withLabel}
        tooltip={`Stopped and purged`}
      />
    );
  } else {
    return (
      <TaskStatusRunning withLabel={props.withLabel} tooltip={`It's running`} />
    );
  }
}

export default TaskStatusFactory;

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Pause } from "@material-ui/icons";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusWaiting extends React.Component {
  render() {
    const { classes, withLabel, tooltip } = this.props;
    return (
      <TaskStatus
        lbl={withLabel ? "Paused for preview" : null}
        icon={<Pause className={classes.iconLeft} />}
        help={tooltip}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusWaiting);

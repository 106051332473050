import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { Component } from "react";

import Button from "@material-ui/core/Button";

class ConfirmDialog extends Component {
  static defaultProps = {
    title: "Attention!",
    closeText: "Cancel",
    closeOnly: false,
  };
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          {this.props.content && (
            <DialogContentText>{this.props.content}</DialogContentText>
          )}
          {this.props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose}>
            {this.props.closeText}
          </Button>
          {!this.props.closeOnly && (
            <Button onClick={this.props.handleConfirm} color="primary">
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDialog;

import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

const fileNamesOrUrls = task_summary => {
  if (task_summary["file_details"]) {
    return task_summary["file_details"].map(({ name, link }) => {
      return <a href={link}>{name}</a>;
    });
  } else {
    return (task_summary["files"] || []).join(", ") || "";
  }
};
class DumpDataFlowSummary extends Component {
  render() {
    const { task } = this.props;
    let summaries = [
      [
        { label: "Bucket", value: task.summary["bucket"] },
        {
          label: "Files",
          value: fileNamesOrUrls(task.summary),
        },
      ],
    ];
    let det = [];
    if (
      !!task.summary["missing_cocs"] &&
      !!task.summary["missing_cocs"].length
    ) {
      det.push({
        label: "Unmatched COCs",
        value: task.summary["missing_cocs"].length,
      });
    }
    det.push({ label: "Records", value: task.summary["records_count"] });
    summaries.push(det);
    return <TaskHashCell task={summaries} />;
  }
}

export default DumpDataFlowSummary;

import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";
import * as types from "../actionTypes";

export function fetchCategoriesMatchings(programId, projectId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/categories`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CATEGORIES_MATCHINGS,
        types.RECEIVE_CATEGORIES_MATCHINGS,
        types.REQUEST_CATEGORIES_MATCHINGS_FAILED,
      ],
    },
  };
}

export function createNewCategoriesMatching(programId, projectId, payload) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/sources/${payload.left_source_id}/category_flows`,
      method: "POST",
      body: JSON.stringify(payload),
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CREATE_CATEGORIES_MATCHING,
        {
          type: types.RECEIVE_CREATE_CATEGORIES_MATCHING,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              return payload;
            });
          },
        },
        types.REQUEST_CREATE_CATEGORIES_MATCHING_FAILED,
      ],
    },
  };
}

import React, { Component } from "react";
import { Card, CardHeader, Avatar } from "@material-ui/core";
import store from "../store";
import { logoutUser } from "../actions/auth";
import history from "../history";

class MenuHeader extends Component {
  logout() {
    history.push("/programs");
    store.dispatch(logoutUser());
  }

  render() {
    const Logout = () => (
      <a href="/" onClick={this.logout.bind(this)}>
        Logout
      </a>
    );

    return (
      <Card>
        <CardHeader
          avatar={<Avatar aria-label="Current user" />}
          title={this.props.userName}
          subheader={<Logout />}
        />
      </Card>
    );
  }
}

export default MenuHeader;

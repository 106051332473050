import React, { Component, Fragment } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Divider,
} from "@material-ui/core";
import { invalidateSourceConnection } from "../actions/sources";
import { bindActionCreators } from "redux";
import { Add, Dashboard } from "@material-ui/icons";
import { connect } from "react-redux";
import store from "../store";
import { NavLink, withRouter, Link } from "react-router-dom";
import MenuHeader from "./MenuHeader";
import { NestedAccordionMenu } from "@blsq/manager-ui";
import { D2dLogo } from "@blsq/manager-ui";
import { fetchProjects } from "../actions/projects";
import InboxIcon from "@material-ui/icons/Inbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withBadge } from "../helpers/projectHelpers";

const drawerWidth = 300;

const styles = theme => ({
  list: {},
  rootpage: { display: "flex" },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  content: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  logo: { width: "130px" },
});

class MenuWrapper extends Component {
  componentDidMount() {
    if (this.props.currentProgram !== null) {
      store.dispatch(fetchProjects(this.props.currentProgram.id));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.countProjects !== this.props.countProjects &&
      this.props.currentProgram !== null &&
      this.props.currentProgram.id !== prevProps.currentProgram.id
    ) {
      store.dispatch(fetchProjects(this.props.currentProgram.id));
    }
  }

  render() {
    const {
      location,
      programs,
      projects,
      classes,
      programProjects,
      currentProgram,
    } = this.props;
    const metadatas = [
      {
        name: "Journal",
        to: "/tasks",
      },
      {
        name: "Scheduler",
        to: "/schedulers",
      },
      {
        name: "General",
        to: "/edit",
      },
      // {
      //   name: "Org. Units",
      //   to: "/edit/org_unit_groups_matchings",
      // },
      {
        name: "Org. Unit Groups",
        to: "/org_unit_groups_matchings",
      },
      // {
      //   name: "Category Options",
      //   to: "/categories_matchings",
      // },
      {
        name: "Categories",
        to: "/categories_matchings",
      },
      {
        name: "Category Combos",
        to: "/category_combos_matchings",
      },
      // {
      //   name: "Data Elements",
      //   to: "/data_element_groups_matchings",
      // },
      {
        name: "Data Element Groups",
        to: "/data_element_groups_matchings",
      },
      { name: "Data Sets", to: "/data_sets_matchings" },
    ];
    let projectsList = !!programProjects > 0 ? programProjects : projects;
    projectsList.forEach((project_meta, index) => {
      projectsList[index]["Icon"] = InboxIcon;
      projectsList[index]["items"] = [];
      projectsList[index]["help"] = project_meta["name"];
      projectsList[index]["decorator"] = i => withBadge(project_meta, i);
      metadatas.forEach((meta, index_meta) => {
        let meta_item = {};
        meta_item["to"] =
          `/programs/${!!currentProgram ? currentProgram.id : ""}/projects/` +
          project_meta["id"] +
          meta["to"];
        meta_item["name"] = meta["name"];
        meta_item["Icon"] = meta["Icon"];
        meta_item["help"] = meta["help"];
        projectsList[index]["items"].push(meta_item);
      });
    });

    const invalidateSourceConnection = () => {
      const sourceTypes = ["leftSource", "rightSource"];
      sourceTypes.forEach(sourceType => {
        this.props.invalidateSourceConnection(sourceType);
      });
    };

    const sideList = (
      <div>
        <Fragment>
          <List>
            <ListItem>
              <Link to={`/programs`}>
                <D2dLogo variant="horizontal" className={classes.logo} />
              </Link>
            </ListItem>
            <ListItem
              key={`dashboard`}
              button
              to={`/programs/${
                !!currentProgram ? currentProgram.id : ""
              }/projects`}
              component={NavLink}
            >
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText
                primary={`${
                  !!currentProgram ? currentProgram.name : ""
                } dashboard`}
              />
            </ListItem>
            {projectsList.length > 0 && (
              <Fragment>
                <Divider />
                <NestedAccordionMenu
                  items={projectsList}
                  currentPath={location.pathname}
                  link={Link}
                />
              </Fragment>
            )}

            <Divider />
            <ListItem
              button
              to={{
                pathname: `/programs/${
                  !!currentProgram ? currentProgram.id : ""
                }/projects/new`,
                state: { backUrl: location.pathname },
              }}
              component={NavLink}
            >
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText
                primary="Add a project"
                onClick={() => invalidateSourceConnection()}
              />
            </ListItem>
            {programs.length > 1 && (
              <Fragment>
                <Divider />
                <ListItem
                  button
                  to={{
                    pathname: `/programs`,
                  }}
                  component={NavLink}
                >
                  <ListItemIcon>
                    <ArrowBackIcon />
                  </ListItemIcon>
                  <ListItemText primary="Change program" />
                </ListItem>
              </Fragment>
            )}
          </List>
        </Fragment>
        <MenuHeader
          className={classes.menuheader}
          userName={this.props.userName}
        />
      </div>
    );

    return (
      <div className={classes.rootpage}>
        <Drawer variant="permanent">
          <div tabIndex={0} role="button" className={classes.drawer}>
            {sideList}
          </div>
        </Drawer>
        <div className={classes.content}>{this.props.children}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  programs: state.auth.user.programs || [],
  programProjects: !!state.programs.currentProgram
    ? state.programs.currentProgram.projects || []
    : [],
  userName: state.auth.user.email,
  countProjects: state.programs.countProjects,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ invalidateSourceConnection }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuWrapper)),
);

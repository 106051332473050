import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class MapMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;

    const match_task_summary = match_key => {
      if (task.summary["match_task"]) {
        return task.summary["match_task"][match_key]
          ? task.summary["match_task"][match_key]
          : 0;
      } else {
        return "Not counted";
      }
    };

    const summaries = [
      [
        {
          label: "Matched Group",
          value: match_task_summary("matched_group_count"),
        },
        {
          label: "Skipped Group",
          value: match_task_summary("skipped_group_count"),
        },
        {
          label: "Matched entities",
          value: match_task_summary("matched_entities_count"),
        },
        {
          label: "Left skipped entities",
          value: match_task_summary("left_skipped_entities_count"),
        },
        {
          label: "Right skipped entities",
          value: match_task_summary("right_skipped_entities_count"),
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default MapMetaFlowSummary;

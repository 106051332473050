import * as types from "../actionTypes";

function dataSets(
  state = {
    suggestions: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_DATA_SET_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: [],
        errors: null,
        isFetching: true,
      });
    case types.RECEIVE_DATA_SET_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: action.payload,
        isFetching: false,
      });
    case types.REQUEST_DATA_SET_SUGGESTIONS_FAILED:
      return Object.assign({}, state, {
        suggestions: [],
        errors: action.payload,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default dataSets;

import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";
import { fetchCategoryOptions } from "./categoryOptions";
import * as types from "../actionTypes";
import { stringify } from "query-string";
import _omitBy from "lodash/omitBy";
import _isUndefined from "lodash/isUndefined";

export function fetchCategoryOptionsMatchings(
  programId,
  projectId,
  catMatchingId,
  page,
  force = false,
) {
  const meta = { meta: { force: force } };

  const queryParams = {
    page: page,
    category_matching_id: catMatchingId,
    per_page: 500,
  };

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));

  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_options?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CATEGORY_OPTIONS_MATCHINGS,
        { type: types.RECEIVE_CATEGORY_OPTIONS_MATCHINGS, ...meta },
        types.REQUEST_CATEGORY_OPTIONS_MATCHINGS_FAILED,
      ],
    },
  };
}

export function createNewCategoryOptionsMatching(
  programId,
  projectId,
  payload,
  matchingType,
  catMatchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_options`,
      method: "POST",
      body: JSON.stringify(payload),
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CREATE_CATEGORY_OPTIONS_MATCHING,
        {
          type: types.RECEIVE_CREATE_CATEGORY_OPTIONS_MATCHING,
          payload: (action, state, response) => {
            return response.json().then(responsePayload => {
              store.dispatch({
                type: types.DESTROY_LOCAL_CATEGORY_OPTION,
                sourceId: payload.first_element.source_id,
                categoryOptionId: payload.first_element.id,
              });
              store.dispatch({
                type: types.DESTROY_LOCAL_CATEGORY_OPTION,
                sourceId: payload.second_element.source_id,
                categoryOptionId: payload.second_element.id,
              });
              store.dispatch(
                fetchCategoryOptionsMatchings(
                  programId,
                  projectId,
                  catMatchingId,
                  1,
                  true,
                ),
              );
              return responsePayload;
            });
          },
        },
        types.REQUEST_CREATE_CATEGORY_OPTIONS_MATCHING_FAILED,
      ],
    },
  };
}

export function destroyCategoryOptionsMatching(
  programId,
  projectId,
  matchingType,
  catMatching,
  coMatchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_options/${coMatchingId}`,
      method: "DELETE",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_DESTROY_CATEGORY_OPTIONS_MATCHING,
        {
          type: types.RECEIVE_DESTROY_CATEGORY_OPTIONS_MATCHING,
          payload: (action, state, response) => {
            store.dispatch(
              fetchCategoryOptions(
                programId,
                projectId,
                catMatching.left.source.id,
                matchingType,
                catMatching.left.id,
                undefined,
                1,
                true,
              ),
            );
            store.dispatch(
              fetchCategoryOptionsMatchings(
                programId,
                projectId,
                catMatching.id,
                1,
                true,
              ),
            );
          },
        },
        types.REQUEST_DESTROY_CATEGORY_OPTIONS_MATCHING_FAILED,
      ],
    },
  };
}

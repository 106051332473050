import React, { Component, Fragment } from "react";

import {
  COMPARE_DEG_DHIS_TO_DHIS_META_FLOW,
  COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
  COMPARE_DEG_D2D_TO_D2D_META_FLOW,
  COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
  SYNCHRONIZE_DEG_META_FLOW,
  COMPARE_TASKS_SCHEDULER_PANELS,
} from "../constants";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Grid,
  FormControl,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import AutoComplete from "../../Shared/AutoComplete";
import CompareParams from "../../Shared/CompareParams";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import CheckBoxComponent from "../Shared/CheckBoxComponent";
import {
  formStyles,
  expansionPanelStyles,
} from "../../../helpers/commonStyles";

const styles = theme => ({
  ...formStyles(theme),
  ...expansionPanelStyles(theme),
  sectionTitle: {
    fontWeight: "bold",
  },
});

export const DEG_COMPARE_PARAMS = [
  {
    key: "new_entities_left",
    tooltip: "Uncheck this if you don't want to create new DE on your target",
    label: "Create new DEs on target",
  },
  {
    key: "existing_entities_right",
    tooltip:
      "Uncheck this if you don't want to create new DE on your target DHIS",
    label: "link existing DEs on Target",
  },
  {
    key: "new_entities_right",
    tooltip:
      "Uncheck that if you don't want to remove any DE from your target DEG",
    label: "Remove DEs from DEG target",
  },
  {
    key: "modified_entities",
    tooltip:
      "Uncheck that if you don't want to update any DE on your target DHIS",
    label: "Update DEs on target",
  },
  { key: "name", label: "Name", tooltip: "The DEG's name" },
  { key: "short_name", label: "Short Name", tooltip: "The DEG's short name" },
];

export const DES_COMPARE_PARAMS = [
  { key: "name", label: "Name", tooltip: "The DE's name" },
  { key: "short_name", label: "Short Name", tooltip: "The DE's short name" },
  {
    key: "category_combo",
    label: "Category Combo",
    tooltip: "The DE's category combo",
  },
];

class CompareMetaFormDeg extends Component {
  constructor(props) {
    super();
    this.state = {
      expanded:
        props.entity.id !== undefined
          ? props.entity.params.side !== undefined
            ? COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class][
                props.entity.params.side
              ]
            : COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class]
          : "panel5",
      preview: props.entity.params.preview ? "Preview" : "",
      preview_checkbox: true,
      sequencer_class: COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
    };
  }

  componentDidMount() {
    this.props.handleChange(`send_mail`, 3);
  }

  handleCompareDegParamsChange = (param, des_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_group_params;
    if (event.target.checked) {
      this.check_des_param(param);
      params.push(param);
    } else {
      this.uncheck_des_param(param);
      params = params.filter(p => p !== param);
    }
    this.props.handleParamChange("compare_group_params", params);
  };

  uncheck_des_param = param => {
    if (param === DEG_COMPARE_PARAMS[3].key) {
      this.props.handleParamChange("compare_entities_params", []);
    }
  };

  check_des_param = param => {
    if (param === DEG_COMPARE_PARAMS[3].key) {
      this.props.handleParamChange("compare_entities_params", [
        DES_COMPARE_PARAMS[0].key,
      ]);
    }
  };

  uncheck_deg_param = (params, deg_params) => {
    if (params.length === 0 && deg_params.includes(DEG_COMPARE_PARAMS[3].key)) {
      deg_params = deg_params.filter(p => p !== DEG_COMPARE_PARAMS[3].key);
      this.props.handleParamChange("compare_group_params", deg_params);
    }
  };

  check_deg_param = (param, deg_params) => {
    if (!deg_params.includes(DEG_COMPARE_PARAMS[3].key)) {
      deg_params = deg_params.push(DEG_COMPARE_PARAMS[3].key);
    }
  };

  handleCompareDesParamsChange = (param, des_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_entities_params;
    if (event.target.checked) {
      this.check_deg_param(param, des_params);
      params.push(param);
    } else {
      params = params.filter(p => p !== param);
      this.uncheck_deg_param(params, des_params);
    }
    this.props.handleParamChange("compare_entities_params", params);
  };

  handleChange = (panel, sequencer_class) => (event, isExpanded) => {
    if (sequencer_class !== COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW) {
      this.props.handleRemoveParam("preview");
      this.setState({
        preview: "",
      });
    }
    this.setState({
      preview_checkbox:
        sequencer_class === COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW
          ? true
          : false,
      expanded: isExpanded ? panel : false,
      sequencer_class: sequencer_class,
    });
  };

  placeholderText = source => {
    return `Search for Data Element Groups from ${source}`;
  };

  getValueEdit = (entity, value, sequencer_class, side) => {
    if (side !== undefined) {
      if (side === entity["params"]["side"]) {
        return value;
      } else {
        return undefined;
      }
    } else if (sequencer_class === entity["sequencer_class"]) {
      return value;
    } else {
      return undefined;
    }
  };

  handlePreviewChange = event => {
    if (this.state.preview === "") {
      this.setState({ preview: "Preview" });
      this.props.handleParamChange("preview", true);
      this.props.handleChangeSequencerClassProp([
        {
          prop: "sequencer_class",
          value: SYNCHRONIZE_DEG_META_FLOW,
        },
      ]);
    } else {
      this.setState({ preview: "" });
      this.props.handleParamChange("preview", false);
      this.props.handleChangeSequencerClassProp([
        {
          prop: "sequencer_class",
          value: COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
        },
      ]);
    }
  };

  render() {
    const { project, entity, handleChanges, classes } = this.props;
    const compare_scenarios = [
      {
        label: "DHIS2 TO DHIS2",
        left_instance: "dhis2",
        right_instance: "dhis2",
        left_source: this.getValueEdit(
          entity,
          entity.params.leftSourceGroup,
          COMPARE_DEG_DHIS_TO_DHIS_META_FLOW,
          undefined,
        ),
        right_source: this.getValueEdit(
          entity,
          entity.params.rightSourceGroup,
          COMPARE_DEG_DHIS_TO_DHIS_META_FLOW,
          undefined,
        ),
        left_source_id: this.props.project.left_source.id,
        right_source_id: this.props.project.right_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class: COMPARE_DEG_DHIS_TO_DHIS_META_FLOW,
        left_source_name: `DHIS2 ${this.props.project.left_source.name}`,
        right_source_name: `DHIS2 ${this.props.project.right_source.name}`,
      },
      {
        label: "DHIS2 SOURCE TO D2D SOURCE",
        left_instance: "dhis2",
        right_instance: "d2d",
        left_source: this.getValueEdit(
          entity,
          entity.params.rightSourceGroup,
          COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
          "source",
        ),
        right_source: this.getValueEdit(
          entity,
          entity.params.leftSourceGroup,
          COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
          "source",
        ),
        left_source_id: this.props.project.left_source.id,
        right_source_id: this.props.project.left_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class: COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
        left_source_name: `DHIS2 ${this.props.project.left_source.name}`,
        right_source_name: `D2D ${this.props.project.left_source.name}`,
        side: "source",
      },
      {
        label: "DHIS2 TARGET TO D2D TARGET",
        left_instance: "dhis2",
        right_instance: "d2d",
        left_source: this.getValueEdit(
          entity,
          entity.params.leftSourceGroup,
          COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
          "target",
        ),
        right_source: this.getValueEdit(
          entity,
          entity.params.rightSourceGroup,
          COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
          "target",
        ),
        left_source_id: this.props.project.right_source.id,
        right_source_id: this.props.project.right_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class: COMPARE_DEG_DHIS_TO_D2D_META_FLOW,
        left_source_name: `DHIS2 ${this.props.project.right_source.name}`,
        right_source_name: `D2D ${this.props.project.right_source.name}`,
        side: "target",
      },
      {
        label: "D2D TO D2D",
        left_instance: "d2d",
        right_instance: "d2d",
        left_source: this.getValueEdit(
          entity,
          entity.params.leftSourceGroup,
          COMPARE_DEG_D2D_TO_D2D_META_FLOW,
          undefined,
        ),
        right_source: this.getValueEdit(
          entity,
          entity.params.rightSourceGroup,
          COMPARE_DEG_D2D_TO_D2D_META_FLOW,
          undefined,
        ),
        left_source_id: this.props.project.left_source.id,
        right_source_id: this.props.project.right_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class: COMPARE_DEG_D2D_TO_D2D_META_FLOW,
        left_source_name: `D2D ${this.props.project.left_source.name}`,
        right_source_name: `D2D ${this.props.project.right_source.name}`,
      },
      {
        label: "DHIS2 AND D2D FULL COMPARISON",
        left_instance: "dhis2",
        right_instance: "dhis2",
        left_source: this.getValueEdit(
          entity,
          entity.params.leftSourceGroup,
          entity.params.preview
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
          undefined,
        ),
        right_source: this.getValueEdit(
          entity,
          entity.params.rightSourceGroup,
          entity.params.preview
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
          undefined,
        ),
        left_source_id: this.props.project.left_source.id,
        right_source_id: this.props.project.right_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class:
          this.state.sequencer_class ===
            COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW && this.state.preview !== ""
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
        left_source_name: `DHIS2 ${this.props.project.left_source.name}`,
        right_source_name: `DHIS2 ${this.props.project.right_source.name}`,
      },
    ];

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div>
              {compare_scenarios.map((item, index) => {
                return (
                  <ExpansionPanel
                    className={classes.expansionPanel}
                    key={`COMP_SCEN_${index}`}
                    square
                    expanded={this.state.expanded === "panel" + (index + 1)}
                    onChange={this.handleChange(
                      "panel" + (index + 1),
                      item["sequencer_class"],
                    )}
                    sequencer_class={item["sequencer_class"]}
                  >
                    <ExpansionPanelSummary
                      className={classes.expansionPanelSummary}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="paneld-content"
                      id="paneld-header"
                    >
                      <Typography>{item["label"]}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      className={classes.expansionPanelDetails}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <AutoComplete
                            projectId={project.id}
                            sourceId={item["left_source_id"]}
                            onChange={e =>
                              handleChanges(
                                [
                                  {
                                    prop: "sequencer_class",
                                    value: item["sequencer_class"],
                                  },
                                ],
                                [
                                  {
                                    prop: item["left_identity"],
                                    value: e,
                                  },
                                  item["side"] !== undefined
                                    ? { prop: "side", value: item["side"] }
                                    : {},
                                ],
                              )
                            }
                            type="data_element_groups"
                            instance={item["left_instance"]}
                            value={item["left_source"]}
                            placeholder={this.placeholderText(
                              item["left_source_name"],
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutoComplete
                            projectId={project.id}
                            sourceId={item["right_source_id"]}
                            onChange={e =>
                              handleChanges(
                                [
                                  {
                                    prop: "sequencer_class",
                                    value: item["sequencer_class"],
                                  },
                                ],
                                [
                                  {
                                    prop: item["right_identity"],
                                    value: e,
                                  },
                                  item["side"] !== undefined
                                    ? { prop: "side", value: item["side"] }
                                    : {},
                                ],
                              )
                            }
                            type="data_element_groups"
                            instance={item["right_instance"]}
                            value={item["right_source"]}
                            placeholder={this.placeholderText(
                              item["right_source_name"],
                            )}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.textField}>
              <CompareParams
                label="Data Element Groups Params"
                group_params={DEG_COMPARE_PARAMS}
                params={entity.params.compare_group_params}
                entities_params={entity.params.compare_entities_params}
                handleCompareParamsChange={this.handleCompareDegParamsChange}
              />
            </FormControl>
            <FormControl className={classes.textField}>
              <CompareParams
                label="Data Elements Params"
                group_params={DES_COMPARE_PARAMS}
                params={entity.params.compare_entities_params}
                entities_params={entity.params.compare_group_params}
                handleCompareParamsChange={this.handleCompareDesParamsChange}
              />
            </FormControl>
          </Grid>
          {this.state.preview_checkbox && (
            <Grid item xs={12}>
              <CheckBoxComponent
                componentStyle={classes.sectionTitle}
                preview={this.state.preview}
                checkboxLabel="Apply changes without confirmation :"
                label="Apply without confirmation"
                handlePreviewChange={() => this.handlePreviewChange}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
});
export default connect(mapStateToProps)(withStyles(styles)(CompareMetaFormDeg));

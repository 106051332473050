import React, { Component } from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import DataElementGroupsMatchingEditForm from "./DataElementGroupsMatchingEditForm";
import { connect } from "react-redux";
import SectionSpinner from "../Shared/SectionSpinner";
import { fetchDataElementGroupsMatchings } from "../../actions/dataElementGroupsMatchings";
import { selectDataElementGroupMatching } from "../../reducers/dataElementGroupsMatchings";
import { leftIcon } from "../../helpers/commonStyles";

const styles = theme => ({
  ...leftIcon(theme),
});

class DataElementGroupsMatchingEdit extends Component {
  componentDidMount() {
    !this.props.degMatching &&
      this.props.fetchDataElementGroupsMatchings(
        this.props.currentProgram.id,
        this.props.project.id,
        false,
      );
  }

  render() {
    const { classes, project, currentProgram } = this.props;
    let content;

    if (!this.props.degMatching) {
      content = <SectionSpinner />;
    } else {
      content = (
        <DataElementGroupsMatchingEditForm
          project={project}
          degMatching={this.props.degMatching}
        />
      );
    }

    return (
      <div>
        <AppBarDefault title="Edit Data Element Groups matching">
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${currentProgram.id}/projects/${project.id}/data_element_groups_matchings`}
          >
            <ArrowBack className={classes.leftIcon} />
            Data Element Groups matchings
          </Button>
        </AppBarDefault>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  degMatching: selectDataElementGroupMatching(
    state.dataElementGroupsMatchings.entities,
    props.match.params.data_element_group_matching_id,
  ),
});

export default connect(mapStateToProps, { fetchDataElementGroupsMatchings })(
  withRouter(withStyles(styles)(DataElementGroupsMatchingEdit)),
);

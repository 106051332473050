import store from "../store";

const routesMatching = {
  orgUnitClones: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/organisation_unit_cloners${opts.id ? "/" + opts.id : ""}`;
  },
  orgUnitGroupFlows: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${opts.sourceId}/organisation_unit_group_flows/${opts.flowId}`;
  },
  projectImporters: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/show_importer`;
  },
  orgUnitsAutoMappers: function(opts) {
    return `/programs/${opts.programId}/projects/${
      opts.projectId
    }/matchings/organisation_unit_groups/${opts.matchingId}/auto_matchers${
      opts.id ? "/" + opts.id : ""
    }`;
  },
  destroyOrgUnitsMatchingPrev: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/organisation_units/${opts.id}/destroy_prev`;
  },
  orgUnitsGroupImports: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/organisation_unit_group_importers${opts.id ? "/" + opts.id : ""}`;
  },
  orgUnitsGroupsMatchings: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/organisation_unit_groups/${opts.id}`;
  },
  categoryImports: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/category_importers${opts.id ? "/" + opts.id : ""}`;
  },
  categoriesMatchings: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/categories/${opts.id}`;
  },
  categoryFlows: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${opts.sourceId}/category_flows/${opts.flowId}`;
  },
  categoryOptionClones: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/category_option_cloners${opts.id ? "/" + opts.id : ""}`;
  },
  categoryOptionsAutoMappers: function(opts) {
    return `/programs/${opts.programId}/projects/${
      opts.projectId
    }/matchings/categories/${opts.matchingId}/auto_matchers${
      opts.id ? "/" + opts.id : ""
    }`;
  },
  destroyCategoryOptionsMatchingPrev: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/category_options/${opts.id}/destroy_prev`;
  },
  categoryComboImports: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/category_combo_importers${opts.id ? "/" + opts.id : ""}`;
  },
  categoryComboFlows: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${opts.sourceId}/category_combo_flows/${opts.flowId}`;
  },
  categoriesCombosMatchings: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/category_combos/${opts.id}`;
  },
  categoryOptionComboClones: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/category_option_combo_cloners${opts.id ? "/" + opts.id : ""}`;
  },
  categoryOptionCombosAutoMappers: function(opts) {
    return `/programs/${opts.programId}/projects/${
      opts.projectId
    }/matchings/category_combos/${opts.matchingId}/auto_matchers${
      opts.id ? "/" + opts.id : ""
    }`;
  },
  dataElementGroupFlows: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${opts.sourceId}/data_element_group_flows/${opts.flowId}`;
  },
  dataElementClones: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/data_element_cloners${opts.id ? "/" + opts.id : ""}`;
  },
  dataElementsAutoMappers: function(opts) {
    return `/programs/${opts.programId}/projects/${
      opts.projectId
    }/matchings/data_element_groups/${opts.matchingId}/auto_matchers${
      opts.id ? "/" + opts.id : ""
    }`;
  },
  destroyDataElementsMatchingPrev: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/data_elements/${opts.id}/destroy_prev`;
  },
  dataElementsGroupImports: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/data_element_group_importers${opts.id ? "/" + opts.id : ""}`;
  },
  dataElementsGroupsMatchings: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/data_element_groups/${opts.id}`;
  },
  dataSetFlows: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${opts.sourceId}/data_set_flows/${opts.flowId}`;
  },
  dataSetsAutoMappers: function(opts) {
    return `/programs/${opts.programId}/projects/${
      opts.projectId
    }/matchings/data_sets/${opts.matchingId}/auto_matchers${
      opts.id ? "/" + opts.id : ""
    }`;
  },
  dataSetImports: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/sources/${
      opts.sourceId
    }/data_set_importers${opts.id ? "/" + opts.id : ""}`;
  },
  dataSetsMatchings: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/matchings/data_sets/${opts.id}`;
  },
  task: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}`;
  },
  relaunchImmediateTask: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/relaunch`;
  },
  relaunchWaiting: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/resume_waiting`;
  },
  launchApply: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/apply_changes`;
  },
  stopWaiting: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/stop_waiting`;
  },
  pauseImmediateTask: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/pause`;
  },
  resumeImmediateTask: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/resume`;
  },
  killImmediateTask: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/kill`;
  },
  purgeImmediateTask: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/tasks/${opts.id}/purge`;
  },
  schedulers: function(opts) {
    return `/programs/${opts.programId}/projects/${opts.projectId}/schedulers/${opts.id}`;
  },
};

function route(name, opts) {
  return baseApiUrl + routesMatching[name](opts);
}

function fullHeaders() {
  return new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${store.getState().auth.token}`,
  });
}

function handleJsonResponse(response) {
  if (response.status === 204) return;
  let success = response.ok;
  return response.json().then(function(obj) {
    if (success) {
      return obj;
    } else {
      throw Error(obj.errors || response.statusText);
    }
  });
}

export const authHeader = token => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export function get(name, opts) {
  return fetch(route(name, opts), {
    method: "get",
    headers: fullHeaders(),
  }).then(handleJsonResponse);
}

export function put(name, opts) {
  return fetch(route(name, opts), {
    method: "put",
    headers: fullHeaders(),
  }).then(handleJsonResponse);
}

export function post(name, opts, params = {}) {
  return fetch(route(name, opts), {
    method: "post",
    headers: fullHeaders(),
    body: JSON.stringify(params),
  }).then(handleJsonResponse);
}

export function destroy(name, opts, params = {}) {
  return fetch(route(name, opts), {
    method: "delete",
    headers: fullHeaders(),
    body: JSON.stringify(params),
  }).then(handleJsonResponse);
}

export const baseApiUrl = process.env.REACT_APP_API_URL;

import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

function HelpWrapper(props) {
  if (props.help) {
    return (
      <Tooltip key={props.key} title={props.help}>
        {props.elt}
      </Tooltip>
    );
  } else {
    return props.elt;
  }
}

HelpWrapper.propTypes = {
  elt: PropTypes.object.isRequired,
};

export default HelpWrapper;

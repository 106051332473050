import React, { Component, Fragment } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";

class CheckBoxComponent extends Component {
  render() {
    return (
      <Fragment>
        <FormLabel className={this.props.componentStyle} component="legend">
          {this.props.checkboxLabel}
        </FormLabel>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.preview}
                onChange={this.props.handlePreviewChange()}
                color="primary"
              />
            }
            label={this.props.label}
          />
        </FormGroup>
      </Fragment>
    );
  }
}

export default CheckBoxComponent;

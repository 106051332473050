import {
  MenuItem,
  TextField,
  Typography,
  withStyles,
  LinearProgress,
  Box,
} from "@material-ui/core";
import React, { Component } from "react";

import { connect } from "react-redux";
import { fetchOrgUnitGroupsMatchings } from "../../actions/orgUnitGroupsMatchings";
import { textField } from "../../helpers/commonStyles";

const styles = theme => ({
  ...textField(theme),
  menu: {
    width: 200,
  },
});

class OrgUnitGroupsMatchingsSelect extends Component {
  componentDidMount() {
    this.props.fetchOrgUnitGroupsMatchings(
      this.props.currentProgram.id,
      this.props.projectId,
      false,
    );
  }

  onChangeFunctions = e => {
    this.props.onChange(e);
    this.onChangeLabelFunction();
  };

  onChangeLabelFunction = () => {
    this.props.onChangeLabel(this.props.orgUnitGroupsMatchings);
  };

  render() {
    const {
      value,
      classes,
      orgUnitGroupsMatchings,
      label,
      withNull = false,
    } = this.props;
    if (!orgUnitGroupsMatchings.length)
      return (
        <Typography className={classes.textField}>
          Searching organisation unit group matchings
          <Box sx={{ width: "80%" }}>
            <LinearProgress />
          </Box>
        </Typography>
      );
    let options = [...orgUnitGroupsMatchings];
    if (withNull) {
      options.unshift({ label: "None", value: null });
    }
    return (
      <TextField
        select
        label={label || "OUG matching"}
        className={classes.textField}
        onChange={this.onChangeFunctions}
        value={value}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

const orgUnitGroupsMatchingsPresenter = matching => ({
  label: matching.display_name,
  value: matching.id,
});

const mapStateToProps = state => ({
  currentProgram: state.programs.currentProgram,
  orgUnitGroupsMatchings: state.orgUnitGroupsMatchings.entities.map(
    matching => {
      return orgUnitGroupsMatchingsPresenter(matching);
    },
  ),
});

export default connect(mapStateToProps, { fetchOrgUnitGroupsMatchings })(
  withStyles(styles)(OrgUnitGroupsMatchingsSelect),
);

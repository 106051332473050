import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import DataElementGroupsMatchingsSelect from "../../DataElementGroupsMatchings/DataElementGroupsMatchingsSelect";
import OrgUnitGroupsMatchingsSelect from "../../OrgUnitGroupsMatchings/OrgUnitGroupsMatchingsSelect";
import AutoComplete from "../../Shared/AutoComplete";
import PeriodRangeForm from "../PeriodRangeForm";
import PeriodIntervalForm from "../PeriodIntervalForm";
import PeriodTypesSelect from "../../Shared/PeriodTypesSelect";
import CheckBoxComponent from "../Shared/CheckBoxComponent";
import { formStyles, textField } from "../../../helpers/commonStyles";

const styles = theme => ({
  ...textField(theme),
  ...formStyles(theme),
  autocomplete: {
    marginTop: "16px;",
  },
  divider: {
    color: "black",
    width: "100%",
  },
  gridContainer: {
    height: 80,
  },
  root: {
    flexGrow: 1,
  },
  description: {
    margin: "auto",
    marginTop: 15,
    color: "grey",
    fontWeight: "bold",
  },
  sectionTitle: {
    fontWeight: "bold",
  },
});

export const DE_AGGREGATIONS = {
  one2one: p => "1 to 1",
  n2uid: p => `all to 1${!!p ? ` (${p["de_target".name]})` : ""}`,
};

export const OU_AGGREGATIONS = {
  one2one: p => "1 to 1",
  n2uid: p => `all to 1${!!p ? ` (${p["ou_target"].name})` : ""}`,
  n2nplus: p => `n to level n + x${!!p ? ` (${p["ou_level_up"]})` : ""}`,
  n2nx: p => `n to level x${!!p ? ` (${p["ou_level_x"]})` : ""}`,
};

class PushAnalyticFlow extends Component {
  constructor(props) {
    super(props);
    this.state = { preview: props.immediate ? "Preview" : "" };
  }
  componentDidMount() {
    this.props.handleChange(`send_mail`, 0);
    if (this.props.immediate) {
      this.props.handleParamChange("preview", true);
    }
  }

  handleSrcPeriodTypeChange = period => event => {
    let ent = this.props.entity;
    let periods = ent.params.src_period_types;
    if (event.target.checked) {
      periods.push(period);
    } else {
      periods = periods.filter(p => p !== period);
    }
    this.props.handleParamChange(`src_period_types`, periods);
  };

  handleDestPeriodTypeChange = period => event => {
    this.props.handleParamChange(
      "dest_period_type",
      !!event.target.checked ? period : null,
    );
  };

  selectOugLabel = ougs => {
    this.getOugLabel(ougs);
  };

  getOugLabel = ougs => {
    const ougId = this.props.entity.params.organisation_unit_group_matching_id;
    const oug = ougs.find(oug => oug.value === ougId);
    this.props.handleParamChange("oug_label", oug.label);
  };

  selectDegLabel = degs => {
    this.getDegLabel(degs);
  };

  getDegLabel = degs => {
    const degId = this.props.entity.params.data_element_group_matching_id;
    const deg = degs.find(deg => deg.value === degId);
    this.props.handleParamChange("deg_label", deg.label);
  };

  handlePreviewChange = event => {
    if (this.state.preview === "") {
      this.setState({ preview: "Preview" });
      this.props.handleParamChange("preview", true);
    } else {
      this.setState({ preview: "" });
      this.props.handleParamChange("preview", false);
    }
  };

  render() {
    const {
      classes,
      project,
      entity,
      immediate,
      handleParamChange,
      handleRemoveParam,
    } = this.props;
    return (
      <Fragment>
        <Typography className={classes.description} variant="h6">
          {`Push data analytics from DHIS2 ${project.left_source.name} to data values in DHIS2
        ${project.right_source.name}.`}
        </Typography>
        <Divider className={classes.divider} style={{ marginTop: 20 }} />
        <Grid item xs={12} className={classes.gridContainer}>
          <FormControl className={classes.textField}>
            <FormGroup row>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={5}>
                  <DataElementGroupsMatchingsSelect
                    projectId={project.id}
                    name="data_element_group_matching_id"
                    onChange={e =>
                      handleParamChange(
                        "data_element_group_matching_id",
                        e.target.value,
                      )
                    }
                    onChangeLabel={this.selectDegLabel}
                    value={entity.params.data_element_group_matching_id}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="select-de_aggregation_type"
                    select
                    label="Aggregation type"
                    className={classes.textField}
                    onChange={e =>
                      handleParamChange("de_aggregation_type", e.target.value)
                    }
                    value={entity.params.de_aggregation_type}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                    {Object.keys(DE_AGGREGATIONS).map(k => (
                      <MenuItem key={`DE_AGG_${k}`} value={k}>
                        {DE_AGGREGATIONS[k](null)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  {entity.params.de_aggregation_type === "n2uid" && (
                    <div className={classes.autocomplete}>
                      <AutoComplete
                        projectId={project.id}
                        sourceId={project.right_source.id}
                        onChange={e => handleParamChange("de_target", e)}
                        type="data_elements"
                        instance="dhis2"
                        value={entity.params.de_target}
                        placeholder="Target DE"
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.textField}>
            <FormGroup row>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={5}>
                  <OrgUnitGroupsMatchingsSelect
                    projectId={project.id}
                    name="organisation_unit_group_matching_id"
                    onChange={e =>
                      handleParamChange(
                        "organisation_unit_group_matching_id",
                        e.target.value,
                      )
                    }
                    onChangeLabel={this.selectOugLabel}
                    value={entity.params.organisation_unit_group_matching_id}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="select-ou_aggregation_type"
                    select
                    label="Aggregation type"
                    className={classes.textField}
                    onChange={e =>
                      handleParamChange("ou_aggregation_type", e.target.value)
                    }
                    value={entity.params.ou_aggregation_type}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                    {Object.keys(OU_AGGREGATIONS).map(k => (
                      <MenuItem key={`OU_AGG_${k}`} value={k}>
                        {OU_AGGREGATIONS[k](null)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  {entity.params.ou_aggregation_type === "n2uid" && (
                    <div className={classes.autocomplete}>
                      <AutoComplete
                        projectId={project.id}
                        sourceId={project.right_source.id}
                        onChange={e => handleParamChange("ou_target", e)}
                        type="organisation_units"
                        instance="dhis2"
                        value={entity.params.ou_target}
                        placeholder="Target OU"
                      />
                    </div>
                  )}
                  {entity.params.ou_aggregation_type === "n2nplus" && (
                    <TextField
                      id="ou_level_up"
                      label="Level up"
                      type="number"
                      className={classes.textField}
                      onChange={e =>
                        handleParamChange("ou_level_up", e.target.value)
                      }
                      value={entity.params.ou_level_up}
                      margin="normal"
                    />
                  )}
                  {entity.params.ou_aggregation_type === "n2nx" && (
                    <TextField
                      id="ou_level_x"
                      label="Level x (1 = root)"
                      type="number"
                      className={classes.textField}
                      onChange={e =>
                        handleParamChange("ou_level_x", e.target.value)
                      }
                      value={entity.params.ou_level_x}
                      margin="normal"
                    />
                  )}
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Divider className={classes.divider} style={{ marginTop: -40 }} />
        <Grid item xs={12}>
          <FormControl className={classes.textField}>
            <PeriodTypesSelect
              label="Source period types"
              componentStyle={classes.sectionTitle}
              selectedPeriodTypes={entity.params.src_period_types}
              handlePeriodTypeChange={v => this.handleSrcPeriodTypeChange(v)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.textField}>
            <PeriodTypesSelect
              componentStyle={classes.sectionTitle}
              label="Target period types"
              selectedPeriodTypes={entity.params.dest_period_type}
              handlePeriodTypeChange={v => this.handleDestPeriodTypeChange(v)}
            />
          </FormControl>
        </Grid>
        {immediate && (
          <Grid item xs={12}>
            <FormLabel className={classes.sectionTitle} component="legend">
              Period interval(From which date to which date)
            </FormLabel>
            <PeriodIntervalForm
              entity={entity}
              handleParamChange={handleParamChange}
            />
          </Grid>
        )}
        {immediate && (
          <Grid item xs={12}>
            <CheckBoxComponent
              componentStyle={classes.sectionTitle}
              preview={this.state.preview}
              checkboxLabel="Preview Analytics to push :"
              handlePreviewChange={() => this.handlePreviewChange}
            />
          </Grid>
        )}
        {!immediate && (
          <Grid item xs={12}>
            <PeriodRangeForm
              entity={entity}
              handleParamChange={handleParamChange}
              handleRemoveParam={handleRemoveParam}
            />
          </Grid>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
});
export default connect(mapStateToProps)(withStyles(styles)(PushAnalyticFlow));

import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class SynchronizeMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;

    const summaries = [
      [
        {
          label: "Applied changes",
          value: task.summary["applied_count"]
            ? task.summary["applied_count"]
            : 0,
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default SynchronizeMetaFlowSummary;

import React, { Component } from "react";
import SectionSpinner from "../Shared/SectionSpinner";
import AppBarDefault from "../Shared/AppBarDefault";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import DataSetsMatchingPreview from "./DataSetsMatchingPreview";
import { Grid, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import LayoutContainer from "../Shared/LayoutContainer";
import { bindActionCreators } from "redux";
import { fetchDataSetsMatchings } from "../../actions/dataSetsMatchings";
import { fabButton, helpSection } from "../../helpers/commonStyles";
import NoDataInfoPage from "../Shared/NoDataInfoPage";

const styles = theme => ({
  ...helpSection(theme),
  ...fabButton(theme),
});

class DataSetsMatchings extends Component {
  componentDidMount() {
    this.props.fetchDataSetsMatchings(
      this.props.currentProgram.id,
      this.props.project.id,
      false,
    );
  }

  render() {
    const {
      classes,
      match,
      dataSetsMatchings,
      isFetching,
      project,
    } = this.props;

    if (isFetching) {
      return <SectionSpinner />;
    }

    if (dataSetsMatchings.length === 0)
      return (
        <div>
          <AppBarDefault title={"Data Sets Matchings"} />
          <NoDataInfoPage
            type="data sets matchings"
            helpText={
              "A data sets matching is the link between a data set in the source dhis2 and another one in the destination dhis2."
            }
          />
        </div>
      );

    return (
      <div>
        <AppBarDefault title={"Data Sets Matchings"} />
        <LayoutContainer>
          {dataSetsMatchings.length > 0 &&
            dataSetsMatchings.map(dataSetsMatching => {
              return (
                <Grid item xs={12} md={6} key={dataSetsMatching.id}>
                  <DataSetsMatchingPreview
                    projectId={project.id}
                    dataSetsMatching={dataSetsMatching}
                  />
                </Grid>
              );
            })}
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fabButton}
            component={Link}
            to={`${match.url}/new`}
          >
            <AddIcon />
          </Fab>
        </LayoutContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  dataSetsMatchings: state.dataSetsMatchings.entities,
  isFetching: state.dataSetsMatchings.isFetching,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchDataSetsMatchings }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(DataSetsMatchings)),
);

import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Chip } from "@material-ui/core";
import moment from "moment";

const styles = theme => ({
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
    marginBottom: "5px",
  },
});

class PushDataFlowPreview extends Component {
  render() {
    const { classes, scheduler } = this.props;
    return (
      <Grid container spacing={2}>
        {!!scheduler.resolved_params.data_set_matching_name && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>DS matching:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <strong>
                  {scheduler.resolved_params.data_set_matching_name}
                </strong>
              </Typography>
            </Grid>
          </Fragment>
        )}
        {!!scheduler.resolved_params.data_element_group_matching_name && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>DEG matching:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <strong>
                  {scheduler.resolved_params.data_element_group_matching_name}
                </strong>
              </Typography>
            </Grid>
          </Fragment>
        )}
        {!!scheduler.resolved_params.organisation_unit_group_matching_name && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>OUG matching:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <strong>
                  {
                    scheduler.resolved_params
                      .organisation_unit_group_matching_name
                  }
                </strong>
              </Typography>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={4}>
          <Typography>Period type(s):</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {!!scheduler.params.period_types &&
                scheduler.params.period_types.join(", ")}
            </strong>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            {!!scheduler.params.start_date ? "Start Date:" : "Period range:"}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            <strong>
              {!!scheduler.params.start_date ? (
                <Chip
                  className={classes.chip}
                  label={moment(scheduler.params.start_date).format(
                    "DD/MM/YYYY HH:mm Z",
                  )}
                />
              ) : (
                !!scheduler.params.period_range &&
                !!scheduler.params.period_range_unit &&
                `${scheduler.params.period_range} ${scheduler.params.period_range_unit}`
              )}
            </strong>
          </Typography>
        </Grid>
        {!!scheduler.params.end_date && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>End Date</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <strong>
                  <Chip
                    className={classes.chip}
                    label={moment(scheduler.params.end_date)
                      .utc()
                      .format("DD/MM/YYYY HH:mm Z")}
                  />
                </strong>
              </Typography>
            </Grid>
          </Fragment>
        )}

        {!!scheduler.resolved_params.periods && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>Next periods:</Typography>
            </Grid>
            <Grid item xs={8}>
              {scheduler.resolved_params.periods.map((p, i) => (
                <Chip className={classes.chip} key={`p-${i}`} label={p} />
              ))}
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PushDataFlowPreview);

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusStopped extends React.Component {
  render() {
    const { classes, withLabel, tooltip } = this.props;
    return (
      <TaskStatus
        lbl={withLabel ? "Stopped" : null}
        icon={<CancelScheduleSendIcon className={classes.iconLeft} />}
        help={tooltip}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusStopped);

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Done } from "@material-ui/icons";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusDone extends React.Component {
  render() {
    const { classes, withLabel, tooltip } = this.props;
    return (
      <TaskStatus
        lbl={withLabel ? "Done" : null}
        icon={<Done className={classes.iconLeft} />}
        help={tooltip}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusDone);

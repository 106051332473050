import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import registerServiceWorker from "./registerServiceWorker";
import store from "./store";
import Home from "./components/Home";
import ProgramProjects from "./components/Home/Programs/ProgramProjects";
import ProgramsIndex from "./components/Home/ProgramsIndex";
import { MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import themeColors from "./helpers/themeColors";
import PrivateRoutes from "./components/Shared/PrivateRoutes";
import ProjectShow from "./components/ProjectShow";
import GeneralNew from "./components/General/GeneralNew";
import MenuWrapper from "./components/MenuWrapper";
import "./index.css";

const app = (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <MuiThemeProvider theme={themeColors}>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoutes>
              <Switch>
                <Route exact path="/programs" component={ProgramsIndex} />
                <MenuWrapper>
                  <Route
                    exact
                    path="/programs/:program_id/projects"
                    component={ProgramProjects}
                  />
                  <Route
                    exact
                    path="/programs/:program_id/projects/new"
                    component={GeneralNew}
                  />
                  <Route
                    path="/programs/:program_id/projects/:project_id"
                    component={ProjectShow}
                  />
                </MenuWrapper>
              </Switch>
            </PrivateRoutes>
          </Switch>
        </Router>
      </Provider>
    </MuiThemeProvider>
  </MuiPickersUtilsProvider>
);

ReactDOM.render(app, document.getElementById("root"));
registerServiceWorker();

import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import fadeIn from "react-animations/lib/fadeIn";

import PreviewSource from "../Shared/PreviewSource";

const styles = theme => ({
  "@keyframes fadeIn": fadeIn,
  root: {
    animationName: "fadeIn",
    animationDuration: "0.5s",
  },
  arrowColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardActions: {
    borderTop: "1px solid #efefef",
  },
});

class DefaultsForm extends Component {
  render() {
    const { left, right, classes } = this.props;

    return (
      <Card className={classes.root}>
        {left &&
          right &&
          left.default_category_combo &&
          right.default_category_combo && (
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <PreviewSource label={left.name} />
                </Grid>
                <Grid item xs={2} className={classes.arrowColumn} />
                <Grid item xs={5}>
                  <PreviewSource label={right.name} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  Default Category Combo
                  <Typography variant="subtitle1">
                    {left.default_category_combo.name}
                  </Typography>
                  <Typography variant="caption">
                    {left.default_category_combo.dhis_id}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.arrowColumn}>
                  <LinkIcon />
                </Grid>
                <Grid item xs={5}>
                  Default Category Combo
                  <Typography variant="subtitle1">
                    {right.default_category_combo.name}
                  </Typography>
                  <Typography variant="caption">
                    {right.default_category_combo.dhis_id}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  Default Category Option Combo
                  <Typography variant="subtitle1">
                    {left.default_category_option_combo.name}
                  </Typography>
                  <Typography variant="caption">
                    {left.default_category_option_combo.dhis_id}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.arrowColumn}>
                  <LinkIcon />
                </Grid>
                <Grid item xs={5}>
                  Default Category Option Combo
                  <Typography variant="subtitle1">
                    {right.default_category_option_combo.name}
                  </Typography>
                  <Typography variant="caption">
                    {right.default_category_option_combo.dhis_id}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          )}
      </Card>
    );
  }
}

export default withStyles(styles)(DefaultsForm);

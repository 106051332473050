import React, { Component } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import SectionSpinner from "./SectionSpinner";
import withStyles from "@material-ui/core/styles/withStyles";
import HelpWrapper from "./HelpWrapper";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    position: "relative",
  },
  table: {
    minWidth: "85%",
    position: "relative",
  },
  fullPaper: {
    width: "100%",
    height: "100%",
    opacity: 0.8,
    position: "absolute",
    background: "white",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    zIndex: 8,
  },
});

class RemoteTable extends Component {
  columns(columns) {
    return columns.map((column, index) => {
      return HelpWrapper({
        help: column.help,
        key: index,
        elt: (
          <TableCell key={index}>
            {column.icon ? column.icon : column.title}
          </TableCell>
        ),
      });
    });
  }

  test = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell>hello</TableCell>
          <TableCell>hello</TableCell>
          <TableCell>hello</TableCell>
          <TableCell>hello</TableCell>
          <TableCell>hello</TableCell>
        </TableRow>
      </TableBody>
    );
  };

  render() {
    const {
      classes,
      columns,
      totalCount,
      perPage,
      currentPage,
      isFetching,
      cellComponent: CellComponent,
      projectId,
      padding,
    } = this.props;
    const handleClickOpen = row => {
      this.props.handleClickOpen(row);
    };

    const handleClickOpenPreview = (row, preview_sequencer_class) => {
      this.props.handleClickOpenPreview(row, preview_sequencer_class);
    };

    let task_rows = this.props.rows;
    task_rows.map((row, index) => {
      row["actions"] = <MoreVertIcon />;
      return row;
    });

    return (
      <Paper className={classes.root}>
        {isFetching && (
          <div className={classes.fullPaper}>
            <SectionSpinner noMargin />
          </div>
        )}
        <Table className={classes.table} padding={padding}>
          <TableHead>
            <TableRow>{this.columns(columns)}</TableRow>
          </TableHead>
          <TableBody>
            {task_rows.map((row, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, key) => {
                    if (CellComponent) {
                      return (
                        <Tooltip
                          title="Show task details"
                          placement="top"
                          arrow
                        >
                          <TableCell key={key}>
                            <CellComponent
                              row={row}
                              column={column}
                              projectId={projectId}
                              handleClickOpen={() => handleClickOpen(row)}
                              handleClickOpenPreview={preview_sequencer_class =>
                                handleClickOpenPreview(
                                  row,
                                  preview_sequencer_class,
                                )
                              }
                            />
                          </TableCell>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <TableCell key={key}>{row[column.name]}</TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalCount}
                rowsPerPage={parseInt(perPage, 10)}
                page={currentPage}
                onChangePage={this.props.onChangePage}
                rowsPerPageOptions={[50, 100, 500]}
                onChangeRowsPerPage={this.props.onChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(RemoteTable);

import * as types from "../actionTypes";
import _keyBy from "lodash/keyBy";
import { inlineParentNames } from "../helpers/orgUnitHelpers";

function orgUnits(state = {}, action) {
  switch (action.type) {
    case types.REQUEST_ORG_UNITS:
      return Object.assign({}, state, {
        ...state.bySourceId,
        [action.meta.sourceId]: {
          ...state[action.meta.sourceId],
          isFetching: true,
        },
      });
    case types.RECEIVE_ORG_UNITS:
      const newEntities = _keyBy(
        action.payload.data.map(inlineParentNames),
        "id",
      );
      let entities = action.meta.force
        ? newEntities
        : {
            ...state[action.meta.sourceId].entities,
            ...newEntities,
          };

      return Object.assign({}, state, {
        ...state.bySourceId,
        [action.meta.sourceId]: {
          entities,
          meta: action.payload.meta,
          isFetching: false,
        },
      });
    case types.DESTROY_LOCAL_ORG_UNIT:
      let oldEntities = state[action.sourceId].entities;
      delete oldEntities[action.orgUnitId];
      let totalCount = state[action.sourceId].meta.total_count - 1;
      return Object.assign({}, state, {
        [action.sourceId]: {
          ...state[action.sourceId],
          meta: { total_count: totalCount },
          entities: oldEntities,
        },
      });
    case types.REQUEST_ORG_UNITS_FAILED:
      return Object.assign({}, state, {
        [action.meta.sourceId]: {
          errors: action.errors,
          isFetching: false,
        },
      });

    default:
      return state;
  }
}

export default orgUnits;

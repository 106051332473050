import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class CompareMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;

    const summaries = [
      [
        {
          label: "Discrepancies",
          value: task.summary["discrepancies"]
            ? task.summary["discrepancies"]
            : 0,
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default CompareMetaFlowSummary;

import React, { Component } from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import CategoryCombosMatchingEditForm from "./CategoryCombosMatchingEditForm";
import { connect } from "react-redux";
import SectionSpinner from "../Shared/SectionSpinner";
import { fetchCategoryCombosMatchings } from "../../actions/categoryCombosMatchings";
import { selectCategoryCombosMatching } from "../../reducers/categoryCombosMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

class CategoryCombosMatchingEdit extends Component {
  componentDidMount() {
    !this.props.ccMatching &&
      this.props.fetchCategoryCombosMatchings(
        this.props.currentProgram.id,
        this.props.project.id,
      );
  }

  render() {
    const { classes, project, currentProgram } = this.props;
    let content;
    if (!this.props.ccMatching) {
      content = <SectionSpinner />;
    } else {
      content = (
        <CategoryCombosMatchingEditForm
          project={project}
          ccMatching={this.props.ccMatching}
        />
      );
    }

    return (
      <div>
        <AppBarDefault title="Edit Category Combos Matching">
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${currentProgram.id}/projects/${project.id}/category_combos_matchings`}
          >
            <ArrowBack className={classes.leftIcon} />
            Category Combos matchings
          </Button>
        </AppBarDefault>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  ccMatching: selectCategoryCombosMatching(
    state.categoryCombosMatchings.entities,
    props.match.params.category_combo_matching_id,
  ),
});

export default connect(mapStateToProps, { fetchCategoryCombosMatchings })(
  withRouter(withStyles(styles)(CategoryCombosMatchingEdit)),
);

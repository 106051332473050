import * as types from "../actionTypes";
import _keyBy from "lodash/keyBy";

function dataElements(
  state = {
    suggestions: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_DE_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: [],
        errors: null,
        isFetching: true,
      });
    case types.RECEIVE_DE_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: action.payload,
        isFetching: false,
      });
    case types.REQUEST_DE_SUGGESTIONS_FAILED:
      return Object.assign({}, state, {
        suggestions: [],
        errors: action.payload,
        isFetching: false,
      });
    case types.REQUEST_DATA_ELEMENTS:
      return Object.assign({}, state, {
        ...state.bySourceId,
        [action.meta.sourceId]: {
          ...state[action.meta.sourceId],
          isFetching: true,
        },
      });
    case types.RECEIVE_DATA_ELEMENTS:
      const newEntities = _keyBy(action.payload.data, "id");
      let entities = action.meta.force
        ? newEntities
        : {
            ...state[action.meta.sourceId].entities,
            ...newEntities,
          };

      return Object.assign({}, state, {
        ...state.bySourceId,
        [action.meta.sourceId]: {
          entities,
          meta: action.payload.meta,
          isFetching: false,
        },
      });
    case types.DESTROY_LOCAL_DATA_ELEMENT:
      let oldEntities = state[action.sourceId].entities;
      delete oldEntities[action.dataElementId];
      let totalCount = state[action.sourceId].meta.total_count - 1;
      return Object.assign({}, state, {
        [action.sourceId]: {
          ...state[action.sourceId],
          meta: { total_count: totalCount },
          entities: oldEntities,
        },
      });
    case types.REQUEST_DATA_ELEMENTS_FAILED:
      return Object.assign({}, state, {
        [action.meta.sourceId]: {
          errors: action.errors,
          isFetching: false,
        },
      });

    default:
      return state;
  }
}

export default dataElements;

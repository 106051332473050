import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";
import * as types from "../actionTypes";

export function fetchOrgUnitGroupsMatchings(
  programId,
  projectId,
  dependent_task_count,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/organisation_unit_groups?task_count=${dependent_task_count}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_ORG_UNIT_GROUPS_MATCHINGS,
        types.RECEIVE_ORG_UNIT_GROUPS_MATCHINGS,
        types.REQUEST_ORG_UNIT_GROUPS_MATCHINGS_FAILED,
      ],
    },
  };
}

export function createNewOrgUnitGroupMatching(programId, projectId, payload) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/sources/${payload.left_source_id}/organisation_unit_group_flows`,
      method: "POST",
      body: JSON.stringify(payload),
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CREATE_ORG_UNIT_GROUPS_MATCHING,
        {
          type: types.RECEIVE_CREATE_ORG_UNIT_GROUPS_MATCHING,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              return payload;
            });
          },
        },
        types.REQUEST_CREATE_ORG_UNIT_GROUPS_MATCHING_FAILED,
      ],
    },
  };
}

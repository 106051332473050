import React, { Component } from "react";
import { Typography, Grid, IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import classnames from "classnames";
import { tableStyles as styles } from "../../helpers/commonStyles";

class MatchingLineItem extends Component {
  handleDestroy = () => {
    this.props.destroyMatching(this.props.matching.id);
  };

  handleCreate = () => {
    this.props.createMatching(this.props.matching);
  };

  render() {
    const { style, matching, classes, key } = this.props;

    if (!!matching) {
      return (
        <Grid container style={style} className={classes.cell} key={key}>
          <Grid item xs={5} className={classes.cell}>
            <Typography variant="subtitle1">
              {matching.left.short_name || matching.left.name}
            </Typography>
            <Typography variant="caption">{matching.left.dhis_id}</Typography>
          </Grid>
          <Grid item xs={5} className={classes.cell}>
            <Typography variant="subtitle1">
              {matching.right.short_name || matching.right.name}
            </Typography>
            <Typography variant="caption">{matching.right.dhis_id}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            className={classnames([classes.cell, classes.actions])}
          >
            {!!this.props.destroyMatching && (
              <IconButton onClick={this.handleDestroy}>
                <Delete />
              </IconButton>
            )}
            {!!this.props.createMatching && (
              <IconButton onClick={this.handleCreate}>
                <Add />
              </IconButton>
            )}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <div className={classes.listItem} style={style} key={key}>
          Loading
        </div>
      );
    }
  }
}

export default withStyles(styles)(MatchingLineItem);

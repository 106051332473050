import React, { Component } from "react";
import LayoutContainer from "../Shared/LayoutContainer";
import withStyles from "@material-ui/core/styles/withStyles";

import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  fetchSchedulers,
  resumeScheduler,
  pauseScheduler,
} from "../../actions/schedulers";
import SchedulerPreview from "./SchedulerPreview";
import { Grid, Fab } from "@material-ui/core";
import SectionSpinner from "../Shared/SectionSpinner";
import { fabButton, helpSection } from "../../helpers/commonStyles";
import NoDataInfoPage from "../Shared/NoDataInfoPage";
import AppBarDefault from "../Shared/AppBarDefault";

const styles = theme => ({
  ...helpSection(theme),
  ...fabButton(theme),
});

class Schedulers extends Component {
  componentDidMount() {
    this.props.fetchSchedulers(
      this.props.currentProgram.id,
      this.props.project.id,
      1,
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.project.id !== this.props.project.id) {
      this.props.fetchSchedulers(
        this.props.currentProgram.id,
        this.props.project.id,
        1,
      );
    }
  }

  render() {
    const {
      classes,
      project,
      match,
      schedulers,
      isFetching,
      currentProgram,
    } = this.props;

    if (isFetching) {
      return <SectionSpinner />;
    }

    if (schedulers.length === 0)
      return (
        <div>
          <AppBarDefault title={"Schedulers"} />
          <NoDataInfoPage
            type="schedulers"
            helpText={
              "A scheduler is a tool to automatically create tasks according to time table (cron expression)."
            }
          />
        </div>
      );

    return (
      <div>
        <AppBarDefault title={"Schedulers"} />
        <LayoutContainer>
          {schedulers.length > 0 &&
            schedulers.map(scheduler => {
              return (
                <Grid item xs={12} md={6} key={scheduler.id}>
                  <SchedulerPreview
                    projectId={project.id}
                    programId={currentProgram.id}
                    scheduler={scheduler}
                    onClickResume={this.props.resumeScheduler}
                    onClickPause={this.props.pauseScheduler}
                  />
                </Grid>
              );
            })}
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fabButton}
            component={Link}
            to={`${match.url}/new`}
          >
            <AddIcon />
          </Fab>
        </LayoutContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  schedulers: state.schedulers.entities || [],
  isFetching: state.schedulers.isFetching,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchSchedulers,
    resumeScheduler,
    pauseScheduler,
  })(withStyles(styles)(Schedulers)),
);

import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import PreviewDetailsContent from "./PreviewDetailsContent";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function ExpansionPanelContent(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
  }));

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [expansionContent, setExpansionContent] = useState("");
  const handleChange = (panel, summary) => (event, isExpanded) => {
    props.fetchPreviewSink(
      props.programId,
      props.project_id,
      props.task_id,
      summary,
    );
    setExpanded(isExpanded ? panel : false);
  };
  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current && expanded !== false) {
      setExpansionContent(props.sink_content);
    } else {
      didMountRef.current = true;
    }
  });

  const searchPreviewDetails = summary => event => {
    props.searchPreviewSink(
      props.programId,
      props.project_id,
      props.task_id,
      event.target.value,
      summary,
    );
  };
  return props.summaries.map((summary, index) => {
    return (
      <ExpansionPanel
        className={classes.root}
        expanded={expanded === "panel" + (index + 1)}
        onChange={handleChange("panel" + (index + 1), summary)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div style={{ width: "100%" }}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography className={classes.heading}>{summary}</Typography>
              </Box>
              <Box>
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <TextField
                      placeholder="Search"
                      id="filled-search"
                      type="search"
                      onChange={searchPreviewDetails(summary)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className={classes.root}>
            <PreviewDetailsContent
              rows={expansionContent}
              searchPreviewDetails={searchPreviewDetails}
              summary={summary}
            />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });
}
export default ExpansionPanelContent;

import React, { Component } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  centered: {
    display: "flex",
    justifyContent: "center",
  },
});

class KnownMatchingForm extends Component {
  state = {
    knownUUID: null,
  };

  render() {
    const { classes, handleMap } = this.props;
    return (
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={4} className={classes.centered}>
            <TextField
              autoFocus
              margin="dense"
              id="knownUUID"
              label="Known UUID"
              onChange={e => this.setState({ knownUUID: e.target.value })}
            />
          </Grid>
          <Grid item xs={8} className={classes.centered}>
            <Button
              color="primary"
              variant="contained"
              onClick={e => handleMap(this.state.knownUUID)}
            >
              Add to Group and Map
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(KnownMatchingForm);

import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class CloneCatMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;
    const clone_cat_summary = (action_key, clone_key) => {
      if (task.summary[action_key]) {
        return task.summary[action_key][clone_key]
          ? task.summary[action_key][clone_key]
          : 0;
      } else {
        return "Not counted";
      }
    };

    const summaries = [
      [
        {
          label: "Cloned categories",
          value: clone_cat_summary("cloned_meta", "new_group_count"),
        },
        {
          label: "Total categories",
          value: clone_cat_summary("cloned_meta", "total_entities"),
        },
        {
          label: "Cloned category options",
          value: clone_cat_summary("cloned_meta", "new_added_entities_count"),
        },
      ],
      [
        {
          label: "Skipped category options",
          value: clone_cat_summary("cloned_meta", "skipped_entities_count"),
        },

        {
          label: "Matched categories",
          value: clone_cat_summary("match_meta", "matched_group_count"),
        },
      ],
      [
        {
          label: "Matched categories",
          value: clone_cat_summary("match_meta", "matched_entities_count"),
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default CloneCatMetaFlowSummary;

import React, { Component } from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import OrgUnitGroupsMatchingEditForm from "./OrgUnitGroupsMatchingEditForm";
import { connect } from "react-redux";
import SectionSpinner from "../Shared/SectionSpinner";
import { fetchOrgUnitGroupsMatchings } from "../../actions/orgUnitGroupsMatchings";
import { selectOrgUnitGroupMatching } from "../../reducers/orgUnitGroupsMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

class OrgUnitGroupsMatchingEdit extends Component {
  componentDidMount() {
    !this.props.ougMatching &&
      !!this.props.currentProgram &&
      this.props.project &&
      this.props.fetchOrgUnitGroupsMatchings(
        this.props.currentProgram.id,
        this.props.project.id,
        false,
      );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    !this.props.ougMatching &&
      !!this.props.currentProgram &&
      !!this.props.project &&
      (prevProps.currentProgram !== this.props.currentProgram ||
        prevProps.project !== this.props.project) &&
      this.props.fetchOrgUnitGroupsMatchings(
        this.props.currentProgram.id,
        this.props.project.id,
        false,
      );
  }

  render() {
    const { classes, project, currentProgram } = this.props;
    let content;

    if (!this.props.ougMatching || !currentProgram || !project) {
      content = <SectionSpinner />;
    } else {
      content = (
        <OrgUnitGroupsMatchingEditForm
          projectId={project.id}
          ougMatching={this.props.ougMatching}
        />
      );
    }

    return (
      <div>
        <AppBarDefault title="Organisation Unit Groups Matching">
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${
              !!currentProgram ? currentProgram.id : ""
            }/projects/${
              !!project ? project.id : ""
            }/org_unit_groups_matchings`}
          >
            <ArrowBack className={classes.leftIcon} />
            Org unit group matchings
          </Button>
        </AppBarDefault>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
  ougMatching: selectOrgUnitGroupMatching(
    state.orgUnitGroupsMatchings.entities,
    props.match.params.org_unit_group_matching_id,
  ),
});

export default connect(mapStateToProps, { fetchOrgUnitGroupsMatchings })(
  withRouter(withStyles(styles)(OrgUnitGroupsMatchingEdit)),
);

import * as types from "../actionTypes";

function schedulers(
  state = {
    entities: [],
    isFetching: false,
    meta: {
      total_count: 0,
      per_page: 100,
      page: 1,
    },
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_SCHEDULERS:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case types.RECEIVE_SCHEDULERS:
      return Object.assign({}, state, {
        entities: action.payload.data,
        isFetching: false,
      });
    case types.REQUEST_SCHEDULERS_FAILED:
      return Object.assign({}, state, {
        errors: action.payload.errors,
      });
    case types.RECEIVE_SCHEDULER:
      return Object.assign({}, state, {
        entity: action.payload,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default schedulers;

import {
  REQUEST_CATEGORY_COMBOS_MATCHINGS,
  RECEIVE_CATEGORY_COMBOS_MATCHINGS,
  REQUEST_CATEGORY_COMBOS_MATCHINGS_FAILED,
} from "../actionTypes";

function categoryCombosMatchings(
  state = {
    entities: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CATEGORY_COMBOS_MATCHINGS:
      return Object.assign({}, state, {
        entities: [],
        isFetching: true,
      });
    case RECEIVE_CATEGORY_COMBOS_MATCHINGS:
      return Object.assign({}, state, {
        entities: action.payload.data,
        isFetching: false,
      });
    case REQUEST_CATEGORY_COMBOS_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        entities: [],
        errors: action.payload.errors,
        isFetching: false,
      });
    default:
      return state;
  }
}

export default categoryCombosMatchings;

export const selectCategoryCombosMatching = (ccMatchings, ccMatchingId) => {
  return ccMatchings.find(matching => matching.id === parseInt(ccMatchingId, 10));
};

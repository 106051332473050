import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import SchedulerForm from "./SchedulerForm";
import { PUSH_DATA_FLOW } from "../Tasks/constants";
import { TASK_FORMS, entityToForm } from "../Tasks/constants";

class SchedulerNew extends Component {
  render() {
    const { project } = this.props;
    var scheduler = {
      sequencer_class: PUSH_DATA_FLOW,
      cron_expression: "0 0 1 * *",
      activation_date: moment().format("YYYY-MM-DD"),
    };
    scheduler.params = TASK_FORMS[entityToForm(scheduler)].resetParams(false);
    return <SchedulerForm projectId={project.id} scheduler={scheduler} />;
  }
}

const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
});

export default connect(mapStateToProps)(SchedulerNew);

import {
  REQUEST_ORG_UNITS_MATCHINGS,
  RECEIVE_ORG_UNITS_MATCHINGS,
  REQUEST_ORG_UNITS_MATCHINGS_FAILED,
  REQUEST_ORG_UNITS_DANGLINGS,
  RECEIVE_ORG_UNITS_DANGLINGS,
  REQUEST_ORG_UNITS_DANGLINGS_FAILED,
  REQUEST_CREATE_ORG_UNITS_MATCHING,
  RECEIVE_CREATE_ORG_UNITS_MATCHING,
  REQUEST_CREATE_ORG_UNITS_MATCHING_FAILED,
} from "../actionTypes";
import { inlineParentNames } from "../helpers/orgUnitHelpers";

function orgUnitsMatchings(
  state = {
    entities: [],
    isFetching: false,
    meta: {},
    danglingEntities: [],
    danglingIsFetching: false,
    dangling: {},
  },
  action,
) {
  switch (action.type) {
    case REQUEST_ORG_UNITS_MATCHINGS:
      return Object.assign({}, state, {
        entities: state.entities,
        isFetching: true,
      });
    case RECEIVE_ORG_UNITS_MATCHINGS:
      var newEntities = action.payload.data.map(function(m) {
        m.left = m.left && inlineParentNames(m.left);
        m.right = m.right && inlineParentNames(m.right);
        return m;
      });

      var entities = action.meta.force
        ? newEntities
        : [...state.entities, ...newEntities];

      return Object.assign({}, state, {
        entities,
        isFetching: false,
        meta: action.payload.meta,
      });
    case REQUEST_ORG_UNITS_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        errors: action.payload.errors,
        isFetching: false,
      });
    case REQUEST_ORG_UNITS_DANGLINGS:
      return Object.assign({}, state, {
        entities: state.entities,
        danglingIsFetching: true,
      });
    case RECEIVE_ORG_UNITS_DANGLINGS:
      var newDanglingEntities = action.payload.data.map(function(m) {
        m.left = m.left && inlineParentNames(m.left);
        m.right = m.right && inlineParentNames(m.right);
        return m;
      });

      var danglingEntities = action.meta.force
        ? newDanglingEntities
        : [...state.entities, ...newDanglingEntities];

      return Object.assign({}, state, {
        danglingEntities,
        danglingIsFetching: false,
        dangling: action.payload.meta,
      });
    case REQUEST_ORG_UNITS_DANGLINGS_FAILED:
      return Object.assign({}, state, {
        errors: action.payload.errors,
        danglingIsFetching: false,
      });
    case REQUEST_CREATE_ORG_UNITS_MATCHING:
      return {
        ...state,
        creatingMatching: true,
        processingErrors: null,
      };
    case RECEIVE_CREATE_ORG_UNITS_MATCHING:
      return {
        ...state,
        creatingMatching: false,
      };
    case REQUEST_CREATE_ORG_UNITS_MATCHING_FAILED:
      return {
        ...state,
        creatingMatching: false,
        processingErrors: action.payload.response.errors,
      };
    default:
      return state;
  }
}

export default orgUnitsMatchings;

import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";

import {
  REQUEST_SOURCE_CONNECTION,
  RECEIVE_SOURCE_CONNECTION,
  REQUEST_SOURCE_CONNECTION_FAILED,
  INVALIDATE_SOURCE_CONNECTION,
} from "../actionTypes.js";

export function testSourceConnection(sourceType, sourceParams) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/connection_test`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(sourceParams),
      types: [
        { type: REQUEST_SOURCE_CONNECTION, meta: { sourceType: sourceType } },
        { type: RECEIVE_SOURCE_CONNECTION, meta: { sourceType: sourceType } },
        {
          type: REQUEST_SOURCE_CONNECTION_FAILED,
          meta: { sourceType: sourceType },
        },
      ],
    },
  };
}

export function invalidateSourceConnection(sourceType) {
  return {
    type: INVALIDATE_SOURCE_CONNECTION,
    sourceType: sourceType,
  };
}

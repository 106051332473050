import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Card, CardContent, CardActions } from "@material-ui/core";
import ImportDataSetButton from "./ImportDataSetButton";
import fadeInLeft from "react-animations/lib/fadeInLeft";
import fadeInRight from "react-animations/lib/fadeInRight";
import classNames from "classnames";
import PreviewSource from "../Shared/PreviewSource";

const styles = theme => ({
  "@keyframes fadeInLeft": fadeInLeft,
  "@keyframes fadeInRight": fadeInRight,
  paper: {
    padding: 16,
    margin: `${theme.spacing(4)}px 0`,
    position: "relative",
    animationDuration: "1s",
    animationTimingFunction: "cubic-bezier(0.17, 0.46, 0.05, 0.95)",
  },
  animleft: {
    animationName: "fadeInLeft",
    opacity: 1,
  },
  animright: {
    animationName: "fadeInRight",
    opacity: 1,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
});

const refresh = function() {
  window.location.reload();
};

function side_parser(sourceSide) {
  switch (sourceSide) {
    case "left":
      return "source";
    case "right":
      return "destination";
    default:
  }
}

function DataSetPreview(props) {
  const { group, classes, projectId, programId, side } = props;
  return (
    <Card className={classNames(classes.paper, classes[`anim${props.side}`])}>
      <CardContent>
        <Typography variant="subtitle1" className={classes.header}>
          <PreviewSource label={group.source.name} />
        </Typography>
        <Typography variant="h6">{group.name}</Typography>
        <Typography variant="caption" className={classes.header}>
          {group.dhis_id}
        </Typography>
        <Typography variant="body1" className={classes.header}>
          {group.data_elements_count} data elements
        </Typography>
        <Typography variant="body1" className={classes.header}>
          {group.organisation_units_count} organisation_units
        </Typography>
      </CardContent>
      <CardActions>
        <ImportDataSetButton
          dataSet={group}
          sourceId={group.source.id}
          programId={programId}
          side={side_parser(side)}
          projectId={projectId}
          btnText="Re-import set"
          onChange={refresh}
        />
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(DataSetPreview);

import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class ImportMatchingFlowSummary extends Component {
  render() {
    const { task } = this.props;

    const import_matching_task_summary = import_matching_key => {
      if (task.summary["imported_task"]) {
        return task.summary["imported_task"][import_matching_key]
          ? task.summary["imported_task"][import_matching_key]
          : 0;
      } else {
        return "Not counted";
      }
    };

    const summaries = [
      [
        {
          label: "Groups",
          value: import_matching_task_summary("imported_group_count"),
        },
        {
          label: "Entities",
          value: import_matching_task_summary("imported_entities_count"),
        },

        {
          label: "Groups matching",
          value: import_matching_task_summary("imported_group_matching_count"),
        },
      ],
      [
        {
          label: "Entities matching",
          value: import_matching_task_summary(
            "imported_entities_matching_count",
          ),
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default ImportMatchingFlowSummary;

import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";

class MatchingListItemCard extends Component {
  render() {
    const { name, dhis_id, detail } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1">{name}</Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        <Typography variant="caption">{dhis_id}</Typography>
        {detail && (
          <Grid item xs={12}>
            <Typography variant="caption">{detail}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default MatchingListItemCard;

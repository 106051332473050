import * as types from "../actionTypes";

import { authHeader, baseApiUrl } from "./api";

import { RSAA } from "redux-api-middleware";
import history from "../history";
import store from "../store";

export function fetchProjects(programId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_PROJECTS,
        types.RECEIVE_PROJECTS,
        types.REQUEST_PROJECTS_FAILED,
      ],
    },
  };
}

export function fetchProgramProjects(programId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_PROGRAM_PROJECTS,
        types.RECEIVE_PROGRAM_PROJECTS,
        types.REQUEST_PROGRAM_PROJECTS_FAILED,
      ],
    },
  };
}

export function fetchProject(programId, projectId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_PROJECT,
        types.RECEIVE_PROJECT,
        types.REQUEST_PROJECT_FAILED,
      ],
    },
  };
}

export function createProject(programId, project) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(project),
      types: [
        types.REQUEST_PROJECT_CREATE,
        {
          type: types.RECEIVE_PROJECT_CREATE,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              return payload;
            });
          },
        },
        {
          type: types.REQUEST_PROJECT_CREATE_FAILED,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              return payload;
            });
          },
        },
      ],
    },
  };
}

export function updateProject(projectParams) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${projectParams.project.program_id}/projects/${projectParams.project.id}`,
      method: "PUT",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(projectParams),
      types: [
        types.REQUEST_PROJECT_UPDATE,
        {
          type: types.RECEIVE_PROJECT,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              // get the projects array from redux state other than the updated one
              let programs = { ...state.programs };
              let currentProgram = programs.currentProgram;
              let projects = currentProgram.projects.filter(
                project => project.id !== payload.id,
              );
              // add the updated one
              projects.push(payload);
              // assign the new projects list and sort it by project id
              currentProgram.projects = projects.sort(
                (p1, p2) => p1.id - p2.id,
              );
              programs.currentProgram = currentProgram;

              history.push(
                `/programs/${payload.program_id}/projects/${payload.id}/org_unit_groups_matchings`,
              );
              // update the redux state
              state = { ...state, programs: programs };
              return payload;
            });
          },
        },
        types.REQUEST_PROJECT_UPDATE_FAILED,
      ],
    },
  };
}

export function destroyProject(program_id, id) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${program_id}/projects/${id}`,
      method: "DELETE",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_PROJECT_DESTROY,
        {
          type: types.RECEIVE_PROJECT_DESTROY,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              return payload;
            });
          },
        },
        types.REQUEST_PROJECT_DESTROY_FAILED,
      ],
    },
  };
}

export function cloneProject(projectParams) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${projectParams.project.program_id}/projects/${projectParams.project.id}/dup_project`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(projectParams),
      types: [
        types.REQUEST_PROJECT_CLONE,
        {
          type: types.RECEIVE_PROJECT_CLONE,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              store.dispatch(fetchProjects());
              return payload;
            });
          },
        },
        types.REQUEST_PROJECT_CLONE_FAILED,
      ],
    },
  };
}

export function addAddress(programId, project, address) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${project.id}/addresses`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify({ address }),
      types: [
        types.REQUEST_PROJECT_ADD_ADDRESS,
        types.RECEIVE_PROJECT_ADDRESS_ADDED,
        types.REQUEST_PROJECT_ADD_ADDRESS_FAILED,
      ],
    },
  };
}

export function deleteAddress(programId, project, addressId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}programs/${programId}/projects/${project.id}/addresses/${addressId}`,
      method: "DELETE",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_PROJECT_DELETE_ADDRESS,
        types.RECEIVE_PROJECT_ADDRESS_DELETED,
        types.REQUEST_PROJECT_DELETE_ADDRESS_FAILED,
      ],
    },
  };
}

export function updateS3bucket(programId, project, s3bucket) {
  const meta = { meta: s3bucket };
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${project.id}/s3bucket`,
      method: "PUT",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify({ s3bucket }),
      types: [
        types.REQUEST_PROJECT_UPDATE_S3BUCKET,
        { type: types.RECEIVE_PROJECT_UPDATE_S3BUCKET, ...meta },
        types.REQUEST_PROJECT_UPDATE_S3BUCKET_FAILED,
      ],
    },
  };
}

export function resolveFlags(programId, projectId, flagIds, comment) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/flags`,
      method: "PUT",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify({ flagIds, comment }),
      types: [
        types.REQUEST_PROJECT_RESOLVE_FLAG,
        {
          type: types.RECEIVE_PROJECT_FLAG_RESOLVED,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              store.dispatch(fetchProjects());
              return payload;
            });
          },
        },
        types.REQUEST_PROJECT_RESOLVE_FLAG_FAILED,
      ],
    },
  };
}

import React, { Component, Fragment } from "react";
import {
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  Fab,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import withStyles from "@material-ui/core/styles/withStyles";
import LayoutContainer from "../Shared/LayoutContainer";
import AutoComplete from "../Shared/AutoComplete";
import AutoCompleteProject from "../Shared/AutoCompleteProject";
import { formStyles } from "../../helpers/commonStyles";
import ConfirmDialog from "../Shared/ConfirmDialog";
import ProcessingDialog from "../Shared/ProcessingDialog";
import CheckBoxComponent from "../Tasks/Shared/CheckBoxComponent";
import { get } from "../../actions/api";
import history from "../../history";

const styles = theme => ({
  ...formStyles(theme),
  fabButton: {
    position: "absolute",
    right: -39,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
  },
});

class NewMatchingFormMeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rightSourceGroup: {},
      leftSourceGroup: {},
      matchingAction: "match",
      confirmCloneOpen: false,
      processingDialogOpen: false,
      timeoutId: null,
      leftSourceName: this.props.project.left_source.name,
      from_project: "",
      inverted: "",
    };
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
  }

  onSubmit(e) {
    switch (this.state.matchingAction) {
      case "clone":
        this.setState({ confirmCloneOpen: true });
        break;
      case "match":
        this.launchMatching();
        break;
      case "import":
        this.launchImporting();
        break;
      default:
    }
  }

  handleGroupChange = source => group => {
    this.setState({ [source]: group });
  };

  handleProjectChange = from_project => project => {
    this.setState({ [from_project]: project });
  };

  handleMatchingActionChange = (e, value) => {
    this.setState({ matchingAction: value });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  launchImporting = () => {
    const payload = {
      left_source_id: this.props.project.left_source.id,
      left_dhis_id: this.state.leftSourceGroup.dhis_id,
      right_source_id: this.props.project.right_source.id,
      right_dhis_id: this.state.rightSourceGroup.dhis_id,
      from_project_id: this.state.from_project.id,
      left_name: this.state.leftSourceGroup.name,
      right_name: this.state.rightSourceGroup.name,
      project_name: this.state.from_project.name,
    };
    if (this.state.inverted !== "") {
      payload["inverted"] = this.state.inverted;
    }
    this.closeConfirmCloneDialog();
    this.props
      .onSubmit(this.props.currentProgram.id, this.props.project.id, payload)
      .then(payload => {
        this.setState(
          {
            processingErrors: null,
            processingDialogOpen: true,
            flowId: payload.payload.id,
          },
          this.delayedCheckProcessing,
        );
      });
  };

  launchMatching = () => {
    let content = {};
    if (this.state.matchingAction === "match") {
      content = {
        right_dhis_id: this.state.rightSourceGroup.dhis_id,
      };
    } else {
      content = {
        prefix: this.state.prefix,
        postfix: this.state.postfix,
      };
      if (this.props.cloneOptions) {
        this.props.cloneOptions.forEach(option => {
          content[option.props.name] = this.state[option.props.name];
        });
      }
    }
    const payload = {
      ...content,
      left_source_id: this.props.project.left_source.id,
      left_dhis_id: this.state.leftSourceGroup.dhis_id,
      right_source_id: this.props.project.right_source.id,
      left_name: this.state.leftSourceGroup.name,
    };

    this.closeConfirmCloneDialog();
    this.props
      .onSubmit(this.props.currentProgram.id, this.props.project.id, payload)
      .then(payload => {
        this.setState(
          {
            processingErrors: null,
            processingDialogOpen: true,
            flowId: payload.payload.id,
          },
          this.delayedCheckProcessing,
        );
      });
  };

  closeConfirmCloneDialog = () => {
    this.setState({ confirmCloneOpen: false });
  };

  closeProcessingDialog = () => {
    clearTimeout(this.state.timeoutId);
    this.setState({
      processingDialogOpen: false,
    });
    history.push(this.props.doneUrl);
  };

  checkProcessing = () => {
    get(this.props.flowApi, {
      programId: this.props.currentProgram.id,
      projectId: this.props.project.id,
      sourceId: this.props.project.left_source.id,
      flowId: this.state.flowId,
    }).then(flow => {
      if (flow.has_completed) {
        this.closeProcessingDialog();
      }

      if (flow.has_latest_errors) {
        this.setState({
          processingErrors: flow.has_latest_errors,
        });
      }
    });
  };

  delayedCheckProcessing() {
    this.setState({
      timeoutId: setInterval(this.checkProcessing, 2000),
    });
  }

  handleInvertedChange = event => {
    if (this.state.inverted === "") {
      this.setState({ inverted: "inverted" });
    } else {
      this.setState({ inverted: "" });
    }
  };

  processingDialog = () => {
    const {
      classes: { primaryText, secondaryText },
    } = this.props;
    return (
      <ProcessingDialog
        open={this.state.processingDialogOpen}
        handleClose={this.closeProcessingDialog}
        errors={this.state.processingErrors}
      >
        <Typography className={secondaryText}>
          {this.state.matchingAction === "match" ? "Matching" : "Copying"}
          <strong className={primaryText}>
            {` ${this.state.leftSourceGroup.name} `}
          </strong>
          {this.props.groupName}
          {this.props.childrenName &&
            ` and all its ${this.props.childrenName} from `}
          <strong className={primaryText}>
            {` ${this.props.project.left_source.name} `}
          </strong>
          to
          {this.state.rightSourceGroup.name && (
            <strong className={primaryText}>
              {` ${this.state.rightSourceGroup.name} in `}
            </strong>
          )}
          <strong className={primaryText}>
            {` ${this.props.project.right_source.name}`}
          </strong>
        </Typography>
      </ProcessingDialog>
    );
  };

  confirmCloneDialog = () => {
    const {
      classes: { primaryText },
    } = this.props;
    return (
      <ConfirmDialog
        handleConfirm={this.launchMatching}
        handleClose={this.closeConfirmCloneDialog}
        open={this.state.confirmCloneOpen}
        title="Please confirm"
      >
        <Typography>
          Are you sure you want to <strong>COPY</strong> the
          <strong className={primaryText}>
            {` ${this.state.leftSourceGroup.name} `}
          </strong>
          {this.props.groupName}
          {this.props.childrenName &&
            ` and all its ${this.props.childrenName} from `}
          <strong className={primaryText}>
            {` ${this.props.project.left_source.name} `}
          </strong>
          to
          <strong className={primaryText}>
            {` ${this.props.project.right_source.name} `}
          </strong>
          ?
        </Typography>
      </ConfirmDialog>
    );
  };

  render() {
    const { classes, project } = this.props;
    const canSave =
      (this.state.leftSourceGroup.dhis_id &&
        ((this.state.rightSourceGroup.dhis_id &&
          this.state.matchingAction !== "import") ||
          this.state.matchingAction === "clone")) ||
      (this.state.from_project !== "" &&
        this.state.matchingAction === "import" &&
        this.state.rightSourceGroup.dhis_id);

    return (
      <LayoutContainer>
        {this.processingDialog()}
        {this.confirmCloneDialog()}
        <Grid item xs={12}>
          <p>{this.props.comment}</p>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography className={classes.paperTitle}>
              Source definitions
            </Typography>
            <TextField
              name="leftSourceId"
              label="Source dhis"
              fullWidth
              margin="normal"
              value={this.state.leftSourceName}
              InputProps={{
                readOnly: true,
              }}
              className={classes.textField}
            />
            <AutoComplete
              projectId={project.id}
              sourceId={this.props.project.left_source.id}
              onChange={this.handleGroupChange("leftSourceGroup")}
              type={this.props.type}
            />
            <Fab color="primary" aria-label="add" className={classes.fabButton}>
              <LinkIcon />
            </Fab>
          </Paper>
        </Grid>
        <Grid item xs={10} md={6}>
          <Paper className={classes.paper}>
            <Tabs
              aria-label="Matching option"
              name="matching"
              value={this.state.matchingAction}
              onChange={this.handleMatchingActionChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.cardTab}
            >
              <Tab label="Match existing" value="match" />
              <Tab label="Copy from source" value="clone" />
              <Tab label="Import matching" value="import" />
            </Tabs>
            {this.state.matchingAction !== "import" && (
              <TextField
                name="target_dhis"
                label="Target dhis"
                fullWidth
                margin="normal"
                value={this.props.project.right_source.name}
                InputProps={{
                  readOnly: true,
                }}
                className={classes.textField}
              />
            )}
            {this.state.matchingAction === "match" && (
              <AutoComplete
                projectId={project.id}
                sourceId={this.props.project.right_source.id}
                onChange={this.handleGroupChange("rightSourceGroup")}
                type={this.props.type}
              />
            )}
            {this.state.matchingAction === "clone" && (
              <Fragment>
                <TextField
                  name="prefix"
                  label="Prefix"
                  fullWidth
                  helperText="Prefix to add to each copied group"
                  onChange={this.handleChange("prefix")}
                />
                <TextField
                  name="postfix"
                  label="Postfix"
                  fullWidth
                  helperText="Postfix to add to each copied group"
                  onChange={this.handleChange("postfix")}
                />
                {this.props.cloneOptions &&
                  this.props.cloneOptions.map(option =>
                    React.cloneElement(option, {
                      onChange: this.handleChange(option.props.name),
                    }),
                  )}
              </Fragment>
            )}
            {this.state.matchingAction === "import" && (
              <Fragment>
                <TextField
                  name="target_dhis"
                  label="Target dhis"
                  fullWidth
                  margin="normal"
                  value={this.props.project.right_source.name}
                  InputProps={{
                    readOnly: true,
                  }}
                  className={classes.textField}
                />
                <AutoComplete
                  projectId={project.id}
                  sourceId={this.props.project.right_source.id}
                  onChange={this.handleGroupChange("rightSourceGroup")}
                  type={this.props.type}
                />
                <AutoCompleteProject
                  projectId={project.id}
                  onChange={this.handleProjectChange("from_project")}
                />
                <CheckBoxComponent
                  preview={this.state.inverted}
                  checkboxLabel="Is source and target inverted?"
                  label="Inverted"
                  handlePreviewChange={() => this.handleInvertedChange}
                />
              </Fragment>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.formFooter}>
            <Button
              color="primary"
              variant="contained"
              onClick={this.onSubmit.bind(this)}
              disabled={!canSave}
            >
              Launch
            </Button>
          </div>
        </Grid>
      </LayoutContainer>
    );
  }
}

export default withStyles(styles)(NewMatchingFormMeta);

import React from "react";
import { getFetchMetaContent, getMatchMeta } from "./details.js";
import { fetchSubTask } from "../../../actions/tasks";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import {
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";

const styles = theme => ({});

function getSteps(task) {
  return ["Step 1: Fetch Meta", "Step 2: Match Meta"];
}

function getSides(task) {
  if (!task.params["side"]) return ["left", "right"];
  if (task.params["side"] === "source") return ["left"];
  return ["right"];
}

function getStepContent(task, step, classes) {
  switch (step) {
    case 0:
      return getFetchMetaContent(task, getSides(task), classes);
    case 1:
      return getMatchMeta(task, classes);
    default:
      return "Unknown step";
  }
}

class MatchMetaDetail extends React.Component {
  state = {
    activeStep: 0,
    completed: {},
  };

  activateStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  componentDidMount() {
    const { completed } = this.state;
    let activeStep = 0;
    this.setState({
      completed: completed,
      activeStep: activeStep,
    });
  }

  render() {
    const { classes, task } = this.props;
    const steps = getSteps(task);
    const { activeStep } = this.state;
    return (
      <div className={classes.root}>
        <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton
                  completed={this.state.completed[index]}
                  onClick={this.activateStep(index)}
                >
                  <StepLabel>
                    <Typography variant="subtitle1">{label}</Typography>
                  </StepLabel>
                </StepButton>
                <StepContent>
                  {getStepContent(task, index, classes)}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  subTask: state.task.entity,
  isFetchingSubTask: state.task.isFetchingSubTask,
});

export default withRouter(
  connect(mapStateToProps, { fetchSubTask })(
    withStyles(styles)(MatchMetaDetail),
  ),
);

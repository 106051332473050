import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class CloneOugMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;

    const clone_oug_summary = (action_key, clone_key) => {
      if (task.summary[action_key]) {
        return task.summary[action_key][clone_key]
          ? task.summary[action_key][clone_key]
          : 0;
      } else {
        return "Not counted";
      }
    };
    const summaries = [
      [
        {
          label: "Cloned ougs",
          value: clone_oug_summary("cloned_meta", "new_group_count"),
        },
        {
          label: "Total ous",
          value: clone_oug_summary("cloned_meta", "total_entities"),
        },
        {
          label: "Cloned ous",
          value: clone_oug_summary("cloned_meta", "new_added_entities_count"),
        },
      ],
      [
        {
          label: "Hierarchy entities",
          value: clone_oug_summary("cloned_meta", "hierarchy_entities_count"),
        },
        {
          label: "Skipped entities",
          value: clone_oug_summary("cloned_meta", "skipped_entities_count"),
        },

        {
          label: "Matched groups",
          value: clone_oug_summary("match_meta", "matched_group_count"),
        },
      ],
      [
        {
          label: "Matched entities",
          value: clone_oug_summary("match_meta", "matched_entities_count"),
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default CloneOugMetaFlowSummary;

import { ADD_ERROR_NOTIFICATIONS, REMOVE_NOTIFICATIONS } from "../actionTypes";

// Action creators
export function removeNotifications(category) {
  return dispatch => {
    dispatch({
      type: REMOVE_NOTIFICATIONS,
      category: category,
    });
  };
}

export function addErrorNotifications(category, messages) {
  return dispatch => {
    dispatch({
      type: ADD_ERROR_NOTIFICATIONS,
      category: category,
      messages: messages,
    });
  };
}

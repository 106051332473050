import React from "react";
import { Grid, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import LayoutContainer from "./LayoutContainer";
import helpGuy from "../../images/helpGuy.svg";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { fabButton, helpSection } from "../../helpers/commonStyles";

const styles = theme => ({
  ...helpSection(theme),
  ...fabButton(theme),
});

function NoDataInfoPage(props) {
  const { classes, match } = props;

  return (
    <LayoutContainer>
      <Grid item xs={12}>
        <p>{props.helpText}</p>
        <div className={classes.helpSection}>
          <p>
            <img src={helpGuy} alt="Help guy" />
          </p>
          <p>You don’t have any {props.type}</p>
        </div>
      </Grid>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fabButton}
        component={Link}
        to={`${match.url}/new`}
      >
        <AddIcon />
      </Fab>
    </LayoutContainer>
  );
}

export default withRouter(withStyles(styles)(NoDataInfoPage));

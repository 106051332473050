import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class CloneDsMetaFlowSummary extends Component {
  render() {
    const { task } = this.props;
    const clone_deg_summary = (action_key, clone_key) => {
      if (task.summary[action_key]) {
        return task.summary[action_key][clone_key]
          ? task.summary[action_key][clone_key]
          : 0;
      } else {
        return "Not counted";
      }
    };

    const summaries = [
      [
        {
          label: "Cloned ds",
          value: clone_deg_summary("cloned_meta", "new_group_count"),
        },
        {
          label: "Total entities",
          value: clone_deg_summary("cloned_meta", "total_entities"),
        },
        {
          label: "Cloned des",
          value: clone_deg_summary(
            "cloned_meta",
            "new_de_added_entities_count",
          ),
        },
        {
          label: "Cloned ous",
          value: clone_deg_summary(
            "cloned_meta",
            "new_ou_added_entities_count",
          ),
        },
        {
          label: "Hierarchy entities",
          value: clone_deg_summary(
            "cloned_meta",
            "hierarchy_ou_entities_count",
          ),
        },
      ],
      [
        {
          label: "Skipped des",
          value: clone_deg_summary("cloned_meta", "skipped_de_entities_count"),
        },
        {
          label: "Skipped ous",
          value: clone_deg_summary("cloned_meta", "skipped_ou_entities_count"),
        },
        {
          label: "Matched degs",
          value: clone_deg_summary("match_meta", "matched_group_count"),
        },
      ],
      [
        {
          label: "Matched entities",
          value: clone_deg_summary("match_meta", "matched_entities_count"),
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default CloneDsMetaFlowSummary;

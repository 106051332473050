import React from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import NewMatchingFormMeta from "../Shared/NewMatchingFormMeta";
import { connect } from "react-redux";
import { createNewDataElementGroupsMatching } from "../../actions/dataElementGroupsMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

function DataElementGroupsMatchingNew(props) {
  const { classes, project, currentProgram } = props;

  return (
    <div>
      <AppBarDefault>
        <Button
          color="secondary"
          aria-label="ArrowBack"
          component={NavLink}
          to={`/programs/${
            !!currentProgram ? currentProgram.id : ""
          }/projects/${project.id}/data_element_groups_matchings`}
        >
          <ArrowBack className={classes.leftIcon} />
          New Data Element Group matching
        </Button>
      </AppBarDefault>
      <NewMatchingFormMeta
        currentProgram={currentProgram}
        project={project}
        onSubmit={props.createNewDataElementGroupsMatching}
        type="data_element_groups"
        groupName="Data Element Group"
        childrenName="Data Elements"
        flowApi="dataElementGroupFlows"
        doneUrl={`/programs/${
          !!currentProgram ? currentProgram.id : ""
        }/projects/${project.id}/data_element_groups_matchings`}
        comment="This matching defines the direction of the copy of data.
        You have to set the source of the data (and hence, the data element definition) on the left."
      />
    </div>
  );
}

const mapStateToProps = state => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createNewDataElementGroupsMatching })(
  withRouter(withStyles(styles)(DataElementGroupsMatchingNew)),
);

import React, { Component, Fragment } from "react";

import {
  COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
  SYNCHRONIZE_DEG_META_FLOW,
  COMPARE_TASKS_SCHEDULER_PANELS,
} from "../constants";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, FormControl } from "@material-ui/core";
import AutoComplete from "../../Shared/AutoComplete";
import CompareParams from "../../Shared/CompareParams";
import CompareOtherParams from "../../Shared/CompareOtherParams";
import CheckBoxComponent from "../Shared/CheckBoxComponent";
import {
  formStyles,
  expansionPanelStyles,
} from "../../../helpers/commonStyles";

const styles = theme => ({
  ...formStyles(theme),
  ...expansionPanelStyles(theme),
  sectionTitle: {
    fontWeight: "bold",
  },
});
export const CC_COMPARE_PARAMS = {
  group: [
    { key: "name", tooltip: "category combo name", label: "Name" },
    {
      key: "short_name",
      tooltip: "category combo short name",
      label: "Short Name",
    },
  ],
  entities: [
    {
      key: "new_entities_left",
      tooltip:
        "Uncheck this if you don't want to create new categories on your target DHIS",
      label: "Create new categories",
    },
    {
      key: "new_entities_right",
      tooltip:
        "Uncheck that if you don't want to remove any categories from your target Data Set",
      label: "Remove categories from DS target",
    },
    {
      key: "modified_entities",
      tooltip:
        "Uncheck that if you don't want to update any categories on your target DHIS",
      label: "Update categories on Target",
    },
  ],
};

export const CAT_COMPARE_PARAMS = [
  { key: "name", tooltip: "category's name", label: "Name" },
  { key: "short_name", tooltip: "category's short name", label: "Short Name" },
  {
    key: "category_options",
    tooltip: "category options into category ",
    label: "Category Options",
  },
];

class CompareMetaFormCc extends Component {
  constructor(props) {
    super();
    this.state = {
      expanded:
        props.entity.id !== undefined
          ? props.entity.params.side !== undefined
            ? COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class][
                props.entity.params.side
              ]
            : COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class]
          : "panel5",
      preview: props.entity.params.preview ? "Preview" : "",
      preview_checkbox: true,
      sequencer_class: COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
    };
  }

  componentDidMount() {
    this.props.handleChange(`send_mail`, 3);
  }

  handleCompareCcParamsChange = (param, des_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_group_params;
    if (event.target.checked) {
      this.check_cats_param(param);
      params.push(param);
    } else {
      params = params.filter(p => p !== param);
      this.uncheck_cats_param(param);
    }
    this.props.handleParamChange("compare_group_params", params);
  };

  uncheck_cats_param = param => {
    if (param === CC_COMPARE_PARAMS.entities[2].key) {
      this.props.handleParamChange("compare_entities_params", []);
    }
  };

  check_cats_param = param => {
    if (param === CC_COMPARE_PARAMS.entities[2].key) {
      this.props.handleParamChange("compare_entities_params", [
        CAT_COMPARE_PARAMS[0].key,
      ]);
    }
  };

  uncheck_cc_param = (params, deg_params) => {
    if (
      params.length === 0 &&
      deg_params.includes(CC_COMPARE_PARAMS.entities[2].key)
    ) {
      deg_params = deg_params.filter(
        p => p !== CC_COMPARE_PARAMS.entities[2].key,
      );
      this.props.handleParamChange("compare_group_params", deg_params);
    }
  };

  check_cc_param = (param, deg_params) => {
    if (!deg_params.includes(CC_COMPARE_PARAMS.entities[2].key)) {
      deg_params = deg_params.push(CC_COMPARE_PARAMS.entities[2].key);
    }
  };

  handleCompareCatParamsChange = (param, cats_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_entities_params;
    if (event.target.checked) {
      this.check_cc_param(params, cats_params);
      params.push(param);
    } else {
      params = params.filter(p => p !== param);
      this.uncheck_cc_param(params, cats_params);
    }
    this.props.handleParamChange("compare_entities_params", params);
  };

  handleChange = (panel, sequencer_class) => (event, isExpanded) => {
    if (sequencer_class !== COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW) {
      this.props.handleRemoveParam("preview");
      this.setState({
        preview: "",
      });
    }
    this.setState({
      preview_checkbox:
        sequencer_class === COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW ? true : false,
      expanded: isExpanded ? panel : false,
      sequencer_class: sequencer_class,
    });
  };

  placeholderText = source => {
    return `Search for category combo from ${source}`;
  };

  getValueEdit = (entity, value, sequencer_class, side) => {
    if (side !== undefined) {
      if (side === entity["params"]["side"]) {
        return value;
      } else {
        return undefined;
      }
    } else if (sequencer_class === entity["sequencer_class"]) {
      return value;
    } else {
      return undefined;
    }
  };

  handlePreviewChange = event => {
    if (this.state.preview === "") {
      this.setState({ preview: "Preview" });
      this.props.handleParamChange("preview", true);
      this.props.handleChangeSequencerClassProp([
        {
          prop: "sequencer_class",
          value: SYNCHRONIZE_DEG_META_FLOW,
        },
      ]);
    } else {
      this.setState({ preview: "" });
      this.props.handleParamChange("preview", false);
      this.props.handleChangeSequencerClassProp([
        {
          prop: "sequencer_class",
          value: COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
        },
      ]);
    }
  };

  render() {
    const { project, entity, handleChanges, classes } = this.props;
    const compare_scenarios = [
      {
        label: "DHIS2 AND D2D FULL COMPARISON",
        left_instance: "dhis2",
        right_instance: "dhis2",
        left_source: this.getValueEdit(
          entity,
          entity.params.leftSourceGroup,
          entity.params.preview
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
          undefined,
        ),
        right_source: this.getValueEdit(
          entity,
          entity.params.rightSourceGroup,
          entity.params.preview
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
          undefined,
        ),
        left_source_id: this.props.project.left_source.id,
        right_source_id: this.props.project.right_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class:
          this.state.sequencer_class === COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW &&
          this.state.preview !== ""
            ? SYNCHRONIZE_DEG_META_FLOW
            : COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
        left_source_name: `DHIS2 ${this.props.project.left_source.name}`,
        right_source_name: `DHIS2 ${this.props.project.right_source.name}`,
      },
    ];

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div>
              {compare_scenarios.map((item, index) => {
                return (
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <AutoComplete
                        projectId={project.id}
                        sourceId={item["left_source_id"]}
                        onChange={e =>
                          handleChanges(
                            [
                              {
                                prop: "sequencer_class",
                                value: item["sequencer_class"],
                              },
                            ],
                            [
                              {
                                prop: item["left_identity"],
                                value: e,
                              },
                              item["side"] !== undefined
                                ? { prop: "side", value: item["side"] }
                                : {},
                            ],
                          )
                        }
                        type="category_combos"
                        instance={item["left_instance"]}
                        value={item["left_source"]}
                        placeholder={this.placeholderText(
                          item["left_source_name"],
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AutoComplete
                        projectId={project.id}
                        sourceId={item["right_source_id"]}
                        onChange={e =>
                          handleChanges(
                            [
                              {
                                prop: "sequencer_class",
                                value: item["sequencer_class"],
                              },
                            ],
                            [
                              {
                                prop: item["right_identity"],
                                value: e,
                              },
                              item["side"] !== undefined
                                ? { prop: "side", value: item["side"] }
                                : {},
                            ],
                          )
                        }
                        type="category_combos"
                        instance={item["right_instance"]}
                        value={item["right_source"]}
                        placeholder={this.placeholderText(
                          item["right_source_name"],
                        )}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.textField}>
              <CompareOtherParams
                label="Category Combo Params"
                group_params={CC_COMPARE_PARAMS}
                params={entity.params.compare_group_params}
                entities_params={entity.params.compare_entities_params}
                handleCompareParamsChange={this.handleCompareCcParamsChange}
              />
            </FormControl>
            <FormControl className={classes.textField}>
              <CompareParams
                label="Categories Params"
                group_params={CAT_COMPARE_PARAMS}
                params={entity.params.compare_entities_params}
                entities_params={entity.params.compare_group_params}
                handleCompareParamsChange={this.handleCompareCatParamsChange}
              />
            </FormControl>
          </Grid>
          {this.state.preview_checkbox && (
            <Grid item xs={12}>
              <CheckBoxComponent
                componentStyle={classes.sectionTitle}
                preview={this.state.preview}
                checkboxLabel="Apply changes without confirmation :"
                label="Apply without confirmation"
                handlePreviewChange={() => this.handlePreviewChange}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
});
export default connect(mapStateToProps)(withStyles(styles)(CompareMetaFormCc));

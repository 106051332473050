import {
  REQUEST_DEG_SUGGESTIONS,
  RECEIVE_DEG_SUGGESTIONS,
  REQUEST_DEG_SUGGESTIONS_FAILED,
} from "../actionTypes";

function dataElementGroups(
  state = {
    suggestions: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_DEG_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: [],
        errors: null,
        isFetching: true,
      });
    case RECEIVE_DEG_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: action.payload,
        isFetching: false,
      });
    case REQUEST_DEG_SUGGESTIONS_FAILED:
      return Object.assign({}, state, {
        suggestions: [],
        errors: action.payload,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default dataElementGroups;

import { Button } from "@material-ui/core";
import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBarDefault from "../Shared/AppBarDefault";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { NavLink } from "react-router-dom";
import { get } from "../../actions/api";
import { connect } from "react-redux";
import { formStyles as styles } from "../../helpers/commonStyles";
import { createImmediateTask } from "../../actions/tasks";
import TaskForm from "../Tasks/TaskForm";
import { TASK_FORMS, PUSH_DATA_FLOW, entityToForm } from "../Tasks/constants";

class ImmediateTaskNew extends Component {
  constructor(props) {
    super(props);
    var task = null;
    if (this.props.match.params.task_id) {
      this.getImmediateTask();
    } else {
      task = {
        sequencer_class: PUSH_DATA_FLOW,
      };
      task.params = TASK_FORMS[entityToForm(task)].resetParams(true);
    }

    this.state = {
      taskToEdit: null,
      timeoutId: null,
      task: task,
      taskId: null,
    };
  }

  onSubmit = task => {
    this.props.createImmediateTask(
      this.props.currentProgram.id,
      this.props.project.id,
      { task },
      true,
    );
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.taskId !== this.state.taskId) {
      this.setState({ task: this.state.taskToEdit });
    }
  }

  getImmediateTask = () => {
    get("task", {
      programId: this.props.currentProgram.id,
      projectId: this.props.project.id,
      id: this.props.match.params.task_id,
    }).then(this.handleImmediateTaskResponse.bind(this));
  };

  handleImmediateTaskResponse = task => {
    this.setState({ taskToEdit: task, taskId: task.id });
    this.pollImmediateTask();
  };

  pollImmediateTask = () => {
    this.setState({
      timeoutId: setTimeout(this.getImmediateTask.bind(this), 2000),
    });
  };

  render() {
    const { classes, project, currentProgram } = this.props;
    return (
      <div>
        <AppBarDefault>
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${currentProgram.id}/projects/${project.id}/tasks`}
          >
            <ArrowBack className={classes.leftIcon} />
            New task
          </Button>
        </AppBarDefault>
        <TaskForm
          immediate={true}
          projectId={project.id}
          entity={this.state.task}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createImmediateTask })(
  withStyles(styles)(ImmediateTaskNew),
);

import React, { Component } from "react";
import SectionSpinner from "../Shared/SectionSpinner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import DataElementGroupsMatchingPreview from "./DataElementGroupsMatchingPreview";
import AppBarDefault from "../Shared/AppBarDefault";
import { Grid, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import LayoutContainer from "../Shared/LayoutContainer";
import { fetchDataElementGroupsMatchings } from "../../actions/dataElementGroupsMatchings";
import { fabButton, helpSection } from "../../helpers/commonStyles";
import NoDataInfoPage from "../Shared/NoDataInfoPage";

const styles = theme => ({
  ...helpSection(theme),
  ...fabButton(theme),
});

class DataElementGroupsMatchings extends Component {
  componentDidMount() {
    this.props.fetchDataElementGroupsMatchings(
      this.props.currentProgram.id,
      this.props.project.id,
      false,
    );
  }

  render() {
    const {
      classes,
      match,
      dataElementGroupsMatchings,
      isFetching,
      project,
    } = this.props;

    if (isFetching) {
      return <SectionSpinner />;
    }

    if (dataElementGroupsMatchings.length === 0)
      return (
        <div>
          <AppBarDefault title={"Data Element Groups Matchings"} />
          <NoDataInfoPage
            type="data element groups matchings"
            helpText={
              "A data element groups matching is the link between a data element group in the source dhis2 and another one in the destination dhis2."
            }
          />
        </div>
      );
    return (
      <div>
        <AppBarDefault title={"Data Element Groups Matchings"} />
        <LayoutContainer>
          {dataElementGroupsMatchings.length > 0 &&
            dataElementGroupsMatchings.map(dataElementGroupsMatching => {
              return (
                <Grid item xs={12} md={6} key={dataElementGroupsMatching.id}>
                  <DataElementGroupsMatchingPreview
                    projectId={project.id}
                    dataElementGroupsMatching={dataElementGroupsMatching}
                  />
                </Grid>
              );
            })}
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fabButton}
            component={Link}
            to={`${match.url}/new`}
          >
            <AddIcon />
          </Fab>
        </LayoutContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  dataElementGroupsMatchings: state.dataElementGroupsMatchings.entities,
  isFetching: state.dataElementGroupsMatchings.isFetching,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchDataElementGroupsMatchings }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(DataElementGroupsMatchings)),
);

import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class MatchMetaParams extends Component {
  render() {
    const { task } = this.props;
    return (
      <TaskHashCell
        task={[
          [
            {
              label: "Left:",
              value: `${task.params["left_name"]}`,
            },
          ],
          [
            {
              label: "Right:",
              value: `${task.params["right_name"]}`,
            },
          ],
        ]}
      />
    );
  }
}
export default MatchMetaParams;

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Error } from "@material-ui/icons";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusFailed extends React.Component {
  render() {
    const { classes, withLabel, error } = this.props;
    return (
      <TaskStatus
        help={error}
        lbl={withLabel ? "Failed" : null}
        icon={<Error className={classes.iconLeft} />}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusFailed);

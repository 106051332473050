import React, { Component } from "react";
import { Divider, Grid, Paper, Tab, Tabs, Fab } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import CloneForm from "./CloneForm";
import KnownMatchingForm from "./KnownMaptchingForm";
import MatchingItems from "./MatchingItems";
import { card, paper } from "../../helpers/commonStyles";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  ...paper(theme),
  ...card(theme),
  fabButton: {
    position: "absolute",
    left: -40,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
  },
  divider: {
    marginTop: 5,
    marginLeft: -32,
    marginRight: -32,
  },
});

class MatchingRight extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      matchingAction: "match",
    };
  }

  handleMatchingActionChange = (event, action) => {
    this.setState({ matchingAction: action });
  };

  render() {
    const {
      itemMatchingQuery,
      itemsRightFetching,
      selectedItemsIds,
      cloneOptions,
      handleCloneFormChange,
      handleItemClone,
      handleItemSelection,
      knownMatch,
      classes,
    } = this.props;
    return (
      <Paper className={classes.paper} style={{ height: 364 }}>
        <Fab
          color="primary"
          aria-label="matching"
          className={classes.fabButton}
        >
          <LinkIcon />
        </Fab>
        <Tabs
          aria-label="Matching option"
          name="matching"
          value={this.state.matchingAction}
          onChange={this.handleMatchingActionChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.cardTab}
        >
          <Tab label="Match existing" value="match" />
          <Tab label="Copy from source" value="clone" />
        </Tabs>
        <div style={{ height: "324px" }}>
          {this.state.matchingAction === "match" && (
            <Grid container>
              {!!knownMatch && !!selectedItemsIds.left.id && (
                <Grid item xs={12}>
                  <KnownMatchingForm
                    handleMap={uuid =>
                      handleItemSelection({ dhis_id: uuid }, -1, false)
                    }
                  />
                  <Divider className={classes.divider} />
                </Grid>
              )}
              <Grid item xs={12} style={{ height: 364 }}>
                <MatchingItems
                  {...this.props}
                  height={
                    !!knownMatch && !!selectedItemsIds.left.id ? 250 : 300
                  }
                  itemMatchingQuery={itemMatchingQuery}
                  itemsRightFetching={itemsRightFetching}
                  selectedItemsIds={selectedItemsIds}
                />
              </Grid>
            </Grid>
          )}
          {this.state.matchingAction === "clone" && (
            <CloneForm
              handleChange={handleCloneFormChange}
              handleLaunch={handleItemClone}
              cloneOptions={cloneOptions}
            />
          )}
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(MatchingRight);

import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Chip } from "@material-ui/core";

const styles = theme => ({
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
    marginBottom: "5px",
  },
});

class CompareMetaFlowPreview extends Component {
  render() {
    const { classes, scheduler } = this.props;
    return (
      <Grid container spacing={2}>
        {!!scheduler.params.leftSourceGroup.name && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>Group Source:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <strong>{scheduler.params.leftSourceGroup.name}</strong>
              </Typography>
            </Grid>
          </Fragment>
        )}
        {!!scheduler.params.rightSourceGroup.name && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>Group Target:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                <strong>{scheduler.params.rightSourceGroup.name}</strong>
              </Typography>
            </Grid>
          </Fragment>
        )}

        {!!scheduler.params.compare_group_params && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>Group Params:</Typography>
            </Grid>
            <Grid item xs={8}>
              {scheduler.params.compare_group_params.map((p, i) => (
                <Chip className={classes.chip} key={`p-${i}`} label={p} />
              ))}
            </Grid>
          </Fragment>
        )}

        {!!scheduler.params.compare_entities_params && (
          <Fragment>
            <Grid item xs={4}>
              <Typography>Entities Params:</Typography>
            </Grid>
            <Grid item xs={8}>
              {scheduler.params.compare_entities_params.map((p, i) => (
                <Chip className={classes.chip} key={`p-${i}`} label={p} />
              ))}
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(CompareMetaFlowPreview);

import * as types from "../actionTypes";

function cocsMatchings(
  state = {
    entities: [],
    isFetching: false,
    meta: {},
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS:
      return Object.assign({}, state, {
        entities: state.entities,
        isFetching: true,
      });
    case types.RECEIVE_CATEGORY_OPTION_COMBOS_MATCHINGS:
      const newEntities = action.payload.data;
      let entities = action.meta.force
        ? newEntities
        : [...state.entities, ...newEntities];

      return Object.assign({}, state, {
        entities,
        isFetching: false,
        meta: action.payload.meta,
      });
    case types.REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        errors: action.payload.errors,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default cocsMatchings;

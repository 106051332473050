import React, { Component, Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TaskDetail from "./ImmediateTasks/TaskDetail";
import ImmediateTaskNew from "./ImmediateTasks/ImmediateTaskNew";
import Tasks from "./Tasks/Tasks";
import SchedulerEdit from "./Schedulers/SchedulerEdit";
import SchedulerNew from "./Schedulers/SchedulerNew";
import Schedulers from "./Schedulers/Schedulers";
import GeneralEdit from "./General/GeneralEdit";
import OrgUnitGroupsMatchings from "./OrgUnitGroupsMatchings/OrgUnitGroupsMatchings";
import OrgUnitGroupsMatchingEdit from "./OrgUnitGroupsMatchings/OrgUnitGroupsMatchingEdit";
import OrgUnitGroupsMatchingNew from "./OrgUnitGroupsMatchings/OrgUnitGroupsMatchingNew";
import CategoriesMatchings from "./CategoriesMatchings/CategoriesMatchings";
import CategoriesMatchingEdit from "./CategoriesMatchings/CategoriesMatchingEdit";
import CategoriesMatchingNew from "./CategoriesMatchings/CategoriesMatchingNew";
import CategoryCombosMatchings from "./CategoryCombosMatchings/CategoryCombosMatchings";
import CategoryCombosMatchingEdit from "./CategoryCombosMatchings/CategoryCombosMatchingEdit";
import CategoryCombosMatchingNew from "./CategoryCombosMatchings/CategoryCombosMatchingNew";
import DataElementGroupsMatchings from "./DataElementGroupsMatchings/DataElementGroupsMatchings";
import DataElementGroupsMatchingEdit from "./DataElementGroupsMatchings/DataElementGroupsMatchingEdit";
import DataElementGroupsMatchingNew from "./DataElementGroupsMatchings/DataElementGroupsMatchingNew";
import DataSetsMatchings from "./DataSetsMatchings/DataSetsMatchings";
import DataSetsMatchingEdit from "./DataSetsMatchings/DataSetsMatchingEdit";
import DataSetsMatchingNew from "./DataSetsMatchings/DataSetsMatchingNew";
import { fetchProject } from "../actions/projects";
import SectionSpinner from "./Shared/SectionSpinner";
class ProjectShow extends Component {
  componentDidMount() {
    if (!!this.props.currentProgram && !!this.props.projectId) {
      this.props.fetchProject(
        this.props.currentProgram.id,
        this.props.projectId,
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevProps.currentProgram !== this.props.currentProgram ||
        prevProps.projectId !== this.props.projectId) &&
      !!this.props.currentProgram &&
      !!this.props.projectId
    ) {
      this.props.fetchProject(
        this.props.currentProgram.id,
        this.props.projectId,
      );
    }
  }

  render() {
    if (!this.props.currentProgram) {
      return null;
    }
    if (!!this.props.isFetchingProject) {
      return <SectionSpinner />;
    }
    return (
      <Fragment>
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/tasks/:task_id/edit`}
          component={ImmediateTaskNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/tasks/:task_id/show`}
          component={TaskDetail}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/tasks/new`}
          component={ImmediateTaskNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/tasks`}
          component={Tasks}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/schedulers/:scheduler_id/edit`}
          component={SchedulerEdit}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/schedulers/new`}
          component={SchedulerNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/schedulers`}
          component={Schedulers}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/edit`}
          component={GeneralEdit}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/org_unit_groups_matchings/:org_unit_group_matching_id/edit`}
          component={OrgUnitGroupsMatchingEdit}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/org_unit_groups_matchings/new`}
          component={OrgUnitGroupsMatchingNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/org_unit_groups_matchings`}
          component={OrgUnitGroupsMatchings}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/categories_matchings/:category_matching_id/edit`}
          component={CategoriesMatchingEdit}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/categories_matchings/new`}
          component={CategoriesMatchingNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/categories_matchings`}
          component={CategoriesMatchings}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/category_combos_matchings/:category_combo_matching_id/edit`}
          component={CategoryCombosMatchingEdit}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/category_combos_matchings/new`}
          component={CategoryCombosMatchingNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/category_combos_matchings`}
          component={CategoryCombosMatchings}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/data_element_groups_matchings/:data_element_group_matching_id/edit`}
          component={DataElementGroupsMatchingEdit}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/data_element_groups_matchings/new`}
          component={DataElementGroupsMatchingNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/data_element_groups_matchings`}
          component={DataElementGroupsMatchings}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/data_sets_matchings/:data_set_matching_id/edit`}
          component={DataSetsMatchingEdit}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/data_sets_matchings/new`}
          component={DataSetsMatchingNew}
        />
        <Route
          exact
          path={`/programs/:program_id/projects/:project_id/data_sets_matchings`}
          component={DataSetsMatchings}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  projectId:
    !!props.match && !!props.match.params
      ? props.match.params.project_id
      : null,
  project: state.programs.currentProject,
  isFetchingProject: state.programs.isFetchingProject,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchProject }, dispatch);
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectShow),
);

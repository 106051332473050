import React, { Component, Fragment } from "react";
import { Divider, Grid, ListItem, Typography } from "@material-ui/core";
import ExecutionStatusShow from "./ExecutionStatusShow";

class ImportMatchingShow extends Component {
  render() {
    const { task } = this.props;

    return (
      <Fragment>
        <ListItem>
          <Grid container spacing={3}>
            <Fragment>
              <Grid item xs={4}>
                <strong className="capitalize">Left group:</strong>
              </Grid>
              <Grid item xs={8}>
                <Typography>{task.params["left_name"]}</Typography>
              </Grid>
              <Grid item xs={4}>
                <strong className="capitalize">Right group:</strong>
              </Grid>
              <Grid item xs={8}>
                <Typography>{task.params["right_name"]}</Typography>
              </Grid>
            </Fragment>
          </Grid>
        </ListItem>
        <Divider light />
        <ExecutionStatusShow task={task} />
      </Fragment>
    );
  }
}

export default ImportMatchingShow;

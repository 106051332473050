import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Grid,
  Chip,
} from "@material-ui/core";

import Edit from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import fadeIn from "react-animations/lib/fadeIn";
import PreviewSource from "../Shared/PreviewSource";
import SharedDeleter from "../Shared/SharedDeleter";
import { fetchCategoryCombosMatchings } from "../../actions/categoryCombosMatchings";

const styles = theme => ({
  "@keyframes fadeIn": fadeIn,
  root: {
    animationName: "fadeIn",
    animationDuration: "0.5s",
  },
  arrowColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardActions: {
    borderTop: "1px solid #efefef",
  },
});

class CategoryCombosMatchingPreview extends Component {
  render() {
    const {
      classes,
      projectId,
      currentProgram,
      categoryCombosMatching: { id, display_name, left, right },
      match,
    } = this.props;

    return (
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <PreviewSource label={left.source.name} />
            </Grid>
            <Grid item xs={2} className={classes.arrowColumn} />
            <Grid item xs={5}>
              <PreviewSource label={right.source.name} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              {left.name}
              <br />
              <Typography variant="caption">{left.dhis_id}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.arrowColumn}>
              <LinkIcon />
            </Grid>
            <Grid item xs={5}>
              {right.name}
              <br />
              <Typography variant="caption">{right.dhis_id}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Chip label={`${left.categories_count} categories`} />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <Chip label={`${right.categories_count} categories`} />
            </Grid>
            <Grid item xs={5}>
              <Chip
                label={`${left.category_option_combos_count} category option combos`}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <Chip
                label={`${right.category_option_combos_count} category option combos`}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <IconButton component={Link} to={`${match.url}/${id}/edit`}>
            <Edit />
          </IconButton>
          <SharedDeleter
            api="categoriesCombosMatchings"
            callback={() =>
              this.props.fetchCategoryCombosMatchings(
                this.props.currentProgram.id,
                projectId,
              )
            }
            projectId={projectId}
            programId={currentProgram.id}
            id={id}
            message={`Are you sure you want to delete the "${display_name}" matching ?`}
          />
        </CardActions>
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  currentProgram: state.programs.currentProgram,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchCategoryCombosMatchings }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(CategoryCombosMatchingPreview)),
);

import {
  REQUEST_LOGIN,
  REQUEST_LOGIN_FAILED,
  RECEIVE_LOGIN,
  REQUEST_LOGOUT,
} from "../actionTypes";

// we would also want a util to check if the token is expired.
function auth(
  state = {
    isFetching: false,
    isAuthenticated: false,
    token: null,
    user: null,
    expiresAt: null,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_LOGIN:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
      };
    case RECEIVE_LOGIN:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
        expiresAt: action.payload.expires_at,
        backUser: state.backUser,
      };
    case REQUEST_LOGIN_FAILED:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
      };
    case REQUEST_LOGOUT:
      return {
        ...state,
        backUser: state.user !== null ? state.user.id : null,
        isFetching: false,
        isAuthenticated: false,
        token: null,
        user: null,
        expiresAt: null,
      };
    default:
      return state;
  }
}

export default auth;

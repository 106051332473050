import React from "react";
import { Grid, withStyles, Tooltip, Fab } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import CategoryComboPreview from "./CategoryComboPreview";

const styles = theme => ({
  relative: {
    position: "relative",
  },
  linkTypeIndicator: {
    position: "absolute",
    right: -25,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
  },
});

function CategoryCombosPreviews({
  programId,
  projectId,
  matchingId,
  categoryCombos,
  classes,
  tooltipText,
  target,
}) {
  return (
    <Grid container spacing={3}>
      {categoryCombos.map((cc, index) => (
        <Grid item xs={12} md={6} key={cc.id} className={classes.relative}>
          <CategoryComboPreview
            categoryCombo={cc}
            projectId={projectId}
            programId={programId}
            matchingId={matchingId}
            side={index === 0 ? "left" : "right"}
            target={index === 0 ? target : null}
          />
          {index === 0 && (
            <Tooltip id="tooltip-fab" title={tooltipText} placement="top">
              <Fab
                color="primary"
                aria-label="add"
                className={classes.linkTypeIndicator}
              >
                <ArrowForward />
              </Fab>
            </Tooltip>
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(CategoryCombosPreviews);

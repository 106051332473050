import React, { Component } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { formStyles } from "../../helpers/commonStyles";

const styles = theme => ({
  ...formStyles(theme),
});

class CloneForm extends Component {
  render() {
    const { classes, handleChange, handleLaunch, cloneOptions } = this.props;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="prefix"
            label="Prefix"
            fullWidth
            helperText="Prefix to add to each copied group"
            onChange={handleChange("prefix")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="postfix"
            label="Postfix"
            fullWidth
            helperText="Postfix to add to each copied group"
            onChange={handleChange("postfix")}
          />
          {cloneOptions &&
            cloneOptions.map(option =>
              React.cloneElement(option, {
                onChange: handleChange(option.props.name),
              }),
            )}
        </Grid>
        {handleLaunch && (
          <Grid item xs={12}>
            <div className={classes.formFooter}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleLaunch}
              >
                Launch
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(CloneForm);

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  typography: {
    fontFamily: ["Rubik", "Roboto", '"Helvetica Neue"', "sans-serif"].join(","),
    fontSize: 14,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    title: {
      fontSize: 20,
      fontWeight: 300,
    },
  },
  palette: {
    primary: { main: "#ff5722" },
    secondary: { main: "#fff" },
  },
});

export default theme;

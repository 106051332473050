import React from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button, TextField } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import NewMatchingFormMeta from "../Shared/NewMatchingFormMeta";
import { connect } from "react-redux";
import { createNewDataSetsMatching } from "../../actions/dataSetsMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

function DataSetsMatchingNew(props) {
  const { classes, project, currentProgram } = props;

  return (
    <div>
      <AppBarDefault>
        <Button
          color="secondary"
          aria-label="ArrowBack"
          component={NavLink}
          to={`/programs/${
            !!currentProgram ? currentProgram.id : ""
          }/projects/${project.id}/data_sets_matchings`}
        >
          <ArrowBack className={classes.leftIcon} />
          New Data Set matching
        </Button>
      </AppBarDefault>
      <NewMatchingFormMeta
        currentProgram={currentProgram}
        project={project}
        onSubmit={props.createNewDataSetsMatching}
        type="data_sets"
        groupName="Data Set"
        childrenName="Data Elements"
        flowApi="dataSetFlows"
        doneUrl={`/programs/${
          !!currentProgram ? currentProgram.id : ""
        }/projects/${project.id}/data_sets_matchings`}
        comment="This matching defines the direction of the copy of data.
        You have to set the source of the data (and hence, the data set definition) on the left."
        cloneOptions={[
          <TextField
            key="skipLevel"
            name="skip_level"
            label="Level to skip"
            fullWidth
            type="number"
            helperText="Level of the org unit hierarchy to skip (root = 1 and cannot be skipped)"
          />,
        ]}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createNewDataSetsMatching })(
  withStyles(styles)(DataSetsMatchingNew),
);

import {
  CircularProgress,
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "./Alert";

class ProcessingDialog extends Component {
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.handleClose();
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={this.props.open}
        onClose={this.handleClose}
        ContentProps={{
          "aria-describedby": "client-snackbar",
        }}
        message={
          <span id="client-snackbar">
            {this.props.content && (
              <Typography>{this.props.content}</Typography>
            )}
            {this.props.children}
            {!this.props.errors && (
              <center>
                <CircularProgress size={24} />
              </center>
            )}
            {this.props.errors && (
              <Alert>
                <Typography color="secondary">Error(s):</Typography>
                <Typography color="secondary">{this.props.errors}</Typography>
              </Alert>
            )}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

export default ProcessingDialog;

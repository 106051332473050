import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";
import { fetchCategoryOptionCombos } from "./categoryOptionCombos";
import * as types from "../actionTypes";
import { stringify } from "query-string";
import _omitBy from "lodash/omitBy";
import _isUndefined from "lodash/isUndefined";

export function fetchCategoryOptionCombosMatchings(
  programId,
  projectId,
  ccMatchingId,
  page,
  force = false,
) {
  const meta = { meta: { force: force } };

  const queryParams = {
    page: page,
    category_combo_matching_id: ccMatchingId,
    per_page: 500,
  };

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));

  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_option_combos?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS,
        { type: types.RECEIVE_CATEGORY_OPTION_COMBOS_MATCHINGS, ...meta },
        types.REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS_FAILED,
      ],
    },
  };
}

export function createNewCategoryOptionCombosMatching(
  programId,
  projectId,
  payload,
  matchingType,
  ccMatchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_option_combos`,
      method: "POST",
      body: JSON.stringify(payload),
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CREATE_CATEGORY_OPTION_COMBOS_MATCHING,
        {
          type: types.RECEIVE_CREATE_CATEGORY_OPTION_COMBOS_MATCHING,
          payload: (action, state, response) => {
            store.dispatch({
              type: types.DESTROY_LOCAL_CATEGORY_OPTION_COMBO,
              sourceId: payload.first_element.source_id,
              categoryOptionComboId: payload.first_element.id,
            });
            store.dispatch({
              type: types.DESTROY_LOCAL_CATEGORY_OPTION_COMBO,
              sourceId: payload.second_element.source_id,
              categoryOptionComboId: payload.second_element.id,
            });
            store.dispatch(
              fetchCategoryOptionCombosMatchings(
                programId,
                projectId,
                ccMatchingId,
                1,
                true,
              ),
            );
          },
        },
        types.REQUEST_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING_FAILED,
      ],
    },
  };
}

export function destroyCategoryOptionCombosMatching(
  programId,
  projectId,
  matchingType,
  ccMatching,
  cocMatchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_option_combos/${cocMatchingId}`,
      method: "DELETE",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING,
        {
          type: types.RECEIVE_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING,
          payload: (action, state, response) => {
            store.dispatch(
              fetchCategoryOptionCombos(
                programId,
                projectId,
                ccMatching.left.source.id,
                ccMatching.left.id,
                undefined,
                1,
                true,
              ),
            );
            store.dispatch(
              fetchCategoryOptionCombosMatchings(
                programId,
                projectId,
                ccMatching.Id,
                1,
                true,
              ),
            );
          },
        },
        types.REQUEST_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING_FAILED,
      ],
    },
  };
}

import React from "react";
import { Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

const styles = theme => ({
  root: {
    margin: "40px auto",
    [theme.breakpoints.down("md")]: {
      margin: "40px 60px",
    },
  },
  stdContent: {
    maxWidth: 1000,
  },
  stdWideContent: {
    maxWidth: 1150,
  },
  wideContent: {
    maxWidth: "87%",
  },
  crossAppBar: {
    marginTop: -161 + 40,
  },
});

function LayoutContainer(props) {
  const { classes } = props;

  return (
    <div
      className={classNames(classes.root, {
        [classes.crossAppBar]: !!props.crossAppBar,
        [classes.stdContent]: !props.wideContent,
        [classes.wideContent]: !!props.wideContent,
        [classes.stdWideContent]: !!props.moreWideContent,
      })}
    >
      <Grid container spacing={3}>
        {props.children}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(LayoutContainer);

import React, { Component, Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import LayoutContainer from "../Shared/LayoutContainer";
import Alert from "../Shared/Alert";
import SectionHeader from "../Shared/SectionHeader";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createNewCategoryOptionsMatching,
  destroyCategoryOptionsMatching,
  fetchCategoryOptionsMatchings,
} from "../../actions/categoryOptionsMatchings";
import {
  refreshCategoryOptionsMatchingMasterList,
  categoryOptionsMatchingMasterListRefreshed,
  matchingsListRefreshed,
} from "../../actions/ui";
import { get } from "../../actions/api";
import { fetchCategoryOptions } from "../../actions/categoryOptions";
import SearcheableMatchingTable from "../Shared/SearcheableMatchingTable";
import _values from "lodash/values";
import { paper as styles } from "../../helpers/commonStyles";
import CategoriesPreviews from "./CategoriesPreviews";
import ConfirmDialog from "../Shared/ConfirmDialog";
import UnmatchedNewSection from "../Shared/UnmatchedNewSection";
import _isEqual from "lodash/isEqual";

const selectedCategoryOptionsEmptyState = {
  selectedCategoryOptionsIds: {
    left: { id: null, index: null },
    right: { id: null, index: null },
  },
};

class CategoriesMatchingEditForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...selectedCategoryOptionsEmptyState,
      categoryOptionMatchingQuery: undefined,
      confirmDestroyOpen: false,
      searchedItems: [],
      searchedItemsCount: 0,
      searchParams: "",
      existingMatchingRowCount: 0,
    };
  }

  componentDidMount() {
    this.setState((state, props) => ({
      existingMatchingRowCount: props.existingMatchingRowCount,
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !_isEqual(
        prevProps.existingMatchingRowCount,
        this.props.existingMatchingRowCount,
      )
    ) {
      this.setState((state, props) => ({
        existingMatchingRowCount: props.existingMatchingRowCount,
      }));
    }
  }

  handleFetchCategoryOptions = params => {
    const { sourceId, query, page, master, forceReload } = params;
    const {
      projectId,
      programId,
      catMatching: { left: categoryLeft, right: categoryRight },
    } = this.props;
    const category = master ? categoryLeft : categoryRight;
    this.props.fetchCategoryOptions(
      programId,
      projectId,
      sourceId,
      "categories",
      category.id,
      query,
      page,
      forceReload,
    );
  };

  closeConfirmDestroyDialog = () => {
    this.setState({
      confirmDestroyOpen: false,
    });
  };

  confirmDestroyContent = () => {
    return (
      <Fragment>
        <Typography>
          This category option matching is shared by the following category
          matchings:
        </Typography>
        <Typography color="primary">
          {this.state.confirmDestroyWarnings &&
            this.state.confirmDestroyWarnings.join(", ")}
        </Typography>
        <Typography>
          Do you want to delete the category option matching in all these
          category matchings?
        </Typography>
      </Fragment>
    );
  };

  confirmDestroyDialog = () => {
    return (
      <ConfirmDialog
        handleConfirm={this.destroyCategoryOptionMatching}
        handleClose={this.closeConfirmDestroyDialog}
        open={this.state.confirmDestroyOpen}
      >
        {this.confirmDestroyContent()}
      </ConfirmDialog>
    );
  };

  destroyCategoryOptionMatching = () => {
    this.props.destroyCategoryOptionsMatching(
      this.props.programId,
      this.props.projectId,
      "categories",
      this.props.catMatching,
      this.state.matchingIdToDestroy,
    );
    this.closeConfirmDestroyDialog();
  };

  handleCategoryOptionMatchingDestroy = matchingIdToDestroy => {
    get("destroyCategoryOptionsMatchingPrev", {
      programId: this.props.programId,
      projectId: this.props.projectId,
      id: matchingIdToDestroy,
    }).then(categories => {
      if (categories.length > 1) {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
          confirmDestroyWarnings: categories,
          confirmDestroyOpen: true,
        });
      } else {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
        });
        this.destroyCategoryOptionMatching();
      }
    });
  };

  categoriesPreview() {
    const {
      catMatching: { left: categoryLeft, right: categoryRight },
      projectId,
      programId,
    } = this.props;

    return (
      <CategoriesPreviews
        projectId={projectId}
        programId={programId}
        categories={[categoryLeft, categoryRight]}
        tooltipText={`The values will be pushed from ${categoryLeft.source.name} to ${categoryRight.source.name}`}
        target={categoryRight.source}
      />
    );
  }

  search_mappings = params => {
    const searched =
      params === ""
        ? []
        : this.props.categoryOptionsLeft.filter(function(el) {
            return (
              el["name"].toLowerCase().includes(params.toLowerCase()) ||
              el["dhis_id"].toLowerCase().includes(params.toLowerCase())
            );
          });
    this.setState({
      searchedItems: searched,
      searchedItemsCount: searched.length,
      searchParams: params,
    });
  };

  searched_results = () => {
    return this.state.searchParams
      ? this.state.searchedItems
      : this.props.categoryOptionsLeft;
  };

  searched_results_count = () => {
    return this.state.searchParams
      ? this.state.searchedItemsCount
      : this.props.categoryOptionsLeftRowCount;
  };

  handleCountChange = (dangling, count, searchParams) => {
    if (searchParams) {
      this.setState({ existingMatchingRowCount: count });
    } else {
      this.setState((state, props) => ({
        existingMatchingRowCount: props.existingMatchingRowCount,
      }));
    }
  };

  render() {
    const {
      catMatching: { left: categoryLeft, right: categoryRight },
      classes,
    } = this.props;

    const catItemsCountDontMatch =
      categoryLeft.elements_count !== categoryRight.elements_count;
    return (
      <LayoutContainer>
        {this.confirmDestroyDialog()}
        <Grid item xs={12}>
          {catItemsCountDontMatch && (
            <Alert type="danger">
              Category options count in each category don’t match. Please verify
              that both categories are identical in both Dhis2.
            </Alert>
          )}

          {this.categoriesPreview()}

          <SectionHeader>
            Unmatched Category Options ({this.searched_results_count()})
          </SectionHeader>
          <UnmatchedNewSection
            search_mappings={this.search_mappings}
            projectId={this.props.projectId}
            programId={this.props.programId}
            itemTypeLabel="Category Option"
            matching={this.props.catMatching}
            itemsLeft={this.searched_results()}
            itemsLeftRowCount={this.searched_results_count()}
            itemsLeftFetching={this.props.categoryOptionsLeftFetching}
            fetchItems={this.handleFetchCategoryOptions}
            itemsRight={this.props.categoryOptionsRight}
            itemsRightRowCount={this.props.categoryOptionsRightRowCount}
            itemsRightFetching={this.props.categoryOptionsRightFetching}
            matchingType="categories"
            taskMatcher="task"
            createItemsMatching={this.props.createNewCategoryOptionsMatching}
            autoMatchersApi="categoryOptionsAutoMappers"
            itemClonesApi="categoryOptionClones"
            sequencer_class="::Services::MetaMatchFlow::MatchCatMetaFlow"
          />
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.paper}>
              <SearcheableMatchingTable
                projectId={this.props.projectId}
                destroyMatching={this.handleCategoryOptionMatchingDestroy}
                catMatchingId={this.props.catMatching.id}
                handleCountChange={this.handleCountChange}
                fetchMetaMachings={(payload, force) =>
                  this.props.fetchCategoryOptionsMatchings(
                    this.props.programId,
                    this.props.projectId,
                    this.props.catMatching.id,
                    payload,
                    force,
                  )
                }
                matchingsListRefreshed={this.props.matchingsListRefreshed}
                metaTypeMatching="categoryOptionsMatchings"
                sectionHeaderLabel="Matched Category Options"
              />
            </Grid>
          </Grid>
        </Grid>
      </LayoutContainer>
    );
  }
}

const categoryOptionsForSource = (categoryOptions, sourceId) => {
  return categoryOptions[sourceId] && categoryOptions[sourceId].entities
    ? _values(categoryOptions[sourceId].entities)
    : [];
};

const categoryOptionsMetaForSource = (categoryOptions, sourceId) => {
  return categoryOptions[sourceId] &&
    categoryOptions[sourceId].meta !== undefined
    ? categoryOptions[sourceId].meta
    : { total_count: 0 };
};

const isFetching = (categoryOptions, sourceId) => {
  return categoryOptions[sourceId] && categoryOptions[sourceId].isFetching;
};

const mapStateToProps = (state, props) => {
  const {
    catMatching: { left: categoryLeft, right: categoryRight },
  } = props;

  const categoryOptionsLeftMeta = categoryOptionsMetaForSource(
    state.categoryOptions,
    categoryLeft.source.id,
  );
  const categoryOptionsRightMeta = categoryOptionsMetaForSource(
    state.categoryOptions,
    categoryRight.source.id,
  );

  const categoryOptionsLeftRowCount = categoryOptionsLeftMeta.total_count;
  const categoryOptionsRightRowCount = categoryOptionsRightMeta.total_count;

  const categoryOptionsLeftFetching = isFetching(
    state.categoryOptions,
    categoryLeft.source.id,
  );
  const categoryOptionsRightFetching = isFetching(
    state.categoryOptions,
    categoryRight.source.id,
  );

  const existingMatchingRowCount =
    state.categoryOptionsMatchings.meta.total_count || 0;
  return {
    programId: state.programs.currentProgram.id,
    projectId: state.programs.currentProject.id,
    categoryOptionsLeft: categoryOptionsForSource(
      state.categoryOptions,
      categoryLeft.source.id,
    ),
    categoryOptionsRight: categoryOptionsForSource(
      state.categoryOptions,
      categoryRight.source.id,
    ),
    categoryOptionsLeftMeta,
    categoryOptionsRightMeta,
    categoryOptionsLeftRowCount,
    categoryOptionsRightRowCount,
    categoryOptionsLeftFetching,
    categoryOptionsRightFetching,
    existingMatchingRowCount,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCategoryOptions,
      createNewCategoryOptionsMatching,
      destroyCategoryOptionsMatching,
      refreshCategoryOptionsMatchingMasterList,
      categoryOptionsMatchingMasterListRefreshed,
      fetchCategoryOptionsMatchings,
      matchingsListRefreshed,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(CategoriesMatchingEditForm)));

import * as types from "../actionTypes";

export function toggleDrawer() {
  return {
    type: types.TOGGLE_DRAWER,
  };
}

export function refreshOrgUnitsMatchingsMasterList() {
  return {
    type: types.REFRESH_ORG_UNITS_MATCHINGS_MASTER_LIST,
  };
}

export function orgUnitsMatchingsMasterListRefreshed() {
  return {
    type: types.ORG_UNITS_MATCHINGS_MASTER_LIST_REFRESHED,
  };
}

export function refreshCategoryOptionsMatchingMasterList() {
  return {
    type: types.REFRESH_CATEGORY_OPTIONS_MATCHING_MASTER_LIST,
  };
}

export function categoryOptionsMatchingMasterListRefreshed() {
  return {
    type: types.CATEGORY_OPTIONS_MATCHING_MASTER_LIST_REFRESHED,
  };
}

export function refreshCategoriesMatchingMasterList() {
  return {
    type: types.REFRESH_CATEGORIES_MATCHING_MASTER_LIST,
  };
}

export function categoriesMatchingMasterListRefreshed() {
  return {
    type: types.CATEGORIES_MATCHING_MASTER_LIST_REFRESHED,
  };
}

export function refreshCategoryOptionCombosMatchingMasterList() {
  return {
    type: types.REFRESH_CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST,
  };
}

export function categoryOptionCombosMatchingMasterListRefreshed() {
  return {
    type: types.CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST_REFRESHED,
  };
}

export function refreshDataElementsMatchingMasterList() {
  return {
    type: types.REFRESH_DATA_ELEMENTS_MATCHING_MASTER_LIST,
  };
}

export function dataElementsMatchingMasterListRefreshed() {
  return {
    type: types.DATA_ELEMENTS_MATCHING_MASTER_LIST_REFRESHED,
  };
}

export function refreshMatchingsList() {
  return {
    type: types.REFRESH_MATCHINGS_LIST,
  };
}

export function matchingsListRefreshed() {
  return {
    type: types.MATCHINGS_LIST_REFRESHED,
  };
}

export function catsMatchingsListRefreshed() {
  return {
    type: types.CATS_MATCHINGS_LIST_REFRESHED,
  };
}

export function cocsMatchingsListRefreshed() {
  return {
    type: types.COCS_MATCHINGS_LIST_REFRESHED,
  };
}

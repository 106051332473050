import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Paper, TextField } from "@material-ui/core";
import MatchingList from "./MatchingList";
import ContextualMenu from "./ContextualMenu";
import { card, paper } from "../../helpers/commonStyles";

const styles = theme => ({
  ...paper(theme),
  ...card(theme),
  fabButton: {
    position: "absolute",
    left: -40,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
  },
  paperTitle: {
    paddingTop: "10px",
    paddingBottom: "18px",
    marginTop: -theme.spacing(4),
    textAlign: "center",
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(1),
    fontWeight: 400,
    fontSize: "80%",
    whiteSpace: "normal",
    textTransform: "uppercase",
  },
});

class UnmatchedLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searched: "",
    };
  }
  handleSearchChange = val => {
    this.setState({ searched: val }, () => {
      this.props.search_mappings(this.state.searched);
    });
  };

  render() {
    const {
      matching: { left: groupLeft },
      itemsLeft,
      itemsLeftRowCount,
      fetchItems,
      itemsLeftFetching,
      selectedItemIndex,
      contextualMenuItemSelected,
      handleItemSelection,
      refreshItems,
      matchingMasterListRefreshed,
      classes,
    } = this.props;

    return (
      <Paper className={classes.paper} style={{ height: 364 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.paperHeader}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              className={classes.paperTitle}
            >
              <Grid xs={4} item>
                Source
              </Grid>
              <Grid xs={8} item>
                <TextField
                  id="standard-search"
                  label="Search"
                  type="search"
                  value={this.state.searched}
                  onChange={e => this.handleSearchChange(e.target.value)}
                />
              </Grid>
            </Grid>
            <ContextualMenu
              classes={classes.paperMenu}
              options={["Auto match", "Copy all"]}
              onItemSelected={contextualMenuItemSelected}
            />
          </Grid>
          <Grid item xs={12}>
            <MatchingList
              items={itemsLeft}
              rowCount={itemsLeftRowCount}
              fetchItems={fetchItems}
              sourceId={groupLeft.source.id}
              handleItemSelection={handleItemSelection}
              selectedItemIndex={selectedItemIndex}
              isFetching={itemsLeftFetching}
              master={true}
              refresh={refreshItems}
              didUpdate={matchingMasterListRefreshed}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(UnmatchedLeft);

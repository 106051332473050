import * as types from "../actionTypes";

function task(
  state = {
    isFetchingSubTask: false,
    isFetchingSinkTask: false,
    isSearchingSinkTask: false,
    entity: "",
    task_sink: "",
    searched_sink: "",
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_SUB_TASK:
      return Object.assign({}, state, {
        isFetchingSubTask: true,
      });
    case types.RECEIVE_SUB_TASK:
      return Object.assign({}, state, {
        entity: action.payload,
        isFetchingSubTask: false,
      });
    case types.REQUEST_SUB_TASK_FAILED:
      return Object.assign({}, state, {
        errors: action.payload.errors,
        isFetchingSubTask: false,
      });
    case types.REQUEST_COMPARE_SINK:
      return Object.assign({}, state, {
        isFetchingSinkTask: true,
      });
    case types.RECEIVE_COMPARE_SINK:
      return Object.assign({}, state, {
        task_sink: action.payload,
        isFetchingSinkTask: false,
      });
    case types.REQUEST_COMPARE_SINK_FAILED:
      return Object.assign({}, state, {
        errors: action.payload,
        isFetchingSinkTask: false,
      });
    case types.REQUEST_SEARCH_SINK:
      return Object.assign({}, state, {
        isSearchingSinkTask: true,
      });
    case types.RECEIVE_SEARCH_SINK:
      return Object.assign({}, state, {
        searched_sink: action.payload,
        isSearchingSinkTask: false,
      });
    case types.REQUEST_SEARCH_SINK_FAILED:
      return Object.assign({}, state, {
        errors: action.payload,
        isSearchingSinkTask: false,
      });
    default:
      return state;
  }
}

export default task;

import React, { Component, Fragment } from "react";

import {
  COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW,
  SYNCHRONIZE_DEG_META_FLOW,
  COMPARE_TASKS_SCHEDULER_PANELS,
} from "../constants";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, FormControl } from "@material-ui/core";
import AutoComplete from "../../Shared/AutoComplete";
import CompareParams from "../../Shared/CompareParams";
import CheckBoxComponent from "../Shared/CheckBoxComponent";
import {
  formStyles,
  expansionPanelStyles,
} from "../../../helpers/commonStyles";

const styles = theme => ({
  ...formStyles(theme),
  ...expansionPanelStyles(theme),
});
export const CATEGORY_COMPARE_PARAMS = [
  {
    key: "new_entities_left",
    tooltip: "uncheck this if you don't want to create new CO on your target",
    label: "Create new COs on target",
  },
  {
    key: "existing_entities_right",
    tooltip:
      "uncheck this if you don't want to create new CO on your target DHIS",
    label: "link existing COs on Target",
  },
  {
    key: "new_entities_right",
    tooltip:
      "Uncheck that if you don't want to remove any CO from your target Category",
    label: "Remove COs from Category target",
  },
  {
    key: "modified_entities",
    tooltip:
      "Uncheck that if you don't want to update any CO on your target DHIS",
    label: "Update COs on target",
  },
  { key: "name", tooltip: "Name", label: "Name" },
  { key: "short_name", tooltip: "Short name", label: "Short Name" },
];

export const CO_COMPARE_PARAMS = [
  { key: "name", tooltip: "Name", label: "Name" },
  { key: "short_name", tooltip: "Short name", label: "Short Name" },
];

class CompareMetaFormCat extends Component {
  constructor(props) {
    super();
    this.state = {
      expanded:
        props.entity.id !== undefined
          ? props.entity.params.side !== undefined
            ? COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class][
                props.entity.params.side
              ]
            : COMPARE_TASKS_SCHEDULER_PANELS[props.entity.sequencer_class]
          : "panel5",
      preview: props.entity.params.preview ? "Preview" : "",
      preview_checkbox: true,
      sequencer_class: COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW,
    };
  }

  componentDidMount() {
    this.props.handleChange(`send_mail`, 3);
  }

  uncheck_co_param = param => {
    if (param === CATEGORY_COMPARE_PARAMS[3].key) {
      this.props.handleParamChange("compare_entities_params", []);
    }
  };

  check_co_param = param => {
    if (param === CATEGORY_COMPARE_PARAMS[3].key) {
      this.props.handleParamChange("compare_entities_params", [
        CO_COMPARE_PARAMS[0].key,
      ]);
    }
  };

  uncheck_cat_param = (params, cat_params) => {
    if (
      params.length === 0 &&
      cat_params.includes(CATEGORY_COMPARE_PARAMS[3].key)
    ) {
      cat_params = cat_params.filter(p => p !== CATEGORY_COMPARE_PARAMS[3].key);
      this.props.handleParamChange("compare_group_params", cat_params);
    }
  };

  check_cat_param = (param, cat_params) => {
    if (!cat_params.includes(CATEGORY_COMPARE_PARAMS[3].key)) {
      cat_params = cat_params.push(CATEGORY_COMPARE_PARAMS[3].key);
    }
  };

  handleCompareCatParamsChange = (param, des_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_group_params;
    if (event.target.checked) {
      this.check_co_param(param);
      params.push(param);
    } else {
      params = params.filter(p => p !== param);
      this.uncheck_co_param(param);
    }
    this.props.handleParamChange("compare_group_params", params);
  };

  handleCompareCosParamsChange = (param, cos_params) => event => {
    let ent = this.props.entity;
    let params = ent.params.compare_entities_params;
    if (event.target.checked) {
      this.check_cat_param(params, cos_params);
      params.push(param);
    } else {
      params = params.filter(p => p !== param);
      this.uncheck_cat_param(params, cos_params);
    }
    this.props.handleParamChange("compare_entities_params", params);
  };

  handleChange = (panel, sequencer_class) => (event, isExpanded) => {
    if (sequencer_class !== COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW) {
      this.props.handleRemoveParam("preview");
      this.setState({
        preview: "",
      });
    }
    this.setState({
      preview_checkbox:
        sequencer_class === COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW
          ? true
          : false,
      expanded: isExpanded ? panel : false,
      sequencer_class: sequencer_class,
    });
  };

  placeholderText = source => {
    return `Search for category combo from ${source}`;
  };

  getValueEdit = (entity, realSide, side) => {
    if (side !== undefined) {
      if (side === entity["params"]["side"]) {
        return entity.params[`${realSide}SourceGroup`];
      } else {
        return undefined;
      }
    } else if (
      this.previewSequencerClass(entity) === entity["sequencer_class"]
    ) {
      return entity.params[`${realSide}SourceGroup`];
    } else {
      return undefined;
    }
  };

  autoComplete = (project, item, side) => {
    return (
      <Grid item xs={6}>
        <AutoComplete
          projectId={project.id}
          sourceId={item[`${side}_source_id`]}
          onChange={e =>
            this.props.handleChanges(
              [
                {
                  prop: "sequencer_class",
                  value: item["sequencer_class"],
                },
              ],
              [
                {
                  prop: item[`${side}_identity`],
                  value: e,
                },
                item["side"] !== undefined
                  ? { prop: "side", value: item["side"] }
                  : {},
              ],
            )
          }
          type="categories"
          instance={item[`${side}_instance`]}
          value={item[`${side}_source`]}
          placeholder={this.placeholderText(item[`${side}_source_name`])}
        />
      </Grid>
    );
  };

  compareParams = (
    label,
    entity,
    group,
    entities,
    handleParamChange,
    params,
  ) => {
    return (
      <FormControl className={this.props.classes.textField}>
        <CompareParams
          label={label}
          group_params={params}
          params={entity.params[`compare_${group}_params`]}
          entities_params={entity.params[`compare_${entities}_params`]}
          handleCompareParamsChange={handleParamChange}
        />
      </FormControl>
    );
  };

  previewChange = (sequencer_class, preview_state, isPreview) => {
    this.setState({ preview: preview_state });
    this.props.handleParamChange("preview", isPreview);
    this.props.handleChangeSequencerClassProp([
      {
        prop: "sequencer_class",
        value: sequencer_class,
      },
    ]);
  };

  handlePreviewChange = event => {
    if (this.state.preview === "") {
      this.previewChange(SYNCHRONIZE_DEG_META_FLOW, "Preview", true);
    } else {
      this.previewChange(COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW, "", false);
    }
  };

  scenariosSequencerClass = () => {
    if (
      this.state.sequencer_class === COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW &&
      this.state.preview !== ""
    ) {
      return SYNCHRONIZE_DEG_META_FLOW;
    } else {
      return COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW;
    }
  };

  previewSequencerClass = entity => {
    if (entity.params.preview) {
      return SYNCHRONIZE_DEG_META_FLOW;
    } else {
      return COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW;
    }
  };

  render() {
    const { project, entity } = this.props;
    const compare_scenarios = [
      {
        label: "DHIS2 AND D2D FULL COMPARISON",
        left_instance: "dhis2",
        right_instance: "dhis2",
        left_source: this.getValueEdit(entity, "left", undefined),
        right_source: this.getValueEdit(entity, "right", undefined),
        left_source_id: this.props.project.left_source.id,
        right_source_id: this.props.project.right_source.id,
        left_identity: "leftSourceGroup",
        right_identity: "rightSourceGroup",
        sequencer_class: this.scenariosSequencerClass(),
        left_source_name: `DHIS2 ${this.props.project.left_source.name}`,
        right_source_name: `DHIS2 ${this.props.project.right_source.name}`,
      },
    ];

    return (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div>
              {compare_scenarios.map((item, index) => {
                return (
                  <Grid container spacing={3}>
                    {this.autoComplete(project, item, "left")}
                    {this.autoComplete(project, item, "right")}
                  </Grid>
                );
              })}
            </div>
          </Grid>

          <Grid item xs={12}>
            {this.compareParams(
              "Category Params",
              entity,
              "group",
              "entities",
              this.handleCompareCatParamsChange,
              CATEGORY_COMPARE_PARAMS,
            )}
            {this.compareParams(
              "Category Options Params",
              entity,
              "entities",
              "group",
              this.handleCompareCosParamsChange,
              CO_COMPARE_PARAMS,
            )}
          </Grid>
          {this.state.preview_checkbox && (
            <Grid item xs={12}>
              <CheckBoxComponent
                preview={this.state.preview}
                checkboxLabel="Apply changes without confirmation :"
                label="Apply without confirmation"
                handlePreviewChange={() => this.handlePreviewChange}
              />
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
});
export default connect(mapStateToProps)(withStyles(styles)(CompareMetaFormCat));

import {
  REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS,
  RECEIVE_DATA_ELEMENT_GROUPS_MATCHINGS,
  REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS_FAILED,
} from "../actionTypes";

function dataElementGroupsMatchings(
  state = {
    entities: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS:
      return Object.assign({}, state, {
        entities: [],
        isFetching: true,
      });
    case RECEIVE_DATA_ELEMENT_GROUPS_MATCHINGS:
      return Object.assign({}, state, {
        entities: action.payload.data,
        isFetching: false,
      });
    case REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        entities: [],
        errors: action.payload.errors,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default dataElementGroupsMatchings;

export const selectDataElementGroupMatching = (degMatchings, degMatchingId) => {
  return degMatchings.find(matching => matching.id === parseInt(degMatchingId, 10));
};

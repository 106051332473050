import * as types from "../actionTypes";

import { authHeader, baseApiUrl } from "./api";

import { RSAA } from "redux-api-middleware";
import _isUndefined from "lodash/isUndefined";
import _omitBy from "lodash/omitBy";
import store from "../store";
import { fetchOrgUnits } from "./orgUnits";
import { stringify } from "query-string";

export function fetchOrgUnitsMatchings(
  programId,
  projectId,
  matchingType,
  matchingId,
  page,
  force = false,
  dangling = false,
) {
  const meta = { meta: { force: force } };

  const queryParams = {
    page: page,
    per_page: 500,
  };
  queryParams[matchingType] = matchingId;
  queryParams["dangling"] = dangling;

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));

  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/organisation_units?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        dangling
          ? types.REQUEST_ORG_UNITS_DANGLINGS
          : types.REQUEST_ORG_UNITS_MATCHINGS,
        {
          type: dangling
            ? types.RECEIVE_ORG_UNITS_DANGLINGS
            : types.RECEIVE_ORG_UNITS_MATCHINGS,
          ...meta,
        },
        dangling
          ? types.REQUEST_ORG_UNITS_DANGLINGS_FAILED
          : types.REQUEST_ORG_UNITS_MATCHINGS_FAILED,
      ],
    },
  };
}

export function createNewOrgUnitsMatching(
  programId,
  projectId,
  payload,
  matchingType,
  matchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/organisation_units`,
      method: "POST",
      body: JSON.stringify(payload),
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CREATE_ORG_UNITS_MATCHING,
        {
          type: types.RECEIVE_CREATE_ORG_UNITS_MATCHING,
          payload: (action, state, response) => {
            return response.json().then(responsePayload => {
              store.dispatch({
                type: types.DESTROY_LOCAL_ORG_UNIT,
                sourceId: payload.first_element.source_id,
                orgUnitId: payload.first_element.id,
              });
              store.dispatch({
                type: types.DESTROY_LOCAL_ORG_UNIT,
                sourceId: payload.second_element.source_id,
                orgUnitId: payload.second_element.id,
              });
              store.dispatch(
                fetchOrgUnitsMatchings(
                  programId,
                  projectId,
                  matchingType,
                  matchingId,
                  1,
                  true,
                ),
              );
              return responsePayload;
            });
          },
        },
        types.REQUEST_CREATE_ORG_UNITS_MATCHING_FAILED,
      ],
    },
  };
}

export function destroyOrgUnitsMatching(
  programId,
  projectId,
  matchingType,
  matchingParam,
  matching,
  ouMatchingId,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/organisation_units/${ouMatchingId}`,
      method: "DELETE",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_DESTROY_ORG_UNITS_MATCHING,
        {
          type: types.RECEIVE_DESTROY_ORG_UNITS_MATCHING,
          payload: (action, state, response) => {
            store.dispatch(
              fetchOrgUnits(
                programId,
                projectId,
                matching.left.source.id,
                matchingType,
                matching.left.id,
                undefined,
                1,
                true,
              ),
            );
            store.dispatch(
              fetchOrgUnitsMatchings(
                programId,
                projectId,
                matchingParam,
                matching.id,
                1,
                true,
              ),
            );
          },
        },
        types.REQUEST_DESTROY_ORG_UNITS_MATCHING_FAILED,
      ],
    },
  };
}

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink, withRouter } from "react-router-dom";
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { leftIcon, paper, fabButton } from "../../helpers/commonStyles";
import AppBarDefault from "../Shared/AppBarDefault";
import LayoutContainer from "../Shared/LayoutContainer";
import SectionSpinner from "../Shared/SectionSpinner";

import ImmediateTaskActions from "./ImmediateTaskActions";
import TaskStatusFactory from "../Tasks/TaskStatusFactory";
import { get } from "../../actions/api";
import { TASK_SHOW_LAYOUTS, TASK_DETAIL_LAYOUTS } from "../Tasks/constants";

const styles = theme => ({
  ...leftIcon(theme),
  ...paper(theme),
  ...fabButton(theme),
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
  },
  iconLeft: {
    marginRight: theme.spacing(),
  },
  iconWithText: {
    display: "flex",
    alignItems: "center",
  },
});

class TaskShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: null,
      timeoutId: null,
    };
    this.getImmediateTask();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
  }

  getImmediateTask() {
    get("task", {
      programId: this.props.currentProgram.id,
      projectId: this.props.project.id,
      id: this.props.taskId,
    }).then(this.handleImmediateTaskResponse.bind(this));
  }

  handleImmediateTaskResponse(task) {
    this.setState({ task: task });
    if ((!task.completed_at && !task.failed_at) || !task.purged_at) {
      this.pollImmediateTask();
    }
  }

  pollImmediateTask() {
    this.setState({
      timeoutId: setTimeout(this.getImmediateTask.bind(this), 2000),
    });
  }

  content() {
    const { project } = this.props;
    const { task } = this.state;
    let Show = TASK_SHOW_LAYOUTS[task["sequencer_class"]];
    let Detail = TASK_DETAIL_LAYOUTS[task["sequencer_class"]];
    return (
      <Fragment>
        <ImmediateTaskActions projectId={project.id} task={task} />
        <List className="mainList">
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <strong>Status:</strong>
              </Grid>
              <Grid item xs={8}>
                <TaskStatusFactory task={task} withLabel={true} />
              </Grid>
            </Grid>
            {task.is_job_running && <CircularProgress />}
          </ListItem>
          <Show task={task} />
        </List>
        <Divider light />
        {!!Detail && <Detail task={task} />}
      </Fragment>
    );
  }

  render() {
    const { classes, project, currentProgram } = this.props;
    return (
      <div>
        <AppBarDefault>
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${currentProgram.id}/projects/${project.id}/tasks`}
          >
            <ArrowBack className={classes.leftIcon} />
            Journal
          </Button>
        </AppBarDefault>
        <LayoutContainer>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              {!this.state.task ? <SectionSpinner /> : this.content()}
            </Paper>
          </Grid>
        </LayoutContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    project: state.programs.currentProject,
    taskId: props.match.params.task_id,
    currentProgram: state.programs.currentProgram,
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(TaskShow)),
);

import React, { Component } from "react";
import { fabButton, helpSection } from "../../helpers/commonStyles";

import AddIcon from "@material-ui/icons/Add";
import {
  TASK_LABELS,
  TASK_PARAMS_LAYOUTS,
  TASK_SUMMARY_LAYOUTS,
  COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW,
  COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW,
  COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW,
  COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW,
  COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW,
  SYNCHRONIZE_DEG_META_FLOW,
  SYNCHRONIZE_OUG_META_FLOW,
  SYNCHRONIZE_DS_META_FLOW,
  SYNCHRONIZE_CC_META_FLOW,
  SYNCHRONIZE_CAT_META_FLOW,
} from "./constants";
import LayoutContainer from "../Shared/LayoutContainer";
import { Link } from "react-router-dom";
import NoDataInfoPage from "../Shared/NoDataInfoPage";
import RemoteTable from "../Shared/RemoteTable";
import TaskRow from "./TaskRow";
import { connect } from "react-redux";
import { fetchTasks, fetchSubTask } from "../../actions/tasks";
import moment from "moment";
import "moment-precise-range-plugin"; //using preciseDiff
import { withRouter } from "react-router";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from "@material-ui/core/Fab";
import AppBarDefault from "../Shared/AppBarDefault";
import SectionSpinner from "../Shared/SectionSpinner";
import ShowResultDialog from "../Shared/ShowResultDialog/ShowResultDialog";
import _some from "lodash/some";

const styles = theme => ({
  ...helpSection(theme),
  ...fabButton(theme),
});

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, results: "", pushData: false };
  }

  componentDidMount() {
    this.props.fetchTasks(
      this.props.currentProgram.id,
      this.props.project.id,
      1,
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.currentProgram.id !== this.props.currentProgram.id ||
      prevProps.project.id !== this.props.project.id
    ) {
      this.props.fetchTasks(
        this.props.currentProgram.id,
        this.props.project.id,
        1,
      );
    }
    if (this.props.isFetchingSubTask !== prevProps.isFetchingSubTask) {
      this.setState({ open: true, results: this.props.task });
    }

    if (this.props.task.context !== prevProps.task.context) {
      this.checkIfPushData();
    }
  }

  checkIfPushData = () => {
    let isValueToPush = false;
    const keysToIgnore = ["deselected_count", "skips_count"];
    isValueToPush = _some(this.props.task.context, (value, key) => {
      return !keysToIgnore.includes(key) && key.includes("_count") && value > 0;
    });

    this.setState({
      pushData: isValueToPush,
    });
  };

  handleChangeRowsPerPage = e => {
    if (e.target.value !== this.props.perPage) {
      this.props.fetchTasks(
        this.props.currentProgram.id,
        this.props.project.id,
        null,
        e.target.value,
      );
    }
  };

  handlePageChange = (e, page) => {
    if (page !== this.props.currentPage) {
      this.props.fetchTasks(
        this.props.currentProgram.id,
        this.props.project.id,
        page + 1, // Compensate Table pagination,
        this.props.perPage,
      );
    }
  };

  handleClickOpen = row => {
    this.setState({ open: true, results: row });
  };

  handleClickOpenPreview = (row, preview_sequencer_class) => {
    this.props.fetchSubTask(
      this.props.currentProgram.id,
      this.props.project.id,
      row.task.id,
      preview_sequencer_class,
    );
  };

  render() {
    const {
      classes,
      match,
      project,
      tasks,
      isFetching,
      currentPage,
      perPage,
      totalCount,
    } = this.props;

    if (isFetching) {
      return <SectionSpinner />;
    }

    if (tasks.length === 0) {
      return (
        <div>
          <AppBarDefault title={"Journal"} />
          <NoDataInfoPage
            type="tasks"
            helpText={
              "A task is an atomic execution of actions dedicated to transfer data or meta-data, compare data or meta-data (,...) between 2 DHIS2. " +
              "Tasks can be automically generated with a scheduler or created manually from here."
            }
          />
        </div>
      );
    }

    const columns = [
      { name: "startedDate", title: "Start" },
      { name: "taskType", title: "Type" },
      { name: "duration", title: "Duration" },
      { name: "params", title: "Params" },
      { name: "summary", title: "Summary" },
      { name: "status", title: "Status" },
      { name: "actions", title: "Actions" },
    ];

    return (
      <div>
        <AppBarDefault title={"Journal"} />
        <LayoutContainer wideContent>
          <RemoteTable
            size="small"
            projectId={project.id}
            isFetching={isFetching}
            rows={tasks}
            columns={columns}
            currentPage={currentPage}
            perPage={perPage}
            totalCount={totalCount}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            cellComponent={TaskRow}
            handleClickOpen={row => this.handleClickOpen(row)}
            handleClickOpenPreview={(row, preview_sequencer_class) =>
              this.handleClickOpenPreview(row, preview_sequencer_class)
            }
          />
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fabButton}
            component={Link}
            to={`${match.url}/new`}
          >
            <AddIcon />
          </Fab>
        </LayoutContainer>
        <ShowResultDialog
          pushData={this.state.pushData}
          open={this.state.open}
          content={this.state.results}
        />
      </div>
    );
  }
}

const dateOrNull = dte => {
  return dte ? moment.utc(dte).calendar() : null;
};

const tasksPresenter = tasks => {
  return tasks.map(task => {
    var Params = TASK_PARAMS_LAYOUTS[task["sequencer_class"]];
    var Summary = TASK_SUMMARY_LAYOUTS[task["sequencer_class"]];
    var time = new Date(null);
    time.setSeconds(task.duration); // specify value of SECONDS
    var hmsTime = time.toISOString().substr(11, 8);
    return {
      startedDate: dateOrNull(task.started_at),
      taskType: `${TASK_LABELS[task["sequencer_class"]]} ${entities_type(
        task,
      )}`,
      duration: hmsTime,
      params: !!Params ? <Params task={task} /> : null,
      summary: !!Summary ? <Summary task={task} /> : null,
      applySequencerClass: applySequencerClass(task["sequencer_class"]),
      task,
    };
  });
};

const applySequencerClass = compareSequencerClass => {
  switch (compareSequencerClass) {
    case COMPARE_OUG_DHIS2_D2D_DHIS2_META_FLOW:
      return SYNCHRONIZE_OUG_META_FLOW;
    case COMPARE_DEG_DHIS2_D2D_DHIS2_META_FLOW:
      return SYNCHRONIZE_DEG_META_FLOW;
    case COMPARE_DS_DHIS2_D2D_DHIS2_META_FLOW:
      return SYNCHRONIZE_DS_META_FLOW;
    case COMPARE_CAT_DHIS2_D2D_DHIS2_META_FLOW:
      return SYNCHRONIZE_CAT_META_FLOW;
    case COMPARE_CC_DHIS2_D2D_DHIS2_META_FLOW:
      return SYNCHRONIZE_CC_META_FLOW;
    default:
  }
};

const entities_type = task => {
  return task["params"]["entities_type"] ? task["params"]["entities_type"] : "";
};

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram || {},
  project: state.programs.currentProject || {},
  tasks: tasksPresenter(state.tasks.entities) || [],
  task: state.task.entity,
  isFetching: state.tasks.isFetching,
  isFetchingSubTask: state.task.isFetchingSubTask,
  currentPage: state.tasks.meta.page - 1, // Table pagination starts at 0,
  perPage: state.tasks.meta.per_page,
  totalCount: state.tasks.meta.total_count,
});

export default withRouter(
  connect(mapStateToProps, { fetchTasks, fetchSubTask })(
    withStyles(styles)(Tasks),
  ),
);

import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBarDefault from "../Shared/AppBarDefault";
import GeneralForm from "./GeneralForm";
import ProcessingDialog from "../Shared/ProcessingDialog";
import { get } from "../../actions/api";
import { connect } from "react-redux";
import { createProject } from "../../actions/projects";
import { leftIcon, formStyles } from "../../helpers/commonStyles";
import history from "../../history";
import store from "../../store";
import * as types from "../../actionTypes";
import _isNil from "lodash/isNil";

const styles = theme => ({
  ...formStyles(theme),
  ...leftIcon(theme),
});

const _newProject = {
  program_id: null,
  name: "",
  emails: [],
  right_source: {
    name: "",
    dhis_user: "",
    dhis_url: "",
    dhis_password: "",
    dhis_version: "",
  },
  left_source: {
    name: "",
    dhis_user: "",
    dhis_url: "",
    dhis_password: "",
    dhis_version: "",
  },
};
class GeneralNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processingDialogOpen: false,
      timeoutId: null,
      flowId: null,
      projectName: null,
      project: { ..._newProject },
    };
    this.submitCreateProject = this.submitCreateProject.bind(this);
  }

  closeProcessingDialog = () => {
    clearTimeout(this.state.timeoutId);
    this.setState({
      processingDialogOpen: false,
    });
    history.push(this.props.doneUrl);
  };

  checkProcessing = () => {
    if (_isNil(this.state.processingErrors)) {
      get("projectImporters", {
        programId: this.props.currentProgram.id,
        projectId: this.state.flowId,
      }).then(flow => {
        if (flow.is_processed) {
          this.closeProcessingDialog();
          history.push(
            `/programs/${this.props.currentProgram.id}/projects/${this.state.flowId}/org_unit_groups_matchings`,
          );
        }
        if (flow.has_errored) {
          this.setState({ processingErrors: flow.latest_errors });
        }
      });
    } else {
      this.closeProcessingDialog();
      history.push(`/programs/${this.props.currentProgram.id}/projects/new`);
    }
  };

  processingDialog = () => {
    const {
      classes: { primaryText, secondaryText },
    } = this.props;
    return (
      <ProcessingDialog
        open={this.state.processingDialogOpen}
        handleClose={this.closeProcessingDialog}
        errors={this.state.processingErrors}
      >
        <Typography className={secondaryText}>
          Creating{" "}
          <strong className={primaryText}> {this.state.projectName} </strong>{" "}
          project
        </Typography>
      </ProcessingDialog>
    );
  };

  delayedCheckProcessing(delayTime) {
    this.setState({
      timeoutId: setInterval(this.checkProcessing, delayTime),
    });
  }

  submitCreateProject(p) {
    this.props.createProject(this.props.currentProgram.id, p).then(payload => {
      if (_isNil(payload.payload.errors)) {
        this.setState(
          {
            processingErrors: null,
            processingDialogOpen: true,
            flowId: payload.payload.project_id,
            projectName: p.project.name,
          },
          this.delayedCheckProcessing(2000),
        );
      } else {
        this.setState(
          {
            processingDialogOpen: true,
            processingErrors: payload.payload.errors[0],
          },
          this.delayedCheckProcessing(4000),
        );
      }

      var projects_array = this.props.currentProgram.projects;
      if (!_isNil(payload.payload.project)) {
        projects_array.push(payload.payload.project);
      }
      store.dispatch({
        type: types.ADD_PROGRAM_PROJECT,
        projects: projects_array,
      });
    });
  }

  componentDidMount() {
    _newProject.program_id =
      this.props.currentProgram !== null ? this.props.currentProgram.id : "";
    this.setState({ project: { ..._newProject } });
  }

  render() {
    const { classes, currentProgram } = this.props;
    return (
      <Fragment>
        <AppBarDefault>
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${
              !!currentProgram ? currentProgram.id : ""
            }/projects`}
          >
            <ArrowBack className={classes.leftIcon} />
            Cancel
          </Button>
        </AppBarDefault>
        {this.processingDialog()}
        <GeneralForm
          project={this.state.project}
          onSubmit={this.submitCreateProject}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
});
export default connect(mapStateToProps, { createProject })(
  withStyles(styles)(GeneralNew),
);

import React, { Component } from "react";

import TaskHashCell from "../TaskHashCell";

class CloneMetaFlowParams extends Component {
  render() {
    const { task } = this.props;
    return (
      <TaskHashCell
        task={[
          [
            {
              label: "Group:",
              value: task.params["left_name"],
            },
          ],
        ]}
      />
    );
  }
}
export default CloneMetaFlowParams;

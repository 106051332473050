import {
  REQUEST_ORG_UNIT_GROUPS_MATCHINGS,
  RECEIVE_ORG_UNIT_GROUPS_MATCHINGS,
  REQUEST_ORG_UNIT_GROUPS_MATCHINGS_FAILED,
} from "../actionTypes";

function orgUnitGroupsMatchings(
  state = {
    entities: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_ORG_UNIT_GROUPS_MATCHINGS:
      return Object.assign({}, state, {
        entities: [],
        isFetching: true,
      });
    case RECEIVE_ORG_UNIT_GROUPS_MATCHINGS:
      return Object.assign({}, state, {
        entities: action.payload.data,
        isFetching: false,
      });
    case REQUEST_ORG_UNIT_GROUPS_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        entities: [],
        errors: action.payload.errors,
        isFetching: false,
      });

    default:
      return state;
  }
}

export default orgUnitGroupsMatchings;

export const selectOrgUnitGroupMatching = (ougMatchings, ougMatchingId) => {
  return ougMatchings.find(matching => matching.id === parseInt(ougMatchingId, 10));
};

import React, { Component } from "react";
import SectionSpinner from "../Shared/SectionSpinner";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import OrgUnitGroupsMatchingPreview from "./OrgUnitGroupsMatchingPreview";
import { Grid, Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import LayoutContainer from "../Shared/LayoutContainer";
import { bindActionCreators } from "redux";
import { fetchOrgUnitGroupsMatchings } from "../../actions/orgUnitGroupsMatchings";
import { fabButton, helpSection } from "../../helpers/commonStyles";
import NoDataInfoPage from "../Shared/NoDataInfoPage";
import AppBarDefault from "../Shared/AppBarDefault";

const styles = theme => ({
  ...helpSection(theme),
  ...fabButton(theme),
});

class OrgUnitGroupsMatchings extends Component {
  componentDidMount() {
    if (this.props.currentProject) {
      this.props.fetchOrgUnitGroupsMatchings(
        this.props.currentProgram.id,
        this.props.currentProject.id,
        false,
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentProject.id !== this.props.currentProject.id) {
      this.props.fetchOrgUnitGroupsMatchings(
        this.props.currentProgram.id,
        this.props.currentProject.id,
        false,
      );
    }
  }

  render() {
    const {
      classes,
      match,
      currentProject,
      orgUnitGroupsMatchings,
      isFetching,
    } = this.props;

    if (isFetching || !currentProject) {
      return <SectionSpinner />;
    }

    if (orgUnitGroupsMatchings.length === 0)
      return (
        <div>
          <AppBarDefault title={"Organisation Unit Groups Matchings"} />
          <NoDataInfoPage
            type="organisation unit groups matchings"
            helpText={
              "An organisation group matching is the link between a organisation group in the source dhis2 and another one in the destination dhis2."
            }
          />
        </div>
      );

    return (
      <div>
        <AppBarDefault title={"Organisation Unit Groups Matchings"} />
        <LayoutContainer>
          {orgUnitGroupsMatchings.length > 0 &&
            orgUnitGroupsMatchings.map(orgUnitGroupsMatching => {
              return (
                <Grid item xs={12} md={6} key={orgUnitGroupsMatching.id}>
                  <OrgUnitGroupsMatchingPreview
                    projectId={this.props.currentProject.id}
                    orgUnitGroupsMatching={orgUnitGroupsMatching}
                  />
                </Grid>
              );
            })}
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fabButton}
            component={Link}
            to={`${match.url}/new`}
          >
            <AddIcon />
          </Fab>
        </LayoutContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  currentProject: state.programs.currentProject,
  orgUnitGroupsMatchings: state.orgUnitGroupsMatchings.entities,
  isFetching: state.orgUnitGroupsMatchings.isFetching,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchOrgUnitGroupsMatchings }, dispatch);
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(OrgUnitGroupsMatchings)),
);

// Ui
// =======

export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const REFRESH_ORG_UNITS_MATCHINGS_MASTER_LIST =
  "REFRESH_ORG_UNITS_MATCHINGS_MASTER_LIST";
export const ORG_UNITS_MATCHINGS_MASTER_LIST_REFRESHED =
  "ORG_UNITS_MATCHINGS_MASTER_LIST_REFRESHED";
export const REFRESH_CATEGORY_OPTIONS_MATCHING_MASTER_LIST =
  "REFRESH_CATEGORY_OPTIONS_MATCHING_MASTER_LIST";
export const CATEGORY_OPTIONS_MATCHING_MASTER_LIST_REFRESHED =
  "CATEGORY_OPTIONS_MATCHING_MASTER_LIST_REFRESHED";
export const REFRESH_DATA_ELEMENTS_MATCHING_MASTER_LIST =
  "REFRESH_DATA_ELEMENTS_MATCHING_MASTER_LIST";
export const DATA_ELEMENTS_MATCHING_MASTER_LIST_REFRESHED =
  "DATA_ELEMENTS_MATCHING_MASTER_LIST_REFRESHED";
export const HIDE_MANUAL_MATCHING = "HIDE_MANUAL_MATCHING";
export const SHOW_MANUAL_MATCHING = "SHOW_MANUAL_MATCHING";
export const REFRESH_MATCHINGS_LIST = "REFRESH_MATCHINGS_LIST";
export const MATCHINGS_LIST_REFRESHED = "MATCHINGS_LIST_REFRESHED";
export const REFRESH_CATS_MATCHINGS_LIST = "REFRESH_CATS_MATCHINGS_LIST";
export const CATS_MATCHINGS_LIST_REFRESHED = "CATS_MATCHINGS_LIST_REFRESHED";
export const REFRESH_COCS_MATCHINGS_LIST = "REFRESH_COCS_MATCHINGS_LIST";
export const COCS_MATCHINGS_LIST_REFRESHED = "COCS_MATCHINGS_LIST_REFRESHED";

// Session
// =======

export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUEST_LOGIN_FAILED = "REQUEST_LOGIN_FAILED";
export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const REQUEST_LOGOUT = "REQUEST_LOGOUT";

// Projects
// =======

// Projects CRUD actions
export const REQUEST_PROJECTS = "REQUEST_PROJECTS";
export const RECEIVE_PROJECTS = "RECEIVE_PROJECTS";
export const REQUEST_PROJECTS_FAILED = "REQUEST_PROJECTS_FAILED";

export const REQUEST_PROJECT = "REQUEST_PROJECT";
export const RECEIVE_PROJECT = "RECEIVE_PROJECT";
export const REQUEST_PROJECT_FAILED = "REQUEST_PROJECT_FAILED";

export const REQUEST_PROJECT_CREATE = "REQUEST_PROJECT_CREATE";
export const RECEIVE_PROJECT_CREATE = "RECEIVE_PROJECT_CREATE";
export const REQUEST_PROJECT_CREATE_FAILED = "REQUEST_PROJECT_CREATE_FAILED";

export const REQUEST_PROJECT_UPDATE = "REQUEST_PROJECT_UPDATE";
export const REQUEST_PROJECT_UPDATE_FAILED = "REQUEST_PROJECT_UPDATE_FAILED";

export const REQUEST_PROJECT_DESTROY = "REQUEST_PROJECT_DESTROY";
export const RECEIVE_PROJECT_DESTROY = "RECEIVE_PROJECT_DESTROY";
export const REQUEST_PROJECT_DESTROY_FAILED = "REQUEST_PROJECT_DESTROY_FAILED";

export const REQUEST_PROJECT_CLONE = "REQUEST_PROJECT_CLONE";
export const RECEIVE_PROJECT_CLONE = "RECEIVE_PROJECT_CLONE";
export const REQUEST_PROJECT_CLONE_FAILED = "REQUEST_PROJECT_CLONE_FAILED";

export const REQUEST_PROJECT_DELETE_ADDRESS = "REQUEST_PROJECT_DELETE_ADDRESS";
export const RECEIVE_PROJECT_ADDRESS_DELETED =
  "RECEIVE_PROJECT_ADDRESS_DELETED";
export const REQUEST_PROJECT_DELETE_ADDRESS_FAILED =
  "REQUEST_PROJECT_DELETE_ADDRESS_FAILED";

export const REQUEST_PROJECT_ADD_ADDRESS = "REQUEST_PROJECT_ADD_ADDRESS";
export const RECEIVE_PROJECT_ADDRESS_ADDED = "RECEIVE_PROJECT_ADDRESS_ADDED";
export const REQUEST_PROJECT_ADD_ADDRESS_FAILED =
  "REQUEST_PROJECT_ADD_ADDRESS_FAILED";

export const REQUEST_PROJECT_UPDATE_S3BUCKET =
  "REQUEST_PROJECT_UPDATE_S3BUCKET";
export const RECEIVE_PROJECT_UPDATE_S3BUCKET =
  "RECEIVE_PROJECT_UPDATE_S3BUCKET";
export const REQUEST_PROJECT_UPDATE_S3BUCKET_FAILED =
  "REQUEST_PROJECT_UPDATE_S3BUCKET_FAILED";
export const REQUEST_PROJECT_RESOLVE_FLAG = "REQUEST_PROJECT_RESOLVE_FLAG";
export const RECEIVE_PROJECT_FLAG_RESOLVED = "RECEIVE_PROJECT_FLAG_RESOLVED";
export const REQUEST_PROJECT_RESOLVE_FLAG_FAILED =
  "REQUEST_PROJECT_RESOLVE_FLAG_FAILED";

export const REQUEST_PROGRAM_PROJECTS = "REQUEST_PROGRAM_PROJECTS";
export const RECEIVE_PROGRAM_PROJECTS = "RECEIVE_PROGRAM_PROJECTS";
export const REQUEST_PROGRAM_PROJECTS_FAILED =
  "REQUEST_PROGRAM_PROJECTS_FAILED";
export const ADD_PROGRAM_PROJECT = "ADD_PROGRAM_PROJECT";
export const DELETE_PROGRAM_PROJECT = "DELETE_PROGRAM_PROJECT";
export const UPDATE_PROGRAM_PROJECT = "UPDATE_PROGRAM_PROJECT";

export const REQUEST_PROGRAM = "REQUEST_PROGRAM";
export const RECEIVE_PROGRAM = "RECEIVE_PROGRAM";
export const REQUEST_PROGRAM_FAILED = "REQUEST_PROGRAM_FAILED";

// Source

export const REQUEST_SOURCE_CONNECTION = "REQUEST_SOURCE_CONNECTION";
export const RECEIVE_SOURCE_CONNECTION = "RECEIVE_SOURCE_CONNECTION";
export const REQUEST_SOURCE_CONNECTION_FAILED =
  "REQUEST_SOURCE_CONNECTION_FAILED";
export const INVALIDATE_SOURCE_CONNECTION = "INVALIDATE_SOURCE_CONNECTION";

// Organisation units
// =======

// name suggestions

export const REQUEST_OU_SUGGESTIONS = "REQUEST_OU_SUGGESTIONS";
export const RECEIVE_OU_SUGGESTIONS = "RECEIVE_OU_SUGGESTIONS";
export const REQUEST_OU_SUGGESTIONS_FAILED = "REQUEST_OU_SUGGESTIONS_FAILED";

// crud actions

export const REQUEST_ORG_UNITS = "REQUEST_ORG_UNITS";
export const RECEIVE_ORG_UNITS = "RECEIVE_ORG_UNITS";
export const REQUEST_ORG_UNITS_FAILED = "REQUEST_ORG_UNITS_FAILED";

// matchings

export const REQUEST_ORG_UNITS_MATCHINGS = "REQUEST_ORG_UNITS_MATCHINGS";
export const RECEIVE_ORG_UNITS_MATCHINGS = "RECEIVE_ORG_UNITS_MATCHINGS";
export const REQUEST_ORG_UNITS_MATCHINGS_FAILED =
  "REQUEST_ORG_UNITS_MATCHINGS_FAILED";

export const REQUEST_ORG_UNITS_DANGLINGS = "REQUEST_ORG_UNITS_DANGLINGS";
export const RECEIVE_ORG_UNITS_DANGLINGS = "RECEIVE_ORG_UNITS_DANGLINGS";
export const REQUEST_ORG_UNITS_DANGLINGS_FAILED =
  "REQUEST_ORG_UNITS_DANGLINGS_FAILED";

export const REQUEST_CREATE_ORG_UNITS_MATCHING =
  "REQUEST_CREATE_ORG_UNITS_MATCHING";
export const RECEIVE_CREATE_ORG_UNITS_MATCHING =
  "RECEIVE_CREATE_ORG_UNITS_MATCHING";
export const REQUEST_CREATE_ORG_UNITS_MATCHING_FAILED =
  "REQUEST_CREATE_ORG_UNITS_MATCHING_FAILED";

export const DESTROY_LOCAL_ORG_UNIT = "DESTROY_LOCAL_ORG_UNIT";

export const REQUEST_DESTROY_ORG_UNITS_MATCHING =
  "REQUEST_DESTROY_ORG_UNITS_MATCHING";
export const RECEIVE_DESTROY_ORG_UNITS_MATCHING =
  "RECEIVE_DESTROY_ORG_UNITS_MATCHING";
export const REQUEST_DESTROY_ORG_UNITS_MATCHING_FAILED =
  "REQUEST_DESTROY_ORG_UNITS_MATCHING_FAILED";

// Organisation unit group
// =======

// name suggestions

export const REQUEST_OUG_SUGGESTIONS = "REQUEST_OUG_SUGGESTIONS";
export const RECEIVE_OUG_SUGGESTIONS = "RECEIVE_OUG_SUGGESTIONS";
export const REQUEST_OUG_SUGGESTIONS_FAILED = "REQUEST_OUG_SUGGESTIONS_FAILED";

// matchings

export const REQUEST_ORG_UNIT_GROUPS_MATCHINGS =
  "REQUEST_ORG_UNIT_GROUPS_MATCHINGS";
export const RECEIVE_ORG_UNIT_GROUPS_MATCHINGS =
  "RECEIVE_ORG_UNIT_GROUPS_MATCHINGS";
export const REQUEST_ORG_UNIT_GROUPS_MATCHINGS_FAILED =
  "REQUEST_ORG_UNIT_GROUPS_MATCHINGS_FAILED";

export const REQUEST_ORG_UNIT_GROUPS_MATCHING =
  "REQUEST_ORG_UNIT_GROUPS_MATCHING";
export const RECEIVE_ORG_UNIT_GROUPS_MATCHING =
  "RECEIVE_ORG_UNIT_GROUPS_MATCHING";
export const REQUEST_ORG_UNIT_GROUPS_MATCHING_FAILED =
  "REQUEST_ORG_UNIT_GROUPS_MATCHING_FAILED";

export const REQUEST_CREATE_ORG_UNIT_GROUPS_MATCHING =
  "REQUEST_CREATE_ORG_UNIT_GROUPS_MATCHING";
export const RECEIVE_CREATE_ORG_UNIT_GROUPS_MATCHING =
  "RECEIVE_CREATE_ORG_UNIT_GROUPS_MATCHING";
export const REQUEST_CREATE_ORG_UNIT_GROUPS_MATCHING_FAILED =
  "REQUEST_CREATE_ORG_UNIT_GROUPS_MATCHING_FAILED";

//Category Options
// =======

//Categories
// =======

// matchings
export const REQUEST_CATEGORIES_MATCHINGS = "REQUEST_CATEGORIES_MATCHINGS";
export const RECEIVE_CATEGORIES_MATCHINGS = "RECEIVE_CATEGORIES_MATCHINGS";
export const REQUEST_CATEGORIES_MATCHINGS_FAILED =
  "REQUEST_CATEGORIES_MATCHINGS_FAILED";

export const REQUEST_CREATE_CATEGORIES_MATCHING =
  "REQUEST_CREATE_CATEGORIES_MATCHING";
export const RECEIVE_CREATE_CATEGORIES_MATCHING =
  "RECEIVE_CREATE_CATEGORIES_MATCHING";
export const REQUEST_CREATE_CATEGORIES_MATCHING_FAILED =
  "REQUEST_CREATE_CATEGORIES_MATCHING_FAILED";

// name suggestions

export const REQUEST_CATEGORY_SUGGESTIONS = "REQUEST_CATEGORY_SUGGESTIONS";
export const RECEIVE_CATEGORY_SUGGESTIONS = "RECEIVE_CATEGORY_SUGGESTIONS";
export const REQUEST_CATEGORY_SUGGESTIONS_FAILED =
  "REQUEST_CATEGORY_SUGGESTIONS_FAILED";

// Category options
// =======

// crud

export const REQUEST_CATEGORY_OPTIONS = "REQUEST_CATEGORY_OPTIONS";
export const RECEIVE_CATEGORY_OPTIONS = "RECEIVE_CATEGORY_OPTIONS";
export const REQUEST_CATEGORY_OPTIONS_FAILED =
  "REQUEST_CATEGORY_OPTIONS_FAILED";

export const DESTROY_LOCAL_CATEGORY_OPTION = "DESTROY_LOCAL_CATEGORY_OPTION";

// matchings
export const REQUEST_CREATE_CATEGORY_OPTIONS_MATCHING =
  "REQUEST_CREATE_CATEGORY_OPTIONS_MATCHING";
export const RECEIVE_CREATE_CATEGORY_OPTIONS_MATCHING =
  "RECEIVE_CREATE_CATEGORY_OPTIONS_MATCHING";
export const REQUEST_CREATE_CATEGORY_OPTIONS_MATCHING_FAILED =
  "REQUEST_CREATE_CATEGORY_OPTIONS_MATCHING_FAILED";
export const REQUEST_DESTROY_CATEGORY_OPTIONS_MATCHING =
  "REQUEST_DESTROY_CATEGORY_OPTIONS_MATCHING";
export const RECEIVE_DESTROY_CATEGORY_OPTIONS_MATCHING =
  "RECEIVE_DESTROY_CATEGORY_OPTIONS_MATCHING";
export const REQUEST_DESTROY_CATEGORY_OPTIONS_MATCHING_FAILED =
  "REQUEST_DESTROY_CATEGORY_OPTIONS_MATCHING_FAILED";
export const REQUEST_CATEGORY_OPTIONS_MATCHINGS =
  "REQUEST_CATEGORY_OPTIONS_MATCHINGS";
export const RECEIVE_CATEGORY_OPTIONS_MATCHINGS =
  "RECEIVE_CATEGORY_OPTIONS_MATCHINGS";
export const REQUEST_CATEGORY_OPTIONS_MATCHINGS_FAILED =
  "REQUEST_CATEGORY_OPTIONS_MATCHINGS_FAILED";

//Category Combos
// =======

// Autocomplete

export const REQUEST_CATEGORY_COMBO_SUGGESTIONS =
  "REQUEST_CATEGORY_COMBO_SUGGESTIONS";
export const RECEIVE_CATEGORY_COMBO_SUGGESTIONS =
  "RECEIVE_CATEGORY_COMBO_SUGGESTIONS";
export const REQUEST_CATEGORY_COMBO_SUGGESTIONS_FAILED =
  "REQUEST_CATEGORY_COMBO_SUGGESTIONS_FAILED";

// matchings
export const REQUEST_CATEGORY_COMBOS_MATCHINGS =
  "REQUEST_CATEGORY_COMBOS_MATCHINGS";
export const RECEIVE_CATEGORY_COMBOS_MATCHINGS =
  "RECEIVE_CATEGORY_COMBOS_MATCHINGS";
export const REQUEST_CATEGORY_COMBOS_MATCHINGS_FAILED =
  "REQUEST_CATEGORY_COMBOS_MATCHINGS_FAILED";

export const REQUEST_CREATE_CATEGORY_COMBOS_MATCHING =
  "REQUEST_CREATE_CATEGORY_COMBOS_MATCHING";
export const RECEIVE_CREATE_CATEGORY_COMBOS_MATCHING =
  "RECEIVE_CREATE_CATEGORY_COMBOS_MATCHING";
export const REQUEST_CREATE_CATEGORY_COMBOS_MATCHING_FAILED =
  "REQUEST_CREATE_CATEGORY_COMBOS_MATCHING_FAILED";

export const REQUEST_CATEGORY_COMBO_CATEGORY_OPTIONS =
  "REQUEST_CATEGORY_COMBO_CATEGORY_OPTIONS";
export const RECEIVE_CATEGORY_COMBO_CATEGORY_OPTIONS =
  "RECEIVE_CATEGORY_COMBO_CATEGORY_OPTIONS";
export const REQUEST_CATEGORY_COMBO_CATEGORY_OPTIONS_FAILED =
  "REQUEST_CATEGORY_COMBO_CATEGORY_OPTIONS_FAILED";
// Category Combo categories
// =======

// crud

export const REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS =
  "REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS";
export const RECEIVE_CATEGORY_COMBO_CATEGORIES_MATCHINGS =
  "RECEIVE_CATEGORY_COMBO_CATEGORIES_MATCHINGS";
export const REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS_FAILED =
  "REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS_FAILED";

export const REQUEST_CATEGORY_COMBO_CATEGORY_OPTION_COMBOS_MATCHINGS =
  "REQUEST_CATEGORY_COMBO_CATEGORY_OPTION_COMBOS_MATCHINGS";
export const RECEIVE_CATEGORY_COMBO_CATEGORY_OPTION_COMBOS_MATCHINGS =
  "RECEIVE_CATEGORY_COMBO_CATEGORY_OPTION_COMBOS_MATCHINGS";
export const REQUEST_CATEGORY_COMBO_CATEGORY_OPTION_COMBOS_MATCHINGS_FAILED =
  "REQUEST_CATEGORY_COMBO_CATEGORY_OPTION_COMBOS_MATCHINGS_FAILED";

export const DESTROY_LOCAL_CATEGORY_COMBO_CATEGORY =
  "DESTROY_LOCAL_CATEGORY_COMBO_CATEGORY";

// matchings

export const REFRESH_CATEGORIES_MATCHING_MASTER_LIST =
  "REFRESH_CATEGORIES_MATCHING_MASTER_LIST";
export const CATEGORIES_MATCHING_MASTER_LIST_REFRESHED =
  "CATEGORIES_MATCHING_MASTER_LIST_REFRESHED";

// Categories
// ========
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES";
export const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";
export const REQUEST_CATEGORIES_FAILED = "REQUEST_CATEGORIES_FAILED";

// Category Combo category option combos
// ========

// crud

export const REQUEST_CATEGORY_OPTION_COMBOS = "REQUEST_CATEGORY_OPTION_COMBOS";
export const RECEIVE_CATEGORY_OPTION_COMBOS = "RECEIVE_CATEGORY_OPTION_COMBOS";
export const REQUEST_CATEGORY_OPTION_COMBOS_FAILED =
  "REQUEST_CATEGORY_OPTION_COMBOS_FAILED";

export const DESTROY_LOCAL_CATEGORY_OPTION_COMBO =
  "DESTROY_LOCAL_CATEGORY_OPTION_COMBO";

// matchings
export const REQUEST_CREATE_CATEGORY_OPTION_COMBOS_MATCHING =
  "REQUEST_CREATE_CATEGORY_OPTION_COMBOS_MATCHING";
export const RECEIVE_CREATE_CATEGORY_OPTION_COMBOS_MATCHING =
  "RECEIVE_CREATE_CATEGORY_OPTION_COMBOS_MATCHING";
export const REQUEST_CREATE_CATEGORY_OPTION_COMBOS_MATCHING_FAILED =
  "REQUEST_CREATE_CATEGORY_OPTION_COMBOS_MATCHING_FAILED";
export const REQUEST_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING =
  "REQUEST_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING";
export const RECEIVE_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING =
  "RECEIVE_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING";
export const REQUEST_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING_FAILED =
  "REQUEST_DESTROY_CATEGORY_OPTION_COMBOS_MATCHING_FAILED";
export const REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS =
  "REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS";
export const RECEIVE_CATEGORY_OPTION_COMBOS_MATCHINGS =
  "RECEIVE_CATEGORY_OPTION_COMBOS_MATCHINGS";
export const REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS_FAILED =
  "REQUEST_CATEGORY_OPTION_COMBOS_MATCHINGS_FAILED";

// Notifications
// =======

export const ADD_ERROR_NOTIFICATIONS = "ADD_ERROR_NOTIFICATIONS";
export const REMOVE_NOTIFICATIONS = "REMOVE_NOTIFICATIONS";

// Data elements group
// =======

// matchings

export const REQUEST_CREATE_DATA_ELEMENT_GROUP_MATCHING =
  "REQUEST_CREATE_DATA_ELEMENT_GROUP_MATCHING";
export const RECEIVE_CREATE_DATA_ELEMENT_GROUP_MATCHING =
  "RECEIVE_CREATE_DATA_ELEMENT_GROUP_MATCHING";
export const REQUEST_CREATE_DATA_ELEMENT_GROUP_MATCHING_FAILED =
  "REQUEST_CREATE_DATA_ELEMENT_GROUP_MATCHING_FAILED";

export const REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS =
  "REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS";
export const RECEIVE_DATA_ELEMENT_GROUPS_MATCHINGS =
  "RECEIVE_DATA_ELEMENT_GROUPS_MATCHINGS";
export const REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS_FAILED =
  "REQUEST_DATA_ELEMENT_GROUPS_MATCHINGS_FAILED";

// name suggestions

export const REQUEST_DEG_SUGGESTIONS = "REQUEST_DEG_SUGGESTIONS";
export const RECEIVE_DEG_SUGGESTIONS = "RECEIVE_DEG_SUGGESTIONS";
export const REQUEST_DEG_SUGGESTIONS_FAILED = "REQUEST_DEG_SUGGESTIONS_FAILED";

// Data elements
// =======

// name suggestions

export const REQUEST_DE_SUGGESTIONS = "REQUEST_DE_SUGGESTIONS";
export const RECEIVE_DE_SUGGESTIONS = "RECEIVE_DE_SUGGESTIONS";
export const REQUEST_DE_SUGGESTIONS_FAILED = "REQUEST_DE_SUGGESTIONS_FAILED";

// crud

export const REQUEST_DATA_ELEMENTS = "REQUEST_DATA_ELEMENTS";
export const RECEIVE_DATA_ELEMENTS = "RECEIVE_DATA_ELEMENTS";
export const REQUEST_DATA_ELEMENTS_FAILED = "REQUEST_DATA_ELEMENTS_FAILED";

export const DESTROY_LOCAL_DATA_ELEMENT = "DESTROY_LOCAL_DATA_ELEMENT";

// matchings

export const REQUEST_DATA_ELEMENTS_MATCHINGS =
  "REQUEST_DATA_ELEMENTS_MATCHINGS";
export const RECEIVE_DATA_ELEMENTS_MATCHINGS =
  "RECEIVE_DATA_ELEMENTS_MATCHINGS";
export const REQUEST_DATA_ELEMENTS_MATCHINGS_FAILED =
  "REQUEST_DATA_ELEMENTS_MATCHINGS_FAILED";

export const REQUEST_CREATE_DATA_ELEMENTS_MATCHING =
  "REQUEST_CREATE_DATA_ELEMENTS_MATCHING";
export const RECEIVE_CREATE_DATA_ELEMENTS_MATCHING =
  "RECEIVE_CREATE_DATA_ELEMENTS_MATCHING";
export const REQUEST_CREATE_DATA_ELEMENTS_MATCHING_FAILED =
  "REQUEST_CREATE_DATA_ELEMENTS_MATCHING_FAILED";

export const REQUEST_DESTROY_DATA_ELEMENTS_MATCHING =
  "REQUEST_DESTROY_DATA_ELEMENTS_MATCHING";
export const RECEIVE_DESTROY_DATA_ELEMENTS_MATCHING =
  "RECEIVE_DESTROY_DATA_ELEMENTS_MATCHING";
export const REQUEST_DESTROY_DATA_ELEMENTS_MATCHING_FAILED =
  "REQUEST_DESTROY_DATA_ELEMENTS_MATCHING_FAILED";

// Data Sets
// =========

export const REQUEST_DATA_SETS_MATCHINGS = "REQUEST_DATA_SETS_MATCHINGS";
export const RECEIVE_DATA_SETS_MATCHINGS = "RECEIVE_DATA_SETS_MATCHINGS";
export const REQUEST_DATA_SETS_MATCHINGS_FAILED =
  "REQUEST_DATA_SETS_MATCHINGS_FAILED";

// Crud actions
export const REQUEST_CREATE_DATA_SET_MATCHING =
  "REQUEST_CREATE_DATA_SET_MATCHING";
export const RECEIVE_CREATE_DATA_SET_MATCHING =
  "RECEIVE_CREATE_DATA_SET_MATCHING";
export const REQUEST_CREATE_DATA_SET_MATCHING_FAILED =
  "REQUEST_CREATE_DATA_SET_MATCHING_FAILED";

// name suggestions

export const REQUEST_DATA_SET_SUGGESTIONS = "REQUEST_DATA_SET_SUGGESTIONS";
export const RECEIVE_DATA_SET_SUGGESTIONS = "RECEIVE_DATA_SET_SUGGESTIONS";
export const REQUEST_DATA_SET_SUGGESTIONS_FAILED =
  "REQUEST_DATA_SET_SUGGESTIONS_FAILED";

// Push tasks
// =======

// Crud actions

export const REQUEST_PUSH_TASK_CREATE = "REQUEST_PUSH_TASK_CREATE";
export const RECEIVE_PUSH_TASK = "RECEIVE_PUSH_TASK";
export const REQUEST_PUSH_TASK_CREATE_FAILED =
  "REQUEST_PUSH_TASK_CREATE_FAILED";

export const REQUEST_PUSH_TASKS = "REQUEST_PUSH_TASKS";
export const RECEIVE_PUSH_TASKS = "RECEIVE_PUSH_TASKS";
export const REQUEST_PUSH_TASKS_FAILED = "REQUEST_PUSH_TASKS_FAILED";

export const REQUEST_SUB_TASK = "REQUEST_SUB_TASK";
export const RECEIVE_SUB_TASK = "RECEIVE_SUB_TASK";
export const REQUEST_SUB_TASK_FAILED = "REQUEST_SUB_TASK_FAILED";

export const REQUEST_COMPARE_SINK = "REQUEST_COMPARE_SINK";
export const RECEIVE_COMPARE_SINK = "RECEIVE_COMPARE_SINK";
export const REQUEST_COMPARE_SINK_FAILED = "REQUEST_COMPARE_SINK_FAILED";

export const REQUEST_SEARCH_SINK = "REQUEST_SEARCH_SINK";
export const RECEIVE_SEARCH_SINK = "RECEIVE_SEARCH_SINK";
export const REQUEST_SEARCH_SINK_FAILED = "REQUEST_SEARCH_SINK_FAILED";

// Schedulers
// =======

// Crud actions

export const REQUEST_SCHEDULER_CREATE_OR_UPDATE =
  "REQUEST_SCHEDULER_CREATE_OR_UPDATE";
export const RECEIVE_SCHEDULER = "RECEIVE_SCHEDULER";
export const REQUEST_SCHEDULER_CREATE_OR_UPDATE_FAILED =
  "REQUEST_SCHEDULER_CREATE_FAILED";

export const REQUEST_SCHEDULERS = "REQUEST_SCHEDULERS";
export const RECEIVE_SCHEDULERS = "RECEIVE_SCHEDULERS";
export const REQUEST_SCHEDULERS_FAILED = "REQUEST_SCHEDULERS_FAILED";

export const REQUEST_SCHEDULER = "REQUEST_SCHEDULER";
export const REQUEST_SCHEDULER_FAILED = "REQUEST_SCHEDULER_FAILED";

// Category option combos
// =======

// Matchings

export const REFRESH_CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST =
  "REFRESH_CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST";
export const CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST_REFRESHED =
  "CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST_REFRESHED";

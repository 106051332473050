import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

function PreviewDetailsContent(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
    },
  }));

  const title = {
    news: "New data",
    dels: "Deleted data",
    diffs: "Data differences",
  };

  const classes = useStyles();
  const { rows } = props;
  const sink_keys = rows.data === undefined ? [] : Object.keys(rows.data);
  return (
    <Fragment className={classes.root}>
      {sink_keys.length > 0
        ? sink_keys.map(key => {
            return rows.data[key].length > 0 ? (
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        <b>{title[key]}</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {rows.headers.map(key => {
                        return <TableCell align="left">{key}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.data[key].map(row => (
                      <TableRow>
                        {rows.keys.map(item => {
                          return "value" in item ? (
                            <TableCell align="left">
                              {`${row[item.value]} `}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {`${row[item.name]} `}
                              <b>{`[${row[item.id]}]`}</b>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>
                <strong>{`No ${title[key]}`}</strong>
                <br />
                <Divider />
              </div>
            );
          })
        : ""}
    </Fragment>
  );
}
export default PreviewDetailsContent;

import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class ImportMetaFlowParams extends Component {
  render() {
    const { task } = this.props;
    return (
      <TaskHashCell
        task={[
          !!task.params["side"]
            ? [{ label: "Side", value: task.params["side"] }]
            : [],
          [
            {
              label: "Meta",
              value: `${task.params["name"]}`,
            },
          ],
        ]}
      />
    );
  }
}
export default ImportMetaFlowParams;

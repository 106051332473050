import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchScheduler } from "../../actions/schedulers";
import SectionSpinner from "../Shared/SectionSpinner";
import SchedulerForm from "./SchedulerForm";

class SchedulerEdit extends Component {
  state = {};

  componentDidMount() {
    this.props.fetchScheduler(
      this.props.currentProgram.id,
      this.props.project.id,
      this.props.schedulerId,
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.schedulerId !== this.props.schedulerId) {
      this.props.fetchScheduler(
        this.props.currentProgram.id,
        this.props.project.id,
        this.props.schedulerId,
      );
    }
  }

  render() {
    const { project, scheduler, isFetching } = this.props;
    if (!!isFetching) {
      return <SectionSpinner />;
    }
    return <SchedulerForm projectId={project.id} scheduler={scheduler} />;
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  schedulerId: props.match.params.scheduler_id,
  scheduler: state.schedulers.entity,
  isFetching: state.schedulers.isFetching,
});

export default connect(mapStateToProps, { fetchScheduler })(SchedulerEdit);

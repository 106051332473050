import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Chip, Box, Tooltip } from "@material-ui/core";
import { TASK_LABELS } from "../../Tasks/constants";
import parse from "html-react-parser";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import _chunk from "lodash/chunk";
import { post } from "../../../actions/api";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchPreviewSink, searchPreviewSink } from "../../../actions/tasks";
import { applyChanges } from "../../../actions/tasks";
import history from "../../../history";
import ExpansionPanelContent from "./ExpansionPanelContent";
import _debounce from "lodash/debounce";
import { DEBOUNCED_TIME } from "../../Tasks/constants";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ShowResultDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { open: props.open, sink_content: "", anchorEl: null };
  }

  componentWillReceiveProps({ open }) {
    this.setState({ ...this.state, open });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isFetchingSinkTask !== prevProps.isFetchingSinkTask) {
      this.setState({ sink_content: this.props.task_sink });
    }

    if (this.props.isSearchingSinkTask !== prevProps.isSearchingSinkTask) {
      this.setState({
        sink_content: this.props.searched_sink,
      });
    }
  }

  handleClick = () => event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  taskAction = (programId, projectId, task, action, callback) => {
    post(action, {
      programId: programId,
      projectId: projectId,
      id: task,
    }).then(callback);
  };

  afterAction = (programId, projectId) => {
    history.push(`/programs/${programId}/projects/${projectId}/tasks`);
  };

  afterActionPushData = (programId, projectId, task_id) => {
    history.push(
      `/programs/${programId}/projects/${projectId}/tasks/${task_id}/show`,
    );
  };

  debounceSearchPreviewSink = _debounce(
    this.props.searchPreviewSink,
    DEBOUNCED_TIME,
  );

  displayValues = (context, item, deselected_names) => {
    if (!deselected_names.includes(item)) {
      return context;
    }
  };

  displayValuesTooltip = (context, item) => {
    return context[item].map(ite => {
      return this.chipItem(context, item, ite);
    });
  };

  toolTipToDisplay = (context, item, value) => {
    return (
      <React.Fragment>
        <b>Name : </b>
        {` ${
          context[item + "_name"][value]["name"]
            ? context[item + "_name"][value]["name"]
            : context[item + "_name"][value]
        }`}{" "}
        <br />
        <b>{context[item + "_name"][value]["cc_name"] && "CC name : "}</b>
        {context[item + "_name"][value]["cc_name"] &&
          `${context[item + "_name"][value]["cc_name"]}`}
      </React.Fragment>
    );
  };

  chipItem = (context, item, value) => {
    if (this.deselected_items().includes(item)) {
      let chipToreturn = "";
      if (context[item + "_name"]) {
        chipToreturn = (
          <Tooltip title={this.toolTipToDisplay(context, item, value)}>
            <Chip key={value} label={value} />
          </Tooltip>
        );
      } else {
        chipToreturn = <Chip key={value} label={value} />;
      }
      return chipToreturn;
    } else {
      return <Chip key={value} label={value} />;
    }
  };

  deselected_names = () => {
    return [
      "deselected_des_name",
      "deselected_ous_name",
      "deselected_cocs_name",
      "unmatched_des_name",
      "unmatched_ous_name",
      "unmatched_cocs_name",
    ];
  };

  deselected_items = () => {
    return [
      "deselected_des",
      "deselected_ous",
      "deselected_cocs",
      "unmatched_des",
      "unmatched_ous",
      "unmatched_cocs",
    ];
  };

  chipItemValue = (context, item) => {
    return Array.isArray(context[item])
      ? context[item].length === 0
        ? 0
        : this.displayValuesTooltip(context, item)
      : context[item] === "" || context[item] === undefined
      ? 0
      : this.displayValues(context[item], item, this.deselected_names());
  };

  labelItem = (content, item) => {
    if (!this.deselected_names().includes(item)) {
      return `${content["keys_label"][item]} :`;
    }
  };

  contentDisplay = (content, summaries) => {
    return (
      <Fragment>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {_chunk(Object.keys(content["context"]), 3).map(items => {
              return (
                <TableRow>
                  {items.map(item => {
                    return (
                      <Fragment>
                        <TableCell align="left">
                          <b>{this.labelItem(content, item)}</b>
                        </TableCell>
                        <TableCell align="left">
                          {this.chipItemValue(content["context"], item)}
                        </TableCell>
                      </Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <ExpansionPanelContent
          summaries={content.summaries}
          project_id={content.project_id}
          programId={this.props.currentProgram.id}
          task_id={content.id}
          sink_content={this.state.sink_content}
          fetchPreviewSink={this.props.fetchPreviewSink}
          searchPreviewSink={this.debounceSearchPreviewSink}
        />
      </Fragment>
    );
  };

  render() {
    const { content, summaries, currentProgram, pushData } = this.props;

    return (
      content !== "" && (
        <div>
          <Dialog
            onClose={() => {
              this.handleClose();
            }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            open={this.state.open}
            scroll="paper"
            maxWidth="lg"
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={() => {
                this.handleClose();
              }}
            >
              {`${
                TASK_LABELS[
                  content.task !== undefined
                    ? content.task.sequencer_class
                    : content.sequencer_class
                ]
              } `}
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                {content.task !== undefined
                  ? parse(content.task.email_result)
                  : this.contentDisplay(content, summaries)}
              </Typography>
            </DialogContent>
            <DialogActions>
              <div style={{ width: "100%" }}>
                <Box display="flex">
                  <Box flexGrow={!pushData === true ? 0 : 1}>
                    {content.task === undefined && pushData === true && (
                      <Button
                        onClick={() =>
                          this.taskAction(
                            currentProgram.id,
                            this.props.project.id,

                            this.props.content.task_id,

                            "relaunchWaiting",

                            () => {
                              this.afterActionPushData(
                                currentProgram.id,
                                this.props.project.id,
                                this.props.content.task_id,
                              );
                              this.setState({ open: false });
                            },
                          )
                        }
                        autoFocus
                        component="span"
                        color="primary"
                        variant="outlined"
                      >
                        Push data
                      </Button>
                    )}

                    {content.task !== undefined &&
                      content.task["has_failed"] !== true &&
                      content.task["summary"]["discrepancies"] !== undefined &&
                      content.task["summary"]["discrepancies"] !== 0 &&
                      content.task["summary"]["applied_changes"] ===
                        undefined && (
                        <Button
                          onClick={() =>
                            this.props
                              .applyChanges(
                                currentProgram.id,
                                this.props.project.id,
                                content.task.id,
                                {
                                  task: {
                                    params: {
                                      leftSourceGroup:
                                        content.task.params.leftSourceGroup,
                                      rightSourceGroup:
                                        content.task.params.rightSourceGroup,
                                      compare_entities_params:
                                        content.task.params
                                          .compare_entities_params,
                                      compare_group_params:
                                        content.task.params
                                          .compare_group_params,
                                      compare_entities_ous_params:
                                        content.task.params
                                          .compare_entities_ous_params,
                                      apply: true,
                                      compare_id: "compare_task_id",
                                      left_fetch_id: "left_fetch_task_id",
                                      right_fetch_id: "right_fetch_task_id",
                                    },
                                    sequencer_class:
                                      content.applySequencerClass,
                                    send_mail: 3,
                                  },
                                },
                                false,
                              )
                              .then(taskImport => {
                                this.afterAction(
                                  currentProgram.id,
                                  this.props.project.id,
                                );

                                this.setState({ open: false });
                              })
                              .then(() => {
                                window.location.reload(false);
                              })
                          }
                          autoFocus
                          component="span"
                          color="primary"
                          variant="outlined"
                        >
                          Apply differences
                        </Button>
                      )}
                  </Box>
                  <Box flexGrow={1}>
                    {content.task === undefined && (
                      <Button
                        onClick={() =>
                          this.taskAction(
                            currentProgram.id,
                            this.props.project.id,
                            this.props.content.task_id,
                            "stopWaiting",
                            () => {
                              this.afterAction(
                                currentProgram.id,
                                this.props.project.id,
                              );
                              this.setState({ open: false });
                              this.setState({ anchorEl: null });
                            },
                          )
                        }
                        autoFocus
                        component="span"
                        variant="outlined"
                      >
                        Stop processing
                      </Button>
                    )}
                  </Box>
                  <Box>
                    <Button
                      autoFocus
                      onClick={() => {
                        this.handleClose();
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      )
    );
  }
}
const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
  task_sink: state.task.task_sink,
  searched_sink: state.task.searched_sink,
  task: state.task,
  isFetchingSinkTask: state.task.isFetchingSinkTask,
  isSearchingSinkTask: state.task.isSearchingSinkTask,
});

export default withRouter(
  connect(mapStateToProps, {
    fetchPreviewSink,
    searchPreviewSink,
    applyChanges,
  })(withStyles(styles)(ShowResultDialog)),
);

import React, { Component, Fragment } from "react";
import { InfiniteLoader, List, AutoSizer } from "react-virtualized";
import { Paper, Grid, TextField } from "@material-ui/core";
import MatchingLineItem from "../Shared/MatchingLineItem";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import SectionSpinner from "../Shared/SectionSpinner";
import SectionHeader from "../Shared/SectionHeader";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

const styles = theme => ({
  root: {
    margin: `${theme.spacing(2)}px 0`,
    position: "relative",
  },
  paperTitle: {
    paddingTop: "18px",
    paddingBottom: "18px",
    marginTop: -theme.spacing(),
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    fontWeight: 400,
    fontSize: "80%",
    whiteSpace: "normal",
  },
});

class SearcheableMatchingTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      pageLoaded: 1,
      left_searched: "",
      right_searched: "",
      searchedItems: [],
      searchedItemsCount: 0,
      searchParams: "",
    };
  }

  componentDidMount() {
    this.loadData(true);
    this.setState((state, props) => ({
      searchedItems: props.list,
      searchedItemsCount: props.rowCount,
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_isEqual(prevProps.list, this.props.list)) {
      this.setState((state, props) => ({
        searchedItems: props.list,
        searchedItemsCount: props.rowCount,
      }));
    }
    if (!_isEqual(prevState.searchParams, this.state.searchParams)) {
      if (this.state.searchParams) {
        this.setState((state, props) => ({
          searchedItems: state.searchedItems,
          searchedItemsCount: state.searchedItemsCount,
        }));
      } else {
        this.setState((state, props) => ({
          searchedItems: props.list,
          searchedItemsCount: props.rowCount,
        }));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.list && this.list.forceUpdateGrid();
    if (nextProps.refreshMatchings) {
      this.props.matchingsListRefreshed();
      this.loadData(false);
    }
  }

  isRowLoaded = ({ index }) => {
    return !!this.props.list[index];
  };

  loadData(force) {
    this.props.fetchMetaMachings(this.state.pageLoaded, force);
  }

  loadMoreRows = () => {
    const page = this.state.pageLoaded;
    this.setState(
      {
        pageLoaded: page + 1,
      },
      () => this.loadData(),
    );
  };

  rowRenderer = ({ key, index, style }) => (
    <MatchingLineItem
      key={key}
      index={index}
      style={style}
      matching={this.state.searchedItems[index]}
      destroyMatching={this.props.destroyMatching}
      createMatching={this.props.createMatching}
    />
  );

  getSearchedItems = (val, side) => {
    const searched =
      val === ""
        ? []
        : this.props.list.filter(function(el) {
            return (
              el[side]["name"].toLowerCase().includes(val.toLowerCase()) ||
              el[side]["dhis_id"].toLowerCase().includes(val.toLowerCase())
            );
          });
    this.setState({
      searchedItems: searched,
      searchedItemsCount: searched.length,
      searchParams: val,
    });
  };

  handleSearchChange = (val, side) => {
    this.getSearchedItems(val, side);
    side === "left"
      ? this.setState({ left_searched: val })
      : this.setState({ right_searched: val });
  };

  render() {
    const { classes } = this.props;
    if (!this.state.searchedItemsCount && this.props.isFetching) {
      return <SectionSpinner />;
    }

    return (
      <Fragment>
        <SectionHeader>
          {this.props.sectionHeaderLabel} ({this.state.searchedItemsCount})
        </SectionHeader>
        <Paper className={this.props.classes.root}>
          <Grid container className={classes.paperTitle}>
            <Grid item xs={6}>
              <TextField
                id="standard-search"
                label="Left search"
                type="search"
                value={this.state.left_searched}
                onChange={e => this.handleSearchChange(e.target.value, "left")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="standard-search"
                label="Right search"
                type="search"
                value={this.state.right_searched}
                onChange={e => this.handleSearchChange(e.target.value, "right")}
              />
            </Grid>
          </Grid>
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={this.state.searchedItemsCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    height={400}
                    onRowsRendered={onRowsRendered}
                    ref={ref => (this.list = ref)}
                    rowCount={this.state.searchedItemsCount}
                    rowHeight={80}
                    rowRenderer={this.rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </Paper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (!!props.dangling) {
    return {
      programId: state.programs.currentProgram.id,
      list: state[props.metaTypeMatching].danglingEntities,
      rowCount:
        (!_isEmpty(state[props.metaTypeMatching].dangling) &&
          state[props.metaTypeMatching].dangling.total_count) ||
        0,
      isFetching: state[props.metaTypeMatching].danglingIsFetching,
      refreshMatchings: state.ui.refreshMatchings,
    };
  } else {
    return {
      programId: state.programs.currentProgram.id,
      list: state[props.metaTypeMatching].entities,
      rowCount:
        (!_isEmpty(state[props.metaTypeMatching].meta) &&
          state[props.metaTypeMatching].meta.total_count) ||
        0,
      isFetching: state[props.metaTypeMatching].isFetching,
      refreshMatchings: state.ui.refreshMatchings,
    };
  }
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(SearcheableMatchingTable));

import React from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button, TextField } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import NewMatchingFormMeta from "../Shared/NewMatchingFormMeta";
import { connect } from "react-redux";
import { createNewOrgUnitGroupMatching } from "../../actions/orgUnitGroupsMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

function OrgUnitGroupsMatchingNew(props) {
  const { classes, project, currentProgram } = props;

  return (
    <div>
      <AppBarDefault title="New Organisation Unit Group matching">
        <Button
          color="secondary"
          aria-label="ArrowBack"
          component={NavLink}
          to={`/programs/${
            !!currentProgram ? currentProgram.id : ""
          }/projects/${project.id}/org_unit_groups_matchings`}
        >
          <ArrowBack className={classes.leftIcon} />
          Organisation Unit Group matchings
        </Button>
      </AppBarDefault>
      <NewMatchingFormMeta
        project={project}
        currentProgram={currentProgram}
        onSubmit={props.createNewOrgUnitGroupMatching}
        type="organisation_unit_groups"
        groupName="Organisation Unit Group"
        childrenName="Organisation Units"
        flowApi="orgUnitGroupFlows"
        doneUrl={`/programs/${
          !!currentProgram ? currentProgram.id : ""
        }/projects/${project.id}/org_unit_groups_matchings`}
        cloneOptions={[
          <TextField
            key="skipLevel"
            name="skip_level"
            label="Level to skip"
            type="number"
            fullWidth
            helperText="Level of the org unit hierarchy to skip (root = 1 and cannot be skipped)"
          />,
        ]}
      />
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createNewOrgUnitGroupMatching })(
  withStyles(styles)(OrgUnitGroupsMatchingNew),
);

import * as types from "../actionTypes";

function ui(
  state = {
    showDrawer: false,
    refreshMatchings: false,
    refreshCatsMatchings: false,
    refreshCocsMatchings: false,
  },
  action,
) {
  switch (action.type) {
    case types.REFRESH_DATA_ELEMENTS_MATCHING_MASTER_LIST:
      return Object.assign({}, state, {
        refreshDataElementsMatchingMasterList: true,
      });
    case types.DATA_ELEMENTS_MATCHING_MASTER_LIST_REFRESHED:
      return Object.assign({}, state, {
        refreshDataElementsMatchingMasterList: false,
      });
    case types.REFRESH_ORG_UNITS_MATCHINGS_MASTER_LIST:
      return Object.assign({}, state, {
        refreshOrgUnitsMatchingsMasterList: true,
      });
    case types.ORG_UNITS_MATCHINGS_MASTER_LIST_REFRESHED:
      return Object.assign({}, state, {
        refreshOrgUnitsMatchingsMasterList: false,
      });
    case types.REFRESH_CATEGORY_OPTIONS_MATCHING_MASTER_LIST:
      return Object.assign({}, state, {
        refreshCategoryOptionsMatchingMasterList: true,
      });
    case types.CATEGORY_OPTIONS_MATCHING_MASTER_LIST_REFRESHED:
      return Object.assign({}, state, {
        refreshCategoryOptionsMatchingMasterList: false,
      });
    case types.REFRESH_CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST:
      return Object.assign({}, state, {
        refreshCategoryOptionCombosMatchingMasterList: true,
      });
    case types.CATEGORY_OPTION_COMBOS_MATCHING_MASTER_LIST_REFRESHED:
      return Object.assign({}, state, {
        refreshCategoryOptionCombosMatchingMasterList: false,
      });
    case types.REFRESH_MATCHINGS_LIST:
      return Object.assign({}, state, {
        refreshMatchings: true,
      });
    case types.MATCHINGS_LIST_REFRESHED:
      return Object.assign({}, state, {
        refreshMatchings: false,
      });
    case types.REFRESH_CATS_MATCHINGS_LIST:
      return Object.assign({}, state, {
        refreshCatsMatchings: true,
      });
    case types.CATS_MATCHINGS_LIST_REFRESHED:
      return Object.assign({}, state, {
        refreshCatsMatchings: false,
      });
    case types.REFRESH_COCS_MATCHINGS_LIST:
      return Object.assign({}, state, {
        refreshCocsMatchings: true,
      });
    case types.COCS_MATCHINGS_LIST_REFRESHED:
      return Object.assign({}, state, {
        refreshCocsMatchings: false,
      });
    case types.TOGGLE_DRAWER:
      return Object.assign({}, state, {
        showDrawer: !state.showDrawer,
      });
    default:
      return state;
  }
}

export default ui;

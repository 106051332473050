import React, { Component } from "react";
import moment from "moment";

import TaskHashCell, { disaplayPeriodTypes } from "../TaskHashCell";
import { OU_AGGREGATIONS, DE_AGGREGATIONS } from "../Forms/PushAnalyticFlow";
import _get from "lodash/get";

class PushDataFlowParams extends Component {
  render() {
    const { task } = this.props;
    return (
      <TaskHashCell
        task={[
          [
            {
              label: "OUGs matching",
              value: task.summary["organisation_unit_group_matching_name"],
            },
            {
              label: "OU aggregation",
              value: _get(OU_AGGREGATIONS, task.params["ou_aggregation_type"], ()=>{})(
                task.params,
              ),
            },
          ],
          [
            {
              label: "DEGs matching",
              value: task.summary["data_element_group_matching_name"],
            },
            {
              label: "DE aggregation",
              value: DE_AGGREGATIONS[task.params["de_aggregation_type"]](
                task.params,
              ),
            },
          ],
          [
            {
              label: "Source PEs",
              value: disaplayPeriodTypes(task.params["src_period_types"]),
            },
            {
              label: "Dest PE",
              value: disaplayPeriodTypes(
                task.params["dest_period_type"]
                  ? [task.params["dest_period_type"]]
                  : [],
              ),
            },
          ],
          task.params["start_date"] && task.params["end_date"]
            ? [
                {
                  label: "Start date",
                  value: moment
                    .utc(task.params["start_date"])
                    .format("MM/YYYY"),
                },
                {
                  label: "End date",
                  value: moment.utc(task.params["end_date"]).format("MM/YYYY"),
                },
              ]
            : [],
        ]}
      />
    );
  }
}
export default PushDataFlowParams;

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Divider,
  Tooltip,
} from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { formStyles, textField } from "../../helpers/commonStyles";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  ...textField(theme),
  ...formStyles(theme),
  sectionTitle: {
    fontWeight: "bold",
  },
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

class CompareDsParams extends Component {
  form_group(params, group_params, entities_params) {
    return (
      <FormGroup row>
        {group_params.map(param => (
          <HtmlTooltip title={<React.Fragment>{param.tooltip}</React.Fragment>}>
            <FormControlLabel
              key={param.key}
              control={
                <Checkbox
                  checked={params.includes(param.key)}
                  onChange={this.props.handleCompareParamsChange(
                    param.key,
                    entities_params,
                  )}
                  color="primary"
                  key={`${param}_checkbox`}
                />
              }
              label={param.label}
            />
          </HtmlTooltip>
        ))}
      </FormGroup>
    );
  }
  render() {
    const {
      classes,
      params,
      group_params,
      entities_params,
      label,
    } = this.props;

    return (
      <Fragment>
        <FormLabel className={classes.sectionTitle} component="legend">
          {label}
        </FormLabel>
        {this.form_group(params, group_params["group"], entities_params)}
        <Divider />
        {this.form_group(params, group_params["data_element"], entities_params)}
        <Divider />
        {this.form_group(
          params,
          group_params["organisation_unit"],
          entities_params,
        )}
      </Fragment>
    );
  }
}
export default withStyles(styles)(CompareDsParams);

import Autosuggest from "react-autosuggest";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React from "react";
import TextField from "@material-ui/core/TextField";
import _debounce from "lodash/debounce";
import { connect } from "react-redux";
import { fetchSuggestions } from "../../actions/autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { textField } from "../../helpers/commonStyles";
import { withStyles } from "@material-ui/core/styles";
import { DEBOUNCED_TIME } from "../Tasks/constants";
const styles = theme => ({
  container: {
    flexGrow: 1,
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  ...textField(theme),
});

class AutoCompleteProject extends React.Component {
  constructor(props) {
    super();
    this.state = {
      value: props.value === undefined ? "" : props.value.name,
      suggestions: [],
    };
  }

  static defaultProps = {
    autoFocus: false,
  };

  getSuggestionValue = suggestion => {
    this.props.onChange(suggestion);
    return suggestion.name;
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (value.length < 1) {
      return false;
    } else {
      this.setState({
        suggestions: this.props.programProjects.filter(
          project =>
            project.name.includes(value) &&
            this.props.currentProject.id !== project.id,
        ),
      });
    }
  };

  debouncedHandleSuggestionsFetchRequested = _debounce(
    this.handleSuggestionsFetchRequested,
    DEBOUNCED_TIME,
  );

  render() {
    const { classes, placeholder } = this.props;
    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={
          this.debouncedHandleSuggestionsFetchRequested
        }
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          autoFocus: this.props.autoFocus,
          classes,
          placeholder: placeholder ? placeholder : `Search for projects`,
          value: this.state.value,
          onChange: this.handleChange,
          error: !!this.props.errors,
          errorMessage: this.props.errors,
        }}
      />
    );
  }
}

// Utils

function renderInput(inputProps) {
  const { classes, autoFocus, value, ref, errorMessage, ...other } = inputProps;

  return (
    <TextField
      autoFocus={autoFocus}
      className={classes.textField}
      value={value}
      inputRef={ref}
      helperText={errorMessage}
      InputProps={{
        classes: {
          input: classes.input,
        },
        ...other,
      }}
      margin="normal"
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={index} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={index} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

// State

const mapStateToProps = (state, props) => ({
  programId: state.programs.currentProgram.id,
  currentProject: state.programs.currentProject,
  programProjects: state.programs.currentProgram.projects,
});

export default connect(mapStateToProps, { fetchSuggestions })(
  withStyles(styles)(AutoCompleteProject),
);

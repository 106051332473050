import { Card, CardContent, CardActionArea } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import Grid from "@material-ui/core/Grid";
import LayoutContainer from "../Shared/LayoutContainer";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { leftIcon, formStyles } from "../../helpers/commonStyles";
import MenuHeader from "../MenuHeader";
const styles = theme => ({
  ...formStyles(theme),
  ...leftIcon(theme),
  projectAlert: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
});
class ProgramsIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <AppBarDefault></AppBarDefault>
        <MenuHeader
          className={classes.menuheader}
          userName={this.props.userName}
        />
        <LayoutContainer position="static">
          {this.props.programs.map(program => (
            <Grid item xs={12} md={4} key={program.id}>
              <Card className={classes.root}>
                <CardActionArea
                  component={Link}
                  to={`/programs/${program.id}/projects`}
                >
                  {!!program.image && (
                    <img
                      src={`data:image/png;base64,${program.image}`}
                      width="100%"
                      height="100"
                      alt={`${program.name}`}
                    />
                  )}

                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h5">
                      {program.name}
                    </Typography>
                    <ul>
                      <li>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          Number of projects :{" "}
                          {program.projects.length > 0
                            ? `${program.projects.length} Projects`
                            : "no projects"}
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          User role : Administrator
                        </Typography>
                      </li>
                    </ul>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </LayoutContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  programs: state.auth.user.programs,
  userName: state.auth.user.email,
});

export default connect(mapStateToProps)(withStyles(styles)(ProgramsIndex));

import React from "react";
import { Link } from "react-router-dom";
import {
  getCompareContent,
  getFetchContent,
  getPushContent,
} from "./details.js";
import { fetchSubTask } from "../../../actions/tasks";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import ShowResultDialog from "../../Shared/ShowResultDialog/ShowResultDialog";
import { TASK_PREVIEW } from "../../Tasks/constants";
const styles = theme => ({
  countGrid: {
    paddingRight: theme.spacing(),
  },
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
    marginBottom: "5px",
  },
});

function getSteps(task) {
  return [
    "Step 1: Resolve scope",
    "Step 2: Fetch Analytics",
    "Step 3: Compare Analytics",
    "Step 4: Push",
  ];
}

function getStepContent(task, step, classes) {
  switch (step) {
    case 0:
      return;
    case 1:
      return getFetchContent(task, ["left", "right"], classes);
    case 2:
      return getCompareContent(task, classes);
    case 3:
      return getPushContent(task, classes);
    default:
      return "Unknown step";
  }
}

class PushAnalyticsDetail extends React.Component {
  state = {
    activeStep: 0,
    completed: {},
    open: false,
    email_result: "",
  };

  activateStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  componentDidMount() {
    const { task } = this.props;
    const { completed } = this.state;

    let activeStep = 0;

    if (task.summary["periods"] !== undefined) {
      completed[0] = true;
      activeStep = 1;
    }
    if (task.summary["fetch_left_count"] !== undefined) {
      completed[1] = true;
      activeStep = 2;
    }

    if (task.summary["new_count"] !== undefined) {
      //new count written == compare is finished
      completed[2] = true;
      activeStep = 3;
    }

    if (!!task.completed_at) {
      completed[3] = true;
      activeStep = null;
    }
    this.setState({
      completed: completed,
      activeStep: activeStep,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isFetchingSubTask !== prevProps.isFetchingSubTask) {
      this.setState({ open: true, email_result: this.props.subTask });
    }
  }

  handleClickOpenPreview(row, preview_sequencer_class) {
    this.props.fetchSubTask(
      this.props.currentProgram.id,
      this.props.project.id,
      row.id,
      preview_sequencer_class,
    );
  }

  render() {
    const { classes, task } = this.props;
    const steps = getSteps(task);
    const { activeStep } = this.state;
    return (
      <div className={classes.root}>
        <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton
                  completed={this.state.completed[index]}
                  onClick={this.activateStep(index)}
                >
                  <StepLabel>
                    <Typography variant="subtitle1">{label}</Typography>
                  </StepLabel>
                </StepButton>
                {task.is_waiting && index === 2 ? (
                  <Link
                    onClick={() =>
                      this.handleClickOpenPreview(
                        task,
                        TASK_PREVIEW[task.sequencer_class],
                      )
                    }
                  >
                    <Tooltip title="Preview analytics to be pushed">
                      <Visibility color="action" />
                    </Tooltip>
                  </Link>
                ) : (
                  ""
                )}
                <StepContent>
                  {getStepContent(task, index, classes)}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        <ShowResultDialog
          open={this.state.open}
          content={this.state.email_result}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  subTask: state.task.entity,
  isFetchingSubTask: state.task.isFetchingSubTask,
});

export default withRouter(
  connect(mapStateToProps, { fetchSubTask })(
    withStyles(styles)(PushAnalyticsDetail),
  ),
);

import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { FormControl, Grid } from "@material-ui/core";
import { formStyles } from "../../helpers/commonStyles";
import { KeyboardDatePicker } from "@material-ui/pickers";

const styles = theme => ({
  ...formStyles(theme),
});

class PeriodIntervalForm extends Component {
  render() {
    const { classes, entity, handleParamChange } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.textField}>
            <KeyboardDatePicker
              label="Start date (d/m/y) included"
              value={entity.params.start_date}
              format="DD/MM/YYYY"
              onChange={d =>
                !!d &&
                d._isValid &&
                handleParamChange("start_date", d.format("YYYY-MM-DD"))
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.textField}>
            <KeyboardDatePicker
              label="End date (d/m/y) included"
              value={entity.params.end_date}
              format="DD/MM/YYYY"
              onChange={d =>
                !!d &&
                d._isValid &&
                handleParamChange("end_date", d.format("YYYY-MM-DD"))
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PeriodIntervalForm);

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { formStyles as styles } from "../../helpers/commonStyles";
import withStyles from "@material-ui/core/styles/withStyles";

export const AVAILABLE_PERIOD_TYPES = [
  "Daily",
  "Weekly",
  "Monthly",
  "Quarterly",
  "SixMonthly",
  "Yearly",
];
export const AVAILABLE_PERIOD_TYPE_UNITS = [
  "Months",
  "Days",
  "Weeks",
  "Quarters",
  "SixMonths",
  "Years",
];

export const periodTypeAbbreviation = periodType => {
  switch (periodType) {
    case "Weekly":
      return "W";
    case "Monthly":
      return "M";
    case "Quarterly":
      return "Q";
    case "SixMonthly":
      return "S";
    case "Yearly":
      return "Y";
    default:
      return "?";
  }
};

class PeriodTypesSelect extends Component {
  render() {
    const {
      selectedPeriodTypes,
      label = "Period types",
      componentStyle,
    } = this.props;
    return (
      <Fragment>
        <FormLabel className={componentStyle} component="legend">
          {label}
        </FormLabel>
        <FormGroup row>
          {AVAILABLE_PERIOD_TYPES.map(period => (
            <FormControlLabel
              key={period}
              control={
                <Checkbox
                  checked={selectedPeriodTypes.includes(period)}
                  onChange={this.props.handlePeriodTypeChange(period)}
                  color="primary"
                  key={`${period}_checkbox`}
                />
              }
              label={period}
            />
          ))}
        </FormGroup>
      </Fragment>
    );
  }
}
export default withStyles(styles)(PeriodTypesSelect);

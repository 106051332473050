import React, { Component, Fragment } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import LayoutContainer from "../Shared/LayoutContainer";
import SectionHeader from "../Shared/SectionHeader";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createNewDataElementsMatching,
  destroyDataElementsMatching,
  fetchDataElementsMatchings,
} from "../../actions/dataElementsMatchings";
import {
  refreshDataElementsMatchingMasterList,
  dataElementsMatchingMasterListRefreshed,
} from "../../actions/ui";
import {
  createNewOrgUnitsMatching,
  destroyOrgUnitsMatching,
  fetchOrgUnitsMatchings,
} from "../../actions/orgUnitsMatchings";
import {
  refreshOrgUnitsMatchingsMasterList,
  orgUnitsMatchingsMasterListRefreshed,
  matchingsListRefreshed,
} from "../../actions/ui";
import { get } from "../../actions/api";
import { fetchDataElements } from "../../actions/dataElements";
import { fetchOrgUnits } from "../../actions/orgUnits";
import SearcheableMatchingTable from "../Shared/SearcheableMatchingTable";
import _values from "lodash/values";
import _isEqual from "lodash/isEqual";
import { paper as styles } from "../../helpers/commonStyles";
import DataSetsPreviews from "./DataSetsPreviews";
import ConfirmDialog from "../Shared/ConfirmDialog";
import UnmatchedNewSection from "../Shared/UnmatchedNewSection";
import CreateMatchingDialog from "../Shared/CreateMatchingDialog";

class DataSetsMatchingEditForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmDestroyOpen: false,
      confirmDestroyOrgUnitMatchingOpen: false,
      processingErrors: null,
      processingDialogOpen: false,
      timeoutId: null,
      searchedDataElementItems: [],
      searchedDataElementItemsCount: 0,
      searchDataElementParams: "",
      searchedOrgUnitItems: [],
      searchedOrgUnitItemsCount: 0,
      searchOrgUnitParams: "",
      danglingMatchingRowCount: 0,
      existingOrgUnitsMatchingsRowCount: 0,
      existingDataElementsMatchingsRowCount: 0,
    };
  }
  componentDidMount() {
    this.setState((state, props) => ({
      existingOrgUnitsMatchingsRowCount:
        props.existingOrgUnitsMatchingsRowCount,
      existingDataElementsMatchingsRowCount:
        props.existingDataElementsMatchingsRowCount,
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!prevProps.creatingMatching && !this.props.creatingMatching) {
      window.location.reload();
    } else if (
      prevProps.creatingMatching !== this.props.creatingMatching ||
      !_isEqual(prevProps.processingErrors, this.props.processingErrors)
    ) {
      this.setState((state, props) => ({
        processingDialogOpen:
          !!props.creatingMatching || !!props.processingErrors,
        processingErrors: props.processingErrors,
      }));
    }

    if (
      !_isEqual(
        prevProps.danglingMatchingRowCount,
        this.props.danglingMatchingRowCount,
      )
    ) {
      this.setState((state, props) => ({
        danglingMatchingRowCount: props.danglingMatchingRowCount,
      }));
    }

    if (
      !_isEqual(
        prevProps.existingOrgUnitsMatchingsRowCount,
        this.props.existingOrgUnitsMatchingsRowCount,
      )
    ) {
      this.setState((state, props) => ({
        existingOrgUnitsMatchingsRowCount:
          props.existingOrgUnitsMatchingsRowCount,
      }));
    }

    if (
      !_isEqual(
        prevProps.existingDataElementsMatchingsRowCount,
        this.props.existingDataElementsMatchingsRowCount,
      )
    ) {
      this.setState((state, props) => ({
        existingDataElementsMatchingsRowCount:
          props.existingDataElementsMatchingsRowCount,
      }));
    }
  }

  handleFetchDataElements = params => {
    const { sourceId, query, page, master, forceReload } = params;
    const {
      projectId,
      programId,
      dsMatching: { left: dataSetLeft, right: dataSetRight },
    } = this.props;
    const dataSet = master ? dataSetLeft : dataSetRight;
    this.props.fetchDataElements(
      programId,
      projectId,
      sourceId,
      "data_sets",
      dataSet.id,
      query,
      page,
      forceReload,
    );
  };

  closeConfirmDestroyDialog = () => {
    this.setState({
      confirmDestroyOpen: false,
    });
  };

  confirmDestroyContent = () => {
    return (
      <Fragment>
        <Typography>
          This data element matching is shared by the following data element
          groups matchings:
        </Typography>
        <Typography color="primary">
          {this.state.confirmDestroyWarnings &&
            this.state.confirmDestroyWarnings.join(", ")}
        </Typography>
        <Typography>
          Do you want to delete the data element matching in all these data
          element group matchings?
        </Typography>
      </Fragment>
    );
  };

  confirmDestroyDialog = () => {
    return (
      <ConfirmDialog
        handleConfirm={this.destroyDataElementMatching}
        handleClose={this.closeConfirmDestroyDialog}
        open={this.state.confirmDestroyOpen}
      >
        {this.confirmDestroyContent()}
      </ConfirmDialog>
    );
  };

  destroyDataElementMatching = () => {
    this.props.destroyDataElementsMatching(
      this.props.programId,
      this.props.projectId,
      "data_sets",
      "data_set_matching",
      this.props.dsMatching,
      this.state.matchingIdToDestroy,
    );
    this.closeConfirmDestroyDialog();
  };

  handleDataElementMatchingDestroy = matchingIdToDestroy => {
    get("destroyDataElementsMatchingPrev", {
      programId: this.props.programId,
      projectId: this.props.projectId,
      id: matchingIdToDestroy,
    }).then(groups => {
      if (groups.length > 1) {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
          confirmDestroyWarnings: groups,
          confirmDestroyOpen: true,
        });
      } else {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
        });
        this.destroyDataElementMatching();
      }
    });
  };

  handleFetchOrgUnits = params => {
    const { sourceId, query, page, master, forceReload } = params;
    const {
      projectId,
      programId,
      dsMatching: { left: dataSetLeft, right: dataSetRight },
    } = this.props;
    const dataSet = master ? dataSetLeft : dataSetRight;
    this.props.fetchOrgUnits(
      programId,
      projectId,
      sourceId,
      "data_sets",
      dataSet.id,
      query,
      page,
      forceReload,
    );
  };

  closeConfirmDestroyOrgUnitMatchingDialog = () => {
    this.setState({
      confirmDestroyOrgUnitMatchingOpen: false,
    });
  };

  confirmDestroyOrgUnitMatchingContent = () => {
    return (
      <Fragment>
        <Typography>
          This org unit matching is shared by the following org unit groups
          matchings:
        </Typography>
        <Typography color="primary">
          {this.state.confirmDestroyWarnings &&
            this.state.confirmDestroyWarnings.join(", ")}
        </Typography>
        <Typography>
          Do you want to delete the org unit matching in all these org unit
          group matchings?
        </Typography>
      </Fragment>
    );
  };

  confirmDestroyOrgUnitMatchingDialog = () => {
    return (
      <ConfirmDialog
        handleConfirm={this.destroyOrgUnitsMatching}
        handleClose={this.closeConfirmDestroyDialog}
        open={this.state.confirmDestroyOrgUnitMatchingOpen}
      >
        {this.confirmDestroyOrgUnitMatchingContent()}
      </ConfirmDialog>
    );
  };

  destroyOrgUnitsMatching = () => {
    this.props.destroyOrgUnitsMatching(
      this.props.programId,
      this.props.projectId,
      "data_sets",
      "data_set_matching",
      this.props.dsMatching,
      this.state.matchingIdToDestroy,
    );
    this.closeConfirmDestroyOrgUnitMatchingDialog();
  };

  handleOrgUnitMatchingDestroy = matchingIdToDestroy => {
    get("destroyOrgUnitsMatchingPrev", {
      programId: this.props.programId,
      projectId: this.props.projectId,
      id: matchingIdToDestroy,
    }).then(orgUnitGroups => {
      if (orgUnitGroups.length > 1) {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
          confirmDestroyWarnings: orgUnitGroups,
          confirmDestroyOrgUnitMatchingOpen: true,
        });
      } else {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
        });
        this.destroyOrgUnitsMatching();
      }
    });
  };

  closeProcessingDialog = () => {
    this.setState({
      processingDialogOpen: false,
    });
  };

  createMatchingDialog = () => {
    const { dsMatching } = this.props;
    return (
      <CreateMatchingDialog
        open={this.state.processingDialogOpen}
        closeProcessingDialog={this.closeProcessingDialog}
        srcLeft={dsMatching.left.source.name}
        matchTo={this.state.matchTo}
        srcRight={dsMatching.right.source.name}
        groupRight={dsMatching.right.name}
        toMap={this.state.toMap}
        processingErrors={this.props.createMatchingProcessingErrors}
      />
    );
  };

  _createNewOrgUnitsMatching = (
    projectId,
    payload,
    matchingType,
    matching_id,
  ) => {
    this.setState(
      {
        toMap: payload.second_element.name || payload.second_element.dhis_id,
        matchTo: payload.first_element.name,
      },
      e =>
        this.props.createItemsMatching(
          projectId,
          payload,
          matchingType,
          matching_id,
        ),
    );
  };

  handleOrgUnitMatchingCreate = matching => {
    const payload = {
      data_set_matching: this.props.dsMatching.id,
      second_element: {
        source_id: this.props.dsMatching.right.source.id,
        id: matching.right.id,
        name: matching.right.name,
      },
    };
    this.setState(
      {
        toMap: payload.second_element.name || payload.second_element.dhis_id,
        matchTo: null,
      },
      e =>
        this.props.createNewOrgUnitsMatching(
          this.props.programId,
          this.props.projectId,
          payload,
          "data_set",
          this.props.dsMatching.id,
        ),
    );
  };

  search_data_element_mappings = params => {
    const searched =
      params === ""
        ? []
        : this.props.dataElementsLeft.filter(function(el) {
            return (
              el["name"].toLowerCase().includes(params.toLowerCase()) ||
              el["dhis_id"].toLowerCase().includes(params.toLowerCase())
            );
          });
    this.setState({
      searchedDataElementItems: searched,
      searchedDataElementItemsCount: searched.length,
      searchDataElementParams: params,
    });
  };

  searched_data_element_results = () => {
    return this.state.searchDataElementParams
      ? this.state.searchedDataElementItems
      : this.props.dataElementsLeft;
  };

  searched_data_element_results_count = () => {
    return this.state.searchDataElementParams
      ? this.state.searchedDataElementItemsCount
      : this.props.dataElementsLeftRowCount;
  };

  search_org_unit_mappings = params => {
    const searched =
      params === ""
        ? []
        : this.props.orgUnitsLeft.filter(function(el) {
            return (
              el["name"].toLowerCase().includes(params.toLowerCase()) ||
              el["dhis_id"].toLowerCase().includes(params.toLowerCase())
            );
          });
    this.setState({
      searchedOrgUnitItems: searched,
      searchedOrgUnitItemsCount: searched.length,
      searchOrgUnitParams: params,
    });
  };

  handleCountChange = (dangling, count, searchParams) => {
    if (searchParams) {
      dangling
        ? this.setState({ danglingMatchingRowCount: count })
        : this.setState({ existingOrgUnitsMatchingsRowCount: count });
    } else {
      dangling
        ? this.setState((state, props) => ({
            danglingMatchingRowCount: props.danglingMatchingRowCount,
          }))
        : this.setState((state, props) => ({
            existingOrgUnitsMatchingsRowCount:
              props.existingOrgUnitsMatchingsRowCount,
          }));
    }
  };

  handleDataElementCountChange = (dangling, count, searchParams) => {
    if (searchParams) {
      this.setState({ existingDataElementsMatchingsRowCount: count });
    } else {
      this.setState((state, props) => ({
        existingDataElementsMatchingsRowCount:
          props.existingDataElementsMatchingsRowCount,
      }));
    }
  };

  searched_org_unit_results = () => {
    return this.state.searchOrgUnitParams
      ? this.state.searchedOrgUnitItems
      : this.props.orgUnitsLeft;
  };

  searched_org_unit_results_count = () => {
    return this.state.searchOrgUnitParams
      ? this.state.searchedOrgUnitItemsCount
      : this.props.orgUnitsLeftRowCount;
  };

  render() {
    const {
      dsMatching: { left: dataSetLeft, right: dataSetRight },
      classes,
      projectId,
      programId,
    } = this.props;
    return (
      <LayoutContainer>
        {this.confirmDestroyDialog()}
        {this.confirmDestroyOrgUnitMatchingDialog()}
        {this.createMatchingDialog()}
        <p>
          Map all data elements and organisation units to the corresponding one
          on the other dhis2.
          <br />
          This matching is directional and defines which data pushes data values
          to the other.
          <br />
          The source is on the left.
          <br />
        </p>

        <DataSetsPreviews
          projectId={projectId}
          programId={programId}
          groups={[dataSetLeft, dataSetRight]}
          tooltipText={`The values will be pushed from ${dataSetLeft.source.name} to ${dataSetRight.source.name}`}
          target={dataSetRight.source}
        />

        <SectionHeader>
          Unmatched Data Elements ({this.searched_data_element_results_count()})
        </SectionHeader>
        <UnmatchedNewSection
          search_mappings={this.search_data_element_mappings}
          projectId={this.props.projectId}
          programId={this.props.programId}
          itemTypeLabel="Data Element"
          matching={this.props.dsMatching}
          itemsLeft={this.searched_data_element_results()}
          itemsLeftRowCount={this.searched_data_element_results_count()}
          itemsLeftFetching={this.props.dataElementsLeftFetching}
          fetchItems={this.handleFetchDataElements}
          itemsRight={this.props.dataElementsRight}
          knownMatch={true}
          itemsRightRowCount={this.props.dataElementsRightRowCount}
          itemsRightFetching={this.props.dataElementsRightFetching}
          matchingType="data_sets"
          entitiesType="data_elements"
          createItemsMatching={this.props.createNewDataElementsMatching}
          taskMatcher="task"
          matchingMasterListRefreshed={this.props.refreshDataElements}
          itemClonesApi="dataElementClones"
          sequencer_class="::Services::MetaMatchFlow::MatchDsMetaFlow"
        />

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.paper}>
            <SearcheableMatchingTable
              projectId={this.props.projectId}
              destroyMatching={this.handleDataElementMatchingDestroy}
              matchingType="data_set_matching"
              matchingId={this.props.dsMatching.id}
              fetchMetaMachings={(payload, force) =>
                this.props.fetchDataElementsMatchings(
                  this.props.programId,
                  this.props.projectId,
                  "data_set_matching",
                  this.props.dsMatching.id,
                  payload,
                  force,
                )
              }
              matchingsListRefreshed={this.props.matchingsListRefreshed}
              metaTypeMatching="dataElementsMatchings"
              sectionHeaderLabel="Matched Data Elements"
            />
          </Grid>
        </Grid>

        <SectionHeader>
          Unmatched Organisation Units ({this.searched_org_unit_results_count()}
          )
        </SectionHeader>
        <UnmatchedNewSection
          search_mappings={this.search_org_unit_mappings}
          projectId={this.props.projectId}
          programId={this.props.programId}
          itemTypeLabel="Organisation Unit"
          matching={this.props.dsMatching}
          itemsLeft={this.searched_org_unit_results()}
          itemsLeftRowCount={this.searched_org_unit_results_count()}
          itemsLeftFetching={this.props.orgUnitsLeftFetching}
          fetchItems={this.handleFetchOrgUnits}
          itemsRight={this.props.orgUnitsRight}
          knownMatch={true}
          itemsRightRowCount={this.props.orgUnitsRightRowCount}
          itemsRightFetching={this.props.orgUnitsRightFetching}
          matchingType="data_sets"
          entitiesType="organisation_units"
          createItemsMatching={this.createNewOrgUnitsMatching}
          taskMatcher="task"
          itemClonesApi="orgUnitClones"
          sequencer_class="::Services::MetaMatchFlow::MatchDsMetaFlow"
          cloneOptions={[
            <TextField
              key="skipLevel"
              name="skip_level"
              label="Level to skip"
              fullWidth
              type="number"
              helperText="Level of the org unit hierarchy to skip (root = 1 and cannot be skipped)"
            />,
          ]}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.paper}>
            <SearcheableMatchingTable
              createMatching={this.handleOrgUnitMatchingCreate}
              projectId={this.props.projectId}
              matchingId={this.props.dsMatching.id}
              matchingType="data_set_matching"
              dangling={true}
              fetchMetaMachings={(pageLoaded, force) =>
                this.props.fetchOrgUnitsMatchings(
                  this.props.programId,
                  this.props.projectId,
                  "data_set_matching",
                  this.props.dsMatching.id,
                  pageLoaded,
                  force,
                  true,
                )
              }
              matchingsListRefreshed={this.props.matchingsListRefreshed}
              metaTypeMatching="orgUnitsMatchings"
              sectionHeaderLabel="Dangling Organisation Units Matchings"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.paper}>
            <SearcheableMatchingTable
              destroyMatching={this.handleOrgUnitMatchingDestroy}
              projectId={this.props.projectId}
              matchingId={this.props.dsMatching.id}
              matchingType="data_set_matching"
              dangling={false}
              fetchMetaMachings={(pageLoaded, force) =>
                this.props.fetchOrgUnitsMatchings(
                  this.props.programId,
                  this.props.projectId,
                  "data_set_matching",
                  this.props.dsMatching.id,
                  pageLoaded,
                  force,
                  false,
                )
              }
              matchingsListRefreshed={this.props.matchingsListRefreshed}
              metaTypeMatching="orgUnitsMatchings"
              sectionHeaderLabel="Matched Organisation Units"
            />
          </Grid>
        </Grid>
      </LayoutContainer>
    );
  }
}

const dataElementsForSource = (dataElements, sourceId) => {
  return dataElements[sourceId] && dataElements[sourceId].entities
    ? _values(dataElements[sourceId].entities)
    : [];
};

const dataElementsMetaForSource = (dataElements, sourceId) => {
  return dataElements[sourceId] && dataElements[sourceId].meta !== undefined
    ? dataElements[sourceId].meta
    : { total_count: 0 };
};

const isFetchingDataElements = (dataElements, sourceId) => {
  return dataElements[sourceId] && dataElements[sourceId].isFetching;
};

const orgUnitsForSource = (orgUnits, sourceId) => {
  return orgUnits[sourceId] && orgUnits[sourceId].entities
    ? _values(orgUnits[sourceId].entities)
    : [];
};

const orgUnitsMetaForSource = (orgUnits, sourceId) => {
  return orgUnits[sourceId] && orgUnits[sourceId].meta !== undefined
    ? orgUnits[sourceId].meta
    : { total_count: 0 };
};

const isFetchingOrgUnits = (orgUnits, sourceId) => {
  return orgUnits[sourceId] && orgUnits[sourceId].isFetching;
};

const mapStateToProps = (state, props) => {
  const {
    dsMatching: { left: dataSetLeft, right: dataSetRight },
  } = props;

  const dataElementsLeftMeta = dataElementsMetaForSource(
    state.dataElements,
    dataSetLeft.source.id,
  );
  const dataElementsRightMeta = dataElementsMetaForSource(
    state.dataElements,
    dataSetRight.source.id,
  );

  const dataElementsLeftRowCount = dataElementsLeftMeta.total_count;
  const dataElementsRightRowCount = dataElementsRightMeta.total_count;

  const dataElementsLeftFetching = isFetchingDataElements(
    state.dataElements,
    dataSetLeft.source.id,
  );
  const dataElementsRightFetching = isFetchingDataElements(
    state.dataElements,
    dataSetRight.source.id,
  );

  const existingDataElementsMatchingsRowCount =
    state.dataElementsMatchings.meta.total_count || 0;

  const orgUnitsLeftMeta = orgUnitsMetaForSource(
    state.orgUnits,
    dataSetLeft.source.id,
  );
  const orgUnitsRightMeta = orgUnitsMetaForSource(
    state.orgUnits,
    dataSetRight.source.id,
  );

  const orgUnitsLeftRowCount = orgUnitsLeftMeta.total_count;
  const orgUnitsRightRowCount = orgUnitsRightMeta.total_count;

  const orgUnitsLeftFetching = isFetchingOrgUnits(
    state.orgUnits,
    dataSetLeft.source.id,
  );
  const orgUnitsRightFetching = isFetchingOrgUnits(
    state.orgUnits,
    dataSetRight.source.id,
  );
  const danglingMatchingRowCount =
    state.orgUnitsMatchings.dangling.total_count || 0;
  const existingOrgUnitsMatchingsRowCount =
    state.orgUnitsMatchings.meta.total_count || 0;

  return {
    programId: state.programs.currentProgram.id,
    projectId: state.programs.currentProject.id,
    dataElementsLeft: dataElementsForSource(
      state.dataElements,
      dataSetLeft.source.id,
    ),
    dataElementsRight: dataElementsForSource(
      state.dataElements,
      dataSetRight.source.id,
    ),
    dataElementsLeftMeta,
    dataElementsRightMeta,
    dataElementsLeftRowCount,
    dataElementsRightRowCount,
    dataElementsLeftFetching,
    dataElementsRightFetching,
    existingDataElementsMatchingsRowCount,

    orgUnitsLeft: orgUnitsForSource(state.orgUnits, dataSetLeft.source.id),
    orgUnitsRight: orgUnitsForSource(state.orgUnits, dataSetRight.source.id),
    orgUnitsLeftMeta,
    orgUnitsRightMeta,
    orgUnitsLeftRowCount,
    orgUnitsRightRowCount,
    orgUnitsLeftFetching,
    orgUnitsRightFetching,
    existingOrgUnitsMatchingsRowCount,
    danglingMatchingRowCount,
    creatingMatching: state.orgUnitsMatchings.creatingMatching,
    processingErrors: state.orgUnitsMatchings.processingErrors,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchDataElements,
      createNewDataElementsMatching,
      destroyDataElementsMatching,
      refreshDataElementsMatchingMasterList,
      dataElementsMatchingMasterListRefreshed,
      fetchOrgUnits,
      createNewOrgUnitsMatching,
      destroyOrgUnitsMatching,
      refreshOrgUnitsMatchingsMasterList,
      orgUnitsMatchingsMasterListRefreshed,
      fetchDataElementsMatchings,
      matchingsListRefreshed,
      fetchOrgUnitsMatchings,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(DataSetsMatchingEditForm)));

import {
  MenuItem,
  TextField,
  withStyles,
  Typography,
  LinearProgress,
  Box,
} from "@material-ui/core";
import React, { Component } from "react";

import { connect } from "react-redux";
import { fetchDataSetsMatchings } from "../../actions/dataSetsMatchings";
import { textField } from "../../helpers/commonStyles";

const styles = theme => ({
  ...textField(theme),
  menu: {
    width: 200,
  },
});

class DataSetsMatchingsSelect extends Component {
  componentDidMount() {
    this.props.fetchDataSetsMatchings(
      this.props.currentProgram.id,
      this.props.projectId,
      false,
    );
  }

  onChangeFunctions = e => {
    this.props.onChange(e);
    this.onChangeLabelFunction();
  };

  onChangeLabelFunction = () => {
    this.props.onChangeLabel(this.props.dataSetsMatchings);
  };

  render() {
    const { value, classes, dataSetsMatchings } = this.props;
    if (!dataSetsMatchings.length)
      return (
        <Typography className={classes.textField}>
          Searching data set matchings
          <Box sx={{ width: "80%" }}>
            <LinearProgress />
          </Box>
        </Typography>
      );
    return (
      <TextField
        select
        label="Data set matching"
        className={classes.textField}
        onChange={this.onChangeFunctions}
        value={value}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
      >
        {dataSetsMatchings &&
          dataSetsMatchings.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    );
  }
}

const dataSetsMatchingsPresenter = matching => ({
  label: matching.display_name,
  value: matching.id,
});

const mapStateToProps = state => ({
  currentProgram: state.programs.currentProgram,
  dataSetsMatchings: state.dataSetsMatchings.entities.map(matching => {
    return dataSetsMatchingsPresenter(matching);
  }),
});

export default connect(mapStateToProps, { fetchDataSetsMatchings })(
  withStyles(styles)(DataSetsMatchingsSelect),
);

import React, { Component } from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import CategoriesMatchingEditForm from "./CategoriesMatchingEditForm";
import { connect } from "react-redux";
import SectionSpinner from "../Shared/SectionSpinner";
import { fetchCategoriesMatchings } from "../../actions/categoriesMatchings";
import { selectCategoriesMatching } from "../../reducers/categoriesMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

class CategoriesMatchingEdit extends Component {
  componentDidMount() {
    !this.props.catMatching &&
      this.props.fetchCategoriesMatchings(
        this.props.currentProgram.id,
        this.props.project.id,
      );
  }

  render() {
    const { classes, project, currentProgram } = this.props;
    let content;

    if (!this.props.catMatching) {
      content = <SectionSpinner />;
    } else {
      content = (
        <CategoriesMatchingEditForm
          project={project}
          catMatching={this.props.catMatching}
        />
      );
    }

    return (
      <div>
        <AppBarDefault title="Edit Categories Matching">
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${currentProgram.id}/projects/${project.id}/categories_matchings`}
          >
            <ArrowBack className={classes.leftIcon} />
            Categories matchings
          </Button>
        </AppBarDefault>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  project: state.programs.currentProject,
  catMatching: selectCategoriesMatching(
    state.categoriesMatchings.entities,
    props.match.params.category_matching_id,
  ),
});

export default connect(mapStateToProps, { fetchCategoriesMatchings })(
  withRouter(withStyles(styles)(CategoriesMatchingEdit)),
);

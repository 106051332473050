import React, { Component } from "react";
import TaskHashCell from "../TaskHashCell";

class PushDataFlowSummary extends Component {
  render() {
    const { task } = this.props;

    const push_task_summary = pushed_count => {
      return pushed_count ? pushed_count : 0;
    };

    const summaries = [
      [
        {
          label: "Created",
          value: push_task_summary(task.summary["push_created_count"]),
        },
        {
          label: "Updated",
          value: push_task_summary(task.summary["push_updated_count"]),
        },
        {
          label: "Deleted",
          value: push_task_summary(task.summary["push_deleted_count"]),
        },
      ],
      [
        {
          label: "Skipped",
          value: push_task_summary(task.summary["skip_count"]),
        },
        {
          label: "Deselected",
          value: push_task_summary(task.summary["deselected_count"]),
        },
        {
          label: "Errors",
          value: push_task_summary(task.summary["push_errors_count"]),
        },
      ],
    ];
    return <TaskHashCell task={summaries} />;
  }
}

export default PushDataFlowSummary;

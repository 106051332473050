import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";

const styles = theme => ({
  root: {
    width: "100%",
    textAlign: "center",
    zIndex: 999,
  },
  withMargins: {
    marginTop: theme.spacing(4),
  },
});

function SectionSpinner(props) {
  const klasses = classNames(props.classes.root, {
    [props.classes.withMargins]: !props.noMargins,
  });
  return (
    <div className={klasses}>
      <CircularProgress />
    </div>
  );
}

export default withStyles(styles)(SectionSpinner);

import { RSAA } from "redux-api-middleware";
import store from "../store";
import history from "../history";
import { baseApiUrl, authHeader } from "./api";
import { addErrorNotifications, removeNotifications } from "./notifications";
import { stringify } from "query-string";
import _omitBy from "lodash/omitBy";
import _isUndefined from "lodash/isUndefined";
import moment from "moment";
import * as types from "../actionTypes";

export function fetchSchedulers(programId, projectId, page = 1, perPage = 50) {
  const queryParams = {
    page: page,
    per_page: perPage,
  };

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));

  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/schedulers?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_SCHEDULERS,
        types.RECEIVE_SCHEDULERS,
        types.REQUEST_SCHEDULERS_FAILED,
      ],
    },
  };
}

export function fetchScheduler(programId, projectId, schedulerId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/schedulers/${schedulerId}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_SCHEDULER,
        types.RECEIVE_SCHEDULER,
        types.REQUEST_SCHEDULER_FAILED,
      ],
    },
  };
}

export function createOrUpdateScheduler(programId, projectId, scheduler) {
  if (!scheduler.scheduler.id) {
    scheduler.scheduler.disabled_at = moment().format("YYYY-MM-DD");
  }
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/schedulers${
        !!scheduler.scheduler.id ? `/${scheduler.scheduler.id}` : ""
      }`,
      method: !!scheduler.scheduler.id ? "PUT" : "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(scheduler),
      types: [
        {
          type: types.REQUEST_SCHEDULER_CREATE_OR_UPDATE,
          payload: (action, state, response) => {
            store.dispatch(removeNotifications("SchedulerForm"));
            return response;
          },
        },
        {
          type: types.RECEIVE_SCHEDULER,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              history.push(
                `/programs/${programId}/projects/${projectId}/schedulers`,
              );
              return payload;
            });
          },
        },
        {
          type: types.REQUEST_SCHEDULER_CREATE_OR_UPDATE_FAILED,
          payload: (action, state, response) => {
            response
              .json()
              .then(json =>
                store.dispatch(
                  addErrorNotifications("SchedulerForm", json.errors),
                ),
              );
            return response;
          },
        },
      ],
    },
  };
}

export function resumeScheduler(
  programId,
  projectId,
  schedulerId,
  disabled_at,
) {
  const SchedulerParams = { disabled_at: disabled_at };
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/schedulers/${schedulerId}/enable`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(SchedulerParams),
      types: [
        types.REQUEST_SCHEDULER,
        {
          type: types.RECEIVE_SCHEDULER,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              window.location.reload();
              return payload;
            });
          },
        },
        types.REQUEST_SCHEDULER_FAILED,
      ],
    },
  };
}

export function pauseScheduler(programId, projectId, schedulerId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/schedulers/${schedulerId}/disable`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_SCHEDULER,
        {
          type: types.RECEIVE_SCHEDULER,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              window.location.reload();
              return payload;
            });
          },
        },
        types.REQUEST_SCHEDULER_FAILED,
      ],
    },
  };
}

import { RSAA } from "redux-api-middleware";
import store from "../store";
import history from "../history";
import { baseApiUrl, authHeader } from "./api";
import { addErrorNotifications, removeNotifications } from "./notifications";
import { stringify } from "query-string";
import _omitBy from "lodash/omitBy";
import _isUndefined from "lodash/isUndefined";

import * as types from "../actionTypes";

export function fetchTasks(programId, projectId, page = 1, perPage = 50) {
  const queryParams = {
    page: page,
    per_page: perPage,
  };

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));

  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/tasks?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_PUSH_TASKS,
        types.RECEIVE_PUSH_TASKS,
        types.REQUEST_PUSH_TASKS_FAILED,
      ],
    },
  };
}

export function fetchSubTask(
  programId,
  projectId,
  task_id,
  preview_sequencer_class,
) {
  const queryParams = {
    preview_sequencer_class: preview_sequencer_class,
  };
  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/tasks/${task_id}/preview_summary_task?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_SUB_TASK,
        types.RECEIVE_SUB_TASK,
        types.REQUEST_SUB_TASK_FAILED,
      ],
    },
  };
}

export function fetchPreviewSink(programId, projectId, task_id, period) {
  const queryParams = {
    period: period,
  };
  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/tasks/${task_id}/preview_details_task?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_COMPARE_SINK,
        types.RECEIVE_COMPARE_SINK,
        types.REQUEST_COMPARE_SINK_FAILED,
      ],
    },
  };
}

export function searchPreviewSink(programId, projectId, task_id, val, period) {
  const queryParams = {
    period: period,
    val: val,
  };
  const search_details_task =
    val !== "" ? "search_details_task" : "preview_details_task";
  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/tasks/${task_id}/${search_details_task}?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_SEARCH_SINK,
        types.RECEIVE_SEARCH_SINK,
        types.REQUEST_SEARCH_SINK_FAILED,
      ],
    },
  };
}

export function createImmediateTask(
  programId,
  projectId,
  taskParams,
  redirect,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/tasks`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(taskParams),
      types: [
        {
          type: types.REQUEST_PUSH_TASK_CREATE,
          payload: (action, state, response) => {
            store.dispatch(removeNotifications("taskForm"));
            return response;
          },
        },
        {
          type: types.RECEIVE_PUSH_TASK,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              if (redirect) {
                history.push(
                  `/programs/${programId}/projects/${projectId}/tasks`,
                );
              }
              return payload;
            });
          },
        },
        {
          type: types.REQUEST_PUSH_TASK_CREATE_FAILED,
          payload: (action, state, response) => {
            response
              .json()
              .then(json =>
                store.dispatch(addErrorNotifications("taskForm", json.errors)),
              );
            return response;
          },
        },
      ],
    },
  };
}

export function applyChanges(
  programId,
  projectId,
  task_id,
  taskParams,
  redirect,
) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/tasks/${task_id}/apply_changes?`,
      method: "POST",
      ...authHeader(store.getState().auth.token),
      body: JSON.stringify(taskParams),
      types: [
        {
          type: types.REQUEST_PUSH_TASK_CREATE,
          payload: (action, state, response) => {
            store.dispatch(removeNotifications("taskForm"));
            return response;
          },
        },
        {
          type: types.RECEIVE_PUSH_TASK,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              if (redirect) {
                history.push(
                  `/programs/${programId}/projects/${projectId}/tasks`,
                );
              }
              return payload;
            });
          },
        },
        {
          type: types.REQUEST_PUSH_TASK_CREATE_FAILED,
          payload: (action, state, response) => {
            response
              .json()
              .then(json =>
                store.dispatch(addErrorNotifications("taskForm", json.errors)),
              );
            return response;
          },
        },
      ],
    },
  };
}

import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AVAILABLE_PERIOD_TYPE_UNITS } from "../Shared/PeriodTypesSelect";
import { formStyles, expansionPanelStyles } from "../../helpers/commonStyles";
import { KeyboardDatePicker } from "@material-ui/pickers";

const styles = theme => ({
  ...formStyles(theme),
  ...expansionPanelStyles(theme),
});

class PeriodRangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: props.immediate ? "Preview" : "",
      expanded: this.expendedType(props.entity.params),
      type: this.checkedType(props.entity.params),
      selectedDate: this.getSelectDate(props.entity.params),
    };
  }

  expendedType(params) {
    if ("relative_period" in params) {
      return "panel_1";
    } else if ("start_date" in params) {
      return "panel_2";
    } else {
      return "panel_1";
    }
  }

  getSelectDate(params) {
    if ("start_date" in params) {
      return params["start_date"];
    } else {
      new Date();
    }
  }

  checkedType(params) {
    if ("relative_period" in params) {
      return "relative_period";
    } else if ("start_date" in params) {
      return "start_date";
    } else {
      return "relative_period";
    }
  }

  removeParam(key) {
    if (this.props.entity.params[key] !== undefined) {
      this.props.handleRemoveParam(key);
    }
  }

  handleExpande = (panel, type) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
      type: type,
    });
  };

  handleTypeChange = (type, panel) => event => {
    this.setState({ type: type, expanded: panel });
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
    this.props.handleParamChange("start_date", date.format("YYYY-MM-DD"));
    this.removeParam("period_range_unit");
    this.removeParam("period_range");
    this.setState({ type: "start_date" });
  };

  handlePeriodRange = e => {
    this.setState({ period_range: e.target.value });
    this.props.handleParamChange("period_range", e.target.value);
    this.removeParam("start_date");
  };

  handlePeriodRangeUnit = e => {
    this.setState({ period_range_unit: e.target.value });
    this.props.handleParamChange("period_range_unit", e.target.value);
    this.removeParam("start_date");
  };

  render() {
    const { classes, entity } = this.props;
    return (
      <FormControl className={classes.textField}>
        <FormLabel component="legend">Period range</FormLabel>
        <ExpansionPanel
          className={classes.expansionPanel}
          square
          expanded={this.state.expanded === "panel_1"}
          onChange={this.handleExpande("panel_1", "relative_period")}
        >
          <ExpansionPanelSummary
            className={classes.expansionPanelSummary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="paneld-content"
            id="paneld-header"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.type === "relative_period"}
                  color="primary"
                  onChange={this.handleTypeChange("relative_period", "panel_1")}
                />
              }
              label="Fixed period length"
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid container>
              <Grid item xs={1}>
                <div className={classes.inlineLabel}>Last</div>
              </Grid>
              <Grid item xs={11} md={5}>
                <FormControl className={classes.textField}>
                  <TextField
                    id="select-range-value"
                    select
                    className={classes.textField}
                    onChange={this.handlePeriodRange}
                    value={entity.params.period_range}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                    {[0, 1, 2, 3, 4, 5, 6].map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={11} md={5}>
                <FormControl className={classes.textField}>
                  <TextField
                    id="select-range-unit"
                    select
                    className={classes.textField}
                    onChange={this.handlePeriodRangeUnit}
                    value={entity.params.period_range_unit}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                  >
                    {AVAILABLE_PERIOD_TYPE_UNITS.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className={classes.expansionPanel}
          square
          expanded={this.state.expanded === "panel_2"}
          onChange={this.handleExpande("panel_2", "start_date")}
        >
          <ExpansionPanelSummary
            className={classes.expansionPanelSummary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="paneld-content"
            id="paneld-header"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.type === "start_date"}
                  color="primary"
                  onChange={this.handleTypeChange("start_date", "panel_2")}
                />
              }
              label="Fixed period start"
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.textField}>
                  <KeyboardDatePicker
                    label="Date picker"
                    value={this.state.selectedDate}
                    format="DD/MM/YYYY"
                    onChange={this.handleDateChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </FormControl>
    );
  }
}

export default withStyles(styles)(PeriodRangeForm);

import React, { Component } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { formStyles } from "../../helpers/commonStyles";
import _isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import { updateS3bucket } from "../../actions/projects";

const styles = theme => ({
  ...formStyles(theme),
});

class S3BucketConfig extends Component {
  state = {
    dirty: false,
    s3bucket: {},
  };

  componentDidMount() {
    this.setState({ s3bucket: this.props.s3bucket || {} });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_isEqual(prevProps.s3bucket, this.props.s3bucket)) {
      this.setState({ s3bucket: this.props.s3bucket || {} });
    }
  }

  onChange = (k, v) => {
    let s3bucket = this.state.s3bucket;
    s3bucket[k] = v;
    this.setState({ s3bucket, dirty: true });
    if (!!this.props.onChange) {
      this.props.onChange(s3bucket);
    }
  };

  render() {
    const { classes, project, currentProgram } = this.props;
    const { s3bucket } = this.state;
    return (
      <Grid container>
        <Grid item xs={6}>
          <TextField
            className={classes.textField}
            label="Access Key Id"
            fullWidth
            margin="normal"
            value={"" + (s3bucket.access_key_id || "")}
            onChange={e => this.onChange("access_key_id", e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.textField}
            label="Secret Access Key (empty = not changed)"
            fullWidth
            margin="normal"
            type="password"
            value={"" + (s3bucket.secret_access_key || "")}
            onChange={e => this.onChange("secret_access_key", e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.textField}
            label="Region"
            fullWidth
            margin="normal"
            value={"" + (s3bucket.region || "")}
            onChange={e => this.onChange("region", e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.textField}
            label="Bucket"
            fullWidth
            margin="normal"
            value={"" + (s3bucket.bucket || "")}
            onChange={e => this.onChange("bucket", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.textField}
            label='File patterns (example: ["*-latest.csv", "%Y/%m/*-%Y-%m-%d.csv"])'
            fullWidth
            margin="normal"
            value={"" + (s3bucket.file_patterns || "")}
            onChange={e => this.onChange("file_patterns", e.target.value)}
          />
        </Grid>
        {this.props.withSave && (
          <Grid item xs={12}>
            <div className={classes.formFooter}>
              <Button
                color="primary"
                disabled={!this.state.dirty}
                variant="contained"
                onClick={e =>
                  this.props
                    .updateS3bucket(
                      currentProgram.id,
                      project,
                      this.state.s3bucket,
                    )
                    .then(this.setState({ dirty: false }))
                }
              >
                Save
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default connect(null, { updateS3bucket })(
  withStyles(styles)(S3BucketConfig),
);

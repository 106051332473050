import React, { Component, Fragment } from "react";
import { Grid, ListItem, Typography } from "@material-ui/core";

export class ExecutionStatusShow extends Component {
  render() {
    const { task } = this.props;
    return (
      <Fragment>
        {task.started_at && (
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <strong>Started at:</strong>
              </Grid>
              <Grid item xs={8}>
                <Typography>{task.started_at}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {task.paused_at && (
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <strong>Paused at:</strong>
              </Grid>
              <Grid item xs={8}>
                <Typography>{task.paused_at}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {task.completed_at && !task.failed_at && (
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <strong>Completed at:</strong>
              </Grid>
              <Grid item xs={8}>
                <Typography>{task.completed_at}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {task.failed_at && (
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <strong>Failed at:</strong>
              </Grid>
              <Grid item xs={8}>
                <Typography>{task.failed_at}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {task.fail_messages && task.fail_messages.length > 0 && (
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <strong>Failure(s):</strong>
              </Grid>
              <Grid item xs={8}>
                <Typography color="primary">{task.fail_messages}</Typography>
              </Grid>
            </Grid>
          </ListItem>
        )}
        {task.summary["latest_errors"] &&
          task.summary["latest_errors"].length > 0 && (
            <ListItem>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <strong>Error(s):</strong>
                </Grid>
                <Grid item xs={8}>
                  <Typography color="primary">
                    {task.summary["latest_errors"]}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          )}
      </Fragment>
    );
  }
}

export default ExecutionStatusShow;

import React, { Component, Fragment } from "react";
import { Chip, Divider, Grid, ListItem, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ExecutionStatusShow from "./ExecutionStatusShow";

const styles = theme => ({
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
  },
});

class DumpDataShow extends Component {
  render() {
    const { classes, task } = this.props;

    return (
      <Fragment>
        <ListItem>
          <Grid container spacing={3}>
            {!!task.summary["data_element_group_matching_name"] && (
              <Grid item xs={4}>
                <strong>Data Element Group matching:</strong>
              </Grid>
            )}
            {!!task.summary["data_element_group_matching_name"] && (
              <Grid item xs={8}>
                <Typography>
                  {task.summary["data_element_group_matching_name"]}
                </Typography>
              </Grid>
            )}
            {!!task.summary["data_set_matching_name"] && (
              <Grid item xs={4}>
                <strong>Data Set matching:</strong>
              </Grid>
            )}
            {!!task.summary["data_set_matching_name"] && (
              <Grid item xs={8}>
                <Typography>
                  {task.summary["data_set_matching_name"]}
                </Typography>
              </Grid>
            )}
            <Grid item xs={4}>
              <strong>Data Elements:</strong>
            </Grid>
            <Grid item xs={8}>
              <Typography>{task.summary["data_elements_count"]}</Typography>
            </Grid>
            <Grid item xs={4}>
              <strong>Organisation Units matching:</strong>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {task.summary["organisation_unit_group_matching_name"]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <strong>Organisation Units:</strong>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {task.summary["organisation_units_count"]}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <strong>Periods:</strong>
            </Grid>
            <Grid item xs={8}>
              <div className={classes.root}>
                {task.summary["periods"] &&
                  task.summary["periods"].map(function(period) {
                    return (
                      <Chip
                        key={period}
                        className={classes.chip}
                        label={period}
                      />
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </ListItem>
        <Divider light />
        <ExecutionStatusShow task={task} />
      </Fragment>
    );
  }
}

export default withStyles(styles)(DumpDataShow);

import React from "react";
import AppBarDefault from "../Shared/AppBarDefault";
import { Button } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink } from "react-router-dom";
import NewMatchingFormMeta from "../Shared/NewMatchingFormMeta";
import { connect } from "react-redux";
import { createNewCategoriesMatching } from "../../actions/categoriesMatchings";
import { leftIcon as styles } from "../../helpers/commonStyles";

function CategoriesMatchingNew(props) {
  const { classes, project, currentProgram } = props;

  return (
    <div>
      <AppBarDefault title="New Categories Matching">
        <Button
          color="secondary"
          aria-label="ArrowBack"
          component={NavLink}
          to={`/programs/${currentProgram.id}/projects/${project.id}/categories_matchings`}
        >
          <ArrowBack className={classes.leftIcon} />
          Categories matchings
        </Button>
      </AppBarDefault>
      <NewMatchingFormMeta
        project={project}
        currentProgram={currentProgram}
        onSubmit={props.createNewCategoriesMatching}
        type="categories"
        groupName="Category"
        childrenName="Category Options"
        flowApi="categoryFlows"
        doneUrl={`/programs/${
          !!currentProgram ? currentProgram.id : ""
        }/projects/${project.id}/categories_matchings`}
        comment="This matching defines the direction of the copy of data.
        You have to set the source of the data (and hence, the data element definition) on the left."
      />
    </div>
  );
}

const mapStateToProps = state => ({
  project: state.programs.currentProject,
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps, { createNewCategoriesMatching })(
  withStyles(styles)(CategoriesMatchingNew),
);

import React from "react";
import { Grid, withStyles, Tooltip, Fab } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import CategoryPreview from "./CategoryPreview";
const styles = theme => ({
  relative: {
    position: "relative",
  },
  linkTypeIndicator: {
    position: "absolute",
    right: -25,
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 2,
  },
});

function CategoriesPreviews({
  programId,
  projectId,
  categories,
  classes,
  tooltipText,
  target,
}) {
  return (
    <Grid container spacing={3}>
      {categories.map((cat, index) => (
        <Grid item xs={12} md={6} key={cat.id} className={classes.relative}>
          <CategoryPreview
            category={cat}
            programId={programId}
            projectId={projectId}
            side={index === 0 ? "left" : "right"}
            target={index === 0 ? target : null}
          />
          {index === 0 && (
            <Tooltip id="tooltip-fab" title={tooltipText} placement="top">
              <Fab
                color="primary"
                aria-label="add"
                className={classes.linkTypeIndicator}
              >
                <ArrowForward />
              </Fab>
            </Tooltip>
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default withStyles(styles)(CategoriesPreviews);

import React, { Component } from "react";
import { Radio } from "@material-ui/core";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import MatchingListItemCard from "./MatchingListItemCard";

const styles = theme => ({
  listItem: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  selected: {
    background: "#efefef",
  },
  label: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    // Remove grey highlight
    WebkitTapHighlightColor: theme.palette.common.transparent,
    marginLeft: -14,
    marginRight: theme.spacing(2), // used for row presentation of radio/checkbox
  },
  disabled: {
    color: theme.palette.text.disabled,
    cursor: "default",
  },
  labelContent: {
    userSelect: "none",
  },
});

class MatchingListItem extends Component {
  selectItem = () => {
    this.props.onSelect(this.props.index, this.props.item);
  };

  render() {
    const { classes, selected, style, key, item } = this.props;

    if (!!item) {
      const klasses = classNames(classes.listItem, {
        [classes.selected]: selected,
      });
      const radio = <Radio checked={selected} />;
      return (
        <div
          className={klasses}
          style={style}
          key={key}
          onClick={this.selectItem}
        >
          <label className={classes.label}>
            {radio}
            <div className={classes.labelContent}>
              <MatchingListItemCard {...item} />
            </div>
          </label>
        </div>
      );
    } else {
      return (
        <div className={classes.listItem} style={style} key={key}>
          Loading
        </div>
      );
    }
  }
}

export default withStyles(styles)(MatchingListItem);

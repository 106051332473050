import React from "react";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
});

function SectionHeader(props) {
  return (
    <Typography variant="h5" className={props.classes.root}>
      {props.children}
    </Typography>
  );
}

export default withStyles(styles)(SectionHeader);

import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";
import * as types from "../actionTypes";
import { stringify } from "query-string";
import _omitBy from "lodash/omitBy";
import _isUndefined from "lodash/isUndefined";

export function fetchDataElements(
  programId,
  projectId,
  sourceId,
  matchingType,
  matchingId,
  query,
  page,
  force = false,
) {
  const meta = { meta: { sourceId: sourceId, force: force } };
  const queryParams = {
    not_matched: true,
    q: query,
    page: page,
    per_page: 500,
  };

  const cleanedQueryParams = stringify(_omitBy(queryParams, _isUndefined));

  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/sources/${sourceId}/${matchingType}/${matchingId}/data_elements?${cleanedQueryParams}`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        { type: types.REQUEST_DATA_ELEMENTS, ...meta },
        { type: types.RECEIVE_DATA_ELEMENTS, ...meta },
        { type: types.REQUEST_DATA_ELEMENTS_FAILED, ...meta },
      ],
    },
  };
}

import {
  MenuItem,
  TextField,
  withStyles,
  Typography,
  LinearProgress,
  Box,
} from "@material-ui/core";
import React, { Component } from "react";

import { connect } from "react-redux";
import { fetchDataElementGroupsMatchings } from "../../actions/dataElementGroupsMatchings";
import { textField } from "../../helpers/commonStyles";

const styles = theme => ({
  ...textField(theme),
  menu: {
    width: 200,
  },
});

class DataElementGroupsMatchingsSelect extends Component {
  componentDidMount() {
    this.props.fetchDataElementGroupsMatchings(
      this.props.currentProgram.id,
      this.props.projectId,
      false,
    );
  }

  onChangeFunctions = e => {
    this.props.onChange(e);
    this.onChangeLabelFunction();
  };

  onChangeLabelFunction = () => {
    this.props.onChangeLabel(this.props.dataElementGroupsMatchings);
  };

  render() {
    const { value, classes, dataElementGroupsMatchings, label } = this.props;
    if (!dataElementGroupsMatchings.length)
      return (
        <Typography className={classes.textField}>
          Searching data element group matchings
          <Box sx={{ width: "80%" }}>
            <LinearProgress />
          </Box>
        </Typography>
      );
    return (
      <TextField
        select
        label={label || "Data element groups matching"}
        className={classes.textField}
        onChange={this.onChangeFunctions}
        value={value || ""}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
      >
        {dataElementGroupsMatchings &&
          dataElementGroupsMatchings.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
    );
  }
}

const leftMerge = degMatchings => {
  let mergedDegMatchings = {};
  for (var idx in degMatchings) {
    if (!!mergedDegMatchings[degMatchings[idx].left.id]) {
      mergedDegMatchings[degMatchings[idx].left.id] = {
        label: `${degMatchings[idx].left.name} - *`,
        value: degMatchings[idx].id,
      };
    } else {
      mergedDegMatchings[degMatchings[idx].left.id] = {
        label: degMatchings[idx].display_name,
        value: degMatchings[idx].id,
      };
    }
  }
  return Object.entries(mergedDegMatchings).map(matching => {
    return { label: matching[1].label, value: matching[1].value };
  });
};

const mapStateToProps = (state, props) => {
  let degMatchings = leftMerge(state.dataElementGroupsMatchings.entities);

  return {
    dataElementGroupsMatchings: degMatchings,
    currentProgram: state.programs.currentProgram,
  };
};

export default connect(mapStateToProps, { fetchDataElementGroupsMatchings })(
  withStyles(styles)(DataElementGroupsMatchingsSelect),
);

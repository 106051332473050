import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";
import HelpWrapper from "../../Shared/HelpWrapper";

const styles = theme => ({
  iconWithText: {
    color: "primary",
    display: "flex",
    alignItems: "center",
  },
});

function TaskStatus(props) {
  const { classes, icon, lbl, help } = props;
  return HelpWrapper({
    help: help,
    elt: (
      <Typography className={classes.iconWithText}>
        {icon}
        {lbl}
      </Typography>
    ),
  });
}

export default withStyles(styles)(TaskStatus);

import React, { Component, Fragment } from "react";
import { Divider, Grid, ListItem, Typography } from "@material-ui/core";
import ExecutionStatusShow from "./ExecutionStatusShow";

class ImportMetaShow extends Component {
  sided = (task, side, index) => (
    <Fragment key={index}>
      <Grid item xs={4}>
        <strong className="capitalize">{side} group:</strong>
      </Grid>
      <Grid item xs={8}>
        <Typography>{`${task.params["name"]}  `}</Typography>
      </Grid>
    </Fragment>
  );

  render() {
    const { task } = this.props;

    return (
      <Fragment>
        <ListItem>
          <Grid container spacing={3}>
            {[task.params["side"]].map((side, index) =>
              this.sided(task, side, index),
            )}
          </Grid>
        </ListItem>
        <Divider light />
        <ExecutionStatusShow task={task} />
      </Fragment>
    );
  }
}

export default ImportMetaShow;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBarDefault from "../Shared/AppBarDefault";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Button } from "@material-ui/core";
import TaskForm from "../Tasks/TaskForm";
import { createOrUpdateScheduler } from "../../actions/schedulers";
import { formStyles, leftIcon } from "../../helpers/commonStyles";

const styles = theme => ({
  ...formStyles(theme),
  ...leftIcon,
});

class SchedulerForm extends Component {
  onSubmit = scheduler => {
    this.props.createOrUpdateScheduler(
      this.props.currentProgram.id,
      this.props.projectId,
      {
        scheduler,
      },
    );
  };

  render() {
    const { classes, projectId, scheduler, currentProgram } = this.props;
    return (
      <div>
        <AppBarDefault
          title={
            !scheduler || !scheduler.id ? "New scheduler" : "Edit Scheduler"
          }
        >
          <Button
            color="secondary"
            aria-label="ArrowBack"
            component={NavLink}
            to={`/programs/${currentProgram.id}/projects/${projectId}/schedulers`}
          >
            <ArrowBack className={classes.leftIcon} />
          </Button>
        </AppBarDefault>
        <TaskForm
          immediate={false}
          projectId={projectId}
          entity={scheduler}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}
const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
});
export default connect(mapStateToProps, { createOrUpdateScheduler })(
  withStyles(styles)(SchedulerForm),
);

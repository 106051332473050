import { RSAA } from "redux-api-middleware";
import store from "../store";
import { baseApiUrl, authHeader } from "./api";
import * as types from "../actionTypes";

export function fetchCategoryCombosMatchings(programId, projectId) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/matchings/category_combos`,
      method: "GET",
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CATEGORY_COMBOS_MATCHINGS,
        types.RECEIVE_CATEGORY_COMBOS_MATCHINGS,
        types.REQUEST_CATEGORY_COMBOS_MATCHINGS_FAILED,
      ],
    },
  };
}

export function createNewCategoryCombosMatching(programId, projectId, payload) {
  return {
    [RSAA]: {
      endpoint: `${baseApiUrl}/programs/${programId}/projects/${projectId}/sources/${payload.left_source_id}/category_combo_flows`,
      method: "POST",
      body: JSON.stringify(payload),
      ...authHeader(store.getState().auth.token),
      types: [
        types.REQUEST_CREATE_CATEGORY_COMBOS_MATCHING,
        {
          type: types.RECEIVE_CREATE_CATEGORY_COMBOS_MATCHING,
          payload: (action, state, response) => {
            return response.json().then(payload => {
              return payload;
            });
          },
        },
        types.REQUEST_CREATE_CATEGORY_COMBOS_MATCHING_FAILED,
      ],
    },
  };
}

import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { PlayArrow } from "@material-ui/icons";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusRunning extends React.Component {
  render() {
    const { classes, withLabel, tooltip } = this.props;
    return (
      <TaskStatus
        lbl={withLabel ? "Running" : null}
        icon={<PlayArrow className={classes.iconLeft} />}
        help={tooltip}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusRunning);

import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { formStyles } from "../../helpers/commonStyles";
import ProcessingDialog from "./ProcessingDialog";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  ...formStyles(theme),
});

class CreateMatchingDialog extends Component {
  render() {
    const {
      classes: { primaryText, secondaryText },
      open,
      srcLeft,
      matchTo,
      srcRight,
      groupRight,
      toMap,
      processingErrors,
      closeProcessingDialog,
    } = this.props;
    return (
      <ProcessingDialog
        open={open}
        handleClose={closeProcessingDialog}
        errors={processingErrors}
      >
        <Typography className={secondaryText}>
          Adding
          <strong className={primaryText}>{` ${toMap} `}</strong>
          to Group
          <strong className={primaryText}>{` ${groupRight} `}</strong>
          in
          <strong className={primaryText}>{` ${srcRight} `}</strong>
          {!!matchTo && (
            <Fragment>
              and matching it to
              <strong className={primaryText}>{` ${matchTo} `}</strong>
              of
              <strong className={primaryText}>{` ${srcLeft} `}</strong>
            </Fragment>
          )}
        </Typography>
      </ProcessingDialog>
    );
  }
}

export default withStyles(styles)(CreateMatchingDialog);

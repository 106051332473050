import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  CircularProgress,
  FormControl,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Typography,
} from "@material-ui/core";
import _isEqual from "lodash/isEqual";
import _forOwn from "lodash/forOwn";
import Notifications from "../Shared/Notifications";
import { formStyles, leftIcon } from "../../helpers/commonStyles";
import LayoutContainer from "../Shared/LayoutContainer";
import { entityToForm, TASK_FORMS } from "./constants";
import { KeyboardDatePicker } from "@material-ui/pickers";
import HelpIcon from "@material-ui/icons/Help";
import {
  PUSH_DATA_FLOW,
  PUSH_ANALYTIC_FLOW,
  DUMP_DATA_FLOW,
} from "../Tasks/constants";
import _isUndefined from "lodash/isUndefined";
const styles = theme => ({
  ...formStyles(theme),
  ...leftIcon,
});

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toolTipInfo: "",
    };
  }

  componentDidMount() {
    this.setState({
      scopeMode: entityToForm(this.props.entity),
      entity: this.props.entity,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_isEqual(prevProps.entity, this.props.entity)) {
      this.setState({
        scopeMode: entityToForm(this.props.entity),
        entity: this.props.entity,
      });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    this.whichActionLaunched();
    this.setState({
      open: true,
    });
  };

  whichActionLaunched = () => {
    const pushdataInfo =
      "The preview data is not selected. If it isn’t selected you will not be able to check the data before pushing it on the target. We strongly recommend to select the preview option!";
    const compareMeta =
      "If the apply without confirmation is selected, it will apply directly differences on target, otherwise it will let the user to check the changes before confirm the apply ";
    switch (this.state.entity.sequencer_class) {
      case PUSH_DATA_FLOW:
        this.setState({ toolTipInfo: pushdataInfo });
        this.pushTaskParams();
        break;
      case DUMP_DATA_FLOW:
        this.setState({ toolTipInfo: pushdataInfo });
        this.pushTaskParams();
        break;
      case PUSH_ANALYTIC_FLOW:
        this.setState({ toolTipInfo: pushdataInfo });
        this.pushAnalyticTaskParams();
        break;
      default:
        this.setState({ toolTipInfo: compareMeta });
        this.compareTaskParams();
    }
  };

  pushTaskParams = () => {
    let params = [];
    _forOwn(this.state.entity.params, function(value, key) {
      if (value !== "") {
        if (key === "preview") {
          params.push({ key: `${key}_data`, value: value });
        } else {
          params.push({ key: key, value: value });
        }
      }
    });
    this.setState({
      params: params,
    });
  };

  pushAnalyticTaskParams = () => {
    let params = [];
    _forOwn(this.state.entity.params, function(value, key) {
      if (key === "preview") {
        params.push({ key: `${key}_data`, value: value });
      } else {
        params.push({ key: key, value: value });
      }
    });
    this.setState({
      params: params,
    });
  };

  compaParams = (key, value) => {
    let param = {};
    switch (key) {
      case "leftSourceGroup":
        param = { key: key, value: value["name"] };
        break;
      case "rightSourceGroup":
        param = { key: key, value: value["name"] };
        break;
      case "preview":
        param = {
          key: `${key}_meta`,
          value: <Checkbox color="primary" checked={value} />,
        };
        break;
      default:
    }
    return param;
  };

  compareTaskParams = () => {
    let sequencer_class = this.state.entity.sequencer_class;
    if (
      sequencer_class.includes("::Services::MetaCompareFlow") ||
      sequencer_class.includes("::Services::MetaSynchronizeFlow")
    ) {
      let params = [];
      const currentObject = this;
      _forOwn(this.state.entity.params, function(value, key) {
        if (!_isUndefined(value)) {
          if (Array.isArray(value)) {
            params.push({ key: key, value: value.join(", ") });
          } else {
            params.push(currentObject.compaParams(key, value));
          }
        }
      });
      this.setState({
        params: params,
      });
    }
  };

  confirmLaunch = () => {
    this.props.onSubmit(this.state.entity);
    this.setState({ open: false });
  };

  handleScopeModeChange = (event, mode) => {
    let entity = { ...this.state.entity };
    entity.sequencer_class = TASK_FORMS[mode].sequencer_class;
    entity.params = TASK_FORMS[mode].resetParams(this.props.immediate);
    this.setState({
      scopeMode: mode,
      entity,
    });
  };

  handleChange = (prop, value) => {
    let entity = { ...this.state.entity };
    entity[prop] = value;
    this.setState({ entity });
  };

  handleParamChange = (prop, value) => {
    let entity = { ...this.state.entity };
    entity.params[prop] = value;
    this.setState({ entity });
  };

  handleRemoveParam = prop => {
    let entity = { ...this.state.entity };
    delete entity.params[prop];
    this.setState({ entity });
  };

  handleChangeSequencerClassProp = props => {
    let entity = { ...this.state.entity };
    props.forEach(c => (entity[c.prop] = c.value));
    this.setState({ entity });
  };

  handleDateChange = prop => date => {
    let entity = { ...this.state.entity };
    entity[prop] = date.format("YYYY-MM-DD");
    this.setState({ entity });
  };

  handleChanges = (entityChanges, paramsChanges) => {
    let entity = { ...this.state.entity };
    entityChanges.forEach(c => (entity[c.prop] = c.value));
    paramsChanges.forEach(c => (entity.params[c.prop] = c.value));
    this.setState({ entity });
  };

  handleClose = () => {
    this.setState({
      params: [],
    });
    this.setState({ open: false });
  };

  getKet = key => {
    let keys = {
      compare_entities_params: "Entities params",
      compare_group_params: "Group compare params",
      leftSourceGroup: "Source Meta name",
      rightSourceGroup: "Right Meta name",
      preview_meta: "Apply changes without confirmation",
      deg_label: "Data element group",
      ds_label: "Data Set",
      end_date: "End date",
      last_updated: "Last updated date",
      oug_label: "Organisation unit group",
      compare_entities_cocs_params: "COC entities params",
      period_types: "Period types",
      preview_data: "Preview data to push",
      s3bucket: "S3 bucket",
      start_date: "Start date",
      last_updated_duration: "Last updated duration",
      compare_entities_ous_params: "OUG entities params",
    };

    return keys[key];
  };

  formatParamValue = (key, value) => {
    switch (key) {
      case "last_updated":
        return value.toString();
      case "last_updated_duration":
        return this.lastUpdatedDuration(value);
      case "period_types":
        return value.join(", ");
      case "preview_data":
        return (
          <Typography color="primary">
            <strong>{value ? "Selected" : "Unselected"}</strong>
          </Typography>
        );
      case "s3bucket":
        return this.s3Bucket(value);
      default:
        return value;
    }
  };

  s3Bucket = value => {
    return (
      <div>
        <b>{"access key id :"}</b>
        {value["access_key_id"]}
        <br />
        <b>{"bucket :"}</b>
        {value["bucket"]}
        <br />
        <b>{"file_patterns :"}</b>
        {value["file_patterns"]}
        <br />
        <b>{"region :"}</b>
        {value["region"]}
        <br />
      </div>
    );
  };

  lastUpdatedDuration = value => {
    let timeUnit = this.timeUnitConverter(value.slice(-1));
    return `${value.slice(0, -1)} ${timeUnit}`;
  };

  timeUnitConverter = chartTimeUnit => {
    switch (chartTimeUnit) {
      case "d":
        return "Days";
      case "h":
        return "Hours";
      default:
    }
  };

  entityForm = () => {
    const { classes, immediate, projectId } = this.props;
    let DetailsForm =
      !!this.state.scopeMode && TASK_FORMS[this.state.scopeMode].form;
    const keys_to_remove = [
      "data_set_matching_id",
      "data_element_group_matching_id",
      "organisation_unit_group_matching_id",
    ];
    const preview_param = this.state.entity.params.preview;
    return (
      <Fragment>
        <Tabs
          name="scope"
          value={this.state.scopeMode}
          onChange={this.handleScopeModeChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.cardTab}
        >
          {Object.keys(TASK_FORMS).map(k => (
            <Tab key={`TAB_${k}`} label={TASK_FORMS[k].label} value={k} />
          ))}
        </Tabs>
        <Notifications category="TaskForm" />
        <Grid container>
          {!immediate && (
            <Fragment>
              <Grid item xs={6}>
                <FormControl className={classes.dateField}>
                  <KeyboardDatePicker
                    label="First enqueing"
                    value={this.state.entity.activation_date}
                    format="DD/MM/YYYY"
                    onChange={d =>
                      d._isValid &&
                      this.handleDateChange(
                        "activation_date",
                        d.format("YYYY-MM-DD"),
                      )
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className={this.props.classes.textField}
                  name="cron"
                  label="Recurrence (cron: m h d M dayOfWeek), UTC-based"
                  fullWidth
                  margin="normal"
                  onChange={e =>
                    this.handleChange("cron_expression", e.target.value)
                  }
                  defaultValue={this.state.entity.cron_expression}
                />
              </Grid>
            </Fragment>
          )}
          {!!DetailsForm && (
            <Grid container spacing={3}>
              <DetailsForm
                projectId={projectId}
                entity={this.state.entity}
                immediate={immediate}
                handleChange={this.handleChange}
                handleDateChange={this.handleDateChange}
                handleParamChange={this.handleParamChange}
                handleRemoveParam={this.handleRemoveParam}
                handleChanges={this.handleChanges}
                handleChangeSequencerClassProp={
                  this.handleChangeSequencerClassProp
                }
              />
            </Grid>
          )}
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" align="center">
              {"Confirm or cancel the launch?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <TableContainer>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableBody>
                      {this.state.params &&
                        this.state.params.map(
                          row =>
                            !keys_to_remove.includes(row.key) && (
                              <TableRow>
                                <TableCell align="left">
                                  <b>{this.getKet(row.key)}</b>
                                </TableCell>
                                <TableCell align="left">
                                  {this.formatParamValue(row.key, row.value)}
                                </TableCell>
                              </TableRow>
                            ),
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {!preview_param && (
                  <Typography color="primary">
                    <HelpIcon />
                    <b>{this.state.toolTipInfo}</b>
                  </Typography>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div style={{ width: "100%" }}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Button
                      onClick={this.handleClose}
                      component="span"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    onClick={this.confirmLaunch}
                    autoFocus
                    component="span"
                    color="primary"
                    variant="outlined"
                  >
                    {immediate ? "Launch" : "Save"}
                  </Button>
                </Box>
              </div>
            </DialogActions>
          </Dialog>
          <Grid item xs={12}>
            <div className={classes.formFooter}>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  !TASK_FORMS[this.state.scopeMode].isValid(
                    immediate,
                    this.state.entity,
                  )
                }
                onClick={this.onSubmit.bind(this)}
              >
                {immediate ? "Launch" : "Save"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <LayoutContainer moreWideContent>
        <Grid item xs={12}>
          <p>
            Push data from source to data values on target, Push analytics from
            source to data values on target and Compare metadatas(OUGs, DEGs,
            DS, CC and Categories)
          </p>
          <Paper className={classes.paper}>
            {!this.state.entity && (
              <center>
                <CircularProgress size={24} />
              </center>
            )}
            {!!this.state.entity && this.entityForm()}
          </Paper>
        </Grid>
      </LayoutContainer>
    );
  }
}

export default withStyles(styles)(TaskForm);

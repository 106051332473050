import React from "react";
import PropTypes from "prop-types";

import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import {
  Cancel,
  DiscFull,
  MoreVert,
  Pause,
  PlayArrow,
  Replay,
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { fabButton } from "../../helpers/commonStyles";
import { post } from "../../actions/api";
import history from "../../history";
import { connect } from "react-redux";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
const styles = theme => ({
  ...fabButton(theme),
});

class ImmediateTaskActions extends React.Component {
  state = {
    open: false,
    hidden: false,
    openDialog: false,
  };

  handleVisibility = () => {
    this.setState(state => ({
      open: false,
      hidden: !state.hidden,
    }));
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true,
      });
    }
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  taskAction = (programId, projectId, task, action, callback) => {
    post(action, {
      programId: programId,
      projectId: projectId,
      id: task.id,
    }).then(callback);
  };

  afterAction = (programId, projectId) => {
    history.push(`/programs/${programId}/projects/${projectId}/tasks`);
  };

  beforeAction = () => {
    this.setState({
      openDialog: true,
    });
  };

  confirmDialog = (currentProgram, projectId, task, action, openDialog) => {
    return (
      <Dialog
        open={openDialog}
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" align="center">
          {"Proceed or cancel"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {action.actionWarning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ width: "100%" }}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Button
                  variant="outlined"
                  onClick={this.handleCloseDialog}
                  autoFocus
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    this.handleSubmitAction(
                      currentProgram.id,
                      projectId,
                      task,
                      action.actionType,
                    )
                  }
                  autoFocus
                >
                  Proceed
                </Button>
              </Box>
            </Box>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  handleSubmitAction = (programId, projectId, task, action) => {
    if (this.state.openDialog === true) {
      this.runAction(programId, projectId, task, action);
      this.setState({
        openDialog: false,
      });
    }
  };

  runAction = (programId, projectId, task, action) => {
    this.taskAction(programId, projectId, task, action, () => {
      this.afterAction(programId, projectId);
    });
  };

  availableActions = (programId, projectId, task) => {
    let actions = [];

    if (task.is_purging) return actions;

    if (task.is_relaunchable) {
      actions.push({
        onClick: () =>
          this.taskAction(
            programId,
            projectId,
            task,
            "relaunchImmediateTask",
            () => {
              this.afterAction(programId, projectId);
            },
          ),
        icon: <Replay />,
        tooltipTitle: "re-launch",
        actionType: "relaunchImmediateTask",
      });
    }

    if (task.is_pausable) {
      actions.push({
        onClick: () =>
          this.taskAction(
            programId,
            projectId,
            task,
            "pauseImmediateTask",
            () => {
              this.afterAction(programId, projectId);
            },
          ),
        icon: <Pause />,
        tooltipTitle: "pause",
        actionType: "pauseImmediateTask",
      });
    }

    if (task.is_resumable) {
      actions.push({
        onClick: () =>
          this.taskAction(
            programId,
            projectId,
            task,
            "resumeImmediateTask",
            () => {
              this.afterAction(programId, projectId);
            },
          ),
        icon: <PlayArrow />,
        tooltipTitle: "resume",
        actionType: "resumeImmediateTask",
      });
    }

    if (task.is_killable) {
      actions.push({
        onClick: () => this.beforeAction(),
        icon: <Cancel />,
        tooltipTitle: "Stop processing",
        actionType: "killImmediateTask",
        actionWarning:
          "It will stop the task and remove it from the queue. Do you want to stop it?",
      });
    }

    if (task.is_purgeable) {
      actions.push({
        onClick: () => this.beforeAction(),
        icon: <DiscFull />,
        tooltipTitle: "purge data",
        actionType: "purgeImmediateTask",
        actionWarning:
          "If you proceed it will delete all data stored in D2D database related to this task. Do you want to proceed?",
      });
    }

    return actions;
  };

  render() {
    const { classes, projectId, task, currentProgram } = this.props;
    const { hidden, open, openDialog } = this.state;
    let actions = this.availableActions(currentProgram.id, projectId, task);
    if (actions.length === 0) {
      return null;
    } else if (actions.length === 1) {
      let action = actions[0];
      return (
        <Fab
          color="primary"
          aria-label={action.tooltipTitle}
          className={classes.fabButton}
          onClick={action.onClick}
        >
          {actions[0].icon}
        </Fab>
      );
    } else if (actions.length > 1) {
      return (
        <div className={classes.root}>
          {actions.map((action, index) =>
            this.confirmDialog(
              currentProgram,
              projectId,
              task,
              action,
              openDialog,
            ),
          )}
          <SpeedDial
            ariaLabel="Actions"
            className={classes.fabButton}
            hidden={hidden}
            icon={<SpeedDialIcon icon={<MoreVert />} />}
            onBlur={this.handleClose}
            onClick={this.handleClick}
            onClose={this.handleClose}
            onFocus={this.handleOpen}
            onMouseEnter={this.handleOpen}
            onMouseLeave={this.handleClose}
            open={open}
          >
            {actions.map((action, index) => (
              <SpeedDialAction key={`${index}_action`} {...action} />
            ))}
          </SpeedDial>
        </div>
      );
    }
  }
}

ImmediateTaskActions.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps)(
  withStyles(styles)(ImmediateTaskActions),
);

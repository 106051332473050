import {
  REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS,
  RECEIVE_CATEGORY_COMBO_CATEGORIES_MATCHINGS,
  REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS_FAILED,
} from "../actionTypes";

function categoryComboCategoriesMatchings(
  state = {
    entities: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS:
      return Object.assign({}, state, {
        entities: [],
        isFetching: true,
      });
    case RECEIVE_CATEGORY_COMBO_CATEGORIES_MATCHINGS:
      const newEntities = action.payload.data;
      let entities = action.meta.force
        ? newEntities
        : [...state.entities, ...newEntities];

      return Object.assign({}, state, {
        entities,
        isFetching: false,
        meta: action.payload.meta,
      });
    case REQUEST_CATEGORY_COMBO_CATEGORIES_MATCHINGS_FAILED:
      return Object.assign({}, state, {
        entities: [],
        errors: action.payload.errors,
        isFetching: false,
      });
    default:
      return state;
  }
}

export default categoryComboCategoriesMatchings;

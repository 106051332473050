import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Grid,
  Chip,
} from "@material-ui/core";
import moment from "moment";
import { Edit, PlayArrow, Pause } from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import fadeIn from "react-animations/lib/fadeIn";
import SharedDeleter from "../Shared/SharedDeleter";
import { fetchSchedulers } from "../../actions/schedulers";

import { TASK_LABELS } from "../Tasks/constants";
import { META_TASKS_SCHEDULER_PREVIEW } from "./constants";

const styles = theme => ({
  "@keyframes fadeIn": fadeIn,
  root: {
    animationName: "fadeIn",
    animationDuration: "0.5s",
  },
  cardActions: {
    borderTop: "1px solid #efefef",
  },
  recurrence: {
    margin: "10px auto",
    textTransform: "capitalize",
  },
  grayed: {
    opacity: "0.3",
  },
  divider: {
    marginBottom: "15px",
    borderBottom: "1px solid #efefef",
  },
  chip: {
    height: "18px",
    marginRight: theme.spacing(),
    marginBottom: "5px",
  },
});

class SchedulerPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      toDestroy: false,
    };
  }

  handleClickResume = (programId, projectId, schedulerId, disabled_at) => e => {
    e.preventDefault();
    this.props.onClickResume(programId, projectId, schedulerId, disabled_at);
  };

  handleClickPause = (programId, projectId, schedulerId) => e => {
    e.preventDefault();
    this.props.onClickPause(programId, projectId, schedulerId);
  };

  render() {
    const { classes, projectId, scheduler, match, programId } = this.props;
    let Preview = META_TASKS_SCHEDULER_PREVIEW[scheduler.sequencer_class];
    return (
      <Card className={classes.root}>
        <CardContent className={scheduler.disabled_at ? classes.grayed : null}>
          <Grid container spacing={2}>
            {!!scheduler.sequencer_class && (
              <Grid item xs={12} className={classes.divider}>
                <Typography variant="h6">
                  {`${TASK_LABELS[scheduler.sequencer_class]} ${
                    scheduler.params.side !== undefined
                      ? scheduler.params.side
                      : ""
                  }`}
                </Typography>
              </Grid>
            )}
            <Grid item xs={4}>
              <Typography>Cron (UTC):</Typography>
            </Grid>
            <Grid item xs={8}>
              {!!scheduler.cron_expression && (
                <Typography>
                  <strong>{scheduler.cron_expression}</strong>
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography>Last run:</Typography>
            </Grid>
            <Grid item xs={8}>
              {!!scheduler.latest_run_at && (
                <Chip
                  className={classes.chip}
                  label={moment(scheduler.latest_run_at).format(
                    "DD/MM/YYYY HH:mm Z",
                  )}
                />
              )}
              {!scheduler.latest_run_at && "-"}
            </Grid>
            <Grid item xs={4}>
              <Typography>Next run:</Typography>
            </Grid>
            <Grid item xs={8}>
              {!!scheduler.next_date && (
                <Chip
                  className={classes.chip}
                  label={moment(scheduler.next_date)
                    .utc()
                    .format("DD/MM/YYYY HH:mm Z")}
                />
              )}
              {!scheduler.next_date && "-"}
            </Grid>
            <Grid item xs={12} className={classes.divider} />
            {!!Preview && (
              <Grid item xs={12}>
                <Preview scheduler={scheduler} />
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <IconButton component={Link} to={`${match.url}/${scheduler.id}/edit`}>
            <Edit />
          </IconButton>
          {scheduler.enabled && (
            <IconButton
              onClick={this.handleClickPause(
                programId,
                projectId,
                scheduler.id,
              )}
            >
              <Pause />
            </IconButton>
          )}
          {!scheduler.enabled && (
            <IconButton
              onClick={this.handleClickResume(
                programId,
                projectId,
                scheduler.id,
                scheduler.disabled_at,
              ).bind(this)}
            >
              <PlayArrow />
            </IconButton>
          )}
          <SharedDeleter
            api="schedulers"
            callback={() => this.props.fetchSchedulers(programId, projectId)}
            projectId={projectId}
            programId={programId}
            id={scheduler.id}
            message={`Are you sure you want to delete the scheduler ?`}
          />
        </CardActions>
      </Card>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ fetchSchedulers }, dispatch);
};

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(SchedulerPreview)),
);

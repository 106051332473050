import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Button,
  Tab,
  Tabs,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import DhisConnectorForm from "./DhisConnectorForm";
import LayoutContainer from "../Shared/LayoutContainer";
import { formStyles as styles } from "../../helpers/commonStyles";
import { addAddress, deleteAddress } from "../../actions/projects";
import _isEqual from "lodash/isEqual";
import S3BucketConfig from "./S3BucketConfig";
import Alert from "../Shared/Alert";

class GeneralForm extends Component {
  state = {
    dirty: false,
    emailDialogOpen: false,
    form: "main",
    addEmail: "",
    project: {
      name: "",
      left_source: {},
      right_source: {},
    },
  };

  onSubmit(e) {
    e.preventDefault();
    this.props.onSubmit({
      project: { ...this.state.project },
    });
  }

  handleChange = prop => event => {
    var project = { ...this.state.project };
    project[prop] = event.target.value;
    this.setState({ project });
    if (this.isDhisVersionExists(project)) {
      this.setState({ dirty: true });
    }
  };

  handleSourceChange = (source_name, params) => {
    var project = { ...this.state.project };
    project[source_name] = { ...project[source_name], ...params };
    this.setState({ project });
    if (this.isDhisVersionExists(project)) {
      this.setState({ dirty: true });
    }
  };

  isDhisVersionExists = project => {
    return (
      (project["left_source"]["dhis_version_display"] &&
        project["right_source"]["dhis_version_display"]) ||
      (project["left_source"]["dhis_version"] &&
        project["right_source"]["dhis_version"])
    );
  };

  handleDeleteEmail = emailId => {
    this.props
      .deleteAddress(this.props.currentProgram.id, this.props.project, emailId)
      .then(() => {
        var project = { ...this.state.project };
        project.emails = project.emails.filter(email => email.id !== emailId);
        this.setState({ project });
      });
  };

  handleCloseEmailDialog = () => {
    this.setState({ emailDialogOpen: false });
  };

  addEmail = () => {
    this.props
      .addAddress(
        this.props.currentProgram.id,
        this.props.project,
        this.state.addEmail,
      )
      .then(() => {
        var project = { ...this.state.project };
        project.emails.push({ address: this.state.addEmail });
        this.setState({ project, addEmail: "", emailDialogOpen: false });
      });
  };

  componentDidMount() {
    this.setState({ project: this.props.project });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_isEqual(prevProps.project, this.props.project)) {
      this.setState({ project: this.props.project });
    }
  }

  render() {
    const { classes, currentProgram } = this.props;
    const { project } = this.state;
    return (
      <LayoutContainer>
        {!!this.props.s3updateError && (
          <Grid item xs={12}>
            <Alert type="danger">
              {`Failed to update s3 config: ${this.props.s3updateError}`}
            </Alert>
          </Grid>
        )}
        <Dialog
          open={this.state.emailDialogOpen}
          onClose={this.handleCloseEmailDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Add email address to project
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              onChange={v => this.setState({ addEmail: v.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseEmailDialog}>Cancel</Button>
            <Button onClick={this.addEmail} color="primary" variant="contained">
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item xs={12}>
          <TextField
            className={classes.textField}
            name="project_name"
            label="Project name"
            fullWidth
            margin="normal"
            onChange={this.handleChange("name")}
            value={project.name}
          />
        </Grid>
        {!this.isDhisVersionExists(project) && (
          <Grid item xs={12}>
            <Typography variant="h6">
              To save the new project, the test connection for both source and
              target DHIS2 must succeed.
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Tabs
                  name="form"
                  value={this.state.form}
                  onChange={(e, v) => this.setState({ form: v })}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="DHIS2 Connections" value="main" />
                  {!!project.id && (
                    <Tab label="Emails and AWS S3" value="ext" />
                  )}
                </Tabs>
                <Divider />
              </Grid>
              {this.state.form === "main" && (
                <Fragment>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">DHIS2 Source:</Typography>
                    <Divider />
                    <DhisConnectorForm
                      sourceType="leftSource"
                      source={project.left_source}
                      handleSourceChange={params =>
                        this.handleSourceChange("left_source", params)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h6">DHIS2 Target:</Typography>
                    <Divider />
                    <DhisConnectorForm
                      sourceType="rightSource"
                      source={project.right_source}
                      handleSourceChange={params =>
                        this.handleSourceChange("right_source", params)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.formFooter}>
                      <Button
                        color="primary"
                        disabled={!this.state.dirty}
                        variant="contained"
                        onClick={this.onSubmit.bind(this)}
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </Fragment>
              )}
              {this.state.form === "ext" && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h6">Contact addresses</Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {!!project.emails &&
                      project.emails.map((email, i) => (
                        <Chip
                          key={`email-${i}`}
                          className={classes.chip}
                          label={email.address}
                          onDelete={e => this.handleDeleteEmail(email.id)}
                        />
                      ))}
                    <IconButton
                      onClick={e => this.setState({ emailDialogOpen: true })}
                      edge="end"
                    >
                      <AddIcon />
                    </IconButton>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">AWS S3 config</Typography>
                    <Divider />
                    <S3BucketConfig
                      currentProgram={currentProgram}
                      project={project}
                      s3bucket={project.s3bucket}
                      withSave={true}
                    />
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Paper>
        </Grid>
      </LayoutContainer>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
  s3updateError: state.programs.s3updateError,
});

export default connect(mapStateToProps, { addAddress, deleteAddress })(
  withStyles(styles)(GeneralForm),
);

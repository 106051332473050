import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import MailIcon from "@material-ui/icons/Mail";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import Visibility from "@material-ui/icons/Visibility";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import TaskStatusFactory from "./TaskStatusFactory";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import history from "../../history";
import { connect } from "react-redux";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import { TASK_SHOW_LAYOUTS, TASK_PREVIEW, taskToUpdate } from "./constants";

const styles = theme => ({
  danger: {
    color: theme.palette.primary[500],
  },
  text: {
    color: "#666",
  },
  root: {
    textDecoration: "none",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  badge: {
    zIndex: 0,
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(1.5),
  },
});

function TaskRow(props) {
  const { row, column, projectId, currentProgram } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let content;
  const isStatus = column.name === "status";
  const danger = row.danger;
  const handleClickOpenPreview = compare_task => {
    props.handleClickOpenPreview(compare_task);
  };

  const handleClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function taskDetail(currentProgram, projectId, taskId) {
    history.push(
      `/programs/${
        !!currentProgram ? currentProgram.id : ""
      }/projects/${projectId}/tasks/${taskId}/show`,
    );
    setAnchorEl(null);
  }

  function taskClone(currentProgram, projectId, taskId) {
    history.push(
      `/programs/${
        !!currentProgram ? currentProgram.id : ""
      }/projects/${projectId}/tasks/${taskId}/edit`,
    );
  }

  if (isStatus) {
    content = row.task.email_result ? (
      <Link onClick={props.handleClickOpen}>
        <Badge
          className={props.classes.badge}
          badgeContent={
            <Avatar variant="square" className={props.classes.small}>
              <MailIcon color="action" />
            </Avatar>
          }
        >
          <TaskStatusFactory task={row.task} badge_message=", mail sent" />
        </Badge>
      </Link>
    ) : row.task.is_waiting ? (
      <Link
        onClick={() =>
          handleClickOpenPreview(TASK_PREVIEW[row.task.sequencer_class])
        }
      >
        <Badge
          className={props.classes.badge}
          badgeContent={
            <Avatar className={props.classes.small}>
              <Visibility color="action" />
            </Avatar>
          }
        >
          <TaskStatusFactory task={row.task} badge_message=" to be previewed" />
        </Badge>
      </Link>
    ) : (
      <TaskStatusFactory task={row.task} />
    );
  } else {
    content = row[column.name];
  }

  return !!TASK_SHOW_LAYOUTS[row["task"]["sequencer_class"]] ? (
    column.name === "actions" ? (
      <Fragment>
        <Link onClick={handleClick}>{content}</Link>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() =>
              taskDetail(currentProgram, projectId, row["task"]["id"])
            }
          >
            <ListItemIcon>
              <VisibilityOutlined />
            </ListItemIcon>
            <Typography variant="inherit">
              <b>Show task</b>
            </Typography>
          </MenuItem>
          {taskToUpdate.includes(row["task"]["sequencer_class"]) && (
            <div>
              <Divider />
              <MenuItem
                onClick={() =>
                  taskClone(currentProgram, projectId, row["task"]["id"])
                }
              >
                <ListItemIcon>
                  <FileCopyOutlinedIcon />
                </ListItemIcon>
                <Typography variant="inherit">
                  <b>Clone task</b>
                </Typography>
              </MenuItem>
            </div>
          )}
        </Menu>
      </Fragment>
    ) : (
      <Link
        to={`/programs/${
          !!currentProgram ? currentProgram.id : ""
        }/projects/${projectId}/tasks/${row["task"]["id"]}/show`}
        className={classNames(
          props.classes.root,
          { [props.classes.text]: !danger },
          { [props.classes.danger]: danger },
          { [props.classes.noWrap]: column.name === "startDate" },
        )}
      >
        {content}
      </Link>
    )
  ) : (
    <Fragment>{content}</Fragment>
  );
}
const mapStateToProps = (state, props) => ({
  currentProgram: state.programs.currentProgram,
});

export default connect(mapStateToProps)(withStyles(styles)(TaskRow));

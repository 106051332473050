import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { ErrorOutline } from "@material-ui/icons";
import TaskStatus from "./TaskStatus";

const styles = theme => ({
  iconLeft: {
    marginRight: theme.spacing(),
  },
});

class TaskStatusFailedAndPurged extends React.Component {
  render() {
    const { classes, withLabel, error } = this.props;
    return (
      <TaskStatus
        help={error}
        lbl={withLabel ? "Failed - purged" : null}
        icon={<ErrorOutline className={classes.iconLeft} />}
      />
    );
  }
}

export default withStyles(styles)(TaskStatusFailedAndPurged);

import React, { Component, Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import LayoutContainer from "../Shared/LayoutContainer";
import Alert from "../Shared/Alert";
import SectionHeader from "../Shared/SectionHeader";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  createNewDataElementsMatching,
  destroyDataElementsMatching,
  fetchDataElementsMatchings,
} from "../../actions/dataElementsMatchings";
import {
  refreshDataElementsMatchingMasterList,
  dataElementsMatchingMasterListRefreshed,
  matchingsListRefreshed,
} from "../../actions/ui";
import { get } from "../../actions/api";
import { fetchDataElements } from "../../actions/dataElements";
import ConfirmDialog from "../Shared/ConfirmDialog";
import UnmatchedNewSection from "../Shared/UnmatchedNewSection";
import SearcheableMatchingTable from "../Shared/SearcheableMatchingTable";
import _values from "lodash/values";
import { paper as styles } from "../../helpers/commonStyles";
import DataElementGroupsPreviews from "./DataElementGroupsPreviews";
import _isEqual from "lodash/isEqual";

const selectedDataElementsEmptyState = {
  selectedDataElementsIds: {
    left: { id: null, index: null },
    right: { id: null, index: null },
  },
};

class DataElementGroupsMatchingEditForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ...selectedDataElementsEmptyState,
      dataElementMatchingQuery: undefined,
      confirmDestroyOpen: false,
      searchedItems: [],
      searchedItemsCount: 0,
      searchParams: "",
      existingMatchingRowCount: 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !_isEqual(
        prevProps.existingMatchingRowCount,
        this.props.existingMatchingRowCount,
      )
    ) {
      this.setState((state, props) => ({
        existingMatchingRowCount: props.existingMatchingRowCount,
      }));
    }
  }

  handleFetchDataElements = params => {
    const { sourceId, query, page, master, forceReload } = params;
    const {
      projectId,
      programId,
      degMatching: { left: dataElementGroupLeft, right: dataElementGroupRight },
    } = this.props;
    const dataElementGroup = master
      ? dataElementGroupLeft
      : dataElementGroupRight;
    this.props.fetchDataElements(
      programId,
      projectId,
      sourceId,
      "data_element_groups",
      dataElementGroup.id,
      query,
      page,
      forceReload,
    );
  };

  closeConfirmDestroyDialog = () => {
    this.setState({
      confirmDestroyOpen: false,
    });
  };

  confirmDestroyContent = () => {
    return (
      <Fragment>
        <Typography>
          This data element matching is shared by the following data element
          groups matchings:
        </Typography>
        <Typography color="primary">
          {this.state.confirmDestroyWarnings &&
            this.state.confirmDestroyWarnings.join(", ")}
        </Typography>
        <Typography>
          Do you want to delete the data element matching in all these data
          element group matchings?
        </Typography>
      </Fragment>
    );
  };

  confirmDestroyDialog = () => {
    return (
      <ConfirmDialog
        handleConfirm={this.destroyDataElementMatching}
        handleClose={this.closeConfirmDestroyDialog}
        open={this.state.confirmDestroyOpen}
      >
        {this.confirmDestroyContent()}
      </ConfirmDialog>
    );
  };

  destroyDataElementMatching = () => {
    this.props.destroyDataElementsMatching(
      this.props.programId,
      this.props.projectId,
      "data_element_groups",
      "data_element_group_matching",
      this.props.degMatching,
      this.state.matchingIdToDestroy,
    );
    this.closeConfirmDestroyDialog();
  };

  handleDataElementMatchingDestroy = matchingIdToDestroy => {
    get("destroyDataElementsMatchingPrev", {
      programId: this.props.programId,
      projectId: this.props.projectId,
      id: matchingIdToDestroy,
    }).then(groups => {
      if (groups.length > 1) {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
          confirmDestroyWarnings: groups,
          confirmDestroyOpen: true,
        });
      } else {
        this.setState({
          matchingIdToDestroy: matchingIdToDestroy,
        });
        this.destroyDataElementMatching();
      }
    });
  };

  search_mappings = params => {
    const searched =
      params === ""
        ? []
        : this.props.dataElementsLeft.filter(function(el) {
            return (
              el["name"].toLowerCase().includes(params.toLowerCase()) ||
              el["dhis_id"].toLowerCase().includes(params.toLowerCase())
            );
          });
    this.setState({
      searchedItems: searched,
      searchedItemsCount: searched.length,
      searchParams: params,
    });
  };

  searched_results = () => {
    return this.state.searchParams
      ? this.state.searchedItems
      : this.props.dataElementsLeft;
  };

  searched_results_count = () => {
    return this.state.searchParams
      ? this.state.searchedItemsCount
      : this.props.dataElementsLeftRowCount;
  };

  handleCountChange = (dangling, count, searchParams) => {
    if (searchParams) {
      this.setState({ existingMatchingRowCount: count });
    } else {
      this.setState((state, props) => ({
        existingMatchingRowCount: props.existingMatchingRowCount,
      }));
    }
  };

  render() {
    const {
      degMatching: { left: dataElementGroupLeft, right: dataElementGroupRight },
      classes,
      projectId,
      programId,
    } = this.props;

    const degItemsCountDontMatch =
      dataElementGroupLeft.elements_count !==
      dataElementGroupRight.elements_count;

    return (
      <LayoutContainer>
        {this.confirmDestroyDialog()}
        <Grid item xs={12}>
          <p>
            Map all data elements to the corresponding one on the other dhis2.
            <br />
            This matching is directional and defines which data pushes data
            values to the other.
            <br />
            The source is on the left.
            <br />
          </p>
          {degItemsCountDontMatch && (
            <Alert type="danger">
              Data elements count in each group don’t match. Please verify that
              both group are identical in both Dhis2.
            </Alert>
          )}

          <DataElementGroupsPreviews
            programId={programId}
            projectId={projectId}
            groups={[dataElementGroupLeft, dataElementGroupRight]}
            tooltipText={`The values will be pushed from ${dataElementGroupLeft.source.name} to ${dataElementGroupRight.source.name}`}
            target={dataElementGroupRight.source}
          />

          <SectionHeader>
            Unmatched Data Elements ({this.searched_results_count()})
          </SectionHeader>
          <UnmatchedNewSection
            search_mappings={this.search_mappings}
            projectId={this.props.projectId}
            programId={this.props.programId}
            itemTypeLabel="Data Element"
            matching={this.props.degMatching}
            itemsLeft={this.searched_results()}
            itemsLeftRowCount={this.searched_results_count()}
            itemsLeftFetching={this.props.dataElementsLeftFetching}
            fetchItems={this.handleFetchDataElements}
            itemsRight={this.props.dataElementsRight}
            knownMatch={true}
            itemsRightRowCount={this.props.dataElementsRightRowCount}
            itemsRightFetching={this.props.dataElementsRightFetching}
            matchingType="data_element_groups"
            createItemsMatching={this.props.createNewDataElementsMatching}
            taskMatcher="task"
            matchingMasterListRefreshed={this.props.refreshDataElements}
            itemClonesApi="dataElementClones"
            sequencer_class="::Services::MetaMatchFlow::MatchDegMetaFlow"
          />

          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.paper}>
              <SearcheableMatchingTable
                projectId={this.props.projectId}
                destroyMatching={this.handleDataElementMatchingDestroy}
                matchingType="data_element_group_matching"
                matchingId={this.props.degMatching.id}
                fetchMetaMachings={(payload, force) =>
                  this.props.fetchDataElementsMatchings(
                    this.props.programId,
                    this.props.projectId,
                    "data_element_group_matching",
                    this.props.degMatching.id,
                    payload,
                    force,
                  )
                }
                matchingsListRefreshed={this.props.matchingsListRefreshed}
                metaTypeMatching="dataElementsMatchings"
                sectionHeaderLabel="Matched Data Elements"
              />
            </Grid>
          </Grid>
        </Grid>
      </LayoutContainer>
    );
  }
}

const dataElementsForSource = (dataElements, sourceId) => {
  return dataElements[sourceId] && dataElements[sourceId].entities
    ? _values(dataElements[sourceId].entities)
    : [];
};

const dataElementsMetaForSource = (dataElements, sourceId) => {
  return dataElements[sourceId] && dataElements[sourceId].meta !== undefined
    ? dataElements[sourceId].meta
    : { total_count: 0 };
};

const isFetching = (dataElements, sourceId) => {
  return dataElements[sourceId] && dataElements[sourceId].isFetching;
};

const mapStateToProps = (state, props) => {
  const {
    degMatching: { left: dataElementGroupLeft, right: dataElementGroupRight },
  } = props;

  const dataElementsLeftMeta = dataElementsMetaForSource(
    state.dataElements,
    dataElementGroupLeft.source.id,
  );
  const dataElementsRightMeta = dataElementsMetaForSource(
    state.dataElements,
    dataElementGroupRight.source.id,
  );

  const dataElementsLeftRowCount = dataElementsLeftMeta.total_count;
  const dataElementsRightRowCount = dataElementsRightMeta.total_count;

  const dataElementsLeftFetching = isFetching(
    state.dataElements,
    dataElementGroupLeft.source.id,
  );
  const dataElementsRightFetching = isFetching(
    state.dataElements,
    dataElementGroupRight.source.id,
  );

  const existingMatchingRowCount =
    state.dataElementsMatchings.meta.total_count || 0;

  return {
    programId: state.programs.currentProgram.id,
    projectId: state.programs.currentProject.id,
    dataElementsLeft: dataElementsForSource(
      state.dataElements,
      dataElementGroupLeft.source.id,
    ),
    dataElementsRight: dataElementsForSource(
      state.dataElements,
      dataElementGroupRight.source.id,
    ),
    dataElementsLeftMeta,
    dataElementsRightMeta,
    dataElementsLeftRowCount,
    dataElementsRightRowCount,
    dataElementsLeftFetching,
    dataElementsRightFetching,
    existingMatchingRowCount,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchDataElements,
      createNewDataElementsMatching,
      destroyDataElementsMatching,
      refreshDataElementsMatchingMasterList,
      dataElementsMatchingMasterListRefreshed,
      fetchDataElementsMatchings,
      matchingsListRefreshed,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(DataElementGroupsMatchingEditForm)));

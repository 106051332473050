import React from "react";
import { AppBar, Grid, Toolbar, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {
    width: "100%",
  },
  bar: {
    height: 64,
  },
  container: {
    height: "100%",
  },
  arrow: {
    position: "absolute",
    marginLeft: "300px",
    left: 0,
  },
});

function AppBarDefault(props) {
  const { classes, title } = props;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.bar}>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.arrow}>
            <Toolbar>{props.children}</Toolbar>
          </Grid>
          <Grid item>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(AppBarDefault);
